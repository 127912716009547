import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { SidebarService } from './sidebar.service';

import { Router, ActivatedRoute } from '@angular/router';
import { setTNodeAndViewData } from '@angular/core/src/render3/state';
import { BaseComponent } from '../core/base.component';
import { B2CAuthService } from '../auth/b2cauth.service';
import { GlobaldataService } from '../service/globaldata.service';
import { HomeService } from '../home/home.service';
// import { MenusService } from './menus.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    animations: [
        trigger('slide', [
            state('up', style({ height: 0 })),
            state('down', style({ height: '*' })),
            transition('up <=> down', animate(200))
        ])
    ]
})
export class SidebarComponent extends BaseComponent implements OnInit {
    Redirect: string;

    menus = [];
    role: string = "ALL";


    constructor(public sidebarservice: SidebarService,
        public auth: B2CAuthService,
        protected _router: Router,
        private activeRouter: ActivatedRoute,
        private globaldataservice: GlobaldataService,
        private homeservice: HomeService
    ) {
        super();
       // this.menus = sidebarservice.getMenuList();
        this.Redirect = "counter";
    }

    ngOnInit() {

        let test: any;

        test = this.auth.username;
        test = this.auth.userProfile;
        test = this.auth.user;

        // this.auth.role = "guest";

        this.getRole();

/*        this.menus = this.sidebarservice.getMenuList(this.auth.role);*/
    }


    getRole() {
        console.log("First {0}, Second {1}, Third {2}, Fourth {3}", this.auth.username, this.auth.useremail, this.auth.userobjectid, this.auth.role)
        this.homeservice.getRole(this.auth.username)
            .subscribe(
                (data) => {
                    this.getRoleCallBack(data);
                });

    }

    getRoleCallBack(data: any) {

        // console.log("getRoleCallBack: role =" + data.name);
        this.auth.role = data.name;
        this.auth.role = this.auth.role.toUpperCase();
        this.globaldataservice.role = this.auth.role;
        this.menus = this.sidebarservice.getMenuList(this.auth.role);
    }

    getSideBarState() {
        return this.sidebarservice.getSidebarState();
    }

    toggle(currentMenu) {

        if (currentMenu.id == 'logout') {
            this.auth.logout();
        }

        if (currentMenu.id == 'admin') {
            //if (!this.isadminuser)
            //    return;
        }

        this.menus.forEach(element => {
            if (element !== currentMenu) {
                element.active = false;
                element.colorreset = false;
            }



            if (element.submenus) {

                let result: any;

                for (var k in element.submenus) {

                    element.submenus[k].active = false;

                }

            }

        });
        currentMenu.active = true;
        this.globaldataservice.employeazesetup = false;
        if (currentMenu.id == "nfor1customer" && currentMenu.parentid == "admin")
            this.globaldataservice.employeazesetup = true;
        

        if (currentMenu.parentid) {
            var element = this.getparentmenu(currentMenu.parentid);
            // this.sidebarselectioncolor(false);
            if (element !== undefined) {
                element.active = true;
                element.colorreset = true;
            }
        }

    }

    getparentmenu(menuid): any {
        var menu;
        for (var k in this.menus) {

            if (this.menus[k].id == menuid) {
                menu = this.menus[k];
                return menu;
            }
        }
        return null;

    }

    getState(currentMenu) {
       
        if (currentMenu.active) {
              return 'down';
        } else {
            return 'up';
        }
    }

    hasBackgroundImage() {
        return this.sidebarservice.hasBackgroundImage;
    }

}
