﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../core/environment.service';
import { ExtendedHttpService } from '../core/extendedhttp.service';
import { HttpHeaders } from '@angular/common/http';
import { Http } from '@angular/http';
import { catchError, map } from 'rxjs/operators';
import { ICQuoteDTO } from '../dto/ICQuoteDTO';



const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
@Injectable({
    providedIn: 'root',

})
export class ACAPlanLookupService extends ExtendedHttpService {

    constructor(private env: EnvironmentService) {
        super();
    }


    getPlansForServiceArea(zipcode: string) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/ACAUniquePlans/' + zipcode).
            pipe(
                map((data) => data.json())
            );
    }


    // 26 JUL 21 JJM This function is never called.
    getPlansForServiceAreaByRole(zipcode: string, role: string) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/ACAUniquePlans/' + zipcode + '/' + role).
            pipe(
                map((data) => data.json())
            );
    }

    getPlansForServiceAreaWithUserId(zipcode: string, userobjectid: string, planyear: string, countyname: string) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/ACAUniquePlans/' + zipcode + '/' + userobjectid + '/' + planyear + '/' + countyname).
            pipe(
                map((data) => data.json())
            );
    }


    getPlansAndRates(planids: string, ratingareaid: string): Observable<any> {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/ACAPlansAndRates/' + planids + '/' + ratingareaid + '/2022').
            pipe(
                map((data) => data.json())
            );
    }

    getRatesForComparison(planids: string, ratingareaid: string, plancostcurrent: number, employercontributioncurrent: number, employeecontributioncurrent: number) {
    // NOTE: TODO JJM 18 Sep 2020 This service is also implemented in the acaplanlookup.service.  Should consolidate to this implementation

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/PlanCostCompare/' + planids + '/' + ratingareaid + '/' + plancostcurrent + '/' + employercontributioncurrent + '/'
            + employeecontributioncurrent).
            pipe(
                map((data) => data.json())
            );

    }

    getAllPlanCostsbyCensus(zipcode: string, userobjectid: string, planyear: string, currentpremium: number, currentmedicarecost: number, numberofemployees: number, countyname: string, ageunits: string) {
        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/ACAUniquePlans/GetPlanRatesWithCensus/' + zipcode + '/' + userobjectid + '/' + planyear + '/' + currentpremium
            + '/' + currentmedicarecost + '/' + numberofemployees + '/' + countyname + '/' + ageunits).
            pipe(
                map((data) => data.json())
            );

    }

    //postIndivdualCoverageByUnitsQuote(planids: string, totalmonthlycost: number, medicarecost: number, effectivedate: string,
    //    postalcode: string, ratingarea: string, employername: string, quotename: string, quotestatus: string, ownerid: string, unitsbyage: string) {


    //    return this.postData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/PlanCostCompare/', planids + '/' + totalmonthlycost  + '/' + medicarecost + '/'
    //        + effectivedate + '/' + postalcode + '/' + ratingarea + '/' + employername + '/' + quotename + '/' + quotestatus + '/' + ownerid + '/' + unitsbyage).
    //        pipe(
    //            map((data) => data.json())
    //        );
        


    //}

    postIndivdualCoverageByUnitsQuote(icquotedto: ICQuoteDTO) {


        return this.postData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/PlanCostCompare/', icquotedto).
            pipe(
                map((data) => data.json())
            );


    }


}
