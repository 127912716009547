import { Component, OnInit, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { B2CAuthService } from '../auth/b2cauth.service';
import { MsalService } from '../auth/msal.service';
import { appInjector } from '../core/util.service';
import { IpAddressService } from '../core/ipaddress.service';
import { LoginDetailDTO } from '../dto/LoginDetailDTO';
import { LoginService } from '../core/login.service';
import { Observable, forkJoin } from 'rxjs';
import { RedirectService } from '../auth/redirect.service';

// Fetch API function
interface HeaderObj {
    [key: string]: string;
  }
  
const getMember = async (headerObj: HeaderObj): Promise<any> => {
    try {
        const response = await fetch(
        'https://enrichlyhr-api-app.azurewebsites.net/api/Member/GetMember',
        {
            method: 'GET',
            headers: headerObj
        }
        );

        if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching member data:', error);
        throw error;
    }
};

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    providers: [
        IpAddressService,
        LoginService

    ]

})
export class HomeComponent implements OnInit {
    logindetaildto: LoginDetailDTO;

    constructor(private router: Router,
        public auth: B2CAuthService,
        private msalService: MsalService,
        private ipAddressService: IpAddressService,
        private loginService: LoginService


    ) {

        var tg = 10;

    }

    ngOnInit(): void {
        if (sessionStorage.getItem('token')) {
            var user = sessionStorage.getItem('user');
            if (user) {
                this.auth.oncallBackLogin(user, sessionStorage.getItem('token'), false);
                sessionStorage.clear()
                // this.router.navigate(['/dashboard']);
                this.ipaddressprocess();
            }
            //this.tkevents.onLogin.emit(sessionStorage.getItem('token'));
            ////redirect.callbackhandler();
        }
    }


    dologin(): void {

     this.auth.login(this);
        //.then(user => {
        //    alert('hi');
        //     if (user) {
        //         this.auth.oncallBackLogin(user, false);                     
        //        // this.router.navigate(['/dashboard']);
        //         this.ipaddressprocess();

        //    } else {
        //         this.auth.oncallBackLogin(null, true);    
        //    }
        //}, () => {
        //         this.auth.oncallBackLogin(null, true);    
        //});       
    }

    private ipaddressprocess() {

        let observableBatch = [];

        // creating a batch object

        // getting statuses
        // decodeURIComponent(encodedURI_string)
        // JJM 4/23/2024 commented out these calls
        /*
        observableBatch.push(this.loginService.getuserpermissions(this.auth.accesstoken));

        observableBatch.push(this.ipAddressService.getIpAddress());


        forkJoin(observableBatch)
            .subscribe(data => {
                this.batchResponse(data);

            });
          */  
        this.logindetaildto = new LoginDetailDTO();


        // this.logindetaildto.userdetails = JSON.stringify(data[1]);


        //     this.logindetaildto.userid = permissions.userdto.id;
        //     this.auth.setemailandobjectid(permissions.userdto.id, permissions.userdto.objectid);

        // JJM 4/23/2024 Commented out the above two lines
        // this.logindetaildto.userid = "jerrymarkland@outlook.com";
        // this.auth.setemailandobjectid(this.auth.username, "BD9AA04F-7584-4E12-92DC-4C9740C8CA41");
        // this.auth.setemailandobjectid(this.auth.username, "792ee5e6-4f6e-4f49-b461-8844649eb20e");
        
        console.log("THis is authData ", this.auth);
        sessionStorage.setItem("auth_token",this.auth.accesstoken);
        this.fetchMemberData();
        // this.auth.role = "admin";
        // this.auth.username = "jerrymarkland@outlook.com";
        // if (this.auth.role === "GUEST")
        //     this.router.navigate(['/employeraccount']);
        // else
        //     this.router.navigate(['/nfor1customer']);



    }
    

    private batchResponse(data: any) {
        this.logindetaildto = new LoginDetailDTO();
        var permissions = data[0];

         // this.logindetaildto.userdetails = JSON.stringify(data[1]);


   //     this.logindetaildto.userid = permissions.userdto.id;
        //     this.auth.setemailandobjectid(permissions.userdto.id, permissions.userdto.objectid);

        // JJM 4/23/2024 Commented out the above two lines
        this.logindetaildto.userid = "jerrymarkland@outlook.com"; 
        this.auth.setemailandobjectid("jerrymarkland@outlook.com", "BD9AA04F-7584-4E12-92DC-4C9740C8CA41");


/* JJM 4/23/2024 Commented out this call also
        this.ipAddressService.addIpAddress(this.logindetaildto)
            .subscribe(
                (data) => { this.onipaddressaddresponse(data); });
                */
    }

    private onipaddressaddresponse(data: any) {
        /*        this.router.navigate(['/employeraccount']);*/

        let test: string = "";
        test = this.auth.role;
        if (this.auth.role === "GUEST")
            this.router.navigate(['/employeraccount']);
        else
            this.router.navigate(['/nfor1customer']);


    }

     // New method to call getMember
    async fetchMemberData(): Promise<void> {
        const headerObj: HeaderObj = {
        Authorization: 'Bearer ' + this.auth.accesstoken,
        // Add other headers as needed
        };

        try {
        const memberData = await getMember(headerObj);
        console.log('Member data:', memberData.data);
        if(memberData.data) {
            this.auth.setemailandobjectid(this.auth.username, memberData.data.id);
            if (this.auth.role === "GUEST")
                this.router.navigate(['/employeraccount']);
            else
                this.router.navigate(['/nfor1customer']);
        }
        } catch (error) {
        console.error('Error:', error);
        }
    }

}
