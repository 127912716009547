﻿//import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ACAPlanLookupService } from './acaplanlookup.service';


import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';

const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';



@Component({
    templateUrl: 'acaplansandrates.html',
    providers: [
        ACAPlanLookupService,
        ListService
    ]
})
export class ACAPlansAndRatesComponent extends BaseComponent implements OnInit {

    columnDefinitions: Column[] = [];
    gridOptions: GridOption = {};
    mydataset: any[] = [];
    bIsLoaded: boolean = false;
    canShowGrid: boolean = false;
    angularGrid: AngularGridInstance;
    metrics: Metrics;
    //companyName: string;
    isCountEnabled = true;
    odataVersion = 2;
    odataQuery = '';
    processing = true;
    totalRowCount: number = 0;
    status = { text: 'processing...', class: 'alert alert-danger' };

    userSelectsString = '';
    name = 'Angular';
    userSelects = [];
    dropdownList = [];
    //    selectedItems = [];
    dropdownSettings = {};
    searchError: boolean = false;
    searchErrorMsg: string = "";

    gridObj: any[];
    selectedGridIDS: number[];
    selectedTitles: any[];
    selectedTitle: any;

    zipcode: string = "";
    selectedplanids: any[];
    selectedplannames: string;
    numberofSelectedPlans: number = 0;
    ratingareanumber: number = 1;
    ratingareaid: string = "";
    city: string = "";
    state: string = "";

    constructor(public acaplanlookupservice: ACAPlanLookupService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService,
        private cd: ChangeDetectorRef,
        protected _router: Router,
        private route: ActivatedRoute    ) {

        super();

        this.route.params.subscribe(params => {
            this.ratingareaid = params.ratingareaid;
            this.city = params.city;
            this.state = params.state;
            this.zipcode = params.zipcode;
            this.numberofSelectedPlans = params.numberofSelectedPlans;
            this.selectedplanids = params.planids;
            this.selectedplannames = params.plannames;

        });


    }


    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};
    }



    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }

    statechange(event) {

    }


    monthchange(event) {
    }


    ngOnInit(): void {
        let planname1: string = "No Plan";
        let planname2: string = "No Plan";
        let planname3: string = "No Plan";
        let planname4: string = "No Plan";
        
        let planstr: string[];
        planstr = this.selectedplannames.split(','); 
        for (var i = 0; i < this.numberofSelectedPlans; i++) {
            if (i == 0)
                planname1 = planstr[i].toString();
            if (i == 1)
                planname2 = planstr[i].toString();
            if (i == 2)
                planname3 = planstr[i].toString();
            if (i == 3)
                planname4 = planstr[i].toString();
            
        }



        this.columnDefinitions = [
            { id: 'id', name: 'Age', field: 'id', sortable: false, width: 80, minWidth: 80 },
            { id: 'plan1rate', name: planname1, field: 'plan1rate', sortable: false, width: 150, minWidth: 150, formatter: Formatters.dollar },
            { id: 'plan2rate', name: planname2, field: 'plan2rate', sortable: false, width: 150, minWidth: 150, formatter: Formatters.dollar },
            { id: 'plan3rate', name: planname3, field: 'plan3rate', sortable: false, width: 150, minWidth: 150, formatter: Formatters.dollar },
            { id: 'plan4rate', name: planname4, field: 'plan4rate', sortable: false, width: 150, minWidth: 150, formatter: Formatters.dollar }
        ];
        this.gridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: true,

            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },

        };
        this.gridOptions.showTopPanel = false;
        this.getrates();

        //this.angularGrid.resizerService.pauseResizer(true);
    }

    getrates() {

        this.searchError = false;
        let count: number;

        count = this.selectedplanids.length;
        this.acaplanlookupservice.getPlansAndRates(this.selectedplanids.toString(), this.ratingareaid)
            .subscribe(
                (data) => { this.getPlansAndRatesCallback(data); });

    }


    getPlansAndRatesCallback(data) {


        if (data.totalcount > 0) {

            this.gridOptions.pagination.totalItems = data.totalcount;
            this.totalRowCount = data.totalcount;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = data.planandratelist;
        }
        else {
            this.totalRowCount = data.totalcount;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = [];
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }

    }



    close() {

        this.zipcode = "";

        //       this.selectedItems = [];
        this.selectedplanids = [];
        // this.angularGrid.gridService.setSelectedRows([]);
        this.totalRowCount = 0;
        this.gridOptions = Object.assign({}, this.gridOptions);
        this.mydataset = [];
        this.searchError = false;
        this.city = "";
        this.state = "";
        this.ratingareaid = "";
        this.ratingareanumber = 0;
    }



    getCustomerCallback(data) {
        if (data.totalcount > 0) {

            this.gridOptions.pagination.totalItems = data.totalcount;
            this.totalRowCount = data.totalcount;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = data.acaUniquePlans;
            this.city = data.city;
            this.state = data.state;
            this.ratingareaid = data.ratingareaid;
        }
        else {
            this.totalRowCount = data.totalcount;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = [];
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;
            this.city = "";
            this.state = "";
            this.ratingareaid = "";
            this.ratingareanumber = 0;

        }
    }

    getCustomerApiCall(query) {

        this.searchError = false;
        this.acaplanlookupservice.getPlansForServiceArea(this.zipcode)
            .subscribe(
                (data) => { this.getCustomerCallback(data); });
    }

    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);


    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }



    /** Dispatched event of a Grid State Changed event */
    gridStateChanged(gridStateChanges: GridStateChange) {
        console.log('Client sample, Grid State changed:: ', gridStateChanges);
        let test: any;
        let a: number;

        // test = gridStateChanges;
        a = 0;

    }

    /** Dispatched event of a Grid State Changed event */
    grid1StateChanged(gridStateChanges: GridStateChange) {
        console.log('Grid State changed:: ', gridStateChanges);
        console.log('Grid State changed:: ', gridStateChanges.change);
    }

    ///** Dispatched event of a Grid State Changed event */
    //grid2StateChanged(gridStateChanges: GridStateChange) {
    //    console.log('Grid State changed:: ', gridStateChanges);
    //    console.log('Grid State changed:: ', gridStateChanges.change);

    //    if (gridStateChanges.gridState.rowSelection) {
    //        this.selectedGridIDS = (gridStateChanges.gridState.rowSelection.filteredDataContextIds || []) as number[];
    //        this.selectedGridIDS = this.selectedGridIDS.sort((a, b) => a - b); // sort by ID
    //        this.selectedTitles = this.selectedGridIDS.map(dataContextId => `Task ${dataContextId}`);
    //        this.cd.detectChanges();
    //    }
    //}


    onSelectedRowsChanged(e, args) {
        if (Array.isArray(args.rows)) {

            this.searchError = false;
            this.numberofSelectedPlans = args.rows.length;
            if (args.rows.length > 4) {
                this.searchError = true;
                this.searchErrorMsg = "You can only select up to 4 plans.";
                return;
            }

            this.selectedplanids = args.rows.map(idx => {
                const item = this.angularGrid.gridService.getDataItemByRowIndex(idx);
                return item.id || '';
            });
        }
    }



    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.gridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.gridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}






