import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SidebarService {
    toggled = false;
    _hasBackgroundImage = true;

/****************************** menus Guest User *************************************/
    menusEBToolKit = [
        {
            title: 'ICHRA Plan Modeling',
            id: 'planmodel',
            icon: 'fa fa-tachometer',
            active: true,
            canshow: true,
            colorreset: true,
            type: 'dropdown',
            submenus: [
                {
                    title: 'Employer Quotes',
                    icon: 'fa fa-search-plus',
                    active: true,
                    id: 'employeraccount',
                    redirect: 'employeraccount',
                    parentid: 'planmodel',
                },
            ]
        },
        {
            title: 'Search 5500 Data',
            id: '5500',
            icon: 'fa fa-tachometer',
            active: false,
            canshow: true,
            colorreset: false,
            type: 'dropdown',
            submenus: [
                {
                    title: 'All Health and Welfare Plans',
                    icon: 'fa fa-search-plus',
                    active: false,
                    //type: 'simple',
                    id: 'contracts',
                    redirect: 'contracts',
                    parentid: '5500',
                },
                {
                    title: 'Large Commission Plans',
                    icon: 'fa fa-search-plus',
                    active: false,
                    // type: 'simple',
                    id: 'companyunqs',
                    redirect: 'companyunqs',
                    parentid: '5500',
                },
                {
                    title: 'Plans by Broker',
                    icon: 'fa fa-search-plus',
                    active: false,
                    //type: 'simple',
                    id: 'brokers',
                    redirect: 'brokers',
                    parentid: '5500',

                },
            ]
        },
        {
            title: 'Logout',
            icon: 'fa fa-sign-out',
            active: false,
            type: 'simple',
            id: 'logout',
        }
        
    ];


/****************************** menus Admin *************************************/
    menusAdmin = [
        //{
        //    title: 'General',
        //    type: 'header'
        //},
/*        {*/
            //title: 'ICHRA Plan Modeling',
            //id: 'planmodel',
            //icon: 'fa fa-tachometer',
            //active: true,
            //canshow: true,
            //colorreset: true,
            //type: 'dropdown',
            //submenus: [
                //{
                //    title: 'Plan Analysis',
                //    icon: 'fa fa-search-plus',
                //    active: true,
                //    // type: 'simple',
                //    id: 'acaplanlookup',
                //    redirect: 'acaplanlookup',
                //    parentid: 'planmodel',

                //},
                //{
                //    title: 'My Cost Comparisons',
                //    icon: 'fa fa-search-plus',
                //    active: false,
                //    // type: 'simple',
                //    id: 'icquote',
                //    redirect: 'icquote',
                //    parentid: 'planmodel',
                //},
                //{
                //    title: 'Employer Quotes',
                //    icon: 'fa fa-search-plus',
                //    active: false,
                //    id: 'employeraccount',
                //    redirect: 'employeraccount',
                //    parentid: 'planmodel',
                //}
                //{
                //    title: 'My Contribution Strategies',
                //    icon: 'fa fa-search-plus',
                //    active: false,
                //    // type: 'simple',
                //    id: 'mystrategies',
                //    redirect: 'mystrategies',
                //    parentid: 'planmodel',
                //},
        //    ]
        //},
        {
            title: 'Employer Management',
            icon: 'fa fa-search-plus',
            active: false,
            id: 'nfor1customer',
            redirect: 'nfor1customer',
        },
        {
            title: 'Manage All Orders',
            icon: 'fa fa-search-plus',
            active: false,
            //type: 'simple',
            id: 'order',
            redirect: 'order',
        },
        {
            title: 'Order Payroll Report',
            icon: 'fa fa-search-plus',
            active: false,
            //type: 'simple',
            id: 'orderpayroll',
            redirect: 'orderpayroll',
        },
        {
            title: 'Order Summary Report',
            icon: 'fa fa-search-plus',
            active: false,
            //type: 'simple',
            id: 'orderpivot',
            redirect: 'orderpivot',
        },
        {
            title: 'Employer Billing Report',
            icon: 'fa fa-search-plus',
            active: false,
            //type: 'simple',
            id: 'employerbilling',
            redirect: 'employerbilling',
        },
        {
            title: 'Quote Plans by Employer',
            icon: 'fa fa-search-plus',
            active: false,
            id: 'employeraccount',
            redirect: 'employeraccount',
        },
        {
            title: 'Search 5500 Data',
            id: '5500',
            icon: 'fa fa-tachometer',
            active: false,
            canshow: true,
            colorreset: false,
            type: 'dropdown',
            submenus: [
                {
                    title: 'All Health and Welfare Plans',
                    icon: 'fa fa-search-plus',
                    active: false,
                    //type: 'simple',
                    id: 'contracts',
                    redirect: 'contracts',
                    parentid: '5500',
                },
                {
                    title: 'Large Commission Plans',
                    icon: 'fa fa-search-plus',
                    active: false,
                    // type: 'simple',
                    id: 'companyunqs',
                    redirect: 'companyunqs',
                    parentid: '5500',
                },
                {
                    title: 'Plans by Broker',
                    icon: 'fa fa-search-plus',
                    active: false,
                    //type: 'simple',
                    id: 'brokers',
                    redirect: 'brokers',
                    parentid: '5500',

                },
            ]
        },
        //{
        //    title: 'View Messages',
        //    icon: 'fa fa-linkedin',
        //    active: false,
        //    type: 'simple',
        //    redirect: 'activity',
        //    id: 'activity'
        //},
        {
            title: 'Admin',
            icon: 'fa fa-building',
            active: false,
            canshow: true,
            colorreset: false,
            //redirect: 'adminplans',
            id: 'admin',
            type: 'dropdown',
            submenus: [
                {
                    title: 'View Onboarding Logs',
                    icon: 'fa fa-search-plus',
                    active: false,
                    //type: 'simple',
                    id: 'logfact',
                    redirect: 'logfact',
                    parentid: 'admin',
                },
                {
                    title: 'Maintain Users',
                    icon: 'fa fa-search-plus',
                    active: false,
                    //type: 'simple',
                    id: 'user',
                    redirect: 'user',
                    parentid: 'admin',
                },
                {
                    title: 'Maintain Teams',
                    icon: 'fa fa-search-plus',
                    active: false,
                    //type: 'simple',
                    id: 'team',
                    redirect: 'team',
                    parentid: 'admin',
                },
                {
                    title: 'Maintain Plans',
                    icon: 'fa fa-search-plus',
                    active: false,
                    //type: 'simple',
                    id: 'adminplans',
                    redirect: 'adminplans',
                    parentid: 'admin',
                },
                {
                    title: 'Maintain Products',
                    icon: 'fa fa-search-plus',
                    active: false,
                    //type: 'simple',
                    id: 'adminproducts',
                    redirect: 'adminproducts',
                    parentid: 'admin',
                },
                //{
                //    title: 'Maintain Contributions',
                //    icon: 'fa fa-search-plus',
                //    active: false,
                //    //type: 'simple',
                //    id: 'admincontributions',
                //    redirect: 'admincontributions',
                //    parentid: 'admin',
                //},
                //{
                //    title: 'Employer Rate Comparison',
                //    icon: 'fa fa-search-plus',
                //    active: false,
                //    //type: 'simple',
                //    id: 'empplancomparison',
                //    redirect: 'empplancomparison',
                //    parentid: 'admin',
                //},
                //{
                //    title: 'Employee OnBoarding Page',
                //    icon: 'fa fa-search-plus',
                //    active: false,
                //    //type: 'simple',
                //    id: 'onboard',
                //    redirect: 'onboard',
                //    parentid: 'admin',
                //},
                {
                    title: 'Media Maintenance',
                    icon: 'fa fa-search-plus',
                    active: false,
                    //type: 'simple',
                    id: 'broker',
                    redirect: 'broker',
                    parentid: 'admin',
                },
                {
                    title: 'View All Quotes',
                    icon: 'fa fa-search-plus',
                    active: false,
                    //type: 'simple',
                    id: 'adminquotes',
                    redirect: 'adminquotes',
                    parentid: 'admin',
                },
                {
                    title: 'Version Information',
                    icon: 'fa fa-search-plus',
                    active: false,
                    //type: 'simple',
                    id: 'version',
                    redirect: 'version',
                    parentid: 'admin',
                },
                //{
                //    title: 'EARC Tracker',
                //    icon: 'fa fa-search-plus',
                //    active: false,
                //    //type: 'simple',
                //    id: 'adminrequestaccess',
                //    redirect: 'adminrequestaccess',
                //    parentid: 'admin',
                //}
            ]
        },
        {
            title: 'Logout',
            icon: 'fa fa-sign-out',
            active: false,
            type: 'simple',
            id: 'logout',
        },

    ];

/****************************** menus External *************************************/
    menusExternal = [

        {
            title: 'Employer Management',
            icon: 'fa fa-search-plus',
            active: false,
            id: 'nfor1customer',
            redirect: 'nfor1customer',
        },
        {
            title: 'Manage All Orders',
            icon: 'fa fa-search-plus',
            active: false,
            //type: 'simple',
            id: 'order',
            redirect: 'order',
        },
        {
            title: 'Order Payroll Report',
            icon: 'fa fa-search-plus',
            active: false,
            //type: 'simple',
            id: 'orderpayroll',
            redirect: 'orderpayroll',
        },
        {
            title: 'Order Summary Report',
            icon: 'fa fa-search-plus',
            active: false,
            //type: 'simple',
            id: 'orderpivot',
            redirect: 'orderpivot',
        },
        {
            title: 'Quote Plans by Employer',
            icon: 'fa fa-search-plus',
            active: false,
            id: 'employeraccount',
            redirect: 'employeraccount',
        },
        {
            title: 'Search 5500 Data',
            id: '5500',
            icon: 'fa fa-tachometer',
            active: false,
            canshow: true,
            colorreset: false,
            type: 'dropdown',
            submenus: [
                {
                    title: 'All Health and Welfare Plans',
                    icon: 'fa fa-search-plus',
                    active: false,
                    //type: 'simple',
                    id: 'contracts',
                    redirect: 'contracts',
                    parentid: '5500',
                },
                {
                    title: 'Large Commission Plans',
                    icon: 'fa fa-search-plus',
                    active: false,
                    // type: 'simple',
                    id: 'companyunqs',
                    redirect: 'companyunqs',
                    parentid: '5500',
                },
                {
                    title: 'Plans by Broker',
                    icon: 'fa fa-search-plus',
                    active: false,
                    //type: 'simple',
                    id: 'brokers',
                    redirect: 'brokers',
                    parentid: '5500',

                },
            ]
        },
        {
            title: 'Admin',
            icon: 'fa fa-building',
            active: false,
            canshow: true,
            colorreset: false,
            //redirect: 'adminplans',
            id: 'admin',
            type: 'dropdown',
            submenus: [
                //{
                //    title: 'View Onboarding Logs',
                //    icon: 'fa fa-search-plus',
                //    active: false,
                //    //type: 'simple',
                //    id: 'logfact',
                //    redirect: 'logfact',
                //    parentid: 'admin',
                //},
                {
                    title: 'Version Information',
                    icon: 'fa fa-search-plus',
                    active: false,
                    //type: 'simple',
                    id: 'version',
                    redirect: 'version',
                    parentid: 'admin',
                },
            ]
        },
        {
            title: 'Logout',
            icon: 'fa fa-sign-out',
            active: false,
            type: 'simple',
            id: 'logout',
        },

    ];




    // MENU for MA Only Brokers - NOT USED
    //menusHSA = [
    //    {
    //        title: 'ICHRA Plan Modeling',
    //        id: 'planmodel',
    //        icon: 'fa fa-tachometer',
    //        active: true,
    //        canshow: true,
    //        colorreset: true,
    //        type: 'dropdown',
    //        submenus: [
    //            {
    //                title: 'Plan Analysis',
    //                icon: 'fa fa-search-plus',
    //                active: true,
    //                id: 'acaplanlookup',
    //                redirect: 'acaplanlookup',
    //                parentid: 'planmodel',

    //            },
    //            {
    //                title: 'My Cost Comparisons',
    //                icon: 'fa fa-search-plus',
    //                active: false,
    //                id: 'icquote',
    //                redirect: 'icquote',
    //                parentid: 'planmodel',
    //            }
    //        ]
    //    },
    //    {
    //        title: 'Logout',
    //        icon: 'fa fa-sign-out',
    //        active: false,
    //        type: 'simple',
    //        id: 'logout',
    //    }


    //];
    constructor() {
        var test;
        console.log("*** IN sidebar.service constructor");
        test = "this is a test";
    }

    toggle() {
        this.toggled = !this.toggled;
    }

    getSidebarState() {
        return this.toggled;
    }

    setSidebarState(state: boolean) {
        this.toggled = state;
    }

    getMenuList(role: string) {
    //    console.log("getMenuList() {0}", role);
        if (role === "ADMIN") {
            return this.menusAdmin;
        }
        else if (role === "EXTERNAL") {
            return this.menusExternal;
        }
        return this.menusEBToolKit;
    }

    get hasBackgroundImage() {
        return this._hasBackgroundImage;
    }

    set hasBackgroundImage(hasBackgroundImage) {
        this._hasBackgroundImage = hasBackgroundImage;
    }
}
