﻿import { ChangeDetectorRef, Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminPlansService } from './adminplans.service';
import { ICQuoteDTO } from '../dto/ICQuoteDTO';
import { ACAUniquePlanDTO } from '../dto/ACAUniquePlanDTO';



import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';
import { ResponseBaseDTO } from '../dto/ResponseBaseDTO';
import { Column, GridOption, AngularGridInstance, Metrics } from 'angular-slickgrid';
import { BrokerManagementService } from './brokermanagement.service';
import {  MediaManagementService } from './mediamanagement.service';

const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';



@Component({
    templateUrl: './brokermanagement.html',
    providers: [       
        ListService,
        MediaManagementService
    ]
})
export class BrokerManagementComponent extends BaseComponent implements OnInit {

   

    @ViewChild("fileInput") fileInput;


    constructor(
        public brokerservice: BrokerManagementService,
        public mediaservice: MediaManagementService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,     
        private cd: ChangeDetectorRef,
        protected _router: Router,
        private route: ActivatedRoute) {

        super();
       
    }

    //  stateList: States[];
    columnDefinitions: Column[] = [];
    gridOptions: GridOption = {};
    brokerdataset: any[] = [];

    accounttypelist: SelectList[] = [
        {
            id: "1",
            name: "Employers"
        },
        {
            id: "2",
            name: "Brokers"
        }

    ];
    accountype: number = 1;
    accounttypetitle: string = "Employer Name";

    bIsLoaded: boolean = false;
    canShowGrid: boolean = false;
    angularGrid: AngularGridInstance;
    metrics: Metrics;
    totalRowCount: number = 0;
    statesearch: string = "0";
    companysearch: string = "";
    naicscodesearch: string = "";
    totalparticipationminsearch: number = 0;
    totalparticipationmaxsearch: number = 1000;
    isquotenotselected: boolean = true;
    selectedquoteidx: number = -1;
    isCountEnabled = true;
    odataVersion = 2;
    odataQuery = '';
    processing = true;
    status = { text: 'processing...', class: 'alert alert-danger' };


    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
    }

    //statechange(event) {
    //    var tg = event;
    //}

    ngOnInit(): void {

        this.columnDefinitions = [

            // { id: 'erisaack_id', name: 'ID', field: 'erisaack_id' },
            { id: 'accountname', name: this.accounttypetitle, field: 'accountname', filterable: true, sortable: true, width: 200 },
            //{ id: 'accountid', name: 'accountid', field: 'accountid', sortable: true, width: 75 },
            { id: 'id', name: 'Broker ID', field: 'id', sortable: false, width: 60, minWidth: 60 },
            { id: 'Medianame', name: 'Logo Name', field: 'medianame', sortable: true, width: 60, minWidth: 60 },
            { id: 'Mediatype', name: 'MediaType', field: 'mediatype', sortable: true, width: 60, minWidth: 60 }
           
           

        ];
        this.gridOptions = {
            enableAutoResize: true,
           // enablePagination: true,
            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableFiltering: true,
            // enableCheckboxSelector: true,
            enableRowSelection: true,
            forceFitColumns: true,
            //pagination: {
            //    pageSizes: [1000],
            //    pageSize: defaultPageSize,
            //    totalItems: 50
            //},

          
        };


       // this.gridOptions.showTopPanel = true;




    }

    getAccounts() {
        this.brokerservice.getbrokers(this.accountype).subscribe(data => {
            this.getbrokersResponse(data);
        });

    }


    statechange(event) {
        this.statesearch = event;
    }

    getbrokersResponse(data) {
        //this.stateList = data;
        this.brokerdataset = data;
        this.selectedquoteidx = -1;
        //this.spinner.hide();
        // this.brokerstateList = data["states"];
    }

    search() {
        this.canShowGrid = true;
        //this.getCustomerApiCall(null);
        //this.spinner.show();
        //this.angularGrid.pluginService.refreshBackendDataset();
    }

    displaySpinner(isProcessing) {
        this.processing = isProcessing;
        this.status = (isProcessing)
            ? { text: 'processing...', class: 'alert alert-danger' }
            : { text: 'done', class: 'alert alert-success' };
    }

    onupload() {
        let fi = this.fileInput.nativeElement;
        if (fi.files && fi.files[0]) {
            let fileToUpload = fi.files[0];

            
            //let file: File = fileList[0];
            let formData: FormData = new FormData();
            formData.append('accountid', this.brokerdataset[this.selectedquoteidx].accountid);
            formData.append('uploadFile', fileToUpload, fileToUpload.name);
            formData.append('mediatype', 'logo');

            this.mediaservice
                .addmedia(formData)
                .subscribe(data => {
                    this.fileuploadResponse(data);
                });
        }
    }  


    fileuploadResponse(data: any) {
    }


    accounttypechange(event) {

        let wrkorderstr: string = event;
        if (wrkorderstr !== "0") {
            this.accountype = parseInt(wrkorderstr);

        }
        else
            this.accountype = 0;

    }

    //getCustomerCallback(data) {

    //    this.gridOptions.pagination.totalItems = data.totalcount;
    //    this.totalRowCount = data.totalcount;
    //    this.gridOptions = Object.assign({}, this.gridOptions);
    //    this.brokerdataset = data.companylist;
    //    //this.spinner.hide();
    //}

    //getCustomerApiCall(query) {
    //    // in your case, you will call your WebAPI function (wich needs to return a Promise)
    //    // for the demo purpose, we will call a mock WebAPI function
    //    // return this.company.service.getData();
    //    this.companyservice.getcompanies(1, 1000, this.statesearch, this.companysearch,
    //        this.naicscodesearch, this.totalparticipationminsearch,
    //        this.totalparticipationmaxsearch)
    //        .subscribe(
    //            (data) => { this.getCustomerCallback(data); });;
    //}

    //goToFirstPage() {
    //    this.angularGrid.paginationService.goToFirstPage();
    //}

    //goToLastPage() {
    //    this.angularGrid.paginationService.goToLastPage();
    //}

    //setFiltersDynamically() {
    //    // we can Set Filters Dynamically (or different filters) afterward through the FilterService
    //    this.angularGrid.filterService.updateFilters([
    //        // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
    //        { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
    //    ]);
    //}

    //setSortingDynamically() {
    //    this.angularGrid.sortService.updateSorting([
    //        { columnId: 'name', direction: 'DESC' },
    //    ]);
    //}



    ///** Dispatched event of a Grid State Changed event */
    //gridStateChanged(gridStateChanges: GridStateChange) {
    //    console.log('Client sample, Grid State changed:: ', gridStateChanges);
    //}

    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---


    onSelectedRowsChanged(e, args) {
        if (Array.isArray(args.rows)) {
            if (args.rows.length > 0) {
                this.isquotenotselected = false;
                this.selectedquoteidx = args.rows.map(idx => {
                    return idx;
                });
            }
            else {
                this.isquotenotselected = true;
            }
        }
    }

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.gridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.gridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }


   


}






