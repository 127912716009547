﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../core/environment.service';
import { ExtendedHttpService } from '../core/extendedhttp.service';
import { HttpHeaders } from '@angular/common/http';
import { Http } from '@angular/http';
import { catchError, map } from 'rxjs/operators';



const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
@Injectable({
    providedIn: 'root',

})
export class ACARateCalcsService extends ExtendedHttpService {

    constructor(private env: EnvironmentService) {
        super();
    }


    getacaratecalcs(plan1rate: number, plan2rate: number, plancostcurrent: number, employercontributioncurrent: number, employeecontributioncurrent: number) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/ACARateCalcForTwoPlans/' + plan1rate + '/' + plan2rate + '/' + plancostcurrent + '/' + employercontributioncurrent + '/'
            + employeecontributioncurrent).
            pipe(
                map((data) => data.json())
            );
    }


}
