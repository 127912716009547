import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { Column, Formatters, OnEventArgs, GridOption } from 'angular-slickgrid';
import { B2CAuthService } from '../auth/b2cauth.service';
import { getversionedURl } from '../core/util.service';


@Component({
  selector: 'app-home',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent  {

    constructor(public auth: B2CAuthService) {
      //this.prepareGrid();
    }

}
