﻿//import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ICQuoteService } from './icquote.service';
import { ICQuoteDTO } from '../dto/ICQuoteDTO';
//import * as moment from 'moment-mini';


import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';
import { ResponseBaseDTO } from '../dto/ResponseBaseDTO';

const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';



@Component({
    templateUrl: 'icquote.component.html',
    providers: [
        ICQuoteService,
        ListService
    ]
})
export class ICQuoteComponent extends BaseComponent implements OnInit {

    columnDefinitions: Column[] = [];
    gridOptions: GridOption = {};
    mydataset: any[] = [];
    bIsLoaded: boolean = false;
    canShowGrid: boolean = false;
    angularGrid: AngularGridInstance;
    metrics: Metrics;
    //companyName: string;
    isCountEnabled = true;
    odataVersion = 2;
    odataQuery = '';
    processing = true;
    totalRowCount: number = 0;
    status = { text: 'processing...', class: 'alert alert-danger' };

    userSelectsString = '';
    name = 'Angular';
    userSelects = [];
    dropdownList = [];
    //    selectedItems = [];
    dropdownSettings = {};
    searchError: boolean = false;
    searchErrorMsg: string = "";

    gridObj: any[];
    selectedGridIDS: number[];
    selectedTitles: any[];
    selectedTitle: any;

    useremail: string;
    userobjectid: string;
    isquotenotselected: boolean = true;

    icquotedto: ICQuoteDTO;
    quotes: any[] = [];
    selectedquoteidx: number = -1;


    constructor(
        public icquoteservice: ICQuoteService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService,
        private cd: ChangeDetectorRef,
        protected _router: Router,
        private route: ActivatedRoute) {

        super();

        this.userobjectid = auth.userobjectid;
        this.useremail = auth.useremail;
    }


    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};
    }



    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }


    ngOnInit(): void {



        this.columnDefinitions = [
            { id: 'accountname', name: 'Employer Name', field: 'accountname', sortable: true, width: 275, minWidth: 275 },
            { id: 'name', name: 'Description', field: 'name', sortable: true, width: 275, minWidth: 275 },
            { id: 'brokername', name: 'Broker Name', field: 'brokername', sortable: true, width: 275, minWidth: 275 },
            { id: 'totalmembers', name: 'Total Members', field: 'totalmembers', sortable: true, width: 125, minWidth: 125 },
            { id: 'postalcode', name: 'Zip Code', field: 'postalcode', sortable: true, width: 100, minWidth: 100 },
            { id: 'currentpremium', name: 'Monthly Total Cost', field: 'currentpremium', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'effectivedate', name: 'Effective Date', field: 'effectivedate', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'modifiedon', name: 'Last Modified', field: 'modifiedon', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'id', name: 'Quote Id', field: 'id', sortable: false, width: 60, minWidth: 60 }
        ];
        this.gridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: true,
            checkboxSelector: {
                // you can toggle these 2 properties to show the "select all" checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true,
            },
            enableCheckboxSelector: true,

            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },
        };
        this.gridOptions.showTopPanel = false;
        this.getQuotes();

        //this.angularGrid.resizerService.pauseResizer(true);
    }

    getQuotes() {

        this.searchError = false;

        this.icquoteservice.getQuotes(this.userobjectid)
            .subscribe(
                (data) => { this.getQuotesCallBack(data); });

    }

    openquote() {
        //let idx: number = 0;
        //let numberofplans: number = 0;
        //let planids: string[] = [];
        //let plannames: string[] = [];
        //let issuernames: string[] = [];


        //idx = this.selectedquoteidx;

        //if (this.quotes[idx].plan1id !== "") {
        //    planids.push(this.quotes[idx].plan1id);
        //    plannames.push(this.quotes[idx].plan1name);
        //    issuernames.push(this.quotes[idx].issuer1name);
        //    numberofplans++;
        //}
        //if (this.quotes[idx].plan2id !== "") {
        //    planids.push(this.quotes[idx].plan2id);
        //    plannames.push(this.quotes[idx].plan2name);
        //    issuernames.push(this.quotes[idx].issuer2name);
        //    numberofplans++;
        //}
        //if (this.quotes[idx].plan3id !== "") {
        //    planids.push(this.quotes[idx].plan3id);
        //    plannames.push(this.quotes[idx].plan3name);
        //    issuernames.push(this.quotes[idx].issuer3name);
        //    numberofplans++;
        //}
        //if (this.quotes[idx].plan4id !== "") {
        //    planids.push(this.quotes[idx].plan4id);
        //    plannames.push(this.quotes[idx].plan4name);
        //    issuernames.push(this.quotes[idx].issuer4name);
        //    numberofplans++;
        //}

        //this._router.navigate(['/totalcost',
        //    {
        //        ratingareaid: this.quotes[idx].ratingarea,
        //        city: this.quotes[idx].city,
        //        state: this.quotes[idx].state,
        //        zipcode: this.quotes[idx].postalcode,
        //        numberofSelectedPlans: numberofplans,
        //        planids: planids,
        //        plannames: plannames,
        //        issuernames: issuernames,
        //        employername: this.quotes[idx].accountname,
        //        quotename: this.quotes[idx].name,
        //        effectivedate: this.quotes[idx].effectivedate,
        //        totalmembers: this.quotes[idx].totalmembers,
        //        unitsbyage: this.quotes[idx].unitsbyage,
        //        currentpremium: this.quotes[idx].currentpremium,
        //        medicarepremium: this.quotes[idx].medicarepremium,
        //        quotestatus: this.quotes[idx].quotestatus,
        //        createdon: this.quotes[idx].createdon,
        //        createdby: this.quotes[idx].createdby,
        //        accountid: this.quotes[idx].accountid,
        //        id: this.quotes[idx].id
        //    }
        //]);

        this.icquotedto = new ICQuoteDTO();

        this.icquotedto = this.quotes[this.selectedquoteidx];

        this._router.navigate(['/totalcost',
            {
                icquotedto: JSON.stringify(this.quotes[this.selectedquoteidx])
            }
        ]);


    }


    getQuotesCallBack(data) {


        if (data.totalcount > 0) {
            // Medicare costs don't come from the plan, so add them to the PlanComparison DTO
            this.searchError = false;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.totalRowCount = data.totalcount;

            this.mydataset = data.quotelist;
            this.quotes = data.quotelist;


        }
        else {
            this.totalRowCount = 0;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = [];
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }

    }


    onSelectedRowsChanged(e, args) {
        if (Array.isArray(args.rows)) {
            if (args.rows.length > 0) {
                this.isquotenotselected = false;
                this.selectedquoteidx = args.rows.map(idx => { 
                    return idx;
                });
            }
            else {
                this.isquotenotselected = true;
            }
        }
    }




// NO CUSTOM CODE BEYOND THIS POINT

    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);


    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }


    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.gridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.gridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}






