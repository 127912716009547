﻿
//Auto Generated file

  
export class LoginDetailDTO {
		// This file is generated from the entities located in the MAKTech.Entities project
 
        public logindetailid: string;
        public userid: string;
        public ipaddress: string;
        public city: string;
        public country: string;
        public userdetails: string;
        public createdon: Date;
    }
