﻿
//Auto Generated file

import { ProductDTO } from "./ProductDTO";
import { ProductFeeViewExDTO } from "./ProductFeeViewExDTO";
import { ImageViewDTO } from "./ImageViewDTO";
import { VideoDTO } from "./VideoDTO";


export class ProductExDTO {
    // This file is generated from the entities located in the MAKTech.Entities project

    public productdto: ProductDTO;
    public productfeedtos: ProductFeeViewExDTO[];
    public imageviewdtos: ImageViewDTO[];
    public videodto: VideoDTO;
    //public financeserviceviewdtos: FinanceServiceViewDTO[];
}
