﻿//import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
//import { ACAPlanLookupService } from './acaplanlookup.service';
import { EmployerAccountService } from './employeraccount.service';
import { TotalCostService } from '../rates/totalcost.service';
import { ICQuoteService } from '../rates/icquote.service';
import { AccountAndAddressDTO } from '../dto/AccountAndAddressDTO';
import { ICQuoteDTO } from '../dto/ICQuoteDTO';
import { GlobaldataService } from '../service/globaldata.service';
//import * as moment from 'moment-mini';


import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';
import { ResponseBaseDTO } from '../dto/ResponseBaseDTO';

const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';



@Component({
    templateUrl: 'accountdetail.html',
    providers: [
        EmployerAccountService,
        ICQuoteService,
        TotalCostService,
        ListService
    ]
})
export class AccountDetailComponent extends BaseComponent implements OnInit {

    employeazecustomerlist: SelectList[] = [
        {
            id: "0",
            name: "No"
        },
        {
            id: "1",
            name: "All Products"
        },
        {
            id: "2",
            name: "No Products"
        }

    ];

    quotecolumnDefinitions: Column[] = [];
    quotegridOptions: GridOption = {};
    quotedataset: any[] = [];

    bIsLoaded: boolean = false;
    canShowGrid: boolean = false;
    angularGrid: AngularGridInstance;
    metrics: Metrics;
    //companyName: string;
    isCountEnabled = true;
    odataVersion = 2;
    odataQuery = '';
    processing = true;
    totalRowCount: number = 0;
    status = { text: 'processing...', class: 'alert alert-danger' };

    userSelectsString = '';
    name = 'Angular';
    userSelects = [];
    dropdownList = [];
    //    selectedItems = [];
    dropdownSettings = {};
    searchError: boolean = false;
    searchErrorMsg: string = "";
    displayMessage: string = "";
    testdate: string;

    gridObj: any[];
    selectedGridIDS: number[];
    selectedTitles: any[];
    selectedTitle: any;

    zipcode: string = "";

    addingaccount: number = 0;
    addingquote: number = 0;

    ratingareaid: string = "";
    city: string = "";
    state: string = "";



    totalquotes: number = 0;
    renewaldate: Date;
    renewaldatestr: string = "";

    useremail: string;
    userobjectid: string;



    employername: string = "";
    quotename: string = "";
    brokername: string = "";
    accountstatus: string = "Active";

    acctaddrdto: AccountAndAddressDTO;
    accountid: string = "";
    quoteid: string = "";
    quotelist: any[] = [];
    createdon: Date;
    createdby: string;

    icquotedto: ICQuoteDTO;
    quotes: any[] = [];
    selectedquoteidx: number = -1;
    isquotenotselected: boolean = true;
    isaccountnotsaved: boolean = true;

    constructor(
        public employeeaccountservice: EmployerAccountService,
        public totalcostservice: TotalCostService,
        public icquoteservice: ICQuoteService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService,
        private cd: ChangeDetectorRef,
        protected _router: Router,
        private route: ActivatedRoute,
        private globaldataservice: GlobaldataService    ) {

        super();

        this.route.params.subscribe(params => {
            this.addingaccount = params.addingaccount;
            this.acctaddrdto = JSON.parse(params.acctaddrdto);

        });

        this.userobjectid = auth.userobjectid;
        this.useremail = auth.useremail;
    }


    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};
    }



    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }


    ngOnInit(): void {

        this.globaldataservice.countystatecitylist = [];
        this.globaldataservice.selectcounty = [];
        if (this.acctaddrdto == undefined) { // this is not a saved employer
            this.renewaldate = new Date();
            this.renewaldatestr = (this.renewaldate.getMonth() + 2).toString() + "/01/" + this.renewaldate.getFullYear().toString();
            this.renewaldate = new Date(this.renewaldatestr);
            this.accountstatus = "Active";
            this.acctaddrdto = new AccountAndAddressDTO();
            this.acctaddrdto.accountname = "";
            this.acctaddrdto.accounttype = 1;  // Employer
            this.acctaddrdto.addresstype = 3;  // Primary Address
            this.acctaddrdto.status = 1;       // Active Account
            this.acctaddrdto.parenttype = 0;   // The parent of the address is the account entity
            this.acctaddrdto.postalcode = "";
        }
        else {
            this.accountid = this.acctaddrdto.accountid;
            if (this.acctaddrdto.renewaldate !== null) {
                this.renewaldate = new Date(this.acctaddrdto.renewaldate);
                this.renewaldatestr = this.renewaldate.getMonth() + 1 + "/" + this.renewaldate.getDate() + "/" + this.renewaldate.getFullYear();
            }
            else {
                this.renewaldatestr = "";
            }
            if (this.acctaddrdto.postalcode == undefined)
                this.acctaddrdto.postalcode = "";
            else
                this.isaccountnotsaved = false;
                
        }


            this.runQuoteMode();




        //this.angularGrid.resizerService.pauseResizer(true);
    }


    runQuoteMode() {


        this.quotecolumnDefinitions = [
            { id: 'name', name: 'Description', field: 'name', sortable: true, width: 275, minWidth: 275 },
            { id: 'effectivedate', name: 'Effective Date', field: 'effectivedate', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'postalcode', name: 'Zip Code', field: 'postalcode', sortable: true, width: 100, minWidth: 100 },
            { id: 'totalmembers', name: 'Total Members', field: 'totalmembers', sortable: true, width: 125, minWidth: 125 },
            { id: 'currentpremium', name: 'Current Monthly Cost', field: 'currentpremium', sortable: true, width: 125, minWidth: 100, formatter: this.moneyFormatter },
            { id: 'plan1name', name: 'Plan Name 1', field: 'plan1name', sortable: true, width: 250, minWidth: 250 },
            { id: 'plan1inddeductible', name: 'Plan 1 Ind Deductible', field: 'plan1inddeductible', sortable: true, width: 225, minWidth: 225 },
            { id: 'plan1monthlycost', name: 'Plan 1 Monthly Cost', field: 'plan1monthlycost', sortable: true, width: 225, minWidth: 225, formatter: this.moneyFormatter },
            { id: 'plan1monthlyvariance', name: 'Plan 1 Monthly Variance', field: 'plan1monthlyvariance', sortable: true, width: 250, minWidth: 250, formatter: this.moneyAccountingFormatter },
            { id: 'plan2name', name: 'Plan Name 2', field: 'plan2name', sortable: true, width: 250, minWidth: 250 },
            { id: 'plan2inddeductible', name: 'Plan 2 Ind Deductible', field: 'plan2inddeductible', sortable: true, width: 225, minWidth: 225 },
            { id: 'plan2monthlycost', name: 'Plan 2 Monthly Cost', field: 'plan2monthlycost', sortable: true, width: 225, minWidth: 225, formatter: this.moneyFormatter },
            { id: 'plan2monthlyvariance', name: 'Plan 2 Monthly Variance', field: 'plan2monthlyvariance', sortable: true, width: 250, minWidth: 250, formatter: this.moneyAccountingFormatter },
            { id: 'plan3name', name: 'Plan Name 3', field: 'plan3name', sortable: true, width: 250, minWidth: 250 },
            { id: 'plan3inddeductible', name: 'Plan 3 Ind Deductible', field: 'plan3inddeductible', sortable: true, width: 225, minWidth: 225 },
            { id: 'plan3monthlycost', name: 'Plan 3 Monthly Cost', field: 'plan3monthlycost', sortable: true, width: 225, minWidth: 225, formatter: this.moneyFormatter },
            { id: 'plan3monthlyvariance', name: 'Plan 3 Monthly Variance', field: 'plan3monthlyvariance', sortable: true, width: 250, minWidth: 250, formatter: this.moneyAccountingFormatter },
            { id: 'plan4name', name: 'Plan Name 4', field: 'plan4name', sortable: true, width: 250, minWidth: 250 },
            { id: 'plan4inddeductible', name: 'Plan 4 Ind Deductible', field: 'plan4inddeductible', sortable: true, width: 225, minWidth: 225 },
            { id: 'plan4monthlycost', name: 'Plan 4 Monthly Cost', field: 'plan4monthlycost', sortable: true, width: 225, minWidth: 225, formatter: this.moneyFormatter },
            { id: 'plan4monthlyvariance', name: 'Plan 4 Monthly Variance', field: 'plan4monthlyvariance', sortable: true, width: 250, minWidth: 250, formatter: this.moneyAccountingFormatter },
            { id: 'modifiedon', name: 'Last Modified', field: 'modifiedon', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'id', name: 'Quote Id', field: 'id', sortable: false, width: 60, minWidth: 60 }
            //{ id: 'censustype', name: 'Census Type', field: 'censustype', sortable: true, width: 100, minWidth: 100 }
        ];
        this.quotegridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: true,
            checkboxSelector: {
                // you can toggle these 2 properties to show the "select all" checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true,
            },
            enableCheckboxSelector: true,

            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },

        };
        this.quotegridOptions.showTopPanel = false;


        if (this.accountid != "") {
            this.acctaddrdto = this.globaldataservice.employers.find(a => a.accountid == this.accountid);
        }

        if (this.addingaccount == 0)
            this.getquotes();

    }

    getquotes() {

        this.searchError = false;

        this.icquoteservice.getEmployerQuotes(this.accountid)
            .subscribe(
                (data) => { this.getquotesCallBack(data); });

    }

    getquotesCallBack(data) {

        this.searchError = false;
        this.searchErrorMsg = "";

        if (data.totalcount > 0) {
            this.quotegridOptions = Object.assign({}, this.quotegridOptions);
            this.totalRowCount = data.totalcount;

            this.quotedataset = data.quotelist;
            this.quotes = data.quotelist;
        }
        else {
            this.totalRowCount = 0;
            this.quotegridOptions = Object.assign({}, this.quotegridOptions);
            this.quotedataset = [];
            this.quotes = [];
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }
    }

    saveaccount() {

        this.searchError = false;
        this.displayMessage = "";

        if (this.renewaldatestr !== "") {
            this.renewaldate = new Date(this.renewaldatestr);
            if (isNaN(this.renewaldate.getDate())) {
                this.searchErrorMsg = "Effective date must be in the format MM/DD/YYYY.";
                this.searchError = true;
                this.renewaldatestr = "";
                this.renewaldate = null;
                return;
            }
            this.acctaddrdto.renewaldate = this.renewaldate;
        }
        else {
            this.acctaddrdto.renewaldate = null;
            this.renewaldate = null;
        }

        if (this.acctaddrdto.postalcode == undefined || this.acctaddrdto.postalcode === "") {
            this.searchError = true;
            this.searchErrorMsg = "Please enter a valid zipcode..";
            return;
        }

        if (this.acctaddrdto.accountname == undefined || this.acctaddrdto.accountname === "") {
            this.searchError = true;
            this.searchErrorMsg = "Please enter the name of the employer.";
            return;
        }



        if (this.accountid !== undefined && this.accountid !== "") {
            this.acctaddrdto.modifiedon = new Date();
            this.acctaddrdto.modifiedby = this.auth.useremail;
        }
        else {
            this.acctaddrdto.createdon = new Date();
            this.acctaddrdto.createdby = this.auth.useremail;
            this.acctaddrdto.ownertenantid = this.userobjectid;
            this.acctaddrdto.employeazestatus = 0;
        }


        this.employeeaccountservice.postAccountAndAddress(this.acctaddrdto)
            .subscribe(
                (data) => { this.saveaccountCallback(data); }
            );

    }

    saveaccountCallback(data) {


        if (data.success) {
            this.accountid = data.data.accountdto.accountid;
            this.acctaddrdto.accountid = this.accountid;
            this.acctaddrdto.addressid = data.data.addressdto.addressid;
            this.acctaddrdto.parentid = data.data.addressdto.parentid;
            this.acctaddrdto.parenttype = data.data.addressdto.parenttype;
            this.isaccountnotsaved = false;

            this.displayMessage = "Saved Successfully";
        }
        else {
            this.searchErrorMsg = data.displaymessage;
            this.searchError = true;

        }

    }

    datechange(e) {
        var a;
        let adate: Date;

        a = e.target.value;

    }

    changePostalCode() {
        this.employeeaccountservice.getUSZipCounty(this.acctaddrdto.postalcode)
            .subscribe(
                (data) => { this.changePostalCodeCallBack(data); }
            );        

    }

    changePostalCodeCallBack(data) {
        this.isaccountnotsaved = true;
        if (data.totalcount > 0) {

            this.globaldataservice.countystatecitylist = data.locationlist;

            // State should be the same for all counties - watch for those 13 zip codes that map to multiple states
            this.acctaddrdto.stateorprovince = this.globaldataservice.countystatecitylist[0].state;
            this.acctaddrdto.city = this.globaldataservice.countystatecitylist[0].city;
            this.acctaddrdto.county = this.globaldataservice.countystatecitylist[0].countyname;
            this.globaldataservice.countycode = this.globaldataservice.countystatecitylist[0].countycode.toString();
            this.globaldataservice.selectcounty = [];

            if (this.globaldataservice.countystatecitylist.length > 1) {  // There is more than one county
                this.globaldataservice.countycode = "";
                for (var i = 0; i < this.globaldataservice.countystatecitylist.length; i++) {
                    let scounty: SelectList = {
                        id: this.globaldataservice.countystatecitylist[i].countyname,
                        name: this.globaldataservice.countystatecitylist[i].countyname
                    }
                    this.globaldataservice.selectcounty.push(scounty);
                }
            }
        }
        else {
            this.searchErrorMsg = data.responseMsg + " Postcode entered is: " + this.acctaddrdto.postalcode;
            this.acctaddrdto.city = "";
            this.acctaddrdto.stateorprovince = "";
            this.acctaddrdto.postalcode = "";
            this.globaldataservice.countystatecitylist = [];
            this.searchError = true;

        }

    }


    countyselected(event) {


        if (event !== "") {
            this.acctaddrdto.county = event;
            //if (this.globaldataservice.countycode !== this.formDataService.oldcountycode && this.formDataService.oldcountycode !== '')
            //    this.formDataService.resetAllData();

            for (var i = 0; i < this.globaldataservice.countystatecitylist.length; i++) {
                if (this.acctaddrdto.county == this.globaldataservice.countystatecitylist[i].countyname) {
                    this.acctaddrdto.city = this.globaldataservice.countystatecitylist[i].city;
                    this.acctaddrdto.stateorprovince = this.globaldataservice.countystatecitylist[i].state;
                    break;
                }

            }

        }
        else {
            this.acctaddrdto.county = "";
        }
        this.dataEntered();


    }

    dataEntered() {
        // When data is entered do any clean up that is necessary
        this.searchError = false;
        this.searchErrorMsg = "";
        this.displayMessage = "";
    }

    openquote() {
        this.icquotedto = new ICQuoteDTO();

        this.icquotedto = this.quotes[this.selectedquoteidx];

        if (this.icquotedto.plan1monthlycost == undefined) {
            this._router.navigate(['/totalcost',
                {
                    icquotedto: JSON.stringify(this.quotes[this.selectedquoteidx])
                }
            ]);

        }
        else {

            this._router.navigate(['/quotedetail',
                {
                    employername: this.acctaddrdto.accountname,
                    accountid: this.accountid,
                    city: this.acctaddrdto.city,
                    state: this.acctaddrdto.stateorprovince,
                    zipcode: this.acctaddrdto.postalcode,
                    countyname: this.acctaddrdto.county,
                    addingquote: 0,
                    icquotedto: JSON.stringify(this.quotes[this.selectedquoteidx])
                }
            ]);
        }
    }

    addquote() {


        this._router.navigate(['/quotedetail',
            {
                employername: this.acctaddrdto.accountname,
                accountid: this.accountid,
                city: this.acctaddrdto.city,
                state: this.acctaddrdto.stateorprovince,
                zipcode: this.acctaddrdto.postalcode,
                countyname: this.acctaddrdto.county,
                addingquote: 1
            }
        ]);
    }

    copyquote() {

        this.icquotedto = new ICQuoteDTO();

        this.icquotedto = this.quotes[this.selectedquoteidx];

        this.icquotedto.id = '00000000-0000-0000-0000-000000000000';
        this.icquotedto.createdon = new Date();
        this.icquotedto.createdby = this.auth.useremail;
        this.icquotedto.name = "Copy " + this.icquotedto.name;

        this.icquotedto.ownerid = this.userobjectid; // TODO: When we implement security groups, this might not be the right thing to do
        this.icquotedto.modifiedby = this.auth.useremail;
        this.icquotedto.modifiedon = new Date();
        this.icquotedto.clientmessage = "RETURNALLQUOTES";


        this.totalcostservice.postIndivdualCoverageByUnitsQuote(this.icquotedto)
            .subscribe(
                (data) => { this.copyquoteCallBack(data); }
            );

    }




    copyquoteCallBack(data) {

        this.searchError = false;
        this.searchErrorMsg = "";

        if (data.totalcount > 0) {
            this.displayMessage = "Copied Successfully";
            this.quotegridOptions = Object.assign({}, this.quotegridOptions);
            this.totalRowCount = data.totalcount;

            this.quotedataset = data.quotelist;
            this.quotes = data.quotelist;
        }
        else {
            this.searchErrorMsg = data.responseMsg;
            this.searchError = true;

        }

    }


    onSelectedRowsChanged(e, args) {
        if (Array.isArray(args.rows)) {
            if (args.rows.length > 0) {
                this.isquotenotselected = false;
                this.selectedquoteidx = args.rows.map(idx => {
                    return idx;
                });
            }
            else {
                this.isquotenotselected = true;
            }
        }
    }

// NOT PART OF CUSTOM CODE

    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);


    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }



    //onSelectedRowsChanged(e, args) {

    //    let test: number = 0;
    //    if (Array.isArray(args.rows)) {

    //        this.searchError = false;
    //        this.numberofSelectedPlans = args.rows.length;
    //        //if (args.rows.length > 4) {
    //        //    this.searchError = true;
    //        //    this.searchErrorMsg = "You can only select up to 4 plans.";
    //        //    return;
    //        //}

    //        test = args.rows.map(idx => idx);

    //        this.membernumber = args.rows.map(idx => {
    //            const item = this.angularGrid.gridService.getDataItemByRowIndex(idx);
    //            return item.numberbyage || 0;
    //        });
    //    }
    //}



    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.quotegridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.quotegridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}






