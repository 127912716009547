import { DatePipe } from "@angular/common";
import { Injector } from "@angular/core";

/**
 * creates a stringified name for any token passed.
 */
export function stringify(token: any): string {
    if (typeof token === 'string') {
        return token;
    }

    if (token == null) {
        return '' + token;
    }

    if (token.overriddenName) {
        return `${token.overriddenName}`;
    }

    if (token.name) {
        return `${token.name}`;
    }

    const res = token.toString();

    if (res == null) {
        return '' + res;
    }

    const newLineIndex = res.indexOf('\n');
    return newLineIndex === -1 ? res : res.substring(0, newLineIndex);
}


export function getversionedURl(url: any): string {

    var version = "01252020.1";
    var versionedurl = url + '?v=' + version;
    return versionedurl;
}


let appInjectorRef: Injector;

export function appInjector(injector?: Injector): Injector {
    if (!injector) {
        return appInjectorRef;
    }
    appInjectorRef = injector;
    return appInjectorRef;
}