﻿
//Auto Generated file

  
export class PermissionDTO {
		// This file is generated from the entities located in the MAKTech.Entities project
 
        public permissionid: string;
        public groupid: string;
        public resourceid: string;
        public createdon: Date;
        public createdby: string;
        public modifiedon: Date;
        public modifiedby: string;
    }
