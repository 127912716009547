﻿import { Component, OnInit } from '@angular/core';
import { ContractsService } from './contracts.service';


import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,    
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';

const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';



@Component({
    templateUrl: './contracts.component.html',
    providers: [
        ContractsService,
        ListService
    ]
})
export class ContractsComponent extends BaseComponent implements OnInit {

    constructor(public contractsservice: ContractsService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
         private listservice:ListService) {
        super();
    }
    stateList: States[];
    brokerstateList: States[];
    monthList: Months[];

    columnDefinitions: Column[] = [];
    gridOptions: GridOption = {};
    mydataset: any[] = [];
    bIsLoaded: boolean = false;
    canShowGrid: boolean = false;
    angularGrid: AngularGridInstance;
    //columnDefinitions: Column[];
    // gridOptions: GridOption;
    //dataset = [];
    metrics: Metrics;
    companyName: string;
    isCountEnabled = true;
    odataVersion = 2;
    odataQuery = '';
    processing = true;
    totalRowCount: number = -1;
    status = { text: 'processing...', class: 'alert alert-danger' };

    statesearch: string = "0";
    companysearch: string = "";
    cbsasearch: string = "";
    coveragessearch: string = "";
    brokernamesearch: string = "AZ_TY";
    brokerstatesearch: string = "0";
    totalparticipationminsearch: number = 0;
    totalparticipationmaxsearch: number = 1000000;
    carriersearch: string = "";
    renewalmonth: string = "0";

    userSelectsString = '';
    name = 'Angular';
    userSelects = [];
    dropdownList = [];
    selectedItems = [];
    dropdownSettings = {};
    searchError: boolean = false;
    searchErrorMsg: string = "";


    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
    }

   

    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }

    statechange(event) {
        this.statesearch = event;
        this.cbsasearch = "";
    }


    monthchange(event) {
        this.renewalmonth = event;
    }

    brokerstatechange(event) {
        this.brokerstatesearch = event;
    }

    ngOnInit(): void {

        this.dropdownList = [
            { id: 10, name: 'Medical' },
            { id: 11, name: 'Dental' },
            { id: 12, name: 'Vision' },
            { id: 13, name: 'Life' },
            { id: 14, name: 'Temp Disability' },
            { id: 15, name: 'LTD' },
            { id: 16, name: ' Sup Unemployement' },
            { id: 17, name: 'Drug' },
            { id: 18, name: 'Stop Loss' },
            { id: 19, name: 'Other Benefit' },
        ];
        this.selectedItems = [
            //{ id: 3, name: 'Pune' },
            //{ id: 4, name: 'Navsari' }
        ];
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
        };
        
            this.columnDefinitions = [
                { id: 'companyname', name: 'Company Name', field: 'companyname', sortable: true, width: 275, minWidth: 275 },
                { id: 'cbsaname', name: 'CBSA Name', field: 'cbsaname', sortable: true, width: 200, minWidth: 200 },
                { id: 'state', name: 'State', field: 'state', sortable: true, width: 50, minWidth: 40 },
                { id: 'carriername', name: 'Carrier Name', field: 'carriername', sortable: true, width: 325, minWidth: 325 },
                { id: 'contractexpirationdate', name: 'Expiration Date', field: 'contractexpirationdate', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
                { id: 'coverages', name: 'Coverages', field: 'coverages', sortable: true, width: 225, minWidth: 225 },
                { id: 'totalparticipation_boy_cnt', name: 'Participants', field: 'totalparticipation_boy_cnt', sortable: true, width: 90, minWidth: 90 },
                { id: 'personscovered_eoy_cnt', name: 'Persons Covered', field: 'personscovered_eoy_cnt', sortable: true, width: 75, minWidth: 75 },
                { id: 'brokername', name: 'Broker Name', field: 'brokername', sortable: true, width: 200, minWidth: 200 },
                { id: 'brokercommission_amt', name: 'Commission', field: 'brokercommission_amt', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
                { id: 'phonenumber', name: 'Phone No', field: 'phonenumber', width: 125, minWidth: 125, formatter: Formatters.mask, params: { mask: '(000) 000-0000' } },
                { id: 'postalcode', name: 'Zip Code', field: 'postalcode', sortable: true, width: 70, minWidth: 70 },
                { id: 'naics_code', name: 'NAICS Code', field: 'naics_code', width: 75, minWidth: 75 },
                { id: 'naics_description', name: 'NAICS Desc', field: 'naics_description', width: 200, minWidth: 200 },
                { id: 'brokerstate', name: 'Broker State', field: 'brokerstate', sortable: true, width: 80, minWidth: 60 },
                { id: 'filingreceiveddate', name: 'Filing Date', field: 'filingreceiveddate', width: 120, minWidth: 100, formatter: Formatters.dateIso },
                { id: 'sponsor_signer', name: 'Sponsor Name', field: 'sponsor_signer', width: 200, minWidth: 200 },
                { id: 'admin_name', name: 'Admin Name', field: 'admin_name', width: 200, minWidth: 200 },
                { id: 'admin_phonenumber', name: 'Admin Phone No', field: 'admin_phonenumber', width: 125, minWidth: 125, formatter: Formatters.mask, params: { mask: '(000) 000-0000' } },
                { id: 'admin_signer', name: 'Admin Signer', field: 'admin_signer', width: 200, minWidth: 200 },
                { id: 'accountantfirmname', name: 'Accountant Firm', field: 'accountantfirmname', width: 200, minWidth: 200 }

            ];
            this.gridOptions = {
               /// enableAutoResize: true,
                //enablePagination: true,
                
                autoResize: {
                    containerId: 'demo-container',
                    sidePadding: 15
                },      
                enableCellNavigation: true,
                enableAutoResize: true,
                enableColumnReorder: false,
                forceFitColumns: false,
                enableRowSelection: true,
                //enableCellNavigation: true,
                // enableFiltering: true,
                //// enableCheckboxSelector: true,
             
                pagination: {
                    pageSizes: [50,1000],
                    pageSize: defaultPageSize,
                    totalItems: 50
                },

                //backendServiceApi: {
                //    service: new GridOdataService(),
                //    options: {
                //        executeProcessCommandOnInit: false,
                //    },                    
                //    process: (query) => this.getCustomerApiCall(query),
                //    postProcess: (response) => {
                //        this.metrics = response.metrics;                       
                //        this.getCustomerCallback(response);
                //    }
                //}
        };

        this.gridOptions.showTopPanel = false;
        this.listservice.getLists("states,months").subscribe(data => {
            this.getlistsResponse(data);
        }
        );


        //this.angularGrid.resizerService.pauseResizer(true);
    }

    getlistsResponse(data) {
        //this.stateList = data;
        this.stateList = data["states"];
        this.monthList = data["months"];
 //       this.brokerstateList = data["states"];
    }

    getCoverages(): any {
        var coverages: string = "";
        for (let selecteditem of this.selectedItems) {
            coverages = coverages + selecteditem.id + ",";
        }
        coverages = coverages.slice(0, -1);       
        return coverages;
    }

    search() {
        this.getCoverages();
        this.canShowGrid = true;
        this.getCustomerApiCall(null);
       // this.angularGrid.pluginService.refreshBackendDataset();
    }

    reset() {

        this.statesearch = "0";
        this.companysearch = "";
        this.cbsasearch = "";
        this.coveragessearch = "";
        this.carriersearch = "";
        this.renewalmonth = "0";
        this.totalparticipationminsearch = 0;
        this.totalparticipationmaxsearch = 1000000;
        this.totalRowCount = -1;
        this.selectedItems = [
          
        ];
    }

   

    getCustomerCallback(data) {

        if (data.totalcount > 0) {

            this.gridOptions.pagination.totalItems = data.totalcount;
            this.totalRowCount = data.totalcount;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = data.contractlist;
        }
        else {
            this.totalRowCount = data.totalcount;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = []; 
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }
    }

    getCustomerApiCall(query) {
        let cbsasearchvalue: string;
        let companysearchvalue: string;
        let coveragesearchvalue: string;
        let carriersearchvalue: string;
        let renewalmonthsearchvalue: string;
        let brokerstatesearchvalue: string;


        this.searchError = false;
        this.searchErrorMsg = "";
        // Check for data entry errors before submitting search
        if (this.statesearch == "0" && this.cbsasearch == "") {
            this.searchErrorMsg = "Please select a state or enter at least 3 characters in CBSA Name field.";
            this.searchError = true;
            return;
        }
        if (this.cbsasearch.length >= 1 && this.cbsasearch.length < 3) {
            this.searchErrorMsg = "Please enter at least 3 characters in CBSA Name field.";
            this.searchError = true;
            return;
        }
        if (this.cbsasearch.length >= 3) {
            this.statesearch = "0";
        }


        coveragesearchvalue = this.getCoverages();
        if (coveragesearchvalue == "")
            coveragesearchvalue = "AZ_TY";

        companysearchvalue = this.companysearch == "" ? "AZ_TY" : this.companysearch;
        cbsasearchvalue = this.cbsasearch == "" ? "AZ_TY" : this.cbsasearch;
        carriersearchvalue = this.carriersearch == "" ? "AZ_TY" : this.carriersearch;

        renewalmonthsearchvalue = this.renewalmonth == "0" ? "AZ_TY" : this.renewalmonth;


        brokerstatesearchvalue = "0";
/*            this.spinner.show();*/


        this.contractsservice.getcontracts(1, 1000, this.statesearch,
            companysearchvalue, cbsasearchvalue, coveragesearchvalue,
            this.brokernamesearch, brokerstatesearchvalue, carriersearchvalue, renewalmonthsearchvalue,
            this.totalparticipationminsearch, this.totalparticipationmaxsearch)
            .subscribe(
                (data) => { this.getCustomerCallback(data); });
    }

    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);

        
    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }

    

    /** Dispatched event of a Grid State Changed event */
    gridStateChanged(gridStateChanges: GridStateChange) {
        console.log('Client sample, Grid State changed:: ', gridStateChanges);

    }

    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.gridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.gridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}






