﻿//import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobaldataService } from '../service/globaldata.service';
import { AdminPlansService } from './adminplans.service';
import { AccountDTO } from '../dto/AccountDTO';

import { Observable, forkJoin } from 'rxjs';


import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
    AngularSlickgridComponent,
    Filter,
    OnEventArgs
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';
import { AdminProductsService } from './adminproducts.service';

const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';



@Component({
    templateUrl: './adminproducts.html',
    providers: [
        AdminProductsService,
        ListService
    ]
})
export class AdminProductsComponent extends BaseComponent implements OnInit {

    constructor(private route: ActivatedRoute,
        protected _router: Router,
        public adminproductsservice: AdminProductsService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService,
        private cd: ChangeDetectorRef,
        private globaldataservice: GlobaldataService) {
        super();

        this.route.params.subscribe(params => {
            this.vendor = params.state;
            this.businessyear = params.businessyear;
            this.planid = params.planid;

        });

        var id = this.auth.userobjectid;

    }

    columnDefinitions: Column[] = [];
    gridOptions: GridOption = {};
    // filters: Filter = {};

    mydataset: any[] = [];
    bIsLoaded: boolean = false;
    canShowGrid: boolean = false;
    angularGrid: AngularGridInstance;
    //columnDefinitions: Column[];
    // gridOptions: GridOption;
    //dataset = [];
    metrics: Metrics;

    isCountEnabled = true;
    odataVersion = 2;
    odataQuery = '';
    processing = true;
    totalRowCount: number = 0;
    status = { text: 'processing...', class: 'alert alert-danger' };

    vendorList: SelectList[] =[];
    vendorsearch: string="0";
    vendorcode: string = "";
    planid: string = "";

    userSelectsString = '';
    name = 'Angular';
    userSelects = [];

    searchError: boolean = false;
    searchErrorMsg: string = "";

    gridObj: any[];
    selectedGridIDS: number[];
    selectedTitles: any[];
    selectedTitle: any;
    selectedplanids: any[];
    selectedplannames: any[];
    selectedissuernames: any[];
    numberofSelectedPlans: number = 0;
    ratingareanumber: number = 1;
    ratingareaid: string = "";
    vendor: string = "";
    isplannotselected: boolean = true;
    selectedquoteidx: number = -1;
    businessyear: number = 2021;

    plans: any[] = [];
    carriersearch: string = "";
    dataview: any;
    isvendornotselected: boolean;

    accounts: AccountDTO[] = [];
    


    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
       this.gridObj = angularGrid && angularGrid.slickGrid || {};
       //  this.angularGrid.dataView.setFilter(this.carrierFilter);
    }



    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }

    vendorchange(event) {
        this.vendorsearch = event;
        if (this.vendorsearch != "0") {
            this.isvendornotselected = false;
        }
        else {
            this.isvendornotselected = true;
        }
        this.adminproductsservice.getProductsByVendor(this.vendorsearch).subscribe(data => {
            this.getProductsResponse(data);
        });
    }


    getProductsResponse(response) {

       // var tg = data;


        if (response.data!=null && response.data.length > 0) {

           // this.gridOptions.pagination.totalItems = response.data.length;
            this.totalRowCount = response.data.length;
            this.gridOptions = Object.assign({}, this.gridOptions);

            this.mydataset = response.data;

            this.angularGrid.resizerService.resizeGrid();
           
        }
        else {
            this.totalRowCount = 0;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = [];
            this.searchError = true;
            this.searchErrorMsg = response.data.responseMsg;

        }

    }

    monthchange(event) {
    }

    getVendorName() {
         var vendorname: string;
        for (let vendor of this.vendorList) {
            if (vendor.id == this.vendorsearch) {
                vendorname = vendor.name;
                break;
            }            
        } 

        return vendorname;
    }
    
    ngOnInit(): void {

        this.isvendornotselected = true;

        this.columnDefinitions = [
            {
                id: 'delete',
                field: 'id',
                excludeFromHeaderMenu: true,
                formatter: Formatters.deleteIcon,
                minWidth: 30,
                maxWidth: 30,
                // use onCellClick OR grid.onClick.subscribe which you can see down below
                onCellClick: (e: Event, args: OnEventArgs) => {
                    
                    if (confirm('Are you sure?')) {
                        this.modifyProduct(args.dataContext.id);                           
                    }
                }
            },
            {
                id: 'modify',
                field: 'id',
                excludeFromHeaderMenu: true,
                formatter: Formatters.editIcon,
                minWidth: 30,
                maxWidth: 30,
                // use onCellClick OR grid.onClick.subscribe which you can see down below
                onCellClick: (e: Event, args: OnEventArgs) => {
                    this.modifyProduct(args.dataContext.id);   
                }
            },
            { id: 'product', filterable: true, name: 'Product Id', field: 'productid', sortable: true, width: 275, minWidth: 275 },
            { id: 'productname', name: 'Product Name', field: 'productname', sortable: true, width: 275, minWidth: 275 },
            { id: 'category', name: 'Category', field: 'category', sortable: true, width: 100, minWidth: 100 },
            { id: 'subcategory', name: 'Sub Category', field: 'subcategory', sortable: true, width: 100, minWidth: 100 },
            { id: 'description', name: 'Description', field: 'description', sortable: true, width: 125, minWidth: 125 },
            { id: 'id', filterable: true, name: 'Id', field: 'id', sortable: true, width: 0, minWidth: 0, },
        ];

        this.gridOptions = {
            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            //enableCellNavigation: true,
            // enableFiltering: true,
            //// enableCheckboxSelector: true,

            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },
          //  gridHeight: 225,
          //  gridWidth: 800,
        };

        //this.gridOptions = {
        //    /// enableAutoResize: true,
        //    //enablePagination: true,

        //    autoResize: {
        //        containerId: 'demo-container',
        //        sidePadding: 15
        //    },
        //    enableCellNavigation: true,
        //    enableAutoResize: true,
        //    enableColumnReorder: false,
        //    forceFitColumns: false,
        //    enableRowSelection: true,
        //    // multiSelect: true,


           

        //    // Paging Support
        //    pagination: {
        //        pageSizes: [50, 1000],
        //        pageSize: defaultPageSize,
        //        totalItems: 50
        //    },
        //};
        this.gridOptions.showTopPanel = false;
        // this.dataview.setFilter(this.carrierFilter);      

       

       
        this.adminproductsservice.getVendors(this.auth.userobjectid,3).subscribe(data => {
            this.getVendorsResponse(data);
            });

       

    }

    modifyProduct(id:string) {

        this._router.navigate(['/productdetail',
            {
                isaddoredit: 'EDIT',
                vendorid: this.vendorsearch,
                productid: id,
                vendorname: this.getVendorName(),

            }
        ]);
    }

    deleteProduct(id:string) {
        this.angularGrid.gridService.deleteItemById(id);
    }


    handleSelectedRowsChanged1(e: Event, args: any) {
        if (Array.isArray(args.rows) && this.gridObj) {
            this.selectedTitle = args.rows.map((idx: number) => {
                //const item = this.gridObj.(idx);
               // return item.title || '';
            });
        }
    }

    getVendorsResponse(data) {
        //this.stateList = data;
       // this.vendorList = data.accountaddresslist;


        let idx: number = 0;
        let newidx: number = 0;
        let locationfound: boolean = false;
      //  console.log("Process in getStateHealthPlanSitesCallback data.TotalCount is: " + data.totalcount);

        if (data !== undefined && data.totalcount > 0) {
            this.accounts = data.accountlist;

            while (idx < data.totalcount) {
                this.vendorList.push(
                    {
                        id: this.accounts[idx].accountid,
                        name: this.accounts[idx].accountname
                    }
                )
                idx++;
            }
        }
        else {
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }
       //this.globaldataservice.stateList = this.vendorList;
    }


    onadd() {       

        this.getVendorName();

        this._router.navigate(['/productdetail',
            {
                isaddoredit: 'ADD',
                vendorid: this.vendorsearch,
                vendorname: this.getVendorName(),

            }
        ]);

    }



    reset() {

        this.vendorcode = "";

        //       this.selectedItems = [];
        this.selectedplanids = [];
        this.selectedplannames = [];
        this.selectedissuernames = [];
        // this.angularGrid.gridService.setSelectedRows([]);
        this.totalRowCount = 0;
        this.gridOptions = Object.assign({}, this.gridOptions);
        this.mydataset = [];
        this.searchError = false;
        this.vendor = "";
        this.ratingareaid = "";
        this.ratingareanumber = 0;
        this.vendorsearch = "";

    }


    getPlansByStateCallback(data) {
        if (data.totalcount > 0) {

            this.gridOptions.pagination.totalItems = data.totalcount;
            this.totalRowCount = data.totalcount;
            this.gridOptions = Object.assign({}, this.gridOptions);
            if (this.businessyear == 2020) {
                this.mydataset = data.acaPlans;
                this.plans = data.acaPlans;
            }
            else {
                this.mydataset = data.indplans;
                this.plans = data.indplans;

            }
        }
        else {
            this.totalRowCount = data.totalcount;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = [];
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }
    }


    onSelectedRowsChanged(e, args) {
        if (Array.isArray(args.rows)) {
            if (args.rows.length > 0) {
                this.isplannotselected = false;
                this.selectedquoteidx = args.rows.map(idx => {
                    return idx;
                });
            }
            else {
                this.isplannotselected = true;
            }
        }
    }

    carrierFilter(item, args) {

        let teststr: string = "";

        teststr = item["issuername"];

        if (teststr.startsWith(this.carriersearch)) {
            return true;
        }
        return false;

    }



    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);


    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }

    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.gridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.gridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}






