import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { environmentprod } from '../../environments/environment.prod';
import { environmenttest } from '../../environments/environment.test';
import { environmentgwtest } from '../../environments/environment.gwtest';



export enum AppEnviornment {
    Prod = 1,
    Development,
    PreProd,
    Uat,
    Test
}


@Injectable()
export class EnvironmentService {
    private appConfig;

    constructor() {
 //       //Chnage envrionment 420
     //      this.EnvType = AppEnviornment.Prod;
        this.EnvType = AppEnviornment.Development;
     //   this.EnvType = AppEnviornment.Test;
        if (!this.Environment) {
            this.setConfig();
        }

    }
    EnvType: AppEnviornment;  
    Environment: any;

   private setConfig() {
        if (this.EnvType == AppEnviornment.Development) {
            this.Environment= environment;
        }

       if (this.EnvType == AppEnviornment.Prod) {
           this.Environment = environmentprod;
           
       }

       if (this.EnvType == AppEnviornment.Test) {
           this.Environment = environmentgwtest;

       }

    } 
}



@Injectable()
export class SpinnerManager {   

    constructor() {
        this.IsSpinnnerRunning = false;
        }

    IsSpinnnerRunning: boolean;
    }