﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../core/environment.service';
import { ExtendedHttpService } from '../core/extendedhttp.service';
import { HttpHeaders } from '@angular/common/http';
import { Http } from '@angular/http';
import { catchError, map } from 'rxjs/operators';



const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
@Injectable({
    providedIn: 'root',

})
export class BrokersService extends ExtendedHttpService {

    constructor(private env: EnvironmentService) {
        super();
    }


    getcontracts(pagenumber: number, pagesize: number, state: string, companyname: string, cbsaname: string, coverages: string,
        brokername: string, brokerstate: string, carriersearch: string, renewalmonth, participation_min: number, participation_max: number) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/contract/' + state + '/' + companyname + '/' + cbsaname + '/' + coverages + '/'
            + brokername + '/' + brokerstate + '/' + participation_min + '/' + participation_max + '/' + carriersearch + '/' + renewalmonth + '/' + pagenumber + '/' + pagesize).
            pipe(
                map((data) => data.json())
            );
    }


}
