﻿import { ChangeDetectorRef, Component, Injectable, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminPlansService } from './adminplans.service';
import { ICQuoteDTO } from '../dto/ICQuoteDTO';
import { ACAUniquePlanDTO } from '../dto/ACAUniquePlanDTO';
//import { CalendarModule } from 'primeng/primeng';


import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';
import { ResponseBaseDTO } from '../dto/ResponseBaseDTO';
import { ProductExDTO } from '../dto/ProductExDTO';
import { ProductDTO } from '../dto/ProductDTO';
import { ProductDetailService } from './productdetail.service';
import { VideoDTO } from '../dto/VideoDTO';
import { ImageDetailService } from './imagedetail.service';
import { Formatters, Column, GridOption, AngularGridInstance, OnEventArgs } from 'angular-slickgrid';

const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';



@Component({
    templateUrl: 'productdetail.html',
    providers: [
        ProductDetailService,
        ImageDetailService,
        //CalendarModule,
        ListService
    ]
})
export class ProductDetailComponent extends BaseComponent implements OnInit {

    @ViewChild('videoPlayer') videoPlayer: ElementRef;
    videoClicked = false;
    isCountEnabled = true;
    odataVersion = 2;
    totalRowCount: number = 0;

    name = 'Angular';
    userSelects = [];

    searchError: boolean = false;
    searchErrorMsg: string = "";
    displayMessage: string = "";
    mydataset: any[] = [];
    gridObj: any[];

    zipcode: string = "";
    ratingareanumber: number = 1;
    ratingareaid: string = "";



    useremail: string;
    userobjectid: string;

    icquotedto: ICQuoteDTO;

    planagelist: any[] = [];
    createdon: Date;
    createdby: string;

    isaddoredit: string = "";

    plan: ACAUniquePlanDTO;

    vendorname: string = "";
    vendorid: string = "";
    state: string = "";
    issuername: string = "";
    planmarketingname: string = "";
    businessyear: number;
    effectivedate: Date = new Date();
    planeffectivedatestr: string = "";
    test: string;
    productexdto: ProductExDTO;
    productid: string=null;
    video: boolean;
    link: boolean;
    fileToUpload: any | null = null;
    formdata: FormData;
    columnDefinitions: Column[] = [];
    gridOptions: GridOption = {};
    angularGrid: AngularGridInstance;
    selectedTitle: any;


    isproductnotsaved: boolean = true;
    producteffectivedatestr: string = "";
    

    categorylist: SelectList[] = [
        {
            id: "insurance",
            name: "Insurance (Not Health)"
        },
        {
            id: "lifestyle",
            name: "Life Style"
        },
        {
            id: "flexiblespending",
            name: "Flexible Spending"
        },
        {
            id: "savings",
            name: "Savings"

        }

    ];

    subcategorylist: SelectList[] = [];


    paymenttypelist: SelectList[] = [
        {
            id: "1",
            name: "Contribution"
        },
        {
            id: "2",
            name: "Fixed Cost"
        }
    ];

    constructor(
        public adminplanservice: AdminPlansService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService,
        private cd: ChangeDetectorRef,
        protected _router: Router,
        private route: ActivatedRoute,
        private productdetailsservice: ProductDetailService,
        private imagedetailsservice: ImageDetailService) {

        super();

        this.route.params.subscribe(params => {
            this.isaddoredit = params.isaddoredit;
            this.vendorname = params.vendorname;
            this.vendorid = params.vendorid;
            if (this, this.isaddoredit == "EDIT") {
                this.productid = params.productid;
            }

        });

        this.userobjectid = auth.userobjectid;
        this.useremail = auth.useremail;
       
    }



    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};
        //  this.angularGrid.dataView.setFilter(this.carrierFilter);
    }

    startVideo(): void {
        this.videoClicked = true;
        this.videoPlayer.nativeElement.src = this.productexdto.videodto.videolink;
        this.videoPlayer.nativeElement.play();
    }


    stopVideo(): void {
        this.videoClicked = true;
        this.videoPlayer.nativeElement.pause();
    }

    setradio(data: string) {
        //this.clearList();
        switch (data) {
            case "video":
                this.video = false;
                this.link = true;
                break;

            case "link":
                this.video = true;
                this.link = false;
                break;

        }
    }

    ngOnInit(): void {    
        if (this.isaddoredit == "EDIT") {
            this.productdetailsservice.getProduct(this.productid)
                .subscribe(
                    (data) => { this.getProductResponse(data); },

                );
        }
        else {
            this.productexdto = new ProductExDTO();
            this.productexdto.productdto = new ProductDTO();

            this.productexdto.videodto.videolink = "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4";
        }



        this.columnDefinitions = [
          
            {
                id: 'modify',
                field: 'id',
                excludeFromHeaderMenu: true,
                formatter: Formatters.editIcon,
                minWidth: 30,
                maxWidth: 30,
                // use onCellClick OR grid.onClick.subscribe which you can see down below
                onCellClick: (e: Event, args: OnEventArgs) => {
                    this.modifyimage(args.dataContext);
                }
            },
            { id: 'imagename', filterable: true, name: 'Image name', field: 'imagename', sortable: true, width: 275, minWidth: 275 },
            { id: 'description', name: 'Descritpion', field: 'description', sortable: true, width: 275, minWidth: 275 },           
            { id: 'id', filterable: true, name: 'Id', field: 'id', sortable: true, width: 0, minWidth: 0, },
        ];

        this.gridOptions = {
            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
           
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },
            //  gridHeight: 225,
            //  gridWidth: 800,
        };

        
        this.gridOptions.showTopPanel = false;


    }

    modifyimage(data: any) {

        this._router.navigate(['/imagedetail',
            {
                isaddoredit: 'EDIT',
                imagename: data.imagename,
                entityid: this.productid, 
                //vendorid: this.vendorsearch,
                id: data.id,
               

            }
        ]);
    }


    handleSelectedRowsChanged1(e: Event, args: any) {
        if (Array.isArray(args.rows) && this.gridObj) {
            this.selectedTitle = args.rows.map((idx: number) => {
                //const item = this.gridObj.(idx);
                // return item.title || '';
            });
        }
    }


    addFile(file): void {

        this.fileToUpload = file[0];
    }

    onUpload() {

        this.productexdto.videodto.entityid = this.productid;
        this.imagedetailsservice
            .uploadVideo(this.fileToUpload, this.productexdto.videodto)
            .subscribe(res => {
                this.onuploadResponse(res);
            });
    }

    onuploadResponse(response) {
        //var image: ImageDTO = response.data;
        //let imageBinary = image.image; //image binary data response from api
        let imageBase64String = response.data;
       // this.imageUrl = 'data:image/jpeg;base64,' + imageBase64String;
    }

    onaddImage() {
        //this.getVendorName();

        this._router.navigate(['/imagedetail',
            {
                isaddoredit: 'ADD',
                entityid: this.productid, 
                productname: this.productexdto.productdto.productname

            }
        ]);
    }

    onmodifyResponse(data:any) {

        var test = data;
    }

    onmodify() {

        //this.productexdto.videodto.
        this.productdetailsservice.updateProduct(this.productexdto)
            .subscribe(
                (data) => { this.onmodifyResponse(data); },

            );
    }


    onadd() {
        this.productexdto.productdto.vendorid = this.vendorid;
        this.productdetailsservice.addProduct(this.productexdto)
            .subscribe(
                (data) => { this.addProductResponse(data); },

            );
        
    }

    getProductResponse(response) {
        this.productexdto = response.data;
        this.productexdto.videodto = new VideoDTO();
        this.productexdto.videodto.videolink = "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4";


        if (response.data != null && this.productexdto.imageviewdtos.length > 0) {

            // this.gridOptions.pagination.totalItems = response.data.length;
            this.totalRowCount = this.productexdto.imageviewdtos.length;
            this.gridOptions = Object.assign({}, this.gridOptions);

            this.mydataset = this.productexdto.imageviewdtos;

           // this.angularGrid.resizerService.resizeGrid();

        }
        else {
            this.totalRowCount = 0;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = [];
            this.searchError = true;
            this.searchErrorMsg = response.data.responseMsg;

        }

    }

    addProductResponse(response) {    

        this.productid = response.data.productdto.id;
    }

    changeProductCategory(target) {
        this.productexdto.productdto.category = target.value;
        this.isproductnotsaved = true;
    }


    changeProductPaymentType(target) {
        this.productexdto.productdto.paymenttype = target.value;
        this.isproductnotsaved = true;
    }

    changeEffectiveDate(e: any) {
        var a;
        let adate: Date;

        console.log("***** change product effectivce date: " + e.target.value);
        a = e.target.value;
        this.productexdto.productdto.effectivedate = new Date(a);
        this.producteffectivedatestr = (this.productexdto.productdto.effectivedate.getUTCMonth() + 1).toString() + "/" + this.productexdto.productdto.effectivedate.getUTCDate().toString() + "/" + this.productexdto.productdto.effectivedate.getUTCFullYear().toString();
        this.isproductnotsaved = true;

    }


    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }




}






