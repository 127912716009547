﻿
//Auto Generated file

  
export class ICEmployerPlanQuoteDTO {
		// This file is generated from the entities located in the MAKTech.Entities project
 
        public id: string;
        public requestaccessid: string;
        public brokerid: string;
        public ownerid: string;
        public city: string;
        public state: string;
        public postalcode: string;
        public ratingarea: string;
        public currentemployeemonthlyrate: number;
        public totalcurrentemployeemonthlyrate: number;
        public effectivedate: Date;
        public totalmembers: number;
        public currentissuer: string;
        public currentdeductible: string;
        public unitsbyage: string;
        public quotestatus: string;
        public planname1: string;
        public planname2: string;
        public planname3: string;
        public planname4: string;
        public issuername1: string;
        public issuername2: string;
        public issuername3: string;
        public issuername4: string;
        public singledeductible1: string;
        public singledeductible2: string;
        public singledeductible3: string;
        public singledeductible4: string;
        public familydeductible1: string;
        public familydeductible2: string;
        public familydeductible3: string;
        public familydeductible4: string;
        public singleoopmax1: string;
        public singleoopmax2: string;
        public singleoopmax3: string;
        public singleoopmax4: string;
        public familyoopmax1: string;
        public familyoopmax2: string;
        public familyoopmax3: string;
        public familyoopmax4: string;
        public totalcost1: number;
        public totalcost2: number;
        public totalcost3: number;
        public totalcost4: number;
        public totalcostdifference1: number;
        public totalcostdifference2: number;
        public totalcostdifference3: number;
        public totalcostdifference4: number;
        public createdon: Date;
        public createdby: string;
        public modifiedon: Date;
        public modifiedby: string;
    }
