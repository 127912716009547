﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../core/environment.service';
import { ExtendedHttpService } from '../core/extendedhttp.service';
import { HttpHeaders } from '@angular/common/http';
import { Http } from '@angular/http';
import { catchError, map } from 'rxjs/operators';




const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
@Injectable({
    providedIn: 'root',

})
export class LogFactService extends ExtendedHttpService {

    constructor(private env: EnvironmentService) {
        super();
    }



    getLogFacts(employerid: string, startdate: Date, enddate: Date) {

        let startdatestr: string = this.formatdate(startdate);
        let enddatestr: string = this.formatdate(enddate);


        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/LogFact/' + employerid + '/' + startdatestr + '/' + enddatestr).
            pipe(
                map((data) => data.json())
            );
    }


    getLogFactsByOption(employerid: string, startdate: Date, enddate: Date, option: number) {

        let startdatestr: string = this.formatdate(startdate);
        let enddatestr: string = this.formatdate(enddate);


        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/LogFact/' + employerid + '/' + startdatestr + '/' + enddatestr + '/' + option).
            pipe(
                map((data) => data.json())
            );
    }


    formatdate(wrkDate: Date): string {

        let wrkstr: string;
        let month: number;

        month = wrkDate.getUTCMonth();
        month = month + 1;

        wrkstr = wrkDate.getUTCFullYear().toString() + "-" + month.toString() + "-" + wrkDate.getUTCDate().toString();
        return wrkstr;

    }


}
