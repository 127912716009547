﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../core/environment.service';
import { ExtendedHttpService } from '../core/extendedhttp.service';
import { HttpHeaders } from '@angular/common/http';
import { Http } from '@angular/http';
import { catchError, map } from 'rxjs/operators';
import { ICQuoteDTO } from '../dto/ICQuoteDTO';



const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
@Injectable({
    providedIn: 'root',

})
export class TotalCostService extends ExtendedHttpService {

    constructor(private env: EnvironmentService) {
        super();
    }


    getPlansForServiceArea(zipcode: string) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/ACAUniquePlans/' + zipcode).
            pipe(
                map((data) => data.json())
            );
    }


    getPlansAndRates(planids: string, ratingareaid: string) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/ACAPlansAndRates/' + planids + '/' + ratingareaid).
            pipe(
                map((data) => data.json())
            );
    }

    getRatesForComparison(planids: string, ratingareaid: string, plancostcurrent: number, employercontributioncurrent: number, employeecontributioncurrent: number) {


        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/PlanCostCompare/' + planids + '/' + ratingareaid + '/' + plancostcurrent + '/' + employercontributioncurrent + '/'
            + employeecontributioncurrent).
            pipe(
                map((data) => data.json())
            );

    }


    getRatesForComparisonByYear(planids: string, ratingareaid: string, plancostcurrent: number, employercontributioncurrent: number, employeecontributioncurrent: number, planyear: string, countyname: string, state: string) {
    // 28 Jan 2022 JJM Only called when user clicks on showagerates on the quotedetail page.

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/PlanCostCompare/' + planids + '/' + ratingareaid + '/' + plancostcurrent + '/' + employercontributioncurrent + '/'
            + employeecontributioncurrent + '/' + planyear + '/marker' + '/' + countyname + '/' + state).
            pipe(
                map((data) => data.json())
            );

    }

    getPlanCostSummary(planids: string, unitsbyage: string, ratingareaid: string, totalmonthlycost: number, medicarecost: number, planyear: string, countyname: string, state: string) {


        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/PlanCostCompare/' + planids + '/' + unitsbyage + '/' + ratingareaid
            + '/' + totalmonthlycost + '/' + medicarecost + '/' + planyear + '/' + countyname + '/' + state).
            pipe(
                map((data) => data.json())
            );

    }

    //postIndivdualCoverageByUnitsQuote(planids: string, totalmonthlycost: number, medicarecost: number, effectivedate: string,
    //    postalcode: string, ratingarea: string, employername: string, quotename: string, quotestatus: string, ownerid: string, unitsbyage: string) {


    //    return this.postData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/PlanCostCompare/', planids + '/' + totalmonthlycost  + '/' + medicarecost + '/'
    //        + effectivedate + '/' + postalcode + '/' + ratingarea + '/' + employername + '/' + quotename + '/' + quotestatus + '/' + ownerid + '/' + unitsbyage).
    //        pipe(
    //            map((data) => data.json())
    //        );



    //}

    postTest(test: string) {
        return this.postData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/PlanCostCompare/', test).
            pipe(
                map((data) => data.json())
            );

    }

    postIndivdualCoverageByUnitsQuote(icquotedto: ICQuoteDTO) {


        return this.postData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/ICQuote/', icquotedto).
            pipe(
                map((data) => data.json())
            );
    }







}
