﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../core/environment.service';
import { ExtendedHttpService } from '../core/extendedhttp.service';
import { HttpHeaders } from '@angular/common/http';
import { Http } from '@angular/http';
import { catchError, map } from 'rxjs/operators';
import { ICEmployerPlanQuoteDTO } from '../dto/ICEmployerPlanQuoteDTO';


const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
@Injectable({
    providedIn: 'root',

})
export class EmpPlanComparisonService extends ExtendedHttpService {

    constructor(private env: EnvironmentService) {
        super();
    }


    getPlans(brokerid: string, employeruserid: string, zipcode: string, businessyear: number, effectivedate: string, employeemonthlypayment: number, insurername: string, deductible: string) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/EmpPlanComparison/' + brokerid + '/' + employeruserid + '/' + zipcode + '/' + businessyear + '/' + "2020-06-01"
            + '/' + employeemonthlypayment + '/' + insurername + '/' + deductible).
            pipe(
                map((data) => data.json())
            );
    }


    postICEmployerPlanQuote(emplanquote: ICEmployerPlanQuoteDTO) {


        return this.postData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/EmpPlanComparison/', emplanquote).
            pipe(
                map((data) => data.json())
            );


    }
}