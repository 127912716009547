import { Component, Injector } from '@angular/core';
import { SidebarService } from './sidebar/sidebar.service';
import { B2CAuthService } from './auth/b2cauth.service';
import { EnvironmentService } from './core/environment.service';
import { appInjector } from './core/util.service';
import 'core-js/es6/array';
import 'core-js/es6/string';
import 'core-js/es7/reflect';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'angular-pro-sidebar';
     
    constructor(public sidebarservice: SidebarService,
        public auth: B2CAuthService, env: EnvironmentService,
        private injector: Injector) {
        appInjector(injector);

        var x = window.matchMedia("(max-width: 768px)")
        this.myFunction(x) // Call listener function at run time
        x.addListener(this.myFunction) // Attach listener function on state changes
      
        document.body.addEventListener('click', this.clickBody, true); 
        
    }

    clickBody() {
        var x = window.matchMedia("(max-width: 768px)")
        if (x.matches) { // If media query matches
            //document.body.style.backgroundColor = "yellow";
            if (document.getElementById("sidebar")) {
                document.getElementById("sidebar").style.display = "none";
            }

        } else {
            // document.body.style.backgroundColor = "pink";
            if (document.getElementById("sidebar")) {
                document.getElementById("sidebar").style.display = "block";
            }

        }
    }


  toggleSidebar() {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
  }
  toggleBackgroundImage() {
    this.sidebarservice.hasBackgroundImage = !this.sidebarservice.hasBackgroundImage;
  }
  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  hideSidebar() {
    this.sidebarservice.setSidebarState(true);
  }

    showSidebar() {
        this.sidebarservice.setSidebarState(false);
    }

     myFunction(x):void {
    if (x.matches) { // If media query matches
        //document.body.style.backgroundColor = "yellow";
        if (document.getElementById("sidebar")) {
            document.getElementById("sidebar").style.display = "none";
        }
       
    } else {
       // document.body.style.backgroundColor = "pink";
        if (document.getElementById("sidebar")) {
            document.getElementById("sidebar").style.display = "block";
        }
        
    }
}



    togglesidebar() {
        //this.sidebarservice.setSidebarState(false);

        var mq = window.matchMedia("(max-width: 768px)");
        if (mq.matches) {
            // window width is at less than 570px
            document.getElementById("sidebar").style.display = "block";
        }
        else {
            document.getElementById("sidebar").style.display = "none";
        }
        //document.getElementById("sidebar").style.visibility = "visible";
    }
    doLogout() {
        this.auth.logout()          
    }
}
