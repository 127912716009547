﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../core/environment.service';
import { ExtendedHttpService } from '../core/extendedhttp.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Http, RequestOptions } from '@angular/http';
import { catchError, map } from 'rxjs/operators';
import { ProductExDTO } from '../dto/ProductExDTO';
import { ImageDTO } from '../dto/ImageDTO';
import { VideoDTO } from '../dto/VideoDTO';




const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
@Injectable({
    providedIn: 'root',

})
export class ImageDetailService extends ExtendedHttpService {

    constructor(private env: EnvironmentService, private http: HttpClient) {
        super();
    }



    addImage(imagedto: ImageDTO) {

        return this.postData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Image/', imagedto).
            pipe(
                map((data) => data.json())
            );
    }


    getProduct(productid: string) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/ProductV2/GetProduct/' + productid).
            pipe(
                map((data) => data.json())
            );
    }

    getImage(imageid: string) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Image/GetImage/' + imageid).
            pipe(
                map((data) => data.json())
            );
    }
   

    upload(fileToUpload: any) {

        const httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'               
            })
        };


        let headers = new Headers();
        /** No need to include Content-Type in Angular 4 */
        headers.append('Content-Disposition', 'multipart/form-data');
        headers.append('Accept', 'application/json');

       // let options = new RequestOptions({ headers: headers });
        //  this.http.post(`${this.apiEndPoint}`, formData, options)

        return this.postData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Image/uploadimage/', headers,fileToUpload).
            pipe(
                map((data) => data.json())
            );

    }



    public uploadFilenew(file: any, imageDTO: ImageDTO): Observable<any> {
        let input = new FormData();
        input.append("image", file);
        input.append("imagename", file.name);
        input.append("description", imageDTO.description);
        input.append("entityid", imageDTO.entityid);

       // let headers = new Headers();
        /** No need to include Content-Type in Angular 4 */
        //headers.append('Content-Type', 'multipart/form-data');
       // headers.append('Accept', 'application/json');

        let headers = new HttpHeaders();
        //headers.append('Content-Type', 'application/json');
        headers.append('Content-Type', 'multipart/form-data');
        const httpOptions = {
            headers: headers
        };  
        return this.http.post("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Image/uploadimage/', input, httpOptions);
    }

    public uploadVideo(file: any, videoDTO: VideoDTO): Observable<any> {
        let input = new FormData();
        input.append("video", file);
        input.append("videoname", file.name);
        input.append("description", videoDTO.description);
        input.append("entityid", videoDTO.entityid);
     
        // let headers = new Headers();
        /** No need to include Content-Type in Angular 4 */
        //headers.append('Content-Type', 'multipart/form-data');
        // headers.append('Accept', 'application/json');

        let headers = new HttpHeaders();
        //headers.append('Content-Type', 'application/json');
        headers.append('Content-Type', 'multipart/form-data');
        const httpOptions = {
            headers: headers
        };
        return this.http.post("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Video/uploadvideo', input, httpOptions);
    }
}