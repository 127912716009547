﻿import { Injectable } from '@angular/core';
import { AccountAndAddressDTO } from '../dto/AccountAndAddressDTO';
import { MemberDTO } from '../dto/MemberDTO';
import { OrderViewDTO } from '../dto/OrderViewDTO';
import { OrderItemDTO } from '../dto/OrderItemDTO';
import { MemberDependentDTO } from '../dto/MemberDependentDTO';
import { USZipCountyDTO } from '../dto/USZipCountyDTO';
import { TeamDTO } from '../dto/TeamDTO';


@Injectable({
    providedIn: 'root'
})
export class GlobaldataService {



    constructor() {

        this.currentemployer = new AccountAndAddressDTO();
    }

    stateList: States[];
    role: string = "";


    // 27 Jan 2022 JJM used to manage multiple counties mapped to a zip
    locationList: SelectList[] = [];
    zipcode: string = "";
    oldzipcode: string = "";
    counties: string[] = [];
    countyname: string = "";
    countycode: string = "";
    oldcountycode: string = "";
    countystatecitylist: USZipCountyDTO[] = [];
    selectcounty: SelectList[] = [];



    policyeffectivedateafterdatestr: string = "";

    // Variables used to determine how the system should be run
    // Mostly based on the authorizations of the user
    employeazesetup: boolean = false;
    useremail: string;
    userobjectid: string;
    employercount: number = 0;
    employers: AccountAndAddressDTO[] = [];
    currentemployer: AccountAndAddressDTO;
    teamlist: TeamDTO[] = [];

    members: MemberDTO[] = [];
    member: MemberDTO;
    memberTotalCount: number = 0;


    orders: OrderViewDTO[] = [];
    order: OrderViewDTO;
    orderTotalCount: number = 0;
    orderdetails: OrderItemDTO[] = [];

    dependents: MemberDependentDTO[] = [];
    dependent: MemberDependentDTO;
    dependentTotalCount: number = 0;


    // used by order.component to remember where we are
    order_employerid: string = "none";
    manage_order_called: number = 0; // 0 - neutral, 1 - Manage Orders Called, 2 - Back pressed in Order Detail Page

    // used by the employerbilling.component
    employerbillingasofdatestr: string = "";






}
