﻿

import { Component, OnInit, AfterViewInit, Directive, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

declare var $: any;

@Component({
    
    providers: []

})
export class BaseComponent implements OnInit {
    theme: string;
    brheader: string;
    brlogotext: string;
    public brcardcolor: string;
    brcardsectioncolor: string;
    brbtncolor: string;
    brside: string;
    brdgheadertext: string;
    phonemask: string;
    dateFormat: string;


    redbasiccolor: string = "#ef5350";
    purplebasiccolor: string = "#ab47bc";
    bluebasiccolor: string = "#26c6da";
    greenbasiccolor: string = "#66bb6a";
    orangebasiccolor: string = "#ffa726";
    operationbuttonText: string;
    cancelbuttonText: string;
    backbuttonText: string;
    closebuttonText: string;
    brtitletext: string;
    brbigtext: string;
    brmediumtext: string;
    brsmalltext: string;

    constructor() {
        this.theme = "blue";
        this.brheader = "br-header-" + this.theme;
        this.brlogotext = "br-logo-text";
        this.brcardcolor = "br-card-color-" + this.theme;
        this.brcardsectioncolor = "br-card-section-color-" + this.theme;
        this.brbtncolor = "br-btn-color-" + this.theme;
        this.brdgheadertext = "br-dg-header-text-" + this.theme;
        this.brbigtext = "br-big-text-" + this.theme;
        this.brmediumtext = "br-medium-text-" + this.theme;
        this.brsmalltext = "br-small-text-" + this.theme;

        this.brtitletext = "br-title-text-" + this.theme;
        this.setDocumentVariable('--mycolor', this.getColor());

        this.setDocumentVariable('--popupheadercolor', this.getColor());

    }

   setDocumentVariable (propertyName, value) {
    document.documentElement.style.setProperty(propertyName, value);
    }

    getdateFormat() {
       
    }
   getColor()
   {
       var color;
       switch (this.theme) {
           case "red":
               color = this.redbasiccolor;
               break;
           case "purple":
               color = this.purplebasiccolor;
               break;
           case "green":
               color = this.greenbasiccolor;
               break;
           case "orange":
               color = this.orangebasiccolor;
               break;
           case "blue":
               color = this.bluebasiccolor;
               break;

       }

       return color;
   }

    brspinnercolor()
    {
      
        switch (this.theme)
        {
            case "red":
                let stylered  = {
                    // CSS property names
                    'background-color': this.redbasiccolor      // italic

                };
                return stylered;
            case "purple":
               let stylespurple = {
                    // CSS property names
                   'background-color': this.purplebasiccolor      // italic

                };
               return stylespurple;
            case "green":
                let stylesgreen = {
                    // CSS property names
                    'background-color': this.greenbasiccolor      // italic

                };
                return stylesgreen;
            case "orange":
                let stylesorange = {
                    // CSS property names
                    'background-color': this.orangebasiccolor      // italic

                };
                return stylesorange;
            case "blue":
                let stylesblue = {
                    // CSS property names
                    'background-color': this.bluebasiccolor      // italic

                };
                return stylesblue;
        }
        
        
    }

    sidebarselectioncolor(menu: any) {
        let styles;
        var isSelected: boolean;
        isSelected = menu.active;

        if (menu) {
            if (menu.colorreset) {
                styles = {
                    // CSS property names
                    'background-color': '#2c405e'      // italic

                };
                return styles;
            }
        }
        if (isSelected) {
             styles = {
                // CSS property names
                 'background-color': '#d7f2f3' ,
                 'color': '#2c405e'     // italic

            };
        }
        else {
             styles = {
                // CSS property names
                 'background-color': '#2c405e'      // italic

            };
        }
       
        return styles;
    }

    brpopupbuttoncolor() {
        let styles = {
            // CSS property names
            'color': 'white'      // italic

        };
        return styles;
    }

    brheadertext() {
        let styles = {
            // CSS property names
            'color': 'white'      // italic

        };
        return styles;
    }

    braddiconcolor() {
        let styles = {
            // CSS property names
            'color': this.getColor()   ,     // italic
            'font-weight':'bold'

        };
        return styles;
    }


    brsavebutton() {
        let styles = {
            // CSS property names
            'width': '125px'     
            

        };
        return styles;
    }

    brcancelbutton() {
        let styles = {
            
             'width': '125px' 

        };
        return styles;
    }

    brsaveandclosebutton() {
        let styles = {
            'width': '200px' 

        };
        return styles;
    }


    brgridediticoncolor()
    {
        let styles = {
            // CSS property names
            'color': this.getColor()
        };
        return styles;
    }

    brfootertextcolor() {
        let styles = {
            // CSS property names
            'color': this.getColor()    
        };
        return styles;
    }

    ngAfterViewInit() {
    }

    setDate(aDate: string): Date {

        let datestr: string;
        let resultdate: Date;
        var datetemp;

        datetemp = aDate.split('-');
        datestr = datetemp[1] + '/' + datetemp[2] + '/' + datetemp[0];

        resultdate = new Date(datestr);

        return resultdate;
    }


    moneyFormatter(row, cell, value, columnDef, dataContext) {
        const numberWithCommas = (x) => {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }

        var wrk = "";
        let result: string = "";
        let ind: number = 0;

        wrk = value;
        wrk = wrk + "";
        ind = wrk.indexOf(".");
        if (ind > -1)
            wrk = wrk.substr(0, ind);
        result = "$" + numberWithCommas(wrk);
        return result;
    }


    moneyAccountingFormatter(row, cell, value, columnDef, dataContext) {
        const numberWithCommas = (x) => {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }

        var wrk = "";
        let result: string = "";
        let ind: number = 0;

        wrk = value;
        wrk = wrk + "";
        ind = wrk.indexOf(".");
        if (ind > -1)
            wrk = wrk.substr(0, ind);
        result = numberWithCommas(wrk);

        ind = result.indexOf("-");
        if (ind === 0) {  // is this a negative dollar amount
            result = result.substr(1);
            result = "<span style='color: red;'>$(" + result + ")</span>";
        }
        else {
            result = "<span style='color: green;'>$" + result + "</span>";
        }

        return result;
    }

  
    ngOnInit() {
       // this.globalService.loadingService.stop();
      


    }


}


