import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import CounterModule from './counter/counter.module';
import { CallbackComponent } from './core/callback.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './auth/auth.guard.spec';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ContractsComponent } from './contracts/contracts.component';
import { AllContractsComponent } from './contracts/allcontracts.component';
import { CompanyComponent } from './companies/company.component';
import { CompanyUNQComponent } from './companies/companyunq.component';
import { BrokersComponent } from './contracts/brokers.component';
import { ACARateCalcComponent } from './rates/acaratecalc.component';
import { ACAPlanLookupComponent } from './rates/acaplanlookup.component';
import { ACAPlansAndRatesComponent } from './rates/acaplansandrates.component';
import { PlanCostCompareComponent } from './rates/plancostcompare.component';
import { TotalCostComponent } from './rates/totalcost.component';
import { ICQuoteComponent } from './rates/icquote.component';
import { AdminPlansComponent } from './admin/adminplans.component';
import { AdminPlanDetailComponent } from './admin/adminplandetail.component';
import { AdminPlanDetail2021Component } from './admin/adminplandetail2021.component';
import { ContributionStrategyComponent } from './rates/contributionstrategy.component';
import { MYStrategiesComponent } from './rates/mystrategies.component';
import { AdminContributionsComponent } from './admin/admincontributions.component';
import { EmpPlanComparisonComponent } from './employer/empplancomparison.component';
import { OnboardComponent } from './employee/onboard.component';
import { BrokerManagementComponent } from './admin/brokermanagement.component';
import { AdminRequestAccessComponent } from './admin/adminrequestaccess.component';
import { ActivityComponent } from './activity/activity.component';
import { EmployerAccountComponent } from './employer/employeraccount.component';
import { AccountDetailComponent } from './employer/accountdetail.component';
import { QuoteDetailComponent } from './employer/quotedetail.component';
import { VersionComponent } from './admin/version.component';
import { HasUnsavedDataGuard } from './core/hasunsaveddataguard';
import { AdminQuotesComponent } from './admin/adminquotes.component';
import { OrderComponent } from './employeaze/order.component';
import { OrderPayrollComponent } from './admin/orderpayroll.component';
import { EmployerSetupComponent } from './employeaze/employersetup.component';
import { EmployerContributionComponent } from './employeaze/employercontribution.component';
import { MemberComponent } from './employeaze/member.component';
import { LogFactComponent } from './admin/logfact.component';
import { AdminProductsComponent } from './admin/adminproducts.component';
import { ProductDetailComponent } from './admin/productdetail.component';
import { ImageDetailComponent } from './admin/imagedetail.component';
import { EnrollmentComponent } from './employer/enrollment.component';
import { Nfor1CustomerComponent } from './employeaze/nfor1customer.component';
import { OrderDetailComponent } from './employeaze/orderdetail.component';
import { UserComponent } from './admin/user.component';
import { UserDetailComponent } from './admin/userdetail.component';
import { DependentDetailComponent } from './employeaze/dependentdetail.component';
import { OrderPivotComponent } from './employeaze/orderpivot.component';
import { EmployerBillingComponent } from './employeaze/employerbilling.component';
import { TeamComponent } from './admin/team.component';

export const routes: Routes = [

    //{
    //    path: '',
    //    component: HomeComponent
        
    //},
   
    {
        path: 'counter',
        loadChildren: './counter/counter.module',    
        canActivate: [
            AuthGuard
        ]

    },
    {
        path: 'callback',
        component: CallbackComponent,

    },
    {
        path: '',
        component: HomeComponent,
        pathMatch: 'full'

    },
    {
        path: 'redirect',
        component: ACAPlanLookupComponent,
        canActivate: [
            AuthGuard
        ]


    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [
            AuthGuard
        ]
        

    },
    {
        path: 'contracts',
        component: ContractsComponent,
        canActivate: [
            AuthGuard
        ]

    },

    {
        path: 'brokers',
        component: BrokersComponent,
        canActivate: [
            AuthGuard
        ]

    },

    {
        path: 'acaratecalc',
        component: ACARateCalcComponent,
        canActivate: [
            AuthGuard
        ]

    },

    {
        path: 'acaplanlookup',
        component: ACAPlanLookupComponent,
        canActivate: [
            AuthGuard
        ]

    },
    {
        path: 'icquote',
        component: ICQuoteComponent,
        canActivate: [
            AuthGuard
        ]

    },
    {
        path: 'employeraccount',
        component: EmployerAccountComponent,
        canActivate: [
            AuthGuard
        ]

    },
    {
        path: 'nfor1customer',
        component: Nfor1CustomerComponent,
        canActivate: [
            AuthGuard
        ]

    },
    {
        path: 'mystrategies',
        component: MYStrategiesComponent,
        canActivate: [
            AuthGuard
        ]

    },
    {
        path: 'acaplansandrates',
        component: ACAPlansAndRatesComponent,
        canActivate: [
            AuthGuard
        ]

    },

    {
        path: 'plancostcompare',
        component: PlanCostCompareComponent,
        canActivate: [
            AuthGuard
        ]

    },

    {
        path: 'totalcost',
        component: TotalCostComponent,
        canActivate: [
            AuthGuard
        ],
        canDeactivate: [
            HasUnsavedDataGuard
        ]

    },

    {
        path: 'contributionstrategy',
        component: ContributionStrategyComponent,
        canActivate: [
            AuthGuard
        ]

    },

    {
        path: 'adminplans',
        component: AdminPlansComponent,
        canActivate: [
            AuthGuard
        ]

    },

    {
        path: 'adminproducts',
        component: AdminProductsComponent,
        canActivate: [
            AuthGuard
        ]

    },

    {
        path: 'admincontributions',
        component: AdminContributionsComponent,
        canActivate: [
            AuthGuard
        ]

    },

    {
        path: 'broker',
        component: BrokerManagementComponent,
        canActivate: [
            AuthGuard
        ]

    },
    {
        path: 'adminrequestaccess',
        component: AdminRequestAccessComponent,
        canActivate: [
            AuthGuard
        ]

    },
    {
        path: 'empplancomparison',
        component: EmpPlanComparisonComponent,
        canActivate: [
            AuthGuard
        ]

    },
    {
        path: 'onboard',
        component: OnboardComponent,
        canActivate: [
            AuthGuard
        ]

    },

    {
        path: 'adminplandetail',
        component: AdminPlanDetailComponent,
        canActivate: [
            AuthGuard
        ]

    },

    {
        path: 'productdetail',
        component: ProductDetailComponent,
        canActivate: [
            AuthGuard
        ]

    },

    {
        path: 'imagedetail',
        component: ImageDetailComponent,
        canActivate: [
            AuthGuard
        ]

    },

    {
        path: 'adminplandetail2021',
        component: AdminPlanDetail2021Component,
        canActivate: [
            AuthGuard
        ]

    },

    {
        path: 'activity',
        component: ActivityComponent,
        canActivate: [
            AuthGuard
        ]

    },
    {
        path: 'accountdetail',
        component: AccountDetailComponent,
        canActivate: [
            AuthGuard
        ]

    },
    {
        path: 'quotedetail',
        component: QuoteDetailComponent,
        canActivate: [
            AuthGuard
        ]
        //canDeactivate: [
        //    HasUnsavedDataGuard
        //]

    },
    {
        path: 'employersetup',
        component: EmployerSetupComponent,
        canActivate: [
            AuthGuard
        ]
    },
    {
        path: 'enrollment',
        component: EnrollmentComponent,
        canActivate: [
            AuthGuard
        ]
    },
    {
        path: 'employercontribution',
        component: EmployerContributionComponent,
        canActivate: [
            AuthGuard
        ]
    },
    {
        path: 'member',
        component: MemberComponent,
        canActivate: [
            AuthGuard
        ]
    },
    {
        path: 'orderdetail',
        component: OrderDetailComponent,
        canActivate: [
            AuthGuard
        ]
    },
    {
        path: 'logfact',
        component: LogFactComponent,
        canActivate: [
            AuthGuard
        ]
    },
    {
        path: 'version',
        component: VersionComponent,
        canActivate: [
            AuthGuard
        ]
    },
    {
        path: 'adminquotes',
        component: AdminQuotesComponent,
        canActivate: [
            AuthGuard
        ]
    },
    {
        path: 'allcontracts',
        component: AllContractsComponent,
        canActivate: [
            AuthGuard
        ]

    },

    {
        path: 'companies',
        component: CompanyComponent,
        canActivate: [
            AuthGuard
        ]

    },

    {
    path: 'companyunqs',
    component: CompanyUNQComponent,
    canActivate: [
        AuthGuard
    ]

    },

    {
        path: 'order',
        component: OrderComponent,
        canActivate: [
            AuthGuard
        ]
    },

    {
        path: 'orderpayroll',
        component: OrderPayrollComponent,
        canActivate: [
            AuthGuard
        ]
    },
    {
        path: 'user',
        component: UserComponent,
        canActivate: [
            AuthGuard
        ]
    },
    {
        path: 'userdetail',
        component: UserDetailComponent,
        canActivate: [
            AuthGuard
        ]
    },
    {
        path: 'dependentdetail',
        component: DependentDetailComponent,
        canActivate: [
            AuthGuard
        ]
    },
    {
        path: 'orderpivot',
        component: OrderPivotComponent,
        canActivate: [
            AuthGuard
        ]
    },
    {
        path: 'employerbilling',
        component: EmployerBillingComponent,
        canActivate: [
            AuthGuard
        ]
    },
    {
        path: 'team',
        component: TeamComponent,
        canActivate: [
            AuthGuard
        ]
    }

    //{
    //    path: 'login',
    //    //component: LoginComponent
    //}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
