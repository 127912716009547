﻿import { Component, OnInit } from '@angular/core';
import { ContractsService } from './contracts.service';


import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { AllContractsService } from './allcontracts.service';
import { EnvironmentService } from '../core/environment.service';
import { getversionedURl } from '../core/util.service';

const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';



@Component({
    templateUrl: './allcontracts.component.html',
    providers: [
        AllContractsService
    ]
})
export class AllContractsComponent extends BaseComponent implements OnInit {

    constructor(public contractsservice: AllContractsService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService) {
        super();
    }

    columnDefinitions: Column[] = [];
    gridOptions: GridOption = {};
    mydataset: any[] = [];
    bIsLoaded: boolean = false;
    canShowGrid: boolean = false;
    angularGrid: AngularGridInstance;
    //columnDefinitions: Column[];
    // gridOptions: GridOption;
    //dataset = [];
    metrics: Metrics;

    isCountEnabled = true;
    odataVersion = 2;
    odataQuery = '';
    processing = true;
    status = { text: 'processing...', class: 'alert alert-danger' };


    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
    }

    ngOnInit(): void {
        
        this.columnDefinitions = [

                { id: 'companyname', name: 'Company Name', field: 'companyname', sortable: true },
                { id: 'state', name: 'State', field: 'state', sortable: true },
                { id: 'cbsaname', name: 'CBSA Name', field: 'cbsaname', sortable: true },
                { id: 'carriername', name: 'Career Name', field: 'carriername' },
               { id: 'Coverages', name: 'Coverages', field: 'broker1Name' },
                { id: 'broker1Name', name: 'Broker 1', field: 'broker1Name' },
                { id: 'personscovered_eoy_cnt', name: 'Participant', field: 'personscovered_eoy_cnt' }

            ];
            this.gridOptions = {
                enableAutoResize: true,
                enablePagination: true,
                autoResize: {
                    containerId: 'demo-container',
                    sidePadding: 15
                },              
                enableCellNavigation: true,
                 enableFiltering: true,
                // enableCheckboxSelector: true,
                 enableRowSelection: true,
                pagination: {
                    pageSizes: [1000],
                    pageSize: defaultPageSize,
                    totalItems: 50
                },

                backendServiceApi: {
                    service: new GridOdataService(),
                    options: {
                        executeProcessCommandOnInit: false,
                    },
                    preProcess: () => this.displaySpinner(true),
                    process: (query) => this.getCustomerApiCall(query),
                    postProcess: (response) => {
                        this.metrics = response.metrics;
                        this.displaySpinner(false);
                        this.getCustomerCallback(response);
                    }
                }
        };


        this.gridOptions.showTopPanel=false;

    }


    search() {
        this.canShowGrid = true;
        //this.spinner.show();
        this.angularGrid.pluginService.refreshBackendDataset();
    }

    displaySpinner(isProcessing) {
        this.processing = isProcessing;
        this.status = (isProcessing)
            ? { text: 'processing...', class: 'alert alert-danger' }
            : { text: 'done', class: 'alert alert-success' };
    }



    getCustomerCallback(data) {

        this.gridOptions.pagination.totalItems = data.totalcount;
        this.gridOptions = Object.assign({}, this.gridOptions);
        this.mydataset = data.contractlist;
        this.spinner.hide();
    }

    getCustomerApiCall(query) {
        // in your case, you will call your WebAPI function (wich needs to return a Promise)
        // for the demo purpose, we will call a mock WebAPI function
        return this.contractsservice.getData();
    }

    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);
    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }

    

    /** Dispatched event of a Grid State Changed event */
    gridStateChanged(gridStateChanges: GridStateChange) {
        console.log('Client sample, Grid State changed:: ', gridStateChanges);
    }

    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.gridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.gridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}





