﻿import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

import { HasUnsavedData } from '../interfaces/hasunsaveddata';
import { QuoteDetailComponent } from '../employer/quotedetail.component';

@Injectable()
export class HasUnsavedDataGuard implements CanDeactivate<HasUnsavedData> {
    canDeactivate(component: HasUnsavedData): boolean {
        if (component.hasUnsavedData) {
            return confirm("You have unsaved changes!\nClick OK to leave the page.\nClick Cancel to stay on the page.");
    }
        return true;
    }
}