﻿import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { BaseComponent } from '../core/base.component';

import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';

import { EmployerSetupService } from './employersetup.services';
import { GlobaldataService } from '../service/globaldata.service';

import { OrderItemDTO } from '../dto/OrderItemDTO';
import { OrderService } from './order.service';
import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
    GridMenu,
    GridMenuItem,
    SlickEventHandler,
    SlickEvent,
    SlickEventData,
    AngularSlickgridComponent
} from 'angular-slickgrid';


const defaultPageSize = 1000;


@Component({
    templateUrl: './orderdetail.component.html',
    styleUrls: ['./employersetup.component.css'],

})
export class OrderDetailComponent extends BaseComponent implements OnInit {

    constructor(private route: ActivatedRoute,
        protected _router: Router,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private cd: ChangeDetectorRef,
        private employerSetupService: EmployerSetupService,
        private globaldataservice: GlobaldataService,
        private location: Location,
        public orderService: OrderService
    ) {
        super();


        this.route.params.subscribe(params => {
            this.employername = params.employername;
            this.employerid = params.accountid;
            this.membername = params.membername;
            this.addingorder = params.addingmember;
            this.orderdetaildto = JSON.parse(params.orderdetaildto);

        });

        //   console.log("Member name: = " + this.membername);
    }

    angularOrderDetailHistoryGridReady(angularGrid: AngularGridInstance) {
        this.angularOrderDetailHistoryGridInstance = angularGrid;
    }


    name = 'Angular';


    searchError: boolean = false;
    searchErrorMsg: string = "";
    file: any;
    arrayBuffer: any;
    filelist: any[];

    employerid: string;
    employername: string;
    membername: string;
    addingorder: number;
    orderdetaildto: OrderItemDTO;
    isorderdetailsaved: boolean = true;
    policyeffectivedatestr: string = "";
    displayMessage: string = "";
    lastmodificationreason: string = "";

    currenttabid: string = "tab-1-div";


    selectedquoteidx: number = -1;
    isquotenotselected: boolean = true;

    //dependents: MemberDependentDTO[] = [];
    //memberaccount: MemberAccountDTO;

    angularGrid: AngularGridInstance;

    orderDetailHistorycolumnDefinitions: Column[] = [];
    orderDetailHistorygridOptions: GridOption = {};
    orderDetailHistorydataset: any[] = [];
    angularOrderDetailHistoryGridInstance: AngularGridInstance;

    orderDetailHistoryTotalCount: number = 0;

    orderDetailHistoryDTO: OrderItemDTO[] = [];



    genderlist: SelectList[] = [
        {
            id: "Female",
            name: "Female"
        },
        {
            id: "Male",
            name: "Male"
        }

    ];

    statuslist: SelectList[] = [
        {
            id: "Active",
            name: "Active"
        },
        {
            id: "Terminated",
            name: "Terminated"
        }

    ];

    orderstatusList: SelectList[] = [
        {
            id: "2",
            name: "Completed"
        },
        {
            id: "3",
            name: "Canceled"
        }

    ];


    ngOnInit(): void {

        this.initOrderDetailPage();
        this.initOrderDetailHistoryGrid();
        this.getOrderItemHistory();

    }

    initOrderDetailPage() {

        this.lastmodificationreason = this.orderdetaildto.modifiedreason;
        this.orderdetaildto.modifiedreason = "";
        if (this.orderdetaildto.policyeffectivedate !== null) {
            this.orderdetaildto.policyeffectivedate = new Date(this.orderdetaildto.policyeffectivedate);
            this.policyeffectivedatestr = this.orderdetaildto.policyeffectivedate.getMonth() + 1 + "/" + this.orderdetaildto.policyeffectivedate.getDate() + "/" + this.orderdetaildto.policyeffectivedate.getFullYear();
        }
        else {
            this.policyeffectivedatestr = "";
        }

    }

    initOrderDetailHistoryGrid() {
        this.orderDetailHistorycolumnDefinitions = [
            { id: 'id', name: '#', field: 'id', sortable: false, width: 40, minWidth: 40 },
            { id: 'modifiedon', name: 'Last Modified', field: 'modifiedon', sortable: true, width: 120, minWidth: 120, formatter: Formatters.dateUs },
            { id: 'status', filterable: true, name: 'Status', field: 'status', sortable: true, width: 125, minWidth: 125 },
            { id: 'modifiedby', filterable: true, name: 'Modified By', field: 'modifiedby', sortable: true, width: 200, minWidth: 200 },
            { id: 'modifiedreason', filterable: true, name: 'Modification', field: 'modifiedreason', sortable: true, width: 250, minWidth: 250 },
            { id: 'amountspent', name: 'Price', field: 'amountspent', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'vendorname', filterable: true, name: 'Vendor Name', field: 'vendorname', sortable: true, width: 200, minWidth: 200 },
            { id: 'productname', filterable: true, name: 'Product Name', field: 'productname', sortable: true, width: 225, minWidth: 225 },
            { id: 'options', filterable: true, name: 'Options', field: 'options', sortable: true, width: 125, minWidth: 125 },
            { id: 'category', filterable: true, name: 'Category', field: 'category', sortable: true, width: 125, minWidth: 125 },
            { id: 'policynumber', name: 'Policy Number', field: 'policynumber', sortable: true, width: 125, minWidth: 125 },
            { id: 'policyeffectivedate', name: 'Policy Eff Date', field: 'policyeffectivedate', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateUs }

        ];
        this.orderDetailHistorygridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: true,
            checkboxSelector: {
                // you can toggle these 2 properties to show the "select all" checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true,
            },
            enableCheckboxSelector: true,

            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },
        };

    }

    getOrderItemHistory() {

        this.searchError = false;

        this.orderService.getOrderItemHistory(this.orderdetaildto.id)
            .subscribe(
                (data) => { this.getOrderItemHistoryCallBack(data); });

    }



    getOrderItemHistoryCallBack(data) {


        if (data.success) {
            this.searchError = false;
            this.orderDetailHistorygridOptions = Object.assign({}, this.orderDetailHistorygridOptions);
            this.orderDetailHistoryDTO = data.data;
            this.orderDetailHistoryTotalCount = this.orderDetailHistoryDTO.length;
            for (var i = 0; i < this.orderDetailHistoryTotalCount; i++) {
                this.orderDetailHistoryDTO[i].id = (i + 1).toString();
            }
            this.orderDetailHistorydataset = this.orderDetailHistoryDTO;

        }
        else {
            this.orderDetailHistoryTotalCount = 0;
            this.orderDetailHistorygridOptions = Object.assign({}, this.orderDetailHistorygridOptions);
            this.orderDetailHistorydataset = [];
            this.orderDetailHistoryDTO = [];
        }

    }

    saveOrderDetail() {
        this.searchError = false;
        this.searchErrorMsg = "";

        this.orderdetaildto.modifiedon = new Date();
        this.orderdetaildto.modifiedby = this.globaldataservice.useremail;


        this.employerSetupService.putOrderDetail(this.orderdetaildto)
            .subscribe((data) => { this.saveOrderDetailCallBack(data) });
    }

    saveOrderDetailCallBack(data) {

        this.displayMessage = "";
        if (data.responseStatus == 0) {
            this.displayMessage = "Successfully updated the order."
            this.addingorder = 0;
            this.isorderdetailsaved = true;
            this.getOrderItemHistory();
        }
        else {
            this.searchErrorMsg = data.responseMsg;
            this.searchError = true;
        }


    }



    //changeBirthDate(e: any) {

    //    let wrkbirthdate: Date = new Date(e.target.value);
    //    if (!isNaN(wrkbirthdate.getDate())) {
    //        this.memberdto.birthdate = wrkbirthdate;
    //        this.birthdatestr = (this.memberdto.birthdate.getUTCMonth() + 1).toString() + "/" + this.memberdto.birthdate.getUTCDate().toString() + "/" + this.memberdto.birthdate.getUTCFullYear().toString();
    //        this.changeMemberField();
    //    }
    //    else {
    //        this.searchError = true;
    //        this.searchErrorMsg = "Please enter a valid date."
    //    }

    //}


    changePolicyEffectiveDate(e: any) {

        let wrkpoleffdate: Date = new Date(e.target.value);
        let wrkpoleffdatestr: string = (wrkpoleffdate.getUTCMonth() + 1).toString() + "/" + wrkpoleffdate.getUTCDate().toString() + "/" + wrkpoleffdate.getUTCFullYear().toString();

        console.log("Effective Date=" + wrkpoleffdatestr);

        if (this.policyeffectivedatestr === wrkpoleffdatestr) // If no change
            return;

        if (!isNaN(wrkpoleffdate.getDate())) {
            this.orderdetaildto.policyeffectivedate = wrkpoleffdate;
            this.policyeffectivedatestr = (this.orderdetaildto.policyeffectivedate.getUTCMonth() + 1).toString() + "/" + this.orderdetaildto.policyeffectivedate.getUTCDate().toString() + "/" + this.orderdetaildto.policyeffectivedate.getUTCFullYear().toString();
            this.changeOrderDetailField('Policy Effective Date');
        }
        else {
            this.searchError = true;
            this.searchErrorMsg = "Please enter a valid date."
        }

    }


    changeOrderDetailField(change: string) {
        this.isorderdetailsaved = false;
        this.displayMessage = "";
        this.searchError = false;
        this.searchErrorMsg = "";

        let delim: string = ", ";

        if (this.orderdetaildto.modifiedreason === "") {
            this.orderdetaildto.modifiedreason = "Changed: ";
            delim = " ";
        }

        this.orderdetaildto.modifiedreason = this.orderdetaildto.modifiedreason + delim + change;


    }

    orderstatuschange(event) {
        let wrkorderstr: string = event;
        this.orderdetaildto.status = parseInt(wrkorderstr);
        this.changeOrderDetailField("Status");

    }

    //changeGender(e) {
    //    this.memberdto.gender = e.value;
    //    this.changeMemberField();
    //}

    //changeStatus(e) {
    //    this.memberdto.status = e.value;
    //    this.changeMemberField();
    //}

    backClicked() {

        if (this.globaldataservice.manage_order_called === 1) // Called by manage orders
            this.globaldataservice.manage_order_called = 2;
        else
            this.globaldataservice.manage_order_called = 0;
        this.location.back();

    }


}