﻿//import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TeamDTO } from '../dto/TeamDTO';
import { UserService } from './user.service';
import { GlobaldataService } from '../service/globaldata.service';
//import * as moment from 'moment-mini';


import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';
import { ResponseBaseDTO } from '../dto/ResponseBaseDTO';

const defaultPageSize = 1000;


@Component({
    templateUrl: 'team.component.html',
})
export class TeamComponent extends BaseComponent implements OnInit {

    columnDefinitions: Column[] = [];
    gridOptions: GridOption = {};
    mydataset: any[] = [];
    bIsLoaded: boolean = false;
    canShowGrid: boolean = false;
    angularGrid: AngularGridInstance;
    metrics: Metrics;
    //companyName: string;
    isCountEnabled = true;
    odataVersion = 2;
    odataQuery = '';
    processing = true;
    totalRowCount: number = 0;
    status = { text: 'processing...', class: 'alert alert-danger' };

    userSelectsString = '';
    name = 'Angular';
    userSelects = [];
    dropdownList = [];
    //    selectedItems = [];
    dropdownSettings = {};
    searchError: boolean = false;
    searchErrorMsg: string = "";

    gridObj: any[];
    selectedGridIDS: number[];
    selectedTitles: any[];
    selectedTitle: any;
    isusernotselected: boolean = false;

    useremail: string;
    userobjectid: string;

    teamname: string = "";
    teamListDTO: TeamDTO[] = [];

    selectedquoteidx: number = -1;



    constructor(
        public userservice: UserService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService,
        private cd: ChangeDetectorRef,
        protected _router: Router,
        private route: ActivatedRoute,
        private globalDataService: GlobaldataService) {

        super();

        this.userobjectid = auth.userobjectid;
        this.useremail = auth.useremail;
    }


    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};
    }



    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }


    ngOnInit(): void {



        this.columnDefinitions = [
            { id: 'name', filterable: true, name: 'Team Name', field: 'name', sortable: true, width: 200, minWidth: 200 },
            { id: 'createdon', name: 'Create Date', field: 'createdon', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'createdby', filterable: true, name: 'Created By', field: 'createdby', sortable: true, width: 200, minWidth: 200 },
            { id: 'id', name: 'Id', field: 'id', sortable: false, width: 225, minWidth: 225 }
        ];
        this.gridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: true,
            checkboxSelector: {
                // you can toggle these 2 properties to show the "select all" checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true,
            },
            enableCheckboxSelector: true,

            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },
        };
        this.gridOptions.showTopPanel = false;
        this.getTeams();

        //this.angularGrid.resizerService.pauseResizer(true);
    }

    getTeams() {


        this.userservice.getTeams()
            .subscribe(
                (data) => { this.getTeamsCallBack(data); });

    }

    getTeamsCallBack(data) {

        this.searchErrorMsg = "";

        if (data.totalcount > 0) {
            this.totalRowCount = data.totalcount;
            this.teamListDTO = data.teamdtolist;
            this.mydataset = this.teamListDTO;

        }
        else {
            this.totalRowCount = 0;
            this.searchErrorMsg = data.responseMsg;

        }
    }

    //openuser() {

    //    var filteredItems = this.angularGrid.dataView.getFilteredItems();

    //    this._router.navigate(['/userdetail',
    //        {
    //            addinguser: 0,
    //            ebuserdto: JSON.stringify(filteredItems[this.selectedquoteidx])
    //        }
    //    ]);

    //}

    addteam() {

        let teamDTO: TeamDTO = new TeamDTO();

        teamDTO.name = this.teamname;
        teamDTO.createdby = this.useremail;
        teamDTO.createdon = new Date();
        teamDTO.modifiedby = this.useremail;
        teamDTO.modifiedon = new Date();
        teamDTO.id = "00000000-0000-0000-0000-000000000000";

        this.userservice.postTeam(teamDTO)
            .subscribe((data) => { this.saveTeamCallBack(data) });

    }

    saveTeamCallBack(data) {

        if (data.success) {
            this.searchErrorMsg = "Successfully saved the team."
            this.teamname = "";
            this.getTeams();
        }
        else {
            this.searchErrorMsg = data.responseMsg;

        }


    }



    onSelectedRowsChanged(e, args) {
        if (Array.isArray(args.rows)) {
            if (args.rows.length > 0) {
                this.isusernotselected = false;
                this.selectedquoteidx = args.rows.map(idx => {
                    return idx;
                });
            }
            else {
                this.isusernotselected = true;
            }
        }
    }



    // NO CUSTOM CODE BEYOND THIS POINT

    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);


    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }


    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.gridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.gridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}






