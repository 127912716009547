﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../core/environment.service';
import { ExtendedHttpService } from '../core/extendedhttp.service';
import { HttpHeaders } from '@angular/common/http';
import { Http } from '@angular/http';
import { catchError, map } from 'rxjs/operators';
import { EBUserDTO } from '../dto/EBUserDTO';
import { TeamDTO } from '../dto/TeamDTO';




const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
@Injectable({
    providedIn: 'root',

})
export class UserService extends ExtendedHttpService {

    constructor(private env: EnvironmentService) {
        super();
    }


    getUsers() {


        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/User').
            pipe(
                map((data) => data.json())
            );
    }

    getTeams() {


        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Team/GetAllTeams').
            pipe(
                map((data) => data.json())
            );
    }

    postUserDetail(ebuserdto: EBUserDTO) {

        return this.postData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/User/', ebuserdto).
            pipe(
                map((data) => data.json())
            );
    }


    putUserDetail(ebuserdto: EBUserDTO) {

        return this.putData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/User/', ebuserdto).
            pipe(
                map((data) => data.json())
            );
    }


    postTeam(teamDTO: TeamDTO) {

        return this.postData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Team/', teamDTO).
            pipe(
                map((data) => data.json())
            );
    }



}
