﻿import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ACAPlanLookupService } from './acaplanlookup.service';
import { ContributionStrategyService } from './contributionstrategy.service';


import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';
import { ResponseBaseDTO } from '../dto/ResponseBaseDTO';
import { Observable, forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ICContributionStrategyDTO } from '../dto/ICContributionStrategyDTO';



@Component({
    templateUrl: 'contributionstrategy.html',
    providers: [
        ContributionStrategyComponent,
        ACAPlanLookupService,
        ContributionStrategyService,
        ListService
    ]
})





export class ContributionStrategyComponent extends BaseComponent implements OnInit {

    columnDefinitions: Column[] = [];
    gridOptions: GridOption = {};
    mydataset: any[] = [];
    angularGrid: AngularGridInstance;
    metrics: Metrics;

    isCountEnabled = true;
    odataVersion = 2;
    totalRowCount: number = 0;

    name = 'Angular';
    userSelects = [];

    searchError: boolean = false;
    searchErrorMsg: string = "";
    displayMessage: string = "";

    gridObj: any[];

    city: string = "";
    state: string = "";
    zipcode: string = "";
    ratingareaid: string = "";
    effectivedate: Date = new Date();


    effectivedatestr: string = (this.effectivedate.getMonth() + 2).toString() + "/01/" + this.effectivedate.getFullYear().toString();  // Default to the first of the next month


    selectedplanids: string;
    selectedplannames: string;
    selectedissuernames: string;
    numberofSelectedPlans: number = 0;

    useremail: string;
    userobjectid: string;

    planid: string = "";
    planagelist: any[] = [];
    ratefactors: any[] = [];
    rates: any[] = [];

    createdon: Date;
    createdby: string;
    totalmembers: number = 0;
    membernumber: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    // User Input
    currentemployercontribution: number = 0;
    modelcost: number = 0;
    percentageofplancost: number = 0;

    medicarecost: number = 0;
    unitstextarea: string = "";
    displayunittextarea: boolean = false;

    // Computed Averages
    avgfiveyearbands: number = 0;
    avgeveryyear: number = 0;
    avgeveryyearflatten: number = 0;
    avgpercentageofplan: number = 0;

    // Names of Strategies
    strategyname_5year: string = "5 Year Bands";
    strategyname_everyyear: string = "Every Year";
    strategyname_everyyearflatten: string = "Every Year Flatten";
    strategyname_percentageofplan: string = "Percentage of Plan";
    strategyname_minimumsalary: string = "Minimum Salary for Affordability";

    planselectedidx: number = 0;  // indicates which plan has been selected and we are working on

    planname: string = "";
    planList: Plans[] = [];
    currentPlan: Plans;
    calculationstatus: string = "Adjust Info and click Calculate";
    employername: string = "";

    contributionstrategy: ICContributionStrategyDTO;
    accountid: string = "";
    contributionstrategyid: string = "";
    tempcs: any;


    constructor(
        public acaplanlookupservice: ACAPlanLookupService,
        public contributionstrategyservice: ContributionStrategyService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService,
        private cd: ChangeDetectorRef,
        protected _router: Router,
        private route: ActivatedRoute) {

        super();


        this.route.params.subscribe(params => {
            this.ratingareaid = params.ratingareaid;
            this.city = params.city;
            this.state = params.state;
            this.zipcode = params.zipcode;
            this.numberofSelectedPlans = params.numberofSelectedPlans;
            this.selectedplanids = params.planids;
            this.selectedplannames = params.plannames;
            this.selectedissuernames = params.issuernames;
            this.accountid = params.accountid;
            this.contributionstrategy = JSON.parse(params.strategyDTO);
        });

        //this.contributionstrategy = JSON.parse(this.tempcs);



        this.userobjectid = auth.userobjectid;
        this.useremail = auth.useremail;
    }




    ngOnInit(): void {


        let planstr: string[];
        let issuerstr: string[];
        let planidstr: string[];

        // Load the planList with either a list of selected plans or from the saved strategy
        if (this.contributionstrategy == undefined) {  // if this is not a saved strategy
            planstr = this.selectedplannames.split('|,');
            issuerstr = this.selectedissuernames.split(',');
            planidstr = this.selectedplanids.split(',');
            

            for (var i = 0; i < this.numberofSelectedPlans; i++) {
                planstr[i] = planstr[i].replace("|", "");
                if (i == 0) {
                    this.currentPlan = {
                        id: planidstr[i].toString(),
                        name: issuerstr[i].toString() + "-" + planstr[i].toString()
                    }
                }
                this.addtoPlanList(planidstr[i].toString(), issuerstr[i].toString(), planstr[i].toString());
            }
            if (this.effectivedate.getMonth() === 11) {
                let year: number = this.effectivedate.getFullYear();
                year++;
                this.effectivedatestr = "01/01/" + year.toString();  // Default to the first of the next month
            }
            this.effectivedate = this.setDate(this.effectivedatestr);
        }
        else {  // load plans from saved strategy DTO and there is always at least one
            this.accountid = this.contributionstrategy.accountid;
            this.contributionstrategyid = this.contributionstrategy.id;
            this.createdby = this.contributionstrategy.createdby;
            this.createdon = this.contributionstrategy.createdon;
            this.city = this.contributionstrategy.city;
            this.state = this.contributionstrategy.state;
            this.zipcode = this.contributionstrategy.postalcode;
            this.ratingareaid = this.contributionstrategy.ratingarea;
            this.employername = this.contributionstrategy.accountname;
            this.currentemployercontribution = this.contributionstrategy.currentcontribution;
            this.modelcost = this.contributionstrategy.modelcost;
            this.percentageofplancost = this.contributionstrategy.percentofrate;
            
            this.effectivedate = new Date(this.contributionstrategy.effectivedate);
            this.effectivedatestr = this.effectivedate.getMonth() + 1 + "/" + this.effectivedate.getDate() + "/" + this.effectivedate.getFullYear();
            this.membernumber = this.contributionstrategy.unitsbyage.split(",").map(function (item) { return parseInt(item, 10) });
            this.totalmembers = this.membernumber.reduce((a, b) => a + b, 0)
            this.avgfiveyearbands = this.contributionstrategy.avg5year;
            this.avgeveryyear = this.contributionstrategy.avgeveryyear;
            this.avgeveryyearflatten = this.contributionstrategy.avgeveryyearflat;
            this.avgpercentageofplan = this.contributionstrategy.avgpercentofplan;


            // Build Plan list 
            this.currentPlan = {
                id: this.contributionstrategy.plan1id,
                name: this.contributionstrategy.issuer1name + "-" + this.contributionstrategy.plan1name
            }
            this.numberofSelectedPlans = this.addtoPlanList(this.contributionstrategy.plan1id, this.contributionstrategy.issuer1name, this.contributionstrategy.plan1name);
            this.numberofSelectedPlans += this.addtoPlanList(this.contributionstrategy.plan2id, this.contributionstrategy.issuer2name, this.contributionstrategy.plan2name);
            this.numberofSelectedPlans += this.addtoPlanList(this.contributionstrategy.plan3id, this.contributionstrategy.issuer3name, this.contributionstrategy.plan3name);
            this.numberofSelectedPlans += this.addtoPlanList(this.contributionstrategy.plan4id, this.contributionstrategy.issuer4name, this.contributionstrategy.plan4name);

        }
        this.columnDefinitions = [
            { id: 'id', name: 'Age', field: 'id', sortable: false, width: 60, minWidth: 60, cssClass: 'age' },
            { id: 'plan1rate', name: "Rates", field: 'plan1rate', sortable: false, width: 150, minWidth: 150, formatter: this.moneyFormatter, cssClass: 'plan1' },
            { id: 'amount5year', name: this.strategyname_5year, field: 'amount5year', sortable: false, width: 100, minWidth: 100, formatter: this.moneyFormatter, cssClass: 'plan1' },
            { id: 'amounteveryyear', name: this.strategyname_everyyear, field: 'amounteveryyear', sortable: false, width: 100, minWidth: 100, formatter: this.moneyFormatter, cssClass: 'plan2' },
            { id: 'amounteveryyearflatten', name: this.strategyname_everyyearflatten, field: 'amounteveryyearflatten', sortable: false, width: 150, minWidth: 150, formatter: this.moneyFormatter, cssClass: 'plan3' },
            { id: 'amountpercentageofplan', name: this.strategyname_percentageofplan, field: 'amountpercentageofplan', sortable: false, width: 150, minWidth: 150, formatter: this.moneyFormatter, cssClass: 'plan4' },
            { id: 'minimumsalary', name: this.strategyname_minimumsalary, field: 'minimumsalary', sortable: false, width: 175, minWidth: 175, formatter: Formatters.multiple, params: { formatters: [this.moneyAccountingFormatter, Formatters.bold], displayNegativeNumberWithParentheses: true } }
        ];
        this.gridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: false,
            editable: false,
            autoEdit: false,
            //autoHeight: true
        };
        this.gridOptions.showTopPanel = false;
        // this.getBoth();
        //this.getratefactors();
        // this.getplans();

        if (this.contributionstrategy === undefined)
            this.getratefactors();
        else
            this.getplansandratefactors();


    }




    getratefactors() {

        let callget: Observable<any>;
        this.searchError = false;

        //callget = this.contributionstrategyservice.getratefactors(this.state, "2020")
        //callget.toPromise().then(map(data => JSON.stringify(data)));


        // this.ratefactors = data.acaRateFactors;


        this.contributionstrategyservice.getratefactors(this.state, "2020")
            .subscribe(
                (data) => { this.getratefactorsCallback(data); });

    }

    getratefactorsCallback(data) {

        this.ratefactors = data.acaRateFactors;
 //       this.getplans();
    }


    //private getBoth() {
    //// Gets the selected plan, rates and rate factors.  Only need the rate factors first time in the component
    //    let observableBatch = [];
    //    let businessyear: string = "2020";  // TODO JJM: User will have to pick the year in the future
    //    observableBatch.push(this.contributionstrategyservice.getratefactors(this.state, businessyear));
    //    observableBatch.push(this.acaplanlookupservice.getPlansAndRates(this.currentPlan.id, this.ratingareaid));
    //    forkJoin(observableBatch)
    //        .subscribe(data => {
    //            this.getinitialbatchresponse(data);
    //        });
    //}


    //private getinitialbatchresponse(data: any) {
    //    let temp: string;
    //    this.ratefactors = data[0].acaRateFactors;


    //    temp = "stop here";
    //}



    addtoPlanList(planid: string, issuername: string, planname: string) {
        if (planid !== "") {
            this.planList.push({
                id: planid,
                name: issuername + "-" + planname
            });
            return 1;
        }
        return 0;

    }

    calculate() {

        this.displayMessage = "";

        if (isNaN(this.effectivedate.getDate())) {
            this.searchError = true;
            this.searchErrorMsg = "You must enter a valid effective date";
            return;
        }
        this.pasteunits();
        this.getplans();  // TODO: Once the rates are are initially retreived, the only reason to call the server is when the user changes plans.  Other recalc only when modelcost, percentage, effectivedate, or new birth dates are entered
        this.calculationstatus = "Information is Calculated";

        //if (this.unitstextarea !== "") {
        //    this.pasteunits();
        //    this.getplans();
        //}
        //else
        //    this.getplans();

    }

    planselected(event) {
        this.displayMessage = "";
        this.currentPlan.id = event;
        for (var i = 0; i < this.numberofSelectedPlans; i++) {
            if (this.planList[i].id === this.currentPlan.id) {
                this.currentPlan.name = this.planList[i].name;
                this.calculationstatus = "Please Click Calculate";
                break;
            }
        }     
    }


    //getplansonly() {

    //    this.searchError = false;

    //    this.acaplanlookupservice.getPlansAndRates(this.currentPlan.id, this.ratingareaid)
    //        .subscribe(
    //            (data) => { this.getplansonlyCallback(data); });

    //}

    //getplansonlyCallback(data) {

    //    if (data.totalcount > 0) {
    //        this.gridOptions = Object.assign({}, this.gridOptions);
    //        this.mydataset = data.acaMulltiplePlanRates;
    //    }
    //    else {
    //        this.gridOptions = Object.assign({}, this.gridOptions);
    //        this.mydataset = [];
    //        this.searchError = true;
    //        this.searchErrorMsg = data.responseMsg;
    //    }

    //}

    getplansandratefactors() {

        this.contributionstrategyservice.getplansandratefactors(this.currentPlan.id, this.ratingareaid, this.state, "2020")
            .subscribe((data) => { this.getplansandratefactorsCallback(data) });

    }

    getplansandratefactorsCallback(data) {

        this.ratefactors = data.ratefactorlist;
        this.getPlansAndRatesCallback(data);
    }


    getplans() {

        this.searchError = false;

        this.acaplanlookupservice.getPlansAndRates(this.currentPlan.id, this.ratingareaid)
            .subscribe(
                (data) => { this.getPlansAndRatesCallback(data); });

    }


    getPlansAndRatesCallback(data) {
    // TODO JJM - Don't need to get the plan from the server if we already have the plan.  Add support for multiple plans.

        if (data.totalcount > 0) {

            let newdata: any[] = [];
            let idx: number = 0;
            let newidx: number = 0;
            let temptotal: any;
            let total5year: number = 0;
            let totaleveryyear: number = 0;
            let totaleveryyearflatten: number = 0;
            let totalpercentageofplan: number = 0;

            //this.totalmembers = 0;

            if (data.totalcount > 0) {
                this.planselectedidx = 1;  // TODO: Need to allow user to cycle through 4 possible plans.
                while (idx < 51) {  // there are 51 ages of 14 to 64, but we need to skip over the first 4 and start at age 18
                    if (idx >= 4) {
                        newdata[newidx] = data.planandratelist[idx];

                        // FIVE YEAR
                        newdata[newidx].amount5year = this.calc5YearStrategy(newdata[newidx].age, 1);
                        temptotal = this.calc5YearStrategy(newdata[newidx].age, this.membernumber[idx]);
                        total5year = total5year + parseFloat(temptotal);  
                        // EVERY YEAR
                        newdata[newidx].amounteveryyear = (this.modelcost * this.ratefactors[idx].ratefactors).toFixed(2);
                        temptotal = ((this.modelcost * this.ratefactors[idx].ratefactors) * this.membernumber[idx]).toFixed(2);
                        totaleveryyear = totaleveryyear + parseFloat(temptotal);
                        // EVERY YEAR FLATTENED
                        newdata[newidx].amounteveryyearflatten = this.calcEveryYearFlattenStrategy(newdata[newidx].age, 1, idx);
                        temptotal = this.calcEveryYearFlattenStrategy(newdata[newidx].age, this.membernumber[idx], idx);
                        totaleveryyearflatten = totaleveryyearflatten + parseFloat(temptotal);
                        // PERCENTAGE OF PLAN
                        newdata[newidx].amountpercentageofplan = (data.planandratelist[idx].plan1rate * (this.percentageofplancost / 100)).toFixed(2);
                        temptotal = ((data.planandratelist[idx].plan1rate * (this.percentageofplancost / 100)) * this.membernumber[idx]).toFixed(2);
                        totalpercentageofplan = totalpercentageofplan + parseFloat(temptotal);
                        // AFFORDABILITY CALCULATION - MINIMUM SALARY
                        newdata[newidx].minimumsalary = Math.round(((data.planandratelist[idx].plan1rate - newdata[newidx].amounteveryyear) / .0983) * 12);
                        newdata[newidx].minimumsalary1 = newdata[newidx].minimumsalary;

                        newidx++;
                    }
                    idx++;
                }
                if (this.totalmembers > 0) {
                    this.avgfiveyearbands = total5year / this.totalmembers;
                    this.avgeveryyear = totaleveryyear / this.totalmembers;
                    this.avgeveryyearflatten = totaleveryyearflatten / this.totalmembers;
                    this.avgpercentageofplan = totalpercentageofplan / this.totalmembers;
                }
                this.gridOptions = Object.assign({}, this.gridOptions);
                this.mydataset = newdata;
            }
        }
        else {
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = [];
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }

    }




    calc5YearStrategy(age: number, nomembers: number) {

        let factorindex: number;
        let contributionbyage: number;

        if (age >= 18 && age <= 24)
            factorindex = 10;
        else if (age >= 25 && age <= 29)
            factorindex = 15;
        else if (age >= 30 && age <= 34)
            factorindex = 20;
        else if (age >= 35 && age <= 39)
            factorindex = 25;
        else if (age >= 40 && age <= 44)
            factorindex = 30;
        else if (age >= 45 && age <= 49)
            factorindex = 35;
        else if (age >= 50 && age <= 54)
            factorindex = 40;
        else if (age >= 55)
            factorindex = 45;

        contributionbyage = (this.ratefactors[factorindex].ratefactors * this.modelcost) * nomembers;
        return contributionbyage.toFixed(2);

    }

    calcEveryYearFlattenStrategy(age: number, nomembers: number, ageindex: number) {

        let factorindex: number;
        let contributionbyage: number;

        if (age <= 26)  // Uses age 26 factor
            contributionbyage = (this.ratefactors[12].ratefactors * this.modelcost) * nomembers;
        else if (age >= 56)  // Uses age 56 factor
            contributionbyage = (this.ratefactors[42].ratefactors * this.modelcost) * nomembers;
        else
            contributionbyage = (this.ratefactors[ageindex].ratefactors * this.modelcost) * nomembers;

        return contributionbyage.toFixed(2);
            
    }


    getRateFactorByAge(age: number) {
    // JJM - NOT USED
        function binary_Search(items, value) {
            var firstIndex = 0,
                lastIndex = items.length - 1,
                middleIndex = Math.floor((lastIndex + firstIndex) / 2);

            while (items[middleIndex] != value && firstIndex < lastIndex) {
                if (value < items[middleIndex]) {
                    lastIndex = middleIndex - 1;
                }
                else if (value > items[middleIndex]) {
                    firstIndex = middleIndex + 1;
                }
                middleIndex = Math.floor((lastIndex + firstIndex) / 2);
            }

            return (items[middleIndex] != value) ? -1 : middleIndex;
        }

    

    }



    pasteunits() {

        let textstr: string = "";
        let rows: any;
        let i: number = 0;
        let bdate: Date;
        let memberage: number = 0;
        let month: number = 0;
        let agecnt: number = 0;


        if (this.unitstextarea === "")
            return;

        this.totalmembers = 0;

        while (i < 51) {
            this.membernumber[i] = 0;
            i++;
        }
        textstr = this.unitstextarea;

        rows = textstr.split(/\r\n|\n|\r/);
        i = 0;
        while (i < rows.length) {
            bdate = new Date(rows[i]);
            if (!isNaN(bdate.getDate())) {

                memberage = this.effectivedate.getFullYear() - bdate.getFullYear();
                month = this.effectivedate.getMonth() - bdate.getMonth();
                if (month < 0 || (month === 0 && this.effectivedate.getDate() < bdate.getDate())) {
                    memberage--;
                }
                if (memberage < 18 || memberage > 64)
                    agecnt++;
                // Since this analysis is for employees only exclude all the units under 18.  Those over 64 will be medicare eligible and will be handled with a different strategy
                if (memberage >= 18 && memberage <= 64) {
                    this.membernumber[memberage - 14] = this.membernumber[memberage - 14] + 1;   // Add another unit to this age
                    this.totalmembers = this.totalmembers + 1;
                }
            }
            i++;
        }
        return;

    }

    inputchanged() {
        this.displayMessage = "";
        this.calculationstatus = "Please Click Calculate";

    }


    seteffectivedate() {
        this.displayMessage = "";
        this.effectivedate = this.setDate(this.effectivedatestr);
        if (isNaN(this.effectivedate.getDate())) {
            this.searchErrorMsg = "Effective date must be in the format MM/DD/YYYY.";
            this.searchError = true;
            return;
        }
        this.calculationstatus = "Please Click Calculate";

    }

    save() {


        let temp: string = "";
        let planname: string = "";
        let issuername: string = "";
        let position: number;

        if (this.employername === "") {
            this.searchError = true;
            this.searchErrorMsg = "You must enter both an employer name to save the contribution strategy.";
            return;
        }

        this.searchError = false;
        this.displayMessage = "";

        this.contributionstrategy = new ICContributionStrategyDTO();

        if (this.accountid !== undefined && this.accountid !== "") {
            this.contributionstrategy.accountid = this.accountid; // user has modified an existing quote
            if (this.contributionstrategyid !== undefined && this.contributionstrategyid !== "") {
                this.contributionstrategy.id = this.contributionstrategyid;
                this.contributionstrategy.createdon = this.createdon; // preserve original data
                this.contributionstrategy.createdby = this.createdby;
            } else {
                this.searchError = true;
                this.searchErrorMsg = "Something went wrong. Please try again.";
                return;
            }
        }
        else {
            //this.icquotedto.accountid = null;
            //this.icquotedto.id = null;
            this.contributionstrategy.createdon = new Date();
            this.contributionstrategy.createdby = this.auth.useremail;
            this.createdon = this.contributionstrategy.createdon;
            this.createdby = this.contributionstrategy.createdby;
        }


        this.contributionstrategy.ownerid = this.userobjectid; // TODO: When we implement security groups, this might not be the right thing to do
        this.contributionstrategy.accountname = this.employername;
        this.contributionstrategy.city = this.city;
        this.contributionstrategy.state = this.state;
        this.contributionstrategy.postalcode = this.zipcode;
        this.contributionstrategy.ratingarea = this.ratingareaid;
        this.contributionstrategy.currentcontribution = this.currentemployercontribution;
        this.contributionstrategy.modelcost = this.modelcost;
        this.contributionstrategy.percentofrate = this.percentageofplancost;
        this.contributionstrategy.effectivedate = this.effectivedate;
        this.contributionstrategy.totalmembers = this.totalmembers;
        this.contributionstrategy.avg5year = this.avgfiveyearbands;
        this.contributionstrategy.avgeveryyear = this.avgeveryyear;
        this.contributionstrategy.avgeveryyearflat = this.avgeveryyearflatten;
        this.contributionstrategy.avgpercentofplan = this.avgpercentageofplan;


        this.contributionstrategy.plan1id = "";
        this.contributionstrategy.plan2id = "";
        this.contributionstrategy.plan3id = "";
        this.contributionstrategy.plan4id = "";



        this.contributionstrategy.plan1id = this.currentPlan.id;
        temp = this.currentPlan.name;
        position = temp.indexOf("-");
        this.contributionstrategy.issuer1name = temp.substring(0, position);
        this.contributionstrategy.plan1name = temp.substring(position + 1);

        let lastplanloaded: number = 1;
        let idx: number = 0;
        while (idx < this.planList.length) {
            if (this.planList[idx].id !== this.currentPlan.id) {
                if (lastplanloaded == 1) {
                    this.contributionstrategy.plan2id = this.planList[idx].id;
                    temp = this.planList[idx].name;
                    position = temp.indexOf("-");
                    this.contributionstrategy.issuer2name = temp.substring(0, position);
                    this.contributionstrategy.plan2name = temp.substring(position + 1);
                    lastplanloaded = 2;
                }
                else if (lastplanloaded == 2) {
                    this.contributionstrategy.plan3id = this.planList[idx].id;
                    temp = this.planList[idx].name;
                    position = temp.indexOf("-");
                    this.contributionstrategy.issuer3name = temp.substring(0, position);
                    this.contributionstrategy.plan3name = temp.substring(position + 1);
                    lastplanloaded = 3;
                }
                else if (lastplanloaded == 3) {
                    this.contributionstrategy.plan4id = this.planList[idx].id;
                    temp = this.planList[idx].name;
                    position = temp.indexOf("-");
                    this.contributionstrategy.issuer4name = temp.substring(0, position);
                    this.contributionstrategy.plan4name = temp.substring(position + 1);
                }
            }
            idx++;
        }


        this.contributionstrategy.unitsbyage = this.membernumber.toString();

        this.contributionstrategy.modifiedby = this.auth.useremail;
        this.contributionstrategy.modifiedon = new Date();


        this.contributionstrategyservice.postContributionStrategy(this.contributionstrategy)
            .subscribe(
                (data) => { this.postContributionStrategyCallback(data); }
            );



    }


    postContributionStrategyCallback(data) {


        if (data.success) {
            this.accountid = data.data.accountid;
            this.contributionstrategyid = data.data.id;
            this.displayMessage = "Saved Successfully";
        }
        else {
            this.searchErrorMsg = data.displaymessage;
            this.searchError = true;

        }

    }
    // THE FOLLOWING METHODS ARE NOT PART OF THE BUSINESS LOGIC

    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};
    }



    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }



    // THE FOLLOWING METHODS ARE USED FOR PAGING AND FILTERING WHEN APPLICABLE

    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);


    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }


    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.gridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.gridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}






