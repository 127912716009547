﻿ 
export class OrderItemDTO {
        public id: string;
        public productid: string;
        public productfeeid: string;
        public orderid: string;
        public options: string;
        public description: string;
        public category: string;
        public status: number;
        public amountspent: number;
        public productname: string;
        public vendorname: string;
        public externalvendorid: string;
        public policyholdersname: string;
        public policycarriername: string;
        public policynumber: string;
        public policyeffectivedate: Date;
        public policyishsaeligible: number;
        public policyisgovsubsidy: number;
        public ordernumber: string;
        public createdon: Date;
        public createdby: string;
        public modifiedon: Date;
        public modifiedby: string;
        public modifiedreason: string;
    }
