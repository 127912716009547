﻿//import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EnrollmentDTO } from '../dto/EnrollmentDTO';
import { EnrollmentViewDTO } from '../dto/EnrollmentViewDTO';
import { ABSDemographicViewDTO } from '../dto/ABSDemographicViewDTO';
import { ABSDependentViewDTO } from '../dto/ABSDependentViewDTO';
import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
    GridMenu,
    GridMenuItem,
    SlickEventHandler,
    SlickEvent,
    SlickEventData,
    AngularSlickgridComponent,
    ExtensionName,
    FileType
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';
import { ResponseBaseDTO } from '../dto/ResponseBaseDTO';
import { OrderPayrollService } from '../admin/orderpayroll.service';
import { AccountAndAddressDTO } from '../dto/AccountAndAddressDTO';
import { GlobaldataService } from '../service/globaldata.service';
import { ABSContributionViewDTO } from '../dto/ABSContributionViewDTO';
import { ABSEnrollmentReportDTO } from '../dto/ABSEnrollmentReportDTO';



const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';


@Component({
    templateUrl: 'enrollment.component.html',
    styleUrls: ['../employeaze/employersetup.component.css'],
    providers: [
        OrderPayrollService,
        ListService
    ]
})
export class EnrollmentComponent extends BaseComponent implements OnInit {

    // GENERAL GRID OPTIONS
    columnDefinitions: Column[] = [];
    gridOptions: GridOption = {};
    mydataset: any[] = [];


    angularMainGrid: AngularGridInstance;
    gridMenu: GridMenu;
    gridMenuItem: GridMenuItem;
    //slickEventHandler: SlickEventHandler;
    //slickEvent: SlickEvent;
    //slickEventData: SlickEventData;
    /*    _addon: any;*/

    // ABS DEMOGRAPHIC GRID DATA SUPPORT
    absdemographiccolumnDefinitions: Column[] = [];
    absgridOptions: GridOption = {};
    absdemographicdataset: any[] = [];
    angularabsGrid: AngularGridInstance;
    absdemographicrowcount: number = 0;


    // ABS DEPENDENT GRID DATA SUPPORT
    absdepcolumnDefinitions: Column[] = [];
    absdepgridOptions: GridOption = {};
    absdepmydataset: any[] = [];
    angularabsdepGrid: AngularGridInstance;
    absdeprowcount: number = 0;

    // ABS ENROLLMENT GRID DATA SUPPORT
    absenrollmentcolumnDefinitions: Column[] = [];
    absenrollmentgridOptions: GridOption = {};
    absenrollmentdataset: any[] = [];
    angularabsenrollmentGrid: AngularGridInstance;
    absenrollmentrowcount: number = 0;


    // ABS CONTRIBUTION GRID DATA SUPPORT
    abscontributioncolumnDefinitions: Column[] = [];
    abscontributiongridOptions: GridOption = {};
    abscontributiondataset: any[] = [];
    angularabscontributionGrid: AngularGridInstance;
    abscontributionrowcount: number = 0;

    // paycard GRID DATA SUPPORT
    paycardcolumnDefinitions: Column[] = [];
    paycardgridOptions: GridOption = {};
    paycarddataset: any[] = [];
    angularpaycardGrid: AngularGridInstance;
    paycardrowcount: number = 0;



    reporttype: string = "GENERAL"; // Also could be ABSDEMOGRAHPIC, ABSDEPENDENT


    currenttabid: string = "tab-1-div";

    bIsLoaded: boolean = false;
    canShowGrid: boolean = false;
    metrics: Metrics;
    //companyName: string;
    isCountEnabled = true;
    odataVersion = 2;
    odataQuery = '';
    processing = true;
    totalRowCount: number = 0;
    status = { text: 'processing...', class: 'alert alert-danger' };

    userSelectsString = '';
    name = 'Angular';
    userSelects = [];
    dropdownList = [];
    //    selectedItems = [];
    dropdownSettings = {};
    searchError: boolean = false;
    searchErrorMsg: string = "";

    gridObj: any;

    selectedGridIDS: number[];
    selectedTitles: any[];
    selectedTitle: any;

    useremail: string;
    userobjectid: string;

    isordernotselected: boolean = true;

    selectedquoteidx: number = -1;

/*    enrollment: EnrollmentDTO;*/
    enrollments: EnrollmentViewDTO[] = [];

    absdemographiclist: ABSDemographicViewDTO[] = [];
    absdependentlist: ABSDependentViewDTO[] = [];

    absenrollmentlist: ABSEnrollmentReportDTO[] = [];
    isabsenrollmentlistLoadedWithHSA: boolean = false;

    abscontributionlist: ABSContributionViewDTO[] = [];

    startdate: Date;
    startdatestr: string = '';
    enddate: Date;
    enddatestr: string = '';

    acctaddrdto: AccountAndAddressDTO;


    constructor(
        public orderService: OrderPayrollService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private listservice: ListService,
        private cd: ChangeDetectorRef,
        protected _router: Router,
        private route: ActivatedRoute,
        private globaldataservice: GlobaldataService
    ) {

        super();

        this.route.params.subscribe(params => {
            this.acctaddrdto = JSON.parse(params.acctaddrdto);

        });

        this.globaldataservice.useremail = auth.useremail;
        this.globaldataservice.userobjectid = auth.userobjectid;
    }

    angularMainGridReady(angularGrid: AngularGridInstance) {
        this.angularMainGrid = angularGrid;
    }

    angularabsGridReady(angularGrid: AngularGridInstance) {
        this.angularabsGrid = angularGrid;
    }

    angularabsdepGridReady(angularGrid: AngularGridInstance) {
        this.angularabsdepGrid = angularGrid;
    }

    angularabsenrollmentGridReady(angularGrid: AngularGridInstance) {
        this.angularabsenrollmentGrid = angularGrid;
    }

    angularabscontributionGridReady(angularGrid: AngularGridInstance) {
        this.angularabscontributionGrid = angularGrid;
    }

    angularpaycardGridReady(angularGrid: AngularGridInstance) {
        this.angularpaycardGrid = angularGrid;
    }

    //get eventHandler(): SlickEventHandler {

    //    return this.slickEventHandler
    //}



    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }




    ngOnInit(): void {

        
        let todaysdate: Date = new Date();
        if (this.globaldataservice.policyeffectivedateafterdatestr === '')
            this.globaldataservice.policyeffectivedateafterdatestr = todaysdate.toLocaleDateString();

        this.setupGeneralGridData();
        this.setupABSDemographicGridData();
        this.setupABSDependentGridData();
        this.setupABSEnrollmentGridData();
        this.setupABSContributionGridData();
        this.setuppaycardGridData();

        this.getEnrollments();


        //this.startdate = new Date();
        //this.startdatestr = (this.startdate.getMonth() - 2).toString() + "/01/" + this.startdate.getFullYear().toString();
        //this.startdate = new Date(this.startdatestr);

        //this.enddate = new Date();
        //this.enddatestr = (this.enddate.getMonth()).toString() + "/" + this.enddate.getDate().toString() + "/" + this.enddate.getFullYear().toString();


        //this.angularGrid.resizerService.pauseResizer(true);
    }


    setupGeneralGridData() {


        this.columnDefinitions = [
            { id: 'id', name: 'No', field: 'id', sortable: false, width: 60, minWidth: 60 },
            { id: 'memberid', name: 'Member Id', field: 'memberid', sortable: true, width: 100, minWidth: 100 },
            { id: 'submitted', name: 'Date of Submission', field: 'submitted', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateTimeIso },
            { id: 'memberstatus', filterable: true, name: 'Member Status', field: 'memberstatus', sortable: true, width: 125, minWidth: 125 },
            { id: 'memberfirstname', name: 'First Name', field: 'memberfirstname', filterable: true, sortable: true, width: 100, minWidth: 100 },
            { id: 'memberlastname', filterable: true, name: 'Last Name', field: 'memberlastname', sortable: true, width: 125, minWidth: 125 },
            { id: 'address1', name: 'Address 1', field: 'address1', width: 100, minWidth: 100 },
            { id: 'city', name: 'City', field: 'city', sortable: true, width: 100, minWidth: 100 },
            { id: 'stateorprovince', name: 'State', field: 'stateorprovince', sortable: true, width: 100, minWidth: 100 },
            { id: 'postalcode', name: 'Zipcode', field: 'postalcode', sortable: true, width: 100, minWidth: 100 },

            { id: 'memberbirthdate_Formatted', name: 'Birth Date', field: 'memberbirthdate_Formatted', width: 120, minWidth: 100 },
            { id: 'membergender', name: 'Gender', field: 'membergender', width: 100, minWidth: 100 },
            { id: 'memberssn', name: 'SSN', field: 'memberssn', sortable: true, width: 125, minWidth: 125 },
            { id: 'phonenumber', name: 'Phone Number', field: 'phonenumber', sortable: true, width: 100, minWidth: 100 },
            { id: 'emailaddress', name: 'Email Address', field: 'emailaddress', sortable: true, width: 100, minWidth: 100 },
            { id: 'memberpcpname', name: 'PCP Name', field: 'memberpcpname', sortable: true, width: 125, minWidth: 125 },
            { id: 'memberpcpid', name: 'PCP Id', field: 'memberpcpid', sortable: true, width: 125, minWidth: 125 },
            { id: 'memberexistingpatient', name: 'Existing Patient', field: 'memberexistingpatient', sortable: true, width: 125, minWidth: 125 },
            { id: 'currentbalance', name: 'Current Balance', field: 'currentbalance', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },

            { id: 'waivecoverage', filterable: true, name: 'Health Ins Waived', field: 'waivecoverage', sortable: true, width: 125, minWidth: 100, formatter: Formatters.checkmark },
            { id: 'waivecoveragereason', filterable: true, name: 'Waive Reason', field: 'waivecoveragereason', sortable: true, width: 125, minWidth: 125 },
            { id: 'istobaccouser', filterable: true, name: 'Uses Tobacco', field: 'istobaccouser', sortable: true, width: 125, minWidth: 125 },
            { id: 'orderdate_Formatted', name: 'Order Date', field: 'orderdate_Formatted', sortable: true, width: 120, minWidth: 100 },
            { id: 'options', filterable: true, name: 'Purchase Source', field: 'options', sortable: true, width: 150, minWidth: 100 },
            { id: 'productname', filterable: true, name: 'Product Name', field: 'productname', sortable: true, width: 200, minWidth: 200 },
            { id: 'description', filterable: true, name: 'More Info', field: 'description', sortable: true, width: 125, minWidth: 125 },
            { id: 'amountspent', name: 'Price', field: 'amountspent', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'policyholdersname', name: 'Policy Holders Name', field: 'policyholdersname', sortable: true, width: 125, minWidth: 125 },
            { id: 'policycarriername', name: 'Carrier Name', field: 'policycarriername', sortable: true, width: 125, minWidth: 125 },
            { id: 'policyeffectivedate_Formatted', name: 'Policy Eff Date', field: 'policyeffectivedate_Formatted', sortable: true, width: 120, minWidth: 100 },

            { id: 'isonhealthpolicy', filterable: true, name: 'Dep On Health Policy', field: 'isonhealthpolicy', sortable: true, width: 125, minWidth: 125 },
            { id: 'dependentfirstname', name: 'Dep First Name', field: 'dependentfirstname', sortable: true, width: 100, minWidth: 100 },
            { id: 'dependentlastname', name: 'Dep Last Name', field: 'dependentlastname', sortable: true, width: 125, minWidth: 125 },
            { id: 'dependentdob_Formatted', name: 'Dep Birth Date', field: 'dependentdob_Formatted', sortable: true, width: 120, minWidth: 100 },
            { id: 'relationship', name: 'Relationship', field: 'relationship', sortable: true, width: 100, minWidth: 100 },
            { id: 'dependentgender', name: 'Dep Gender', field: 'dependentgender', width: 100, minWidth: 100 },
            { id: 'dependentssn', name: 'Dep SSN', field: 'dependentssn', width: 100, minWidth: 100 },
            { id: 'dependentpcpame', name: 'Dep PCP Name', field: 'dependentpcpame', width: 100, minWidth: 100 },
            { id: 'dependentpcpid', name: 'Dep PCP Id', field: 'dependentpcpid', width: 100, minWidth: 100 },
            { id: 'dependentexistingpatient', name: 'Dep Existing Patient', field: 'dependentexistingpatient', width: 100, minWidth: 100 },
            { id: 'fein', name: 'Federal EIN', field: 'fein', width: 100, minWidth: 100 }


        ];
        this.gridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: true,
            checkboxSelector: {
                // you can toggle these 2 properties to show the "select all" checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true,
            },
            enableCheckboxSelector: true,


            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },
            //gridMenu: {
            //    //onCommand: (e, args) => {
            //    //    const dataview = args.grid.getData();
            //    //    this._addon = dataview;
            //    //    this.onCommandEvent(e, args);
            //    //}
            //    onBeforeMenuShow: 

            //},
            //excelExportOptions: {
            //    filename: this.acctaddrdto.accountname,
            //    format: FileType.xlsx

            //}

        };
        this.gridOptions.showTopPanel = false;



    }

    setupABSDemographicGridData() {


        this.absdemographiccolumnDefinitions = [
            { id: 'id', name: 'No', field: 'id', sortable: false, width: 60, minWidth: 60 },
            //{ id: 'submitted', name: 'DateSubmitted', field: 'submitted', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateTimeIso },
            //{ id: 'memberid', name: 'EmployeeIdentifier', field: 'memberid', sortable: true, width: 100, minWidth: 100 },
            { id: 'memberstatus', filterable: true, name: 'Member Status', field: 'memberstatus', sortable: true, width: 125, minWidth: 125 },
            { id: 'memberlastname', name: 'LastName', field: 'memberlastname', filterable: true, sortable: true, width: 125, minWidth: 125 },
            { id: 'memberfirstname', name: 'FirstName', field: 'memberfirstname', filterable: true,sortable: true, width: 100, minWidth: 100 },
            { id: 'memberbirthdate_Formatted', name: 'DateOfBirth', field: 'memberbirthdate_Formatted', width: 120, minWidth: 100 },
            { id: 'address1', name: 'AddressLine1', field: 'address1', width: 100, minWidth: 100 },
/*            { id: 'address2', name: 'AddressLine2', field: 'address2', width: 100, minWidth: 100 },*/
            { id: 'city', name: 'City', field: 'city', sortable: true, width: 100, minWidth: 100 },
            { id: 'stateorprovince', name: 'State', field: 'stateorprovince', sortable: true, width: 100, minWidth: 100 },
            { id: 'postalcode', name: 'Zipcode', field: 'postalcode', sortable: true, width: 100, minWidth: 100 },
            //{ id: 'country', name: 'Country', field: 'country', width: 30, minWidth: 30 },
            //{ id: 'username', name: 'Username', field: 'username', width: 30, minWidth: 30 },
            //{ id: 'password', name: 'Password', field: 'password', width: 30, minWidth: 30 },
            { id: 'emailaddress', name: 'EmailAddress', field: 'emailaddress', width: 100, minWidth: 100 },
            { id: 'phonenumber', name: 'HomeNumber', field: 'phonenumber', width: 100, minWidth: 100 },
            { id: 'memberid', name: 'EmployeeNumber', field: 'memberid', sortable: true, width: 100, minWidth: 100 },
            { id: 'employer', name: 'EmployerEmployerID', field: 'employer', width: 30, minWidth: 30 },
            { id: 'memberssn', name: 'SSN', field: 'memberssn', sortable: true, width: 125, minWidth: 125 },
            //{ id: 'division', name: 'Division', field: 'division', width: 30, minWidth: 30 },
            //{ id: 'class', name: 'Class', field: 'class', width: 30, minWidth: 30 },
            //{ id: 'payroll', name: 'Payroll', field: 'payroll', width: 30, minWidth: 30 },
            //{ id: 'payrolleffectivedate', name: 'PayrollEffectiveDate', field: 'payrolleffectivedate', width: 30, minWidth: 30 },
            //{ id: 'employeestatus', name: 'EmployeeStatus', field: 'employeestatus', width: 30, minWidth: 30 },
            //{ id: 'statuseffectivedate', name: 'StatusEffectiveDate', field: 'statuseffectivedate', width: 30, minWidth: 30 },
            //{ id: 'hiredate', name: 'HireDate', field: 'hiredate', width: 30, minWidth: 30 },
            //{ id: 'medicarebeneficiary', name: 'MedicareBeneficiary', field: 'medicarebeneficiary', width: 30, minWidth: 30 },
            //{ id: 'medicareid', name: 'MedicareID', field: 'medicareid', width: 30, minWidth: 30 },
            { id: 'policycarriername', name: 'Carrier', field: 'policycarriername', sortable: true, width: 125, minWidth: 125 },
            { id: 'productname', filterable: true, name: 'PlanName', field: 'productname', sortable: true, width: 225, minWidth: 225 },
            { id: 'amountspent', name: 'Premium', field: 'amountspent', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'policyeffectivedate_Formatted', name: 'PolicyEffectiveDate', field: 'policyeffectivedate_Formatted', sortable: true, width: 120, minWidth: 100 },
            { id: 'policynumber', filterable: true, name: 'PolicyNumber', field: 'policynumber', sortable: true, width: 225, minWidth: 225 },
            { id: 'istobaccouser', filterable: true, name: 'Uses Tobacco', field: 'istobaccouser', sortable: true, width: 125, minWidth: 125 },
            { id: 'validatedamountspent', name: 'FinalPremium', field: 'validatedamountspent', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
/*            { id: 'orderitemid', name: 'OrderItemId', field: 'orderitemid', sortable: true, width: 100, minWidth: 100 },*/


        ];
        this.absgridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: true,
            checkboxSelector: {
                // you can toggle these 2 properties to show the "select all" checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true,
            },
            enableCheckboxSelector: true,


            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },
            //excelExportOptions: {
            //    filename: this.acctaddrdto.accountname,
            //    format: FileType.xlsx

            //}

        };
        this.absgridOptions.showTopPanel = false;

    }

    setupABSDependentGridData() {
        this.absdepcolumnDefinitions = [
            { id: 'id', name: 'No', field: 'id', sortable: false, width: 60, minWidth: 60 },
            { id: 'submitted', name: 'DateSubmitted', field: 'submitted', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateTimeIso },
            { id: 'memberid', name: 'EmployeeIdentifier', field: 'memberid', sortable: true, width: 100, minWidth: 100 },
            { id: 'dependentid', name: 'DependentIdentifier', field: 'dependentid', sortable: true, width: 100, minWidth: 100 },
            { id: 'dependentlastname', name: 'LastName', field: 'dependentlastname', filterable: true, sortable: true, width: 125, minWidth: 125 },
            { id: 'dependentfirstname', name: 'FirstName', field: 'dependentfirstname', sortable: true, width: 100, minWidth: 100 },
            { id: 'dependentdob_Formatted', name: 'DateOfBirth', field: 'dependentdob_Formatted', sortable: true, width: 120, minWidth: 100 },
            { id: 'relationship', name: 'Relationship', field: 'relationship', sortable: true, width: 100, minWidth: 100 },
            { id: 'relationshiptype', name: 'RelationshipType', field: 'relationshiptype', sortable: true, width: 100, minWidth: 100 },
            { id: 'dependentgender', name: 'Gender', field: 'dependentgender', width: 100, minWidth: 100 },
            { id: 'dependentssn', name: 'SSN', field: 'dependentssn', width: 100, minWidth: 100 },
            { id: 'medicarebeneficiary', name: 'MedicareBeneficiary', field: 'medicarebeneficiary', width: 100, minWidth: 100 },
            { id: 'medicareid', name: 'MedicareID', field: 'medicareid', width: 100, minWidth: 100 },
            { id: 'status', name: 'Status', field: 'status', width: 100, minWidth: 100 },
            { id: 'student', name: 'Student', field: 'student', width: 100, minWidth: 100 },
            { id: 'issuecard', name: 'IssueCard', field: 'issuecard', width: 100, minWidth: 100 },
            { id: 'hraenrollmenteffectivcedate_Formatted', name: 'HRAEnrollmentEffectiveDate', field: 'hraenrollmenteffectivcedate_Formatted', width: 100, minWidth: 100 },
            { id: 'hraenrollmentterminationdate', name: 'HRAEnrollmentTerminationDate', field: 'hraenrollmentterminationdate', width: 100, minWidth: 100 },
            { id: 'externaldependentid', name: 'ExternalDependentID', field: 'externaldependentid', width: 100, minWidth: 100 }


        ];
        this.absdepgridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: true,
            checkboxSelector: {
                // you can toggle these 2 properties to show the "select all" checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true,
            },
            enableCheckboxSelector: true,


            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },
            //excelExportOptions: {
            //    filename: this.acctaddrdto.accountname,
            //    format: FileType.xlsx

            //}

        };
        this.absdepgridOptions.showTopPanel = false;

    }


    setupABSEnrollmentGridData() {


        this.absenrollmentcolumnDefinitions = [
            { id: 'id', name: 'No', field: 'id', sortable: false, width: 60, minWidth: 60 },
            { id: 'submitted', name: 'DateSubmitted', field: 'submitted', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateTimeIso },
            { id: 'memberstatus', filterable: true, name: 'Member Status', field: 'memberstatus', sortable: true, width: 125, minWidth: 125 },
            { id: 'memberid', name: 'EmployeeIdentifier', field: 'memberid', sortable: true, width: 100, minWidth: 100 },
            { id: 'amountspent', name: 'ElectionAmount', field: 'amountspent', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'absplanname', name: 'PlanName', field: 'absplanname', sortable: true, width: 225, minWidth: 225 },
            { id: 'policyeffectivedate_Formatted', name: 'EnrollmentEffectiveDate', field: 'policyeffectivedate_Formatted', sortable: true, width: 120, minWidth: 100 },
            { id: 'amountspent', name: 'EmployerContribution', field: 'amountspent', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'hiredate', name: 'EnrollmentTermDate', field: 'hiredate', width: 30, minWidth: 30 },
            { id: 'division', name: 'PrimaryReimbursement', field: 'division', width: 30, minWidth: 30 },
            { id: 'class', name: 'ElectionAmountIndicator', field: 'class', width: 30, minWidth: 30 },
            { id: 'hdhpcoveragelevel', name: 'HDHPCoverageLevel', field: 'hdhpcoveragelevel', width: 30, minWidth: 30 },
            { id: 'password', name: 'EnrolledInClaimsExchange', field: 'password', width: 30, minWidth: 30 },
            { id: 'payroll', name: 'HSATermsFlag', field: 'payroll', width: 30, minWidth: 30 },
            { id: 'memberfirstname', name: 'First Name', field: 'memberfirstname', filterable: true, sortable: true, width: 100, minWidth: 100 },
            { id: 'memberlastname', filterable: true, name: 'Last Name', field: 'memberlastname', sortable: true, width: 125, minWidth: 125 },
            { id: 'istobaccouser', name: 'Uses Tobacco', field: 'istobaccouser', filterable: true, width: 100, minWidth: 100 },
        ];
        this.absenrollmentgridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: true,
            checkboxSelector: {
                // you can toggle these 2 properties to show the "select all" checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true,
            },
            enableCheckboxSelector: true,


            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },
            //excelExportOptions: {
            //    filename: this.acctaddrdto.accountname,
            //    format: FileType.xlsx

            //}

        };
        this.absgridOptions.showTopPanel = false;

    }


    setupABSContributionGridData() {


        this.abscontributioncolumnDefinitions = [
            { id: 'id', name: 'No', field: 'id', sortable: false, width: 60, minWidth: 60 },
            { id: 'createdon', name: 'DateSubmitted', field: 'createdon', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateTimeIso },
            { id: 'memberstatus', filterable: true, name: 'Member Status', field: 'memberstatus', sortable: true, width: 125, minWidth: 125 },
            { id: 'additionalid', name: 'EmployeeIdentifier', field: 'additionalid', sortable: true, width: 100, minWidth: 100 },
            { id: 'policyeffectivedate_Formatted', name: 'ContributionDate', field: 'policyeffectivedate_Formatted', sortable: true, width: 120, minWidth: 100 },
            { id: 'contributiondescription', name: 'ContributionDescription', field: 'contributiondescription', sortable: true, width: 100, minWidth: 100},
            { id: 'amountspent', name: 'ContributionAmount', field: 'amountspent', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'productname', filterable: true, name: 'PlanName', field: 'productname', sortable: true, width: 100, minWidth: 100 },
            { id: 'firstname', name: 'First Name', field: 'firstname', sortable: true, width: 100, minWidth: 100 },
            { id: 'lastname', filterable: true, name: 'Last Name', field: 'lastname', sortable: true, width: 125, minWidth: 125 }

        ];
        this.abscontributiongridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: true,
            checkboxSelector: {
                // you can toggle these 2 properties to show the "select all" checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true,
            },
            enableCheckboxSelector: true,


            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },
            //excelExportOptions: {
            //    filename: this.acctaddrdto.accountname,
            //    format: FileType.xlsx

            //}

        };
        this.absgridOptions.showTopPanel = false;

    }



    setuppaycardGridData() {


        this.paycardcolumnDefinitions = [
            { id: 'fein', name: 'FEIN', field: 'fein', width: 100, minWidth: 100 },
            { id: 'memberid', name: 'Employee Unique Id', field: 'memberid', sortable: true, width: 100, minWidth: 100 },
            { id: 'memberfirstname', name: 'First Name', field: 'memberfirstname', sortable: true, width: 100, minWidth: 100 },
            { id: 'memberlastname', filterable: true, name: 'Last Name', field: 'memberlastname', sortable: true, width: 125, minWidth: 125 },
            { id: 'stateorprovince', name: 'State', field: 'stateorprovince', sortable: true, width: 100, minWidth: 100 },
            { id: 'policyeffectivedate_Formatted', name: 'Coverage Start Date', field: 'policyeffectivedate_Formatted', sortable: true, width: 120, minWidth: 100 },
            { id: 'policyexpirationdate_Formatted', name: 'Coverage End Date', field: 'policyexpirationdate_Formatted', sortable: true, width: 120, minWidth: 100 },
            { id: 'amountspent', name: 'Premium', field: 'amountspent', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'externalvendorid', name: 'Vendor Code', field: 'externalvendorid', sortable: true, width: 125, minWidth: 125 },
            { id: 'policycarriername', name: 'Vendor Name', field: 'policycarriername', sortable: true, width: 125, minWidth: 125 },
            { id: 'productname', filterable: true, name: 'Plan', field: 'productname', sortable: true, width: 200, minWidth: 200 },
            { id: 'employer', name: 'Employer Name', field: 'employer', sortable: true, width: 100, minWidth: 100 },
            { id: 'employercontribution', name: 'Employer Contribution', field: 'employercontribution', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'payrolldeduction', name: 'Payroll Deduction', field: 'payrolldeduction', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'id', name: 'No', field: 'id', sortable: false, width: 60, minWidth: 60 }

        ];
        this.paycardgridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: true,
            checkboxSelector: {
                // you can toggle these 2 properties to show the "select all" checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true,
            },
            enableCheckboxSelector: true,


            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },
            //gridMenu: {
            //    //onCommand: (e, args) => {
            //    //    const dataview = args.grid.getData();
            //    //    this._addon = dataview;
            //    //    this.onCommandEvent(e, args);
            //    //}
            //    onBeforeMenuShow: 

            //},
            //excelExportOptions: {
            //    filename: this.acctaddrdto.accountname,
            //    format: FileType.xlsx

            //}

        };
        this.paycardgridOptions.showTopPanel = false;



    }

    onCommandEvent(event, args) {
        /*        var teston = args.getOptions();*/
        let test: string = "";
        test = "here";
    }

    // rowCountChanged(event, args) {

    //     let rows: number = this.angularGrid.dataView.getLength();

    //     console.log("***** rowCountChanged =" + rows.toString());
    ////     this.totalRowCount = this.angularGrid.dataView.getLength();


    // }



    getEnrollments() {

        this.searchError = false;
        this.searchErrorMsg = "";



        let todaysdate: Date = new Date(this.globaldataservice.policyeffectivedateafterdatestr);
        this.startdatestr = todaysdate.getFullYear().toString() + "-" + (todaysdate.getMonth() + 1).toString() + "-" + todaysdate.getDate().toString();
        this.enddatestr = "2052-01-01";

        this.orderService.getEnrollmentsForEmployer(this.globaldataservice.userobjectid, this.acctaddrdto.accountid, this.startdatestr, this.enddatestr, "EMPLOYEESANDDEPENDENTS")
            .subscribe(
                (data) => { this.getEnrollmentsCallBack(data); });

    }


    getEnrollmentsCallBack(data) {

        if (data.totalcount > 0) {
            this.searchError = false;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.totalRowCount = data.totalcount;

            this.enrollments = data.enrollmentdto;

            // 20 Dec 2021 JJM - Format the dates to MM/DD/YYYY. Otherwise they don't stay naturally formatted when export to excel
            for (var i = 0; i < this.enrollments.length; i++) {


                this.enrollments[i].memberbirthdate = new Date(this.enrollments[i].memberbirthdate);  // Date will come over the internet as a number, so this is necessary
                this.enrollments[i].memberbirthdate_Formatted = this.getFormattedDate(this.enrollments[i].memberbirthdate);

                this.enrollments[i].policyeffectivedate = new Date(this.enrollments[i].policyeffectivedate);
                if (this.enrollments[i].policyeffectivedate.getFullYear() > 1900) {
                    this.enrollments[i].policyeffectivedate_Formatted = this.getFormattedDate(this.enrollments[i].policyeffectivedate);
                }
                else {
                    this.enrollments[i].policyeffectivedate_Formatted = "";
                }

                this.enrollments[i].orderdate = new Date(this.enrollments[i].orderdate);
                this.enrollments[i].orderdate_Formatted = this.getFormattedDate(this.enrollments[i].orderdate);

                this.enrollments[i].dependentdob_Formatted = "";

                if (this.enrollments[i].dependentdob !== null) {
                    this.enrollments[i].dependentdob = new Date(this.enrollments[i].dependentdob);
                    if (this.enrollments[i].dependentdob.getFullYear() > 1900) {
                        this.enrollments[i].dependentdob_Formatted = this.getFormattedDate(this.enrollments[i].dependentdob);
                    }
                }

                if (this.enrollments[i].isonhealthpolicy === "Yes") {
                    if (this.enrollments[i].waivecoverage !== 1)
                        this.loadABSDependents(this.enrollments[i]);
                    else
                        console.log("Waived set for dependent");
                }
                this.enrollments[i].fein = this.acctaddrdto.fein;

            }


            this.mydataset = this.enrollments;


        }
        else {
            this.totalRowCount = 0;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = [];
            this.enrollments = [];
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }

    }

    getFormattedDate(sourcedate: Date): string {
        return ((sourcedate.getMonth() + 1).toString() + "/" + sourcedate.getDate().toString() + "/" + sourcedate.getFullYear().toString());
    }


    loadABSDependents(enroll: EnrollmentViewDTO) {
        let absdependent: ABSDependentViewDTO = {
            id: enroll.id,
            memberid: enroll.memberssn == "" ? enroll.memberid : enroll.memberssn,
            dependentid: enroll.dependentid,
            submitted: enroll.submitted,
            dependentfirstname: enroll.dependentfirstname,
            dependentlastname: enroll.dependentlastname,
            depdenentmiddleinitial: "",
            dependentdob_Formatted: enroll.dependentdob_Formatted,
            relationship: enroll.relationship,
            relationshiptype: "",
            dependentgender: enroll.dependentgender == "Male" ? "M" : "F",
            dependentssn: enroll.dependentssn,
            medicarebeneficiary: "",
            medicareid: "",
            status: "",
            student: "",
            issuecard: "",
            hraenrollmenteffectivcedate_Formatted: enroll.policyeffectivedate_Formatted,
            hraenrollmentterminationdate: "",
            externaldependentid: ""
        }
        this.absdependentlist.push(absdependent);
        this.absdeprowcount++;


    }


    onSelectedRowsChanged(e, args) {
        if (Array.isArray(args.rows)) {
            if (args.rows.length > 0) {
                this.isordernotselected = false;
                this.selectedquoteidx = args.rows.map(idx => {
                    return idx;
                });
            }
            else {
                this.isordernotselected = true;
            }
        }
    }



    tabClicked(event, tabId) {

        var element;
        this.searchError = false;
        this.searchErrorMsg = "";

        if (tabId === this.currenttabid)
            return;

        this.absenrollmentdataset = []; // doing this to clear it as quickly as possible

        if (this.currenttabid === 'tab-1-div') {  // JJM - This is a kludge, for some reason the first tab (not tab-content) needs active to be removed manually.  The rest don't
            element = document.getElementById(this.currenttabid);
            element.classList.remove('active');

        }

        let wrktabid: string = this.currenttabid.substring(0, 5);
        element = document.getElementById(wrktabid);
        element.classList.remove('active');

        wrktabid = tabId.substring(0, 5);
        element = document.getElementById(wrktabid);
        element.classList.add('active');



        if (this.currenttabid !== "") {
            element = document.getElementById(this.currenttabid);
            element.classList.remove("tcc_tab_on");
            element.classList.add("tcc_tab_off");
        }

        element = document.getElementById(tabId);
        element.classList.remove("tcc_tab_off");
        element.classList.add("tcc_tab_on");
        this.currenttabid = tabId;

        // window.scroll(0, 0);

        if (this.currenttabid === 'tab-3-div') {
            if (this.absdemographiclist.length == 0) {
                this.getABSDemographicEmployeeData();
            }
            else {
                this.absdemographicdataset = this.absdemographiclist;
                this.resizeabsGrid();
            }
        }
        else if (this.currenttabid === 'tab-4-div') {
            if (this.absdemographiclist.length == 0) {
                this.getABSDemographicEmployeeData();
            }
            else {
                this.getEnrollmentReportData();
            }
        }
        else if (this.currenttabid === 'tab-2-div') {
            this.absdepmydataset = this.absdependentlist;
            this.resizeabsdepGrid();
        }
        else if (this.currenttabid === 'tab-1-div') {
            this.mydataset = this.enrollments;
            this.resizeMainGrid();

        }
        else if (this.currenttabid === 'tab-5-div') {
            if (this.abscontributionlist.length == 0) {
                this.getABSContributionData();
            }
            else {
                this.abscontributiondataset = this.abscontributionlist;
                this.resizeabscontributionGrid();
            }
        }
        else if (this.currenttabid === 'tab-6-div') {
            if (this.absdemographiclist.length == 0) {
                this.getABSDemographicEmployeeData();
            }
            else {
                this.paycarddataset = this.absdemographiclist;
                this.paycardrowcount = this.absdemographiclist.length;
                this.resizepaycardGrid();
            }
        }

    }

    getEnrollmentReportData() {

        if (this.absdemographiclist.length > 0 && this.absenrollmentlist.length == 0) {
            for (var i = 0; i < this.absdemographiclist.length; i++) {
                this.loadEnrollmentsReportData(this.absdemographiclist[i])
            }

        }
        var test = "";
        test = "test";

        if (this.abscontributionlist.length > 0 && !this.isabsenrollmentlistLoadedWithHSA) {
            let idnumber: number = this.absdemographiclist.length+1000;
            for (var i = 0; i < this.abscontributionlist.length; i++) {
                if (this.abscontributionlist[i].productname === "HSA") {
                    this.loadEnrollmentsReportDataWithHSA(this.abscontributionlist[i], idnumber);
                    idnumber++;
                }

            }
            this.isabsenrollmentlistLoadedWithHSA = true;

        }
        this.absenrollmentdataset = this.absenrollmentlist;
        this.resizeabsenrollmentGrid();

    }

    loadEnrollmentsReportData(enroll: ABSDemographicViewDTO) {
        let enrollment: ABSEnrollmentReportDTO = {
            id: enroll.id,
            memberid: enroll.memberid,
            submitted: enroll.submitted,
            employer: enroll.employername,
            memberfirstname: enroll.memberfirstname,
            memberlastname: enroll.memberlastname,
            amountspent: enroll.amountspent,
            hdhpcoveragelevel: enroll.hdhpcoveragelevel,
            absplanname: enroll.absplanname,
            policycarriername: enroll.policycarriername,
            policyeffectivedate: enroll.policyeffectivedate,
            policyeffectivedate_Formatted: enroll.policyeffectivedate_Formatted,
            productname: enroll.productname,
            policynumber: enroll.policynumber,
            memberstatus: enroll.memberstatus,
            istobaccouser: enroll.istobaccouser

        }
        this.absenrollmentlist.push(enrollment);
        this.absenrollmentrowcount++;
    }


    loadEnrollmentsReportDataWithHSA(contribution: ABSContributionViewDTO, idnumber: number) {
        let enrollment: ABSEnrollmentReportDTO = {
            id: idnumber,
            memberid: contribution.additionalid,
            submitted: contribution.createdon,
            employer: contribution.accountname,
            memberfirstname: contribution.firstname,
            memberlastname: contribution.lastname,
            amountspent: 0,
            hdhpcoveragelevel: this.getHDHPCoverageLevel(contribution.id),
            absplanname: "HSA",
            policycarriername: "",
            policyeffectivedate: contribution.policyeffectivedate,
            policyeffectivedate_Formatted: contribution.policyeffectivedate_Formatted,
            productname: contribution.productname,
            policynumber: contribution.ordernumber,
            memberstatus: contribution.memberstatus,
            istobaccouser: ""

        }
        this.absenrollmentlist.push(enrollment);
        this.absenrollmentrowcount++;
    }

    getHDHPCoverageLevel(addid: string): string {

        for (var i = 0; i < this.absdemographiclist.length; i++) {
            if (addid === this.absdemographiclist[i].memberid) {
                return this.absdemographiclist[i].hdhpcoveragelevel;
            }
        }
        return "Single";
    }

    getABSDemographicEmployeeData() {

        this.searchError = false;
        this.searchErrorMsg = "";


        let todaysdate: Date = new Date(this.globaldataservice.policyeffectivedateafterdatestr);
        this.startdatestr = todaysdate.getFullYear().toString() + "-" + (todaysdate.getMonth() + 1).toString() + "-" + todaysdate.getDate().toString();
        this.enddatestr = "2052-01-01";

        this.orderService.getEnrollmentsForEmployer(this.globaldataservice.userobjectid, this.acctaddrdto.accountid, this.startdatestr, this.enddatestr, "EMPLOYEESONLY")
            .subscribe(
                (data) => { this.getABSDemographicEmployeeDataCallBack(data); });

    }

    getABSDemographicEmployeeDataCallBack(data) {
        if (data.totalcount > 0) {
            this.searchError = false;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.absdemographicrowcount = data.totalcount;

            this.absdemographiclist = data.enrollmentdto;
            

            // 20 Dec 2021 JJM - Format the dates to MM/DD/YYYY. Otherwise they don't stay naturally formatted when export to excel
            for (var i = 0; i < this.absdemographiclist.length; i++) {


                this.absdemographiclist[i].memberbirthdate = new Date(this.absdemographiclist[i].memberbirthdate);  // Date will come over the internet as a number, so this is necessary
                this.absdemographiclist[i].memberbirthdate_Formatted = this.getFormattedDate(this.absdemographiclist[i].memberbirthdate);

                this.absdemographiclist[i].policyeffectivedate = new Date(this.absdemographiclist[i].policyeffectivedate);
                if (this.absdemographiclist[i].policyeffectivedate.getFullYear() > 1900) {
                    this.absdemographiclist[i].policyeffectivedate_Formatted = this.getFormattedDate(this.absdemographiclist[i].policyeffectivedate);
                }
                else {
                    this.absdemographiclist[i].policyeffectivedate_Formatted = "";
                }

                this.absdemographiclist[i].policyexpirationdate = new Date(this.absdemographiclist[i].policyexpirationdate);
                if (this.absdemographiclist[i].policyexpirationdate.getFullYear() > 1900) {
                    this.absdemographiclist[i].policyexpirationdate_Formatted = this.getFormattedDate(this.absdemographiclist[i].policyexpirationdate);
                }
                else {
                    this.absdemographiclist[i].policyexpirationdate_Formatted = "";
                }

                this.absdemographiclist[i].orderdate = new Date(this.absdemographiclist[i].orderdate);
                this.absdemographiclist[i].orderdate_Formatted = this.getFormattedDate(this.absdemographiclist[i].orderdate);

                if (this.absdemographiclist[i].memberssn !== "")
                    this.absdemographiclist[i].memberid = this.absdemographiclist[i].memberssn;

                this.absdemographiclist[i].hdhpcoveragelevel = "Single";
                for (var x = 0; x < this.absdependentlist.length; x++) {  // if there is a dependent then set the HDHPCoverageLevel to Family
                    if (this.absdemographiclist[i].memberid == this.absdependentlist[x].memberid) {
                        this.absdemographiclist[i].hdhpcoveragelevel = "Family";
                        break;
                    }
                }
                let year: string = this.absdemographiclist[i].policyeffectivedate.getFullYear().toString();

                this.absdemographiclist[i].absplanname = 'ICHRA' + year.substr(2);


                //if (this.absdemographiclist[i].waivecoverage === 0) {
                //    this.absrowcount++;
                //}
            }

            if (this.currenttabid === 'tab-3-div') {
                this.absdemographicdataset = this.absdemographiclist;
                this.resizeabsGrid();
            }
            else if (this.currenttabid === 'tab-4-div') {
                this.getEnrollmentReportData();
            }
            else if (this.currenttabid === 'tab-6-div') {
                this.paycarddataset = this.absdemographiclist;
                this.paycardrowcount = this.absdemographiclist.length;
                this.resizepaycardGrid();
            }



        }
        else {
            this.totalRowCount = 0;
            this.absgridOptions = Object.assign({}, this.absgridOptions);
            this.absdemographiclist = [];
            this.absdemographicdataset = [];
            this.absdemographicrowcount = 0;
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }


    }


    getABSContributionData() {

        this.searchError = false;
        this.searchErrorMsg = "";

        let todaysdate: Date = new Date(this.globaldataservice.policyeffectivedateafterdatestr);
        this.startdatestr = todaysdate.getFullYear().toString() + "-" + (todaysdate.getMonth() + 1).toString() + "-" + todaysdate.getDate().toString();
        this.enddatestr = "2052-01-01";

        this.orderService.getOrdersHSAByEmployer(this.acctaddrdto.accountid, this.startdatestr, this.enddatestr)
            .subscribe(
                (data) => { this.getABSContributionCallBack(data); });

    }



    getABSContributionCallBack(data) {
        if (data.totalcount > 0) {
            this.searchError = false;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.abscontributionrowcount = data.totalcount;

            this.abscontributionlist = data.orderhsadto;

            // 20 Dec 2021 JJM - Format the dates to MM/DD/YYYY. Otherwise they don't stay naturally formatted when export to excel
            for (var i = 0; i < this.abscontributionlist.length; i++) {

                this.abscontributionlist[i].policyeffectivedate = new Date(this.abscontributionlist[i].policyeffectivedate);
                if (this.abscontributionlist[i].policyeffectivedate.getFullYear() > 1900) {
                    this.abscontributionlist[i].policyeffectivedate_Formatted = this.getFormattedDate(this.abscontributionlist[i].policyeffectivedate);
                }
                else {
                    this.abscontributionlist[i].policyeffectivedate_Formatted = "";
                }


                this.abscontributionlist[i].contributiondescription =  "Employer";
                this.abscontributionlist[i].productname = "HSA";

                if (this.abscontributionlist[i].additionalid === "")
                    this.abscontributionlist[i].additionalid = this.abscontributionlist[i].id;


            }


            this.abscontributiondataset = this.abscontributionlist;
            this.resizeabscontributionGrid();
        }
        else {
            this.absgridOptions = Object.assign({}, this.absgridOptions);
            this.abscontributionlist = [];
            this.abscontributiondataset = [];
            this.abscontributionrowcount = 0;
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }


    }


    changeStartPolicyEffectiveDate(e: any) {

        let wrktermdate: Date = new Date(e.target.value);
        if (!isNaN(wrktermdate.getDate())) {
            this.globaldataservice.policyeffectivedateafterdatestr = (wrktermdate.getUTCMonth() + 1).toString() + "/" + wrktermdate.getUTCDate().toString() + "/" + wrktermdate.getUTCFullYear().toString();
            this.clearAllDataSets();
            this.getEnrollments();
        }
        else {
            this.searchError = true;
            this.searchErrorMsg = "Please enter a valid date."
        }

    }

    clearAllDataSets() {


        this.totalRowCount = 0;
        this.mydataset = [];
        this.enrollments = [];

        // Demographics 
        this.absdemographicdataset = [];
        this.absdemographicrowcount = 0;
        this.absdemographiclist = [];

        // Dependents
        this.absdepmydataset = [];
        this.absdependentlist = [];
        this.absdeprowcount = 0;

        // Enrollments
        this.absenrollmentdataset = [];
        this.absenrollmentrowcount = 0;

        // Contributions
        this.abscontributionlist = [];
        this.abscontributiondataset = [];
        this.abscontributionrowcount = 0;

    }



    toggleMainGridMenu(e: MouseEvent) {
        if (this.angularMainGrid && this.angularMainGrid.extensionService) {
            const gridMenuInstance = this.angularMainGrid.extensionService.getExtensionByName(ExtensionName.gridMenu).instance;
            // open the external button Grid Menu, you can also optionally pass Grid Menu options as 2nd argument
            // for example we want to align our external button on the right without affecting the menu within the grid which will stay aligned on the left
            gridMenuInstance.showGridMenu(e, { dropSide: 'right' });
        }
    }

    toggleABSGridMenu(e: MouseEvent) {
        if (this.angularabsGrid && this.angularabsGrid.extensionService) {
            const gridMenuInstance = this.angularabsGrid.extensionService.getExtensionByName(ExtensionName.gridMenu).instance;
            // open the external button Grid Menu, you can also optionally pass Grid Menu options as 2nd argument
            // for example we want to align our external button on the right without affecting the menu within the grid which will stay aligned on the left
            gridMenuInstance.showGridMenu(e, { dropSide: 'right' });
        }
    }

    toggleABSDEPGridMenu(e: MouseEvent) {
        if (this.angularabsdepGrid && this.angularabsdepGrid.extensionService) {
            const gridMenuInstance = this.angularabsdepGrid.extensionService.getExtensionByName(ExtensionName.gridMenu).instance;
            // open the external button Grid Menu, you can also optionally pass Grid Menu options as 2nd argument
            // for example we want to align our external button on the right without affecting the menu within the grid which will stay aligned on the left
            gridMenuInstance.showGridMenu(e, { dropSide: 'right' });
        }
    }

    toggleABSEnrollmentGridMenu(e: MouseEvent) {
        if (this.angularabsenrollmentGrid && this.angularabsenrollmentGrid.extensionService) {
            const gridMenuInstance = this.angularabsenrollmentGrid.extensionService.getExtensionByName(ExtensionName.gridMenu).instance;
            // open the external button Grid Menu, you can also optionally pass Grid Menu options as 2nd argument
            // for example we want to align our external button on the right without affecting the menu within the grid which will stay aligned on the left
            gridMenuInstance.showGridMenu(e, { dropSide: 'right' });
        }
    }

    toggleABSContributionGridMenu(e: MouseEvent) {
        if (this.angularabscontributionGrid && this.angularabscontributionGrid.extensionService) {
            const gridMenuInstance = this.angularabscontributionGrid.extensionService.getExtensionByName(ExtensionName.gridMenu).instance;
            // open the external button Grid Menu, you can also optionally pass Grid Menu options as 2nd argument
            // for example we want to align our external button on the right without affecting the menu within the grid which will stay aligned on the left
            gridMenuInstance.showGridMenu(e, { dropSide: 'right' });
        }
    }

    togglePayCardGridMenu(e: MouseEvent) {
        if (this.angularpaycardGrid && this.angularpaycardGrid.extensionService) {
            const gridMenuInstance = this.angularpaycardGrid.extensionService.getExtensionByName(ExtensionName.gridMenu).instance;
            // open the external button Grid Menu, you can also optionally pass Grid Menu options as 2nd argument
            // for example we want to align our external button on the right without affecting the menu within the grid which will stay aligned on the left
            gridMenuInstance.showGridMenu(e, { dropSide: 'right' });
        }
    }

    resizeMainGrid() {
        this.angularMainGrid.resizerService.resizeGrid();
    }

    resizeabsGrid() {
        this.angularabsGrid.resizerService.resizeGrid();
    }

    resizeabsenrollmentGrid() {
        this.angularabsenrollmentGrid.resizerService.resizeGrid();
    }

    resizeabsdepGrid() {
        this.angularabsdepGrid.resizerService.resizeGrid();
    }

    resizeabscontributionGrid() {
        this.angularabscontributionGrid.resizerService.resizeGrid();
    }

    resizepaycardGrid() {
        this.angularpaycardGrid.resizerService.resizeGrid();
    }


}

    // NO CUSTOM CODE BEYOND THIS POINT

    //goToFirstPage() {
    //    this.angularGrid.paginationService.goToFirstPage();
    //}

    //goToLastPage() {
    //    this.angularGrid.paginationService.goToLastPage();
    //}

    //setFiltersDynamically() {
    //    // we can Set Filters Dynamically (or different filters) afterward through the FilterService
    //    this.angularGrid.filterService.updateFilters([
    //        // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
    //        { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
    //    ]);


    //}

    //setSortingDynamically() {
    //    this.angularGrid.sortService.updateSorting([
    //        { columnId: 'name', direction: 'DESC' },
    //    ]);
    //}


    //// THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    //// ---

    //changeCountEnableFlag() {
    //    this.isCountEnabled = !this.isCountEnabled;
    //    const odataService = this.gridOptions.backendServiceApi.service;

    //    // @ts-ignore
    //    odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
    //    odataService.clearFilters();
    //    this.angularGrid.filterService.clearFilters();
    //    return true;
    //}

    //setOdataVersion(version: number) {
    //    this.odataVersion = version;
    //    const odataService = this.gridOptions.backendServiceApi.service;
    //    // @ts-ignore
    //    odataService.updateOptions({ version: this.odataVersion } as OdataOption);
    //    odataService.clearFilters();
    //    this.angularGrid.filterService.clearFilters();
    //    return true;
    //}







