﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../core/environment.service';
import { ExtendedHttpService } from '../core/extendedhttp.service';
import { HttpHeaders } from '@angular/common/http';
import { Http } from '@angular/http';
import { catchError, map } from 'rxjs/operators';
import { ICQuoteDTO } from '../dto/ICQuoteDTO';
import { AccountAndAddressDTO } from '../dto/AccountAndAddressDTO';

var authToken = sessionStorage.getItem('auth_token');
// if(authToken){
//     authToken = JSON.parse(authToken);
// }
var httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
    })
};
if(authToken){
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        })
    };
}
@Injectable({
    providedIn: 'root',

})
export class EmployerAccountService extends ExtendedHttpService {

    constructor(private env: EnvironmentService) {
        super();
    }


    getEmployerAccounts(ownerid: string, accounttype: number, customertype: number) {
        let marker: number = 0;

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Account/' + ownerid + '/' + accounttype + '/' + customertype + '/' + marker).
            pipe(
                map((data) => data.json())
            );
    }

    // Employer Billing Service Calls

    getEmployerBillingContractInfo(ownerid: string, asofdate: string) {
        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Account/GetEmployerActiveMembers/' + ownerid + '/' + asofdate).
            pipe(
                map((data) => data.json())
            );
    }


    getCityStatebyZip(zipcode: string) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Account/' + zipcode).
            pipe(
                map((data) => data.json())
            );
    }


    getUSZipCounty(zipcode: string) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/USZipCounty/' + zipcode).
            pipe(
                map((data) => data.json())
            );
    }


    postAccountAndAddress(acctaddrdto: AccountAndAddressDTO) {


        return this.postData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Account/', acctaddrdto).
            pipe(
                map((data) => data.json())
            );


    }

}
