﻿
//Auto Generated file

  
export class EmployeeOnBoardingTrackerDTO {
		// This file is generated from the entities located in the MAKTech.Entities project
 
        public id: string;
        public employercontributionid: string;
        public firstname: string;
        public lastname: string;
        public familystatus: string;
        public employercontributionpretaxbucket: number;
        public healthinsurancepremium: number;
        public processedstatus: string;
        public processedon: Date;
        public processedby: string;
        public processedownerid: string;
        public createdon: Date;
        public createdby: string;
        public modifiedon: Date;
        public modifiedby: string;
        public pretaxbucket: number;
        public posttaxbucket: number;
        public employercontributionposttaxbucket: number;
        public moneyfromposttaxtopretax: number;
        public location: string;
        public birthdate: Date;
    }
