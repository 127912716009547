import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule, routes } from './app-routing.module';
import { BsDropdownModule } from 'ngx-bootstrap';

import { AppComponent } from './app.component';
import { SidebarComponent } from './sidebar/sidebar.component';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { RouterModule } from '@angular/router';


import { AuthGuard } from './auth/auth.guard.spec';
import { CallbackComponent } from './core/callback.component';
import { HomeComponent } from './home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { AngularSlickgridModule } from 'angular-slickgrid';

import { DashboardComponent } from './dashboard/dashboard.component';
import { ContractsComponent } from './contracts/contracts.component';
import { BrokersComponent } from './contracts/brokers.component';
import { CompanyComponent } from './companies/company.component';
import { CompanyUNQComponent } from './companies/companyunq.component';
import { ACARateCalcComponent } from './rates/acaratecalc.component';
import { ACAPlanLookupComponent } from './rates/acaplanlookup.component';
import { ACAPlansAndRatesComponent } from './rates/acaplansandrates.component';
import { PlanCostCompareComponent } from './rates/plancostcompare.component';
import { TotalCostComponent } from './rates/totalcost.component';
import { ICQuoteComponent } from './rates/icquote.component';
import { AdminPlansComponent } from './admin/adminplans.component';
import { AdminPlanDetailComponent } from './admin/adminplandetail.component';
import { AdminProductsComponent } from './admin/adminproducts.component';
import { AdminPlanDetail2021Component } from './admin/adminplandetail2021.component';
import { ContributionStrategyComponent } from './rates/contributionstrategy.component';
import { MYStrategiesComponent } from './rates/mystrategies.component';
import { AdminContributionsComponent } from './admin/admincontributions.component';
import { EmpPlanComparisonComponent } from './employer/empplancomparison.component';
import { OnboardComponent } from './employee/onboard.component';


import { NgxSpinnerModule } from "ngx-spinner";
import { B2CAuthService } from './auth/b2cauth.service';
import { AllContractsComponent } from './contracts/allcontracts.component';
import { MsalService } from './auth/msal.service';
import { EnvironmentService, SpinnerManager } from './core/environment.service';
import { Http, HttpModule } from '@angular/http';
import { IpAddressService } from './core/ipaddress.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { RedirectService } from './auth/redirect.service';
import { ToolKitEvents } from './auth/toolkitevents.service';
import { BrokerManagementComponent } from './admin/brokermanagement.component';
import { AdminRequestAccessComponent } from './admin/adminrequestaccess.component';
import { ActivityComponent } from './activity/activity.component';
import { EmployerAccountComponent } from './employer/employeraccount.component';
import { AccountDetailComponent } from './employer/accountdetail.component';
import { QuoteDetailComponent } from './employer/quotedetail.component';
import { VersionComponent } from './admin/version.component';
import { HasUnsavedDataGuard } from './core/hasunsaveddataguard';
import { AdminQuotesComponent } from './admin/adminquotes.component';
import { OrderComponent } from './employeaze/order.component';
import { OrderPayrollComponent } from './admin/orderpayroll.component';
import { EmployerSetupComponent } from './employeaze/employersetup.component';
import { EmployerContributionComponent } from './employeaze/employercontribution.component';
import { MemberComponent } from './employeaze/member.component';
import { LogFactComponent } from './admin/logfact.component';
import { ProductDetailComponent } from './admin/productdetail.component';
import { ImageDetailComponent } from './admin/imagedetail.component';
import { EnrollmentComponent } from './employer/enrollment.component';
import { Nfor1CustomerComponent } from './employeaze/nfor1customer.component';
import { OrderDetailComponent } from './employeaze/orderdetail.component';
import { UserComponent } from './admin/user.component';
import { UserDetailComponent } from './admin/userdetail.component';
import { DependentDetailComponent } from './employeaze/dependentdetail.component';
import { OrderPivotComponent } from './employeaze/orderpivot.component';
import { EmployerBillingComponent } from './employeaze/employerbilling.component';
import { TeamComponent } from './admin/team.component';

// import { n4onespinnerService } from './core/n4onespinner.service';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent,
      SidebarComponent,
      CallbackComponent,
        HomeComponent,
        DashboardComponent,
        CompanyUNQComponent,
        ContractsComponent,
        AllContractsComponent,
        CompanyComponent,
        BrokersComponent,
        ACARateCalcComponent,
        ACAPlanLookupComponent,
        ACAPlansAndRatesComponent,
        PlanCostCompareComponent,
        TotalCostComponent,
        ICQuoteComponent,
        AdminPlansComponent,
        AdminPlanDetailComponent,
        AdminProductsComponent,
        ProductDetailComponent,
        ImageDetailComponent,
        ContributionStrategyComponent,
        MYStrategiesComponent,
        AdminContributionsComponent,
        EmpPlanComparisonComponent,
        OnboardComponent,
        BrokerManagementComponent,
        AdminRequestAccessComponent,
        ActivityComponent,
        EmployerAccountComponent,
        AccountDetailComponent,
        QuoteDetailComponent,
        VersionComponent,
        AdminPlanDetail2021Component,
        AdminQuotesComponent,
        OrderComponent,
        OrderPayrollComponent,
        EmployerSetupComponent,
        EmployerContributionComponent,
        MemberComponent,
        LogFactComponent,
        EnrollmentComponent,
        Nfor1CustomerComponent,
        OrderDetailComponent,
        UserComponent,
        UserDetailComponent,
        DependentDetailComponent,
        OrderPivotComponent,
        EmployerBillingComponent,
        TeamComponent

      
  ],
  imports: [
      BrowserModule,
      FormsModule,
      BrowserAnimationsModule,
      HttpClientModule,     
      AppRoutingModule,
      RouterModule.forRoot(routes),
      BsDropdownModule.forRoot(),
      AngularSlickgridModule.forRoot(), 
      NgMultiSelectDropDownModule.forRoot(),
      NgxSpinnerModule,
      PerfectScrollbarModule,
      HttpModule
  ],
  providers: [ {
    provide: PERFECT_SCROLLBAR_CONFIG,
    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
  },
      AuthGuard, EnvironmentService, MsalService, B2CAuthService, SpinnerManager, HasUnsavedDataGuard
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
