﻿//import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ICQuoteService } from '../rates/icquote.service';
import { ICQuoteDTO } from '../dto/ICQuoteDTO';
//import * as moment from 'moment-mini';


import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';
import { ResponseBaseDTO } from '../dto/ResponseBaseDTO';

const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';



@Component({
    templateUrl: 'adminquotes.html',
    providers: [
        ICQuoteService,
        ListService
    ]
})
export class AdminQuotesComponent extends BaseComponent implements OnInit {

    quotecolumnDefinitions: Column[] = [];
    quotegridOptions: GridOption = {};
    quotedataset: any[] = [];

    bIsLoaded: boolean = false;
    canShowGrid: boolean = false;
    angularGrid: AngularGridInstance;
    metrics: Metrics;
    //companyName: string;
    isCountEnabled = true;
    odataVersion = 2;
    odataQuery = '';
    processing = true;
    totalRowCount: number = 0;
    status = { text: 'processing...', class: 'alert alert-danger' };

    userSelectsString = '';
    name = 'Angular';
    userSelects = [];
    dropdownList = [];
    //    selectedItems = [];
    dropdownSettings = {};
    searchError: boolean = false;
    searchErrorMsg: string = "";
    displayMessage: string = "";
    testdate: string;

    gridObj: any[];
    selectedGridIDS: number[];
    selectedTitles: any[];
    selectedTitle: any;

    zipcode: string = "";

    addingaccount: number = 0;
    addingquote: number = 0;

    ratingareaid: string = "";
    city: string = "";
    state: string = "";



    totalquotes: number = 0;
    renewaldate: Date;
    renewaldatestr: string = "";

    useremail: string;
    userobjectid: string;



    employername: string = "";
    quotename: string = "";
    brokername: string = "";
    accountstatus: string = "Active";

    accountid: string = "";
    quoteid: string = "";
    quotelist: any[] = [];
    createdon: Date;
    createdby: string;

    icquotedto: ICQuoteDTO;
    quotes: any[] = [];
    selectedquoteidx: number = -1;
    isquotenotselected: boolean = true;
    isaccountnotsaved: boolean = true;

    constructor(
        public icquoteservice: ICQuoteService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService,
        private cd: ChangeDetectorRef,
        protected _router: Router,
        private route: ActivatedRoute) {

        super();

        this.userobjectid = auth.userobjectid;
        this.useremail = auth.useremail;
    }


    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};
    }



    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }


    ngOnInit(): void {


        this.quotecolumnDefinitions = [
            { id: 'accountname', name: 'Employer Name', field: 'accountname', sortable: true, width: 275, minWidth: 275 },
            { id: 'name', name: 'Description', field: 'name', sortable: true, width: 275, minWidth: 275 },
            { id: 'brokername', name: 'Broker Name', field: 'brokername', sortable: true, width: 275, minWidth: 275 },
            { id: 'city', name: 'City', field: 'city', sortable: true, width: 125, minWidth: 125 },
            { id: 'state', filterable: true, name: 'State', field: 'state', sortable: true, width: 75, minWidth: 75 },
            { id: 'postalcode', name: 'Zip Code', field: 'postalcode', sortable: true, width: 100, minWidth: 100 },
            { id: 'ratingarea', name: 'Rating Area', field: 'ratingarea', sortable: true, width: 150, minWidth: 150 },
            { id: 'currentpremium', name: 'Current Premium', field: 'currentpremium', sortable: true, width: 125, minWidth: 100, formatter: this.moneyFormatter },
            { id: 'medicarepremium', name: 'Medicare Premium', field: 'currentpremium', sortable: true, width: 125, minWidth: 100, formatter: this.moneyFormatter },
            { id: 'effectivedate', name: 'Effective Date', field: 'effectivedate', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'totalmembers', name: 'Total Members', field: 'totalmembers', sortable: true, width: 125, minWidth: 125 },
            { id: 'createdon', name: 'Created On', field: 'createdon', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'createdby', name: 'Created By', field: 'createdby', sortable: true, width: 175, minWidth: 175 },
            { id: 'issuer1name', name: 'Issuer 1', field: 'issuer1name', sortable: true, width: 150, minWidth: 150 },
            { id: 'plan1name', name: 'Plan Name 1', field: 'plan1name', sortable: true, width: 250, minWidth: 250 },
            { id: 'plan1monthlycost', name: 'Plan 1 Monthly Cost', field: 'plan1monthlycost', sortable: true, width: 225, minWidth: 225, formatter: this.moneyFormatter },
            { id: 'issuer2name', name: 'Issuer 2', field: 'issuer2name', sortable: true, width: 150, minWidth: 150 },
            { id: 'plan2name', name: 'Plan Name 2', field: 'plan2name', sortable: true, width: 250, minWidth: 250 },
            { id: 'plan2monthlycost', name: 'Plan 2 Monthly Cost', field: 'plan2monthlycost', sortable: true, width: 225, minWidth: 225, formatter: this.moneyFormatter },
            { id: 'issuer3name', name: 'Issuer 3', field: 'issuer3name', sortable: true, width: 150, minWidth: 150 },
            { id: 'plan3name', name: 'Plan Name 3', field: 'plan3name', sortable: true, width: 250, minWidth: 250 },
            { id: 'plan3monthlycost', name: 'Plan 3 Monthly Cost', field: 'plan3monthlycost', sortable: true, width: 225, minWidth: 225, formatter: this.moneyFormatter },
            { id: 'issuer4name', name: 'Issuer 4', field: 'issuer4name', sortable: true, width: 150, minWidth: 150 },
            { id: 'plan4name', name: 'Plan Name 4', field: 'plan4name', sortable: true, width: 250, minWidth: 250 },
            { id: 'plan4monthlycost', name: 'Plan 4 Monthly Cost', field: 'plan4monthlycost', sortable: true, width: 225, minWidth: 225, formatter: this.moneyFormatter },
            { id: 'modifiedon', name: 'Last Modified', field: 'modifiedon', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'id', name: 'Quote Id', field: 'id', sortable: false, width: 60, minWidth: 60 }
        ];
        this.quotegridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: true,
            //checkboxSelector: {
            //    // you can toggle these 2 properties to show the "select all" checkbox in different location
            //    hideInFilterHeaderRow: false,
            //    hideInColumnTitleRow: true
            //},
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true,
            },
            // enableCheckboxSelector: true,

            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },

        };
        this.quotegridOptions.showTopPanel = false;


        this.getquotes();

        //this.angularGrid.resizerService.pauseResizer(true);
    }

    getquotes() {

        this.searchError = false;

        this.icquoteservice.getAllQuotes()
            .subscribe(
                (data) => { this.getquotesCallBack(data); });

    }

    getquotesCallBack(data) {

        this.searchError = false;
        this.searchErrorMsg = "";

        if (data.totalcount > 0) {
            this.quotegridOptions = Object.assign({}, this.quotegridOptions);
            this.totalRowCount = data.totalcount;

            this.quotedataset = data.quotelist;
            this.quotes = data.quotelist;
        }
        else {
            this.totalRowCount = 0;
            this.quotegridOptions = Object.assign({}, this.quotegridOptions);
            this.quotedataset = [];
            this.quotes = [];
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }
    }



    onSelectedRowsChanged(e, args) {
        if (Array.isArray(args.rows)) {
            if (args.rows.length > 0) {
                this.isquotenotselected = false;
                this.selectedquoteidx = args.rows.map(idx => {
                    return idx;
                });
            }
            else {
                this.isquotenotselected = true;
            }
        }
    }

    // NOT PART OF CUSTOM CODE

    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);


    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }



    //onSelectedRowsChanged(e, args) {

    //    let test: number = 0;
    //    if (Array.isArray(args.rows)) {

    //        this.searchError = false;
    //        this.numberofSelectedPlans = args.rows.length;
    //        //if (args.rows.length > 4) {
    //        //    this.searchError = true;
    //        //    this.searchErrorMsg = "You can only select up to 4 plans.";
    //        //    return;
    //        //}

    //        test = args.rows.map(idx => idx);

    //        this.membernumber = args.rows.map(idx => {
    //            const item = this.angularGrid.gridService.getDataItemByRowIndex(idx);
    //            return item.numberbyage || 0;
    //        });
    //    }
    //}



    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.quotegridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.quotegridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}






