﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../core/environment.service';
import { ExtendedHttpService } from '../core/extendedhttp.service';
import { HttpHeaders } from '@angular/common/http';
import { Http } from '@angular/http';
import { catchError, map } from 'rxjs/operators';
import { ICContributionStrategyDTO } from '../dto/ICContributionStrategyDTO'



const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
@Injectable({
    providedIn: 'root',

})
export class ContributionStrategyService extends ExtendedHttpService {

    constructor(private env: EnvironmentService) {
        super();
    }


    getratefactors(state: string, businessyear: string): Observable<any> {

        //return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/ACARateCalcForTwoPlans/' + state + '/' + businessyear);


        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/ACARateCalcForTwoPlans/' + state + '/' + businessyear).
            pipe(
                map((data) => data.json())
            );
    }



    getplansandratefactors(planids: string, ratingarea: string, state: string, businessyear: string): Observable<any> {

        //return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/ACARateCalcForTwoPlans/' + state + '/' + businessyear);


        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/ICContributionStrategy/' + planids + '/' + ratingarea + '/' + state + '/' + businessyear).
            pipe(
                map((data) => data.json())
            );
    }

    postContributionStrategy(contributionstrategyDTO: ICContributionStrategyDTO) {


        return this.postData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/ICContributionStrategy/', contributionstrategyDTO).
            pipe(
                map((data) => data.json())
            );


    }


}
