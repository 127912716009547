﻿
//Auto Generated file


export class ProductDTO {
    // This file is generated from the entities located in the MAKTech.Entities project

    public id: string;
    public vendorid: string;
    public productname: string;
    public category: string;
    public subcategory: string;
    public paymenttype: number;
    public level: string;
    public description: string;
    public teaser: string;
    public status: number;
    public isproductgroup: number;
    public taxstatus: string;
    public createdon: Date;
    public createdby: string;
    public effectivedate: Date;
    public discontinueddate: Date;
    public modifiedon: Date;
    public modifiedby: string;


}
