﻿import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Headers, RequestOptions, RequestOptionsArgs, Response, ResponseContentType } from '@angular/http';
import { B2CAuthService } from '../auth/b2cauth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/finally';
import { appInjector } from './util.service';
import { SpinnerManager } from './environment.service';

@Injectable()
export class ExtendedHttpService {
    private spinner: NgxSpinnerService;
    protected _http: Http;
    private auth: B2CAuthService;
    public ShowSpinner: Boolean = true;
    private spinnerMgr: SpinnerManager;

    constructor() {
        this._http = appInjector().get(Http);
        this.spinner = appInjector().get(NgxSpinnerService);
        this.auth = appInjector().get(B2CAuthService);
        this.spinnerMgr = appInjector().get(SpinnerManager);
    }

    private _buildAuthHeader(): string {
        return localStorage.getItem("authToken");
    }

    private getOptionsupload(): any {
        let headers = new Headers({ 'Accept': 'application/json' });
        headers.append("Authorization", "Bearer " + this.auth.accesstoken);
        return new RequestOptions({ headers: headers });
    }

    private getOptions(reqArgs?: RequestOptions): any {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        headers.append("Authorization", "Bearer " + this.auth.accesstoken);
        if (reqArgs != null) {
            return new RequestOptions({ headers: headers, body: reqArgs });
        } else {
            return new RequestOptions({ headers: headers });
        }
    }

    private getOptionsBlob(reqArgs?: RequestOptions): any {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        headers.append("Authorization", "Bearer " + this.auth.accesstoken);
        if (reqArgs != null) {
            return new RequestOptions({ headers: headers, body: reqArgs });
        } else {
            return new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
        }
    }

    protected processRequestoptions() {}

    public getData(url: string, options?: RequestOptions): Observable<Response> {
        if (this.spinnerMgr.IsSpinnnerRunning) {
            this.spinner.hide();
        }
        this.spinnerMgr.IsSpinnnerRunning = true;
        this.spinner.show();
        options = this.getOptions(options);
        console.log("Here is the API call header : ", options);

        return this._http.get(url, options)
            .catch(res => {
                if (res.status === 401) {
                    var router: Router = appInjector().get(Router);
                    this.spinner.hide();
                    window.location.reload();
                    router.navigate(['login']);
                }
                this.handleError(res);
                return Observable.throw(res.json());
            })
            .finally(() => {
                this.spinnerMgr.IsSpinnnerRunning = false;
                this.spinner.hide();
            });
    }

    public postData(url: string, body: any, options?: RequestOptions, isupload: boolean = false): Observable<Response> {
        if (this.spinnerMgr.IsSpinnnerRunning) {
            this.spinner.hide();
        }
        this.spinnerMgr.IsSpinnnerRunning = true;
        this.spinner.show();
        options = isupload ? this.getOptionsupload() : this.getOptions(options);

        return this._http.post(url, body, options)
            .catch(res => {
                this.handleError(res);
                let errMsg = res._body || 'Server error';
                return Observable.throw(errMsg);
            })
            .finally(() => {
                this.spinnerMgr.IsSpinnnerRunning = false;
                this.spinner.hide();
            });
    }

    public postDataBlob(url: string, body: any, options?: RequestOptions): Observable<Response> {
        if (this.ShowSpinner) {
            this.spinner.show();
        }
        options = this.getOptionsBlob(options);
        return this._http.post(url, body, options)
            .catch(res => {
                this.handleError(res);
                let errMsg = res._body || 'Server error';
                return Observable.throw(errMsg);
            })
            .finally(() => {
                if (this.ShowSpinner) {
                    this.spinner.hide();
                }
            });
    }

    public putData(url: string, body: any, options?: RequestOptions): Observable<Response> {
        if (this.ShowSpinner) {
            this.spinner.show();
        }
        let headers = new Headers({ 'Content-Type': 'application/json' });
        headers.append("Authorization", "Bearer " + this.auth.accesstoken);
        options = new RequestOptions({ headers: headers });

        return this._http.put(url, body, options)
            .catch(res => {
                this.handleError(res);
                let errMsg = res._body || 'Server error';
                return Observable.throw(errMsg);
            })
            .finally(() => {
                if (this.ShowSpinner) {
                    this.spinner.hide();
                }
            });
    }

    public deleteData(url: string, options?: RequestOptions): Observable<Response> {
        if (this.ShowSpinner) {
            this.spinner.show();
        }
        options = this.getOptions(options);
        return this._http.delete(url, options)
            .catch(res => {
                this.handleError(res);
                let errMsg = res._body || 'Server error';
                return Observable.throw(errMsg);
            })
            .finally(() => {
                if (this.ShowSpinner) {
                    this.spinner.hide();
                }
            });
    }

    public patch(url: string, body: string, options?: RequestOptionsArgs): Observable<Response> {
        return null;
    }

    public head(url: string, options?: RequestOptionsArgs): Observable<Response> {
        return null;
    }

    private handleError(error: any) {
        let data = error.json();
        if (data.ExceptionMessage) {
            // Handle the service error
        }
    }
}
