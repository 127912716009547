﻿import { Component, OnInit } from '@angular/core';
import { CompanyUNQService } from './companyunq.service';


import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
//import { AllContractsService } from './allcontracts.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';

const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';



@Component({
    templateUrl: './companyunq.component.html',
    providers: [
        CompanyUNQService
    ]
})
export class CompanyUNQComponent extends BaseComponent implements OnInit {

    constructor(public companyunqservice: CompanyUNQService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService) {
        super();
    }

    stateList: States[];
    monthList: Months[];

    //  stateList: States[];
    columnDefinitions: Column[] = [];
    gridOptions: GridOption = {};
    companydataset: any[] = [];
    bIsLoaded: boolean = false;
    canShowGrid: boolean = false;
    angularGrid: AngularGridInstance;
    metrics: Metrics;

    statesearch: string = "0";
    companysearch: string = "";
    cbsasearch: string = "";
    totalparticipationminsearch: number = 0;
    totalparticipationmaxsearch: number = 1000000;
    renewalmonth: string = "0";

    totalRowCount: number = -1;
    isCountEnabled = true;
    odataVersion = 2;
    odataQuery = '';
    processing = true;
    status = { text: 'processing...', class: 'alert alert-danger' };

    searchError: boolean = false;
    searchErrorMsg: string = "";


    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
    }

    //statechange(event) {
    //    var tg = event;
    //}

    ngOnInit(): void {

        this.columnDefinitions = [
            { id: 'companyname', name: 'Company Name', field: 'companyname', sortable: true, width: 275, minWidth: 275 },
            { id: 'cbsaname', name: 'CBSA Name', field: 'cbsaname', sortable: true, width: 200, minWidth: 200 },
            { id: 'state', name: 'State', field: 'state', sortable: true, width: 50, minWidth: 40 },
            { id: 'carriername', name: 'Carrier Name', field: 'carriername', sortable: true, width: 325, minWidth: 325},
            { id: 'contractexpirationdate', name: 'Expiration Date', field: 'contractexpirationdate', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'coverages', name: 'Coverages', field: 'coverages', sortable: true, width: 225, minWidth: 225 },
            { id: 'totalparticipation_boy_cnt', name: 'Participants', field: 'totalparticipation_boy_cnt', sortable: true, width: 90, minWidth: 90 },
            { id: 'personscovered_eoy_cnt', name: 'Persons Covered', field: 'personscovered_eoy_cnt', sortable: true, width: 75, minWidth: 75 },
            { id: 'brokername', name: 'Broker Name', field: 'brokername', sortable: true, width: 200, minWidth: 200 },
            { id: 'brokercommission_amt', name: 'Commission', field: 'brokercommission_amt', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'phonenumber', name: 'Phone No', field: 'phonenumber', width: 125, minWidth: 125, formatter: Formatters.mask, params: { mask: '(000) 000-0000' } },
            { id: 'postalcode', name: 'Zip Code', field: 'postalcode', sortable: true, width: 70, minWidth: 70 },
            { id: 'naics_code', name: 'NAICS Code', field: 'naics_code', width: 75, minWidth: 75 },
            { id: 'naics_description', name: 'NAICS Desc', field: 'naics_description', width: 200, minWidth: 200 },
            { id: 'brokerstate', name: 'Broker State', field: 'brokerstate', sortable: true, width: 80, minWidth: 60 },
            { id: 'filingreceiveddate', name: 'Filing Date', field: 'filingreceiveddate', width: 120, minWidth: 100, formatter: Formatters.dateIso }  
        ];
        this.gridOptions = {
            enableAutoResize: true,
            enablePagination: true,
            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableFiltering: true,
            // enableCheckboxSelector: true,
            enableRowSelection: true,
//            forceFitColumns: true,
            pagination: {
                pageSizes: [1000],
                pageSize: defaultPageSize,
                totalItems: 50
            }
 //           enableAutoSizeColumns: true,
 //           syncColumnCellResize: true,
 //           autoFitColumnsOnFirstLoad: false

          
        };


        this.gridOptions.showTopPanel = false;
        this.listservice.getLists("states,months").subscribe(data => {
            this.getlistsResponse(data);
        });
    }

    statechange(event) {
        this.statesearch = event;
        this.cbsasearch = "";
    }

    monthchange(event) {
        this.renewalmonth = event;
    }

    getlistsResponse(data) {
        //this.stateList = data;
        this.stateList = data["states"];
        this.monthList = data["months"];
        // this.brokerstateList = data["states"];
    }

   

    searchunq() {
        this.canShowGrid = true;
        this.getCustomerApiCall(null);
       // this.spinner.show();
        //this.angularGrid.pluginService.refreshBackendDataset();
    }


    reset() {

        this.statesearch = "0";
        this.companysearch = "";
        this.cbsasearch = "";
        this.renewalmonth = "0";
        this.totalparticipationminsearch = 0;
        this.totalparticipationmaxsearch = 1000000;
        this.totalRowCount = -1;

    }

    


    getCustomerCallback(data) {

        if (data.totalcount > 0) {
            this.gridOptions.pagination.totalItems = data.totalcount;
            this.totalRowCount = data.totalcount;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.companydataset = data.companylist;
        }
        else {
            this.totalRowCount = data.totalcount;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.companydataset = [];
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }
    }

    getCustomerApiCall(query) {
        let cbsasearchvalue: string;
        let companysearchvalue: string;
        let renewalmonthsearchvalue: string;



        this.searchError = false;
        this.searchErrorMsg = "";
        // Check for data entry errors before submitting search
        if (this.statesearch == "0" && this.cbsasearch == "") {
            this.searchErrorMsg = "Please select a state or enter at least 3 characters in CBSA Name field.";
            this.searchError = true;
            return;
        }
        if (this.cbsasearch.length >= 1 && this.cbsasearch.length < 3) {
            this.searchErrorMsg = "Please enter at least 3 characters in CBSA Name field.";
            this.searchError = true;
            return;
        }
        if (this.cbsasearch.length >= 3) {
            this.statesearch = "0";
        }


        companysearchvalue = this.companysearch == "" ? "AZ_TY" : this.companysearch;
        cbsasearchvalue = this.cbsasearch == "" ? "AZ_TY" : this.cbsasearch;

        renewalmonthsearchvalue = this.renewalmonth == "0" ? "AZ_TY" : this.renewalmonth;

        return this.companyunqservice.getCompanyAndContractWithHighCommission(1, 1000, this.statesearch, companysearchvalue, cbsasearchvalue, renewalmonthsearchvalue,
             this.totalparticipationminsearch, this.totalparticipationmaxsearch)
              .subscribe(
                  (data) => { this.getCustomerCallback(data); });;;
    }

   

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);
    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }



    /** Dispatched event of a Grid State Changed event */
    gridStateChanged(gridStateChanges: GridStateChange) {
        console.log('Client sample, Grid State changed:: ', gridStateChanges);
    }

    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.gridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.gridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}





