﻿//import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';

import { BaseComponent } from '../core/base.component';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';
import { VersionService } from './version.service';

const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';



@Component({
    templateUrl: './version.component.html',
    providers: [
        VersionService,
    ]
})
export class VersionComponent extends BaseComponent implements OnInit {

    constructor(private route: ActivatedRoute,
        protected _router: Router,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private listservice: ListService,
        public versionservice: VersionService,
        private cd: ChangeDetectorRef) {
        super();
    }

    name = 'Angular';

    searchError: boolean = false;
    searchErrorMsg: string = "";

    webappiversion: string = "";
    clientversion: string = "EBTOOLKIT UI 2023.01.31.1344 TC-TEST DBT 154 paycard"    // LAST PUBLISHED
   // clientversion: string = "EBTOOLKIT 2023.01.27.1344 TC-TEST DBT 154 paycard"    // LAST PUBLISHED
   //  clientversion: string = "EBTOOLKIT 2023.01.11.1546 LT-PROD DBP 154 paycard"    
  //  clientversion: string = "EBTOOLKIT 2022.12.19.1645 LT-PROD DBP 154 paycard"
  //  clientversion: string = "EBTOOLKIT 2022.12.05.1412 LT-PROD DBP 154 EC update"
   // clientversion: string = "EBTOOLKIT 2022.12.03.0903 LT-PROD DBP 154 ec location"
   // clientversion: string = "EBTOOLKIT 2022.08.22.1027 LT-PROD DBP 151 Ord-Sum-Rep"
   // clientversion: string = "EBTOOLKIT 2022.08.05.0909 LT-PROD DBP 151 AZST-EBUP"
   // clientversion: string = "EBTOOLKIT 2022.08.01.1238 LT-PROD DBP 151 AZST"
    clientnote: string = "";


    ngOnInit(): void {

        this.getVersionInfo();


    }


    getVersionInfoCallBack(data) {
        if (data.success) {
            this.webappiversion = data.data;
        }
        else {
            this.searchError = true;
            this.searchErrorMsg = "Version could not be shown";

        }
    }

    getVersionInfo() {


        this.searchError = false;
        this.searchErrorMsg = "";
        // Check for data entry errors before submitting search

        this.versionservice.getVersionInfo("TEST")
            .subscribe(
                (data) => { this.getVersionInfoCallBack(data); });
    }


}






