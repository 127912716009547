﻿
//Auto Generated file sometime


  
export class ICQuoteDTO {
		// This file is generated from the entities located in the MAKTech.Entities project
 
        public id: string;
        public accountid: string;
        public ownerid: string;
        public accountname: string;
        public name: string;
        public city: string;
        public state: string;
        public postalcode: string;
        public ratingarea: string;
        public currentpremium: number;
        public medicarepremium: number;
        public effectivedate: Date;
        public totalmembers: number;
        public plan1id: string;
        public plan2id: string;
        public plan3id: string;
        public plan4id: string;
        public quotestatus: string;
        public unitsbyage: string;
        public createdon: Date;
        public createdby: string;
        public modifiedon: Date;
        public modifiedby: string;
        public plan1name: string;
        public plan2name: string;
        public plan3name: string;
        public plan4name: string;
        public issuer1name: string;
        public issuer2name: string;
        public issuer3name: string;
        public issuer4name: string;
        public currentcarrier: string;
        public plan1monthlycost: number;
        public plan2monthlycost: number;
        public plan3monthlycost: number;
        public plan4monthlycost: number;
        public brokername: string;
        public birthdatelist: string;
        public noselectedplans: number;
        public plan1monthlyvariance: number;
        public plan2monthlyvariance: number;
        public plan3monthlyvariance: number;
        public plan4monthlyvariance: number;
        public plan1annualvariance: number;
        public plan2annualvariance: number;
        public plan3annualvariance: number;
        public plan4annualvariance: number;
        public plan1metallevel: string;
        public plan2metallevel: string;
        public plan3metallevel: string;
        public plan4metallevel: string;
        public plan1percentdifferencetext: string;
        public plan2percentdifferencetext: string;
        public plan3percentdifferencetext: string;
        public plan4percentdifferencetext: string;
        public censustype: string;
        public currentplanname: string;
        public clientmessage: string;
        public plan1inddeductible: string;
        public plan2inddeductible: string;
        public plan3inddeductible: string;
        public plan4inddeductible: string;
        public plan1familydeductible: string;
        public plan2familydeductible: string;
        public plan3familydeductible: string;
        public plan4familydeductible: string;
        public plan1indoopmax: string;
        public plan2indoopmax: string;
        public plan3indoopmax: string;
        public plan4indoopmax: string;
        public plan1familyoopmax: string;
        public plan2familyoopmax: string;
        public plan3familyoopmax: string;
        public plan4familyoopmax: string;
        public plan1ishsaeligible: string;
        public plan2ishsaeligible: string;
        public plan3ishsaeligible: string;
        public plan4ishsaeligible: string;
        public plan1coinsurance: number;
        public plan2coinsurance: number;
        public plan3coinsurance: number;
        public plan4coinsurance: number;
        public numberofemployees: number;
        public countyname: string;
    }
