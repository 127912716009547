﻿export class EmployerContributionDTO {
    public id: string;
    public accountid: string;
    public accountname: string;
    public externalemployerid: string;
    public location: string;
    public healthinsuranceurl: string;
    public secondaryhealthinsuranceurl: string;
    public medicarerul: string;
    public primaryinsurancedescription: string;
    public secondaryinsurancedescription: string;
    public contributiontype: string;
    public effectivedate: Date;
    public createdon: Date;
    public createdby: string;
    public modifiedon: Date;
    public modifiedby: string;
    public strategytype: string;
    public navigationtype: string;
    public matchemployee: number;
    public contributionamount: number;
    public flatemployee: number;
    public flatemployeeandspouse: number;
    public flatemployeeandchildren: number;
    public flatfamily: number;
}
