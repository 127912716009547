﻿
//Auto Generated file sometime


  
export class TeamDTO {
		// This file is generated from the entities located in the MAKTech.Entities project
 
        public id: string;
        public name: string;
        public createdon: Date;
        public createdby: string;
        public modifiedon: Date;
        public modifiedby: string;
    }
