﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../core/environment.service';
import { ExtendedHttpService } from '../core/extendedhttp.service';
import { HttpHeaders } from '@angular/common/http';
import { Http } from '@angular/http';
import { catchError, map } from 'rxjs/operators';
import { OrderViewDTO } from '../dto/OrderViewDTO';
import { EmployerSetupContributionContainerDTO } from '../dto/EmployerSetupContributionContainerDTO';


const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
@Injectable({
    providedIn: 'root',

})
export class HomeService extends ExtendedHttpService {

    constructor(private env: EnvironmentService) {
        super();
    }

    // EMPLOYER CONTRIBUTION SERVICE CALLS
    getRole(usermail: string) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Role/' + usermail).
            pipe(
                map((data) => data.json())
            );
    }


}

