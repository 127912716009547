﻿import { Component, OnInit } from '@angular/core';

import { B2CAuthService } from '../auth/b2cauth.service';




@Component({
    selector: 'app-callback',
    template: `
    <p>
      callback Loading...
    </p>
  `,
    styles: []
})
export class CallbackComponent implements OnInit {
    title = 'angular-pro-sidebar';
    constructor(public auth: B2CAuthService) {
    }

    ngOnInit() {
        if (!this.auth.ishandleLoginCallback) {
           
            //this.auth.handleLoginCallback();
        }
        
    }

}



