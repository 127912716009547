﻿import { Component, OnInit } from '@angular/core';
import { ACARateCalcsService } from './acaratecalc.service';


import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';

const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';



@Component({
    templateUrl: './acaratecalc.component.html',
    providers: [
        ACARateCalcsService,
        ListService
    ]
})
export class ACARateCalcComponent extends BaseComponent implements OnInit {

    constructor(public acaratecalcsservice: ACARateCalcsService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService) {
        super();
    }
    stateList: States[];
    brokerstateList: States[];
    monthList: Months[];

    columnDefinitions: Column[] = [];
    gridOptions: GridOption = {};
    mydataset: any[] = [];
    bIsLoaded: boolean = false;
    canShowGrid: boolean = false;
    angularGrid: AngularGridInstance;
    //columnDefinitions: Column[];
    // gridOptions: GridOption;
    //dataset = [];
    metrics: Metrics;
    companyName: string;
    isCountEnabled = true;
    odataVersion = 2;
    odataQuery = '';
    processing = true;
    totalRowCount: number = 0;
    status = { text: 'processing...', class: 'alert alert-danger' };

    plan1_rate: number = 0;
    plan2_rate: number = 0;
    currentplan_rate: number = 0;
    currentemployer_contribution: number = 0;
    currentemployee_contribution: number = 0;
    plan1_changeinexposure: number = 0;
    plan2_changeinexposure: number = 0;
    plan1_changeindeductible: number = 0;
    plan2_changeindeductible: number = 0;

    plan1_employeeavgsavings: number = 0;
    plan2_employeeavgsavings: number = 0;

    userSelectsString = '';
    name = 'Angular';
    userSelects = [];
    dropdownList = [];
    selectedItems = [];
    dropdownSettings = {};
    searchError: boolean = false;
    searchErrorMsg: string = "";

    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
    }



    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }

    statechange(event) {

    }


    monthchange(event) {
    }


    ngOnInit(): void {



        this.columnDefinitions = [
            { id: 'age', name: 'Age', field: 'age', sortable: true, width: 50, minWidth: 40 },
            { id: 'plan1_cost', name: 'Plan 1 Cost', field: 'plan1_cost', sortable: false, width: 125, minWidth: 100, formatter: Formatters.dollar},
            { id: 'plan1_variance', name: 'Variance from Current', field: 'plan1_variance', sortable: false, width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'plan1_employeecontribution', name: 'Employee Contribution Plan 1', field: 'plan1_employeecontribution', sortable: false, width: 150, minWidth: 150, formatter: Formatters.dollar },
            { id: 'plan1_annualemployeeimpact', name: 'Employee Annual Impact Plan 1', field: 'plan1_annualemployeeimpact', sortable: false, width: 150, minWidth: 150, formatter: Formatters.dollar, cssClass: 'impactcol' },
            { id: 'plan2_cost', name: 'Plan 2 Cost', field: 'plan2_cost', sortable: false, width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'plan2_variance', name: 'Variance from Current', field: 'plan2_variance', sortable: false, width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'plan2_employeecontribution', name: 'Employee Contribution Plan 2', field: 'plan2_employeecontribution', sortable: false, width: 150, minWidth: 150, formatter: Formatters.dollar },
            { id: 'plan2_annualemployeeimpact', name: 'Employee Annual Impact Plan 2', field: 'plan2_annualemployeeimpact', sortable: false, width: 150, minWidth: 150, formatter: Formatters.dollar, cssClass: 'impactcol' }
        ];
        this.gridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            
            //enableCellNavigation: true,
            // enableFiltering: true,
            //// enableCheckboxSelector: true,

            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },

            //backendServiceApi: {
            //    service: new GridOdataService(),
            //    options: {
            //        executeProcessCommandOnInit: false,
            //    },                    
            //    process: (query) => this.getCustomerApiCall(query),
            //    postProcess: (response) => {
            //        this.metrics = response.metrics;                       
            //        this.getCustomerCallback(response);
            //    }
            //}
        };
        this.gridOptions.showTopPanel = false;
        
        //this.angularGrid.resizerService.pauseResizer(true);
    }


    search() {
        this.canShowGrid = true;
        this.getCustomerApiCall(null);
        // this.angularGrid.pluginService.refreshBackendDataset();
    }

    reset() {

        this.plan1_rate = 0;
        this.plan2_rate = 0;
        this.currentplan_rate = 0;
        this.currentemployer_contribution = 0;
        this.currentemployee_contribution = 0;
        this.plan1_changeinexposure = 0;
        this.plan2_changeinexposure = 0;
        this.plan1_changeindeductible = 0;
        this.plan2_changeindeductible = 0;
        this.plan1_employeeavgsavings = 0;
        this.plan2_employeeavgsavings = 0;

        this.selectedItems = [
        ];
    }



    getCustomerCallback(data) {
        if (data.totalcount > 0) {

            this.gridOptions.pagination.totalItems = data.totalcount;
            this.plan1_employeeavgsavings = data.plan1_averageemployeesavings;
            this.plan2_employeeavgsavings = data.plan2_averageemployeesavings;
            this.totalRowCount = data.totalcount;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = data.acaRates;
        }
        else {
            this.totalRowCount = data.totalcount;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = [];
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }
    }

    getCustomerApiCall(query) {

        this.acaratecalcsservice.getacaratecalcs(this.plan1_rate, this.plan2_rate, this.currentplan_rate, this.currentemployer_contribution, this.currentemployee_contribution)
            .subscribe(
                (data) => { this.getCustomerCallback(data); });
    }

    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);


    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }



    /** Dispatched event of a Grid State Changed event */
    gridStateChanged(gridStateChanges: GridStateChange) {
        console.log('Client sample, Grid State changed:: ', gridStateChanges);

    }

    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.gridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.gridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}






