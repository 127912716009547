﻿//import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { TotalCostService } from '../rates/totalcost.service';
import { ICQuoteDTO } from '../dto/ICQuoteDTO';
import { ACAUniquePlanDTO } from '../dto/ACAUniquePlanDTO';
import fed from './fedfactorsdata.json';
//import * as moment from 'moment-mini';


import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';
import { ResponseBaseDTO } from '../dto/ResponseBaseDTO';
import { ACAPlanLookupService } from '../rates/acaplanlookup.service';
import { PlanCostSummaryDTO } from '../dto/PlanCostSummaryDTO';
import { HasUnsavedData } from '../interfaces/hasunsaveddata';
import { GlobaldataService } from '../service/globaldata.service';
import { EmployerAccountService } from './employeraccount.service';

const defaultPageSize = 2000;
const sampleDataRoot = 'assets/data';



@Component({
    templateUrl: 'quotedetail.html',
    styleUrls: ['quotedetail.component.css'],
    providers: [
        ACAPlanLookupService,
        TotalCostService,
        ListService
    ]
})
export class QuoteDetailComponent extends BaseComponent implements OnInit {

    // Grid Variables for Plan Grid
    columnDefinitions: Column[] = [];
    gridOptions: GridOption = {};
    mydataset: any[] = [];
    angularGrid: AngularGridInstance;
    isCountEnabled = true;
    odataVersion = 2;
    gridObj: any[];

    planviewstate: string = "Show Plans";
    canShowGrid: boolean = false;


    // Grid Variables for Age Rate Grid
    ageratecolumnDefinitions: Column[] = [];
    agerategridOptions: GridOption = {};
    ageratemydataset: any[] = [];
    agerateangularGrid: AngularGridInstance;
    agerategridObj: any[];

    ageratesviewstate: string = "Show Age Rates";
    canShowagerateGrid: boolean = false;

    // Grid Variables for Plan Cost by Census Grid
    plancostbycensusColumnDefinitions: Column[] = [];
    plancostbycensusgridOptions: GridOption = {};
    plancostbycensusmydataset: any[] = [];
    plancostbycensusangularGrid: AngularGridInstance;
    plancostbycensusgridObj: any[];

    plancostbycensusviewstate: string = "Show All Plan Costs";
    canShowplancostbycensusGrid: boolean = false;



    metrics: Metrics;
    //companyName: string;



    totalRowCount: number = 0;
    status = { text: 'processing...', class: 'alert alert-danger' };

    name = 'Angular';
    userSelects = [];

    searchError: boolean = false;
    searchErrorMsg: string = "";
    displayMessage: string = "";


    // Quote Detail
    city: string = "";
    state: string = "";
    ratingareaid: string = "";
    zipcode: string = "";
    countyname: string = "";
    didpostalcodechange: boolean = false;


    // PLAN DATA

    acaplans: ACAUniquePlanDTO;
    plans: any[] = [];
    selectedplanindices: number[];

    selectedplanids: string;;
    numberofSelectedPlans: number = 0;
    numberofplans: number = 0;

    plan1percentdifference: number = 0;
    plan2percentdifference: number = 0;
    plan3percentdifference: number = 0;
    plan4percentdifference: number = 0;

    plan1name: string = "";
    plan2name: string = "";
    plan3name: string = "";
    plan4name: string = "";


    censustype: string = "0";
    censustypelist: CensusTypes[] = [
        { id: 'employeeonly', name: 'Employee Only' },
        { id: 'allmembers', name: 'All Members' }
    ];

    // Fed Rate Factors
    fedfactors: FedFactors[] = fed;


    // QUOTE PAGE DATA

    unitstextarea: string = "";
    displayunittextarea: boolean = false;
    effectivedate: Date;
    effectivedatestr: string = "";
    addingquote: number;
    iscalculatedisabled: boolean = true;


    useremail: string;
    userobjectid: string;

    employername: string = "";

    planloaded: boolean = false;

    icquotedto: ICQuoteDTO;
    plancostsummarylist: PlanCostSummaryDTO[] = [];

    accountid: string = "";
    planagelist: any[] = [];
    modifiedon: Date;
    lastquotedate: string = "";
    canSaveQuote: boolean = false;

    isdatachange: boolean = false;

    //minIndDeductible: number = 0;
    //maxIndDeductible: number = 9000;

    plancensuslist: any[] = [];


    membernumber: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    constructor(
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService,
        public acaplanlookupservice: ACAPlanLookupService,
        public totalcostservice: TotalCostService,
        private cd: ChangeDetectorRef,
        protected _router: Router,
        private route: ActivatedRoute,
        private location: Location,
        private globaldataservice: GlobaldataService,
        private employeeaccountservice: EmployerAccountService,

    ) {

        super();

        this.route.params.subscribe(params => {
            this.employername = params.employername;
            this.accountid = params.accountid;
            this.city = params.city;
            this.state = params.state;
            this.zipcode = params.zipcode;
            this.effectivedate = params.effectivedate;
            this.countyname = params.countyname;
            this.addingquote = params.addingquote;
            this.icquotedto = JSON.parse(params.icquotedto);

        });

        this.userobjectid = auth.userobjectid;
        this.useremail = auth.useremail;
    }

    
    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};
    }


    agerateangularGridReady(angularGrid: AngularGridInstance) {
        this.agerateangularGrid = angularGrid;
        this.agerategridObj = angularGrid && angularGrid.slickGrid || {};
    }



    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }

    hasUnsavedData() {

        if (this.isdatachange)
            return true;
        return false;

    }

    ngOnInit(): void {
        this.globaldataservice.countystatecitylist = [];
        this.globaldataservice.selectcounty = [];

        if (this.icquotedto == undefined) { // this is not a saved quote

             this.effectivedate = new Date();
            
            this.effectivedatestr = (this.effectivedate.getMonth() + 2).toString() + "/01/" + this.effectivedate.getFullYear().toString();
            this.effectivedate = new Date(this.effectivedatestr);

            this.icquotedto = new ICQuoteDTO();
            this.icquotedto.accountid = this.accountid;
            this.icquotedto.accountname = this.employername;
            this.icquotedto.name = "Quote for " + this.employername;
            this.icquotedto.quotestatus = "Active";

            this.icquotedto.city = this.city;
            this.icquotedto.state = this.state;
            this.icquotedto.postalcode = this.zipcode;
            this.icquotedto.countyname = this.countyname;
            this.icquotedto.currentpremium = 0;
            this.icquotedto.medicarepremium = 0;
            this.icquotedto.totalmembers = 0;
            this.icquotedto.numberofemployees = 0;

        }
        else {
            this.effectivedate = new Date(this.icquotedto.effectivedate);
            this.effectivedatestr = this.effectivedate.getMonth() + 1 + "/" + this.effectivedate.getDate() + "/" + this.effectivedate.getFullYear();
            this.membernumber = this.icquotedto.unitsbyage.split(",").map(function (item) { return parseInt(item, 10) });
            this.ratingareaid = this.icquotedto.ratingarea;
            this.unitstextarea = this.icquotedto.birthdatelist;
            this.censustype = this.icquotedto.censustype;
            this.modifiedon = new Date(this.icquotedto.modifiedon);
            this.lastquotedate = (this.modifiedon.getMonth() + 1) + "/" + this.modifiedon.getDate() + "/" + this.modifiedon.getFullYear();
            this.setSelectedPlanIds();
            this.canSaveQuote = true;
            if (this.icquotedto.numberofemployees == undefined)
                this.icquotedto.numberofemployees = 0;
        }



        this.initPlanGrid();
        this.showplans();


        //this.angularGrid.dataView.beginUpdate();
        //this.angularGrid.dataView.setFilterArgs({
        //    minIndDeductible: this.minIndDeductible,
        //    maxIndDeductible: this.maxIndDeductible

        //});
        //this.angularGrid.dataView.setFilter(this.myFilter);
        //this.angularGrid.dataView.endUpdate();
        

    }

    setSelectedPlanIds() {

        // One plan must be selected before calling this function
        this.selectedplanids = this.icquotedto.plan1id;
        this.iscalculatedisabled = false;
        this.numberofSelectedPlans = 1;
        if (this.icquotedto.plan2id !== undefined && this.icquotedto.plan2id !== "") {
            this.selectedplanids = this.selectedplanids + "," + this.icquotedto.plan2id;
            this.numberofSelectedPlans++;
        }
        if (this.icquotedto.plan3id !== undefined && this.icquotedto.plan3id !== "") {
            this.selectedplanids = this.selectedplanids + "," + this.icquotedto.plan3id;
            this.numberofSelectedPlans++;
        }
        if (this.icquotedto.plan4id !== undefined && this.icquotedto.plan4id !== "") {
            this.selectedplanids = this.selectedplanids + "," + this.icquotedto.plan4id;
            this.numberofSelectedPlans++;
        }


    }

    initPlanGrid() {

        this.columnDefinitions = [
            { id: 'planmarketingname', name: 'Plan Name', field: 'planmarketingname', sortable: true, width: 275, minWidth: 275 },
            { id: 'issuername', name: 'Carrier Name', field: 'issuername', sortable: true, width: 275, minWidth: 275 },
            { id: 'metallevel', name: 'Metal Level', field: 'metallevel', sortable: true, width: 100, minWidth: 100 },
            { id: 'individualrate', name: 'Age 21 Rate', field: 'individualrate', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'ishsaeligible', name: 'HSA Eligible', field: 'ishsaeligible', sortable: true, width: 100, minWidth: 100 },
            { id: 'annualdeductibleindividual', name: 'Individual Deductible', field: 'annualdeductibleindividual', sortable: true, width: 125, minWidth: 125 },
            { id: 'annualdeductiblefamily', name: 'Family Deductible', field: 'annualdeductiblefamily', sortable: true, width: 125, minWidth: 125 },
            { id: 'annualoopmaxindividual', name: 'Individual OOP Max', field: 'annualoopmaxindividual', sortable: true, width: 125, minWidth: 125 },
            { id: 'annualoopmaxfamily', name: 'Family OOP Max', field: 'annualoopmaxfamily', sortable: true, width: 125, minWidth: 125 },
            { id: 'onoffexchange', name: 'On/Off Exchange', field: 'onoffexchange', sortable: true, width: 100, minWidth: 100 },
            { id: 'plantype', name: 'Plan Type', field: 'plantype', sortable: true, width: 125, minWidth: 125 },
            { id: 'statecode', name: 'State', field: 'statecode', width: 125, minWidth: 125 },
            { id: 'id', name: 'Plan Id', field: 'id', sortable: false, width: 60, minWidth: 60 }
        ];
        this.gridOptions = {
            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            autoHeight: true,   // expand the height of the grid to show all the rows


            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: false,
            checkboxSelector: {
                // you can toggle these 2 properties to show the "select all" checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: false
            },
            enableCheckboxSelector: true,

            // Paging Support
            pagination: {
                pageSizes: [100, 2000],
                pageSize: defaultPageSize,
                totalItems: 100
            },
        };
        this.gridOptions.showTopPanel = false;


    }

    initAgeRateGrid() {

        this.ageratecolumnDefinitions = [
            { id: 'id', name: 'Age', field: 'id', sortable: true, width: 80, minWidth: 80, cssClass: 'age' },
            {
                id: 'numberbyage', name: '# Members', field: 'numberbyage', sortable: true, width: 100, minWidth: 100, type: FieldType.number,
                    editor: { model: Editors.integer, placeholder: 1 }
            },
            { id: 'plan1rate', name: this.icquotedto.plan1name, field: 'plan1rate', sortable: false, width: 150, minWidth: 150, formatter: Formatters.dollar },
            { id: 'plan1totalforage', name: 'Total Cost for Members', field: 'plan1totalforage', sortable: false, width: 100, minWidth: 100, formatter: Formatters.dollar },
            { id: 'plan2rate', name: this.icquotedto.plan2name, field: 'plan2rate', sortable: false, width: 150, minWidth: 150, formatter: Formatters.dollar},
            { id: 'plan2totalforage', name: 'Total Cost for Members', field: 'plan2totalforage', sortable: false, width: 100, minWidth: 100, formatter: Formatters.dollar },
            { id: 'plan3rate', name: this.icquotedto.plan3name, field: 'plan3rate', sortable: false, width: 150, minWidth: 150, formatter: Formatters.dollar},
            { id: 'plan3totalforage', name: 'Total Cost for Members', field: 'plan3totalforage', sortable: false, width: 100, minWidth: 100, formatter: Formatters.dollar },
            { id: 'plan4rate', name: this.icquotedto.plan4name, field: 'plan4rate', sortable: false, width: 150, minWidth: 150, formatter: Formatters.dollar},
            { id: 'plan4totalforage', name: 'Total Cost for Members', field: 'plan4totalforage', sortable: false, width: 100, minWidth: 100, formatter: Formatters.dollar }
        ];
        this.agerategridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            enableHeaderMenu: true,

            autoResize: {
                containerId: 'demo-container1',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            
            enableRowSelection: false,
            editable: false,
            autoEdit: false,
            autoHeight: true,   // expand the height of the grid to show all the rows
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: false,

            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },

        };
        this.agerategridOptions.showTopPanel = false;

    }

    // annualdeductibleindividual

    initPlanCostByCensusGrid() {

        this.plancostbycensusColumnDefinitions = [
            { id: 'planmarketingname', name: 'Plan Name', field: 'planmarketingname', sortable: true, width: 275, minWidth: 275 },
            { id: 'issuername', name: 'Carrier Name', field: 'issuername', sortable: true, width: 275, minWidth: 275 },
            { id: 'metallevel', name: 'Metal Level', field: 'metallevel', sortable: true, width: 100, minWidth: 100 },
            { id: 'monthlytotalpremium', name: 'Monthly Total Premium', field: 'monthlytotalpremium', sortable: true, width: 125, minWidth: 100, formatter: this.moneyFormatter },
            { id: 'annualvariancefromcurrent', name: 'Annual Variance from Current', field: 'annualvariancefromcurrent', sortable: true, width: 175, minWidth: 175, formatter: this.moneyAccountingFormatter },
            { id: 'percentageofdifference', name: '% Difference', field: 'percentageofdifference', sortable: true, width: 125, minWidth: 100, formatter: Formatters.percentSymbol },
            { id: 'annualvarianceperemployee', name: 'Annual Variance per Employee', field: 'annualvarianceperemployee', sortable: true, width: 175, minWidth: 175, formatter: this.moneyAccountingFormatter },
            //{
            //    id: 'individualdeductible', filterable: true, filter: {
            //        model: Filters.input,
            //        operator: OperatorType.rangeInclusive
            //    }, name: 'Individual Deductible', field: 'individualdeductible', type: FieldType.number, sortable: true, width: 125, minWidth: 125
            //},
            {
                id: 'individualdeductible', filterable: true, filter: {
                    model: Filters.sliderRange,
                    maxValue: 9000,
                    operator: OperatorType.rangeInclusive,
                    params: { hideSliderNumbers: false },
                    filterOptions: { min: 0, step: 250}
                }, name: 'Individual Deductible', field: 'individualdeductible', type: FieldType.number, sortable: true, width: 250, minWidth: 250
            },
            { id: 'annualdeductiblefamily', name: 'Family Deductible', field: 'annualdeductiblefamily', sortable: true, width: 125, minWidth: 125 },
            { id: 'ishsaeligible', name: 'HSA Eligible', field: 'ishsaeligible', sortable: true, width: 100, minWidth: 100 },
            { id: 'annualoopmaxindividual', name: 'Individual OOP Max', field: 'annualoopmaxindividual', sortable: true, width: 125, minWidth: 125 },
            { id: 'annualoopmaxfamily', name: 'Family OOP Max', field: 'annualoopmaxfamily', sortable: true, width: 125, minWidth: 125 },
            { id: 'id', name: 'Plan Id', field: 'id', sortable: false, width: 60, minWidth: 60 }

        ];
        this.plancostbycensusgridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            enableHeaderMenu: true,

            autoResize: {
                containerId: 'demo-container2',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,

            enableRowSelection: false,
            editable: false,
            autoEdit: false,
            autoHeight: true,   // expand the height of the grid to show all the rows
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: true,

            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },

        };
        this.plancostbycensusgridOptions.showTopPanel = false;

    }


    showplans() {

        if (this.canShowGrid) {
            this.canShowGrid = false;
            this.planviewstate = "Show Plans";
            return;
        }
        this.canShowGrid = true;
        this.ageratesviewstate = "Show Age Rates";
        this.canShowagerateGrid = false;

        this.plancostbycensusviewstate = "Show All Plan Costs";
        this.canShowplancostbycensusGrid = false;

        this.planviewstate = "Hide Plans";
        if (this.planloaded) {
            return;
        }
        this.loadplans();

    }

    showagerates() {
        if (this.canShowagerateGrid) {
            this.canShowagerateGrid = false;
            this.ageratesviewstate = "Show Age Rates";
        }
        else {
            this.initAgeRateGrid();
            this.loadagerates();
        }
    }


    

    loadplansCallback(data) {
        this.displayMessage = "";
        this.searchError = false;
        this.searchErrorMsg = "";
        if (data.totalcount > 0) {

            // this.gridOptions.pagination.totalItems = data.totalcount;
            // this.totalRowCount = data.totalcount;
            this.numberofplans = data.totalcount;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = data.acaUniquePlans;
            this.plans = data.acaUniquePlans;
            this.numberofplans = this.plans.length;
            this.ratingareaid = data.ratingareaid;
            if (this.didpostalcodechange) {
                this.icquotedto.city = data.city;
                this.icquotedto.state = data.state;
                this.didpostalcodechange = false;
            }
            this.planloaded = true;
            this.displayMessage = "SELECT PLANS AND CLICK CALCULATE TO SEE RATES."
        }
        else {
            // this.totalRowCount = data.totalcount;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = [];
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;
            this.planloaded = false;
            if (this.didpostalcodechange) {
                this.icquotedto.city = data.city;
                this.icquotedto.state = data.state;
                this.didpostalcodechange = false;
            }

        }
    }


    loadplans() {
        // Loads the plans into the grid so that user can select plans

        this.searchError = false;

        this.effectivedate = new Date(this.effectivedatestr);
        if (isNaN(this.effectivedate.getDate())) {
            this.searchErrorMsg = "Effective date must be entered and be in a valid format (MM/DD/YYYY).";
            this.searchError = true;
            return;
        }

        //let planyear: string = this.effectivedatestr.substr(this.effectivedatestr.length - 4, 4);
        let planyear: string = this.effectivedate.getUTCFullYear().toString();
        console.log("Plan Year is: " + planyear + " and effectivedate is: " + this.effectivedate.toLocaleDateString() + " and effectivedatestr is: " + this.effectivedatestr);

        this.acaplanlookupservice.getPlansForServiceAreaWithUserId(this.icquotedto.postalcode, this.auth.userobjectid, planyear, this.icquotedto.countyname)
            .subscribe(
                (data) => { this.loadplansCallback(data); });
    }

    calculateplans() {

        let idx: number = 0;
        let rate: number = 0;

        this.searchError = false;
        this.searchErrorMsg = "";
        this.displayMessage = "";

        if (this.unitstextarea == undefined || this.unitstextarea == "") {
            this.searchError = true;
            this.searchErrorMsg = "You must enter member dates of birth";
            return;
        }
        //this.effectivedate = new Date(this.effectivedatestr);
        //if (isNaN(this.effectivedate.getDate())) {
        //    this.searchErrorMsg = "Effective date must be entered and be in a valid format (MM/DD/YYYY).";
        //    this.searchError = true;
        //    return;
        //}
        if (this.numberofSelectedPlans === 0) {
            this.searchErrorMsg = "You must select 1 to 4 plans.";
            this.searchError = true;
            return;
        }
        if (this.ratingareaid == undefined || this.ratingareaid == "") {
            this.searchError = true;
            this.searchErrorMsg = "There must be a rating area before Calculating.  Check you zipcode.";
            return;
        }

        // this.reset();

        this.pasteunits();

        // Get all the plan ids selected to prepare for getting the rates
        this.plan1name = "";
        this.plan2name = "";
        this.plan3name = "";
        this.plan4name = "";

        if (this.selectedplanindices !== undefined && this.selectedplanindices.length > 0) {

            this.resetPlanAttributes();
            while (idx < this.numberofSelectedPlans) {
                if (idx === 0) {
                    this.icquotedto.plan1name = this.plans[this.selectedplanindices[idx]].planmarketingname;
                    this.plan1name = this.icquotedto.plan1name;
                    this.icquotedto.plan1id = this.plans[this.selectedplanindices[idx]].id;
                    this.icquotedto.issuer1name = this.plans[this.selectedplanindices[idx]].issuername;
                    this.selectedplanids = this.icquotedto.plan1id;
                    this.icquotedto.plan1metallevel = this.plans[this.selectedplanindices[idx]].metallevel;
                    this.icquotedto.plan1inddeductible = this.plans[this.selectedplanindices[idx]].annualdeductibleindividual;
                    this.icquotedto.plan1familydeductible = this.plans[this.selectedplanindices[idx]].annualdeductiblefamily;
                    this.icquotedto.plan1indoopmax = this.plans[this.selectedplanindices[idx]].annualoopmaxindividual;
                    this.icquotedto.plan1familyoopmax = this.plans[this.selectedplanindices[idx]].annualoopmaxfamily;
                    this.icquotedto.plan1ishsaeligible = this.plans[this.selectedplanindices[idx]].ishsaeligible;
                    rate = this.plans[this.selectedplanindices[idx]].individualrate;
                    // this.icquotedto.plan1coinsurance = this.plans[this.selectedplanindices[idx]].coinsurance;
                    
                }
                else if (idx === 1) {
                    this.icquotedto.plan2name = this.plans[this.selectedplanindices[idx]].planmarketingname;
                    this.plan2name = this.icquotedto.plan2name;
                    this.icquotedto.plan2id = this.plans[this.selectedplanindices[idx]].id;
                    this.icquotedto.issuer2name = this.plans[this.selectedplanindices[idx]].issuername;
                    this.selectedplanids = this.selectedplanids + "," + this.icquotedto.plan2id;
                    this.icquotedto.plan2metallevel = this.plans[this.selectedplanindices[idx]].metallevel;
                    this.icquotedto.plan2inddeductible = this.plans[this.selectedplanindices[idx]].annualdeductibleindividual;
                    this.icquotedto.plan2familydeductible = this.plans[this.selectedplanindices[idx]].annualdeductiblefamily;
                    this.icquotedto.plan2indoopmax = this.plans[this.selectedplanindices[idx]].annualoopmaxindividual;
                    this.icquotedto.plan2familyoopmax = this.plans[this.selectedplanindices[idx]].annualoopmaxfamily;
                    this.icquotedto.plan2ishsaeligible = this.plans[this.selectedplanindices[idx]].ishsaeligible;
                    rate = this.plans[this.selectedplanindices[idx]].individualrate;
                   // this.icquotedto.plan2coinsurance = this.plans[this.selectedplanindices[idx]].coinsurance;
                }
                else if (idx === 2) {
                    this.icquotedto.plan3name = this.plans[this.selectedplanindices[idx]].planmarketingname;
                    this.plan3name = this.icquotedto.plan3name;
                    this.icquotedto.plan3id = this.plans[this.selectedplanindices[idx]].id;
                    this.icquotedto.issuer3name = this.plans[this.selectedplanindices[idx]].issuername;
                    this.selectedplanids = this.selectedplanids + "," + this.icquotedto.plan3id;
                    this.icquotedto.plan3metallevel = this.plans[this.selectedplanindices[idx]].metallevel;
                    this.icquotedto.plan3inddeductible = this.plans[this.selectedplanindices[idx]].annualdeductibleindividual;
                    this.icquotedto.plan3familydeductible = this.plans[this.selectedplanindices[idx]].annualdeductiblefamily;
                    this.icquotedto.plan3indoopmax = this.plans[this.selectedplanindices[idx]].annualoopmaxindividual;
                    this.icquotedto.plan3familyoopmax = this.plans[this.selectedplanindices[idx]].annualoopmaxfamily;
                    this.icquotedto.plan3ishsaeligible = this.plans[this.selectedplanindices[idx]].ishsaeligible;
                    rate = this.plans[this.selectedplanindices[idx]].individualrate;
                    // this.icquotedto.plan3coinsurance = this.plans[this.selectedplanindices[idx]].coinsurance;
                }
                else if (idx === 3) {
                    this.icquotedto.plan4name = this.plans[this.selectedplanindices[idx]].planmarketingname;
                    this.plan4name = this.icquotedto.plan4name;
                    this.icquotedto.plan4id = this.plans[this.selectedplanindices[idx]].id;
                    this.icquotedto.issuer4name = this.plans[this.selectedplanindices[idx]].issuername;
                    this.selectedplanids = this.selectedplanids + "," + this.icquotedto.plan4id;
                    this.icquotedto.plan4metallevel = this.plans[this.selectedplanindices[idx]].metallevel;
                    this.icquotedto.plan4inddeductible = this.plans[this.selectedplanindices[idx]].annualdeductibleindividual;
                    this.icquotedto.plan4familydeductible = this.plans[this.selectedplanindices[idx]].annualdeductiblefamily;
                    this.icquotedto.plan4indoopmax = this.plans[this.selectedplanindices[idx]].annualoopmaxindividual;
                    this.icquotedto.plan4familyoopmax = this.plans[this.selectedplanindices[idx]].annualoopmaxfamily;
                    this.icquotedto.plan4ishsaeligible = this.plans[this.selectedplanindices[idx]].ishsaeligible;
                    rate = this.plans[this.selectedplanindices[idx]].individualrate;
                    // this.icquotedto.plan4coinsurance = this.plans[this.selectedplanindices[idx]].coinsurance;
                }
                idx++;

            }
        }

        this.searchError = false;
        if (this.icquotedto.state === "NY") {  // if NY then rates are not age based so we have all the information we need and don't have to make a round trip
            this.calculateNYPlanCostSummry();
            return;
        }


        let planyear: string = this.effectivedate.getUTCFullYear().toString();

        this.totalcostservice.getPlanCostSummary(this.selectedplanids, this.membernumber.toString(), this.ratingareaid,
            this.icquotedto.currentpremium, this.icquotedto.medicarepremium, planyear, this.icquotedto.countyname, this.icquotedto.state)
            .subscribe(
                (data) => { this.calculateplansCallback(data); });

    }

    calculateplansCallback(data) {

        let idx: number = 0;

        if (data.totalcount > 0) {
            this.plancostsummarylist = data.plancostsummarydto;
            //this.plancostsummarylist.sort((a, b) => (a.monthlycost > b.monthlycost) ? 1 : ((b.monthlycost > a.monthlycost) ? -1 : 0));
            while (idx < data.totalcount) {
                if (idx === 0) {
                    this.icquotedto.plan1monthlycost = this.plancostsummarylist[idx].monthlycost;
                    this.icquotedto.plan1monthlyvariance = this.plancostsummarylist[idx].monthlyvariance;
                    this.icquotedto.plan1annualvariance = this.plancostsummarylist[idx].annualvariance;
                    this.icquotedto.plan1percentdifferencetext = this.calcPlanCostPercent(this.icquotedto.plan1monthlyvariance, this.icquotedto.plan1monthlycost);
                }
                else if (idx === 1) {
                    this.icquotedto.plan2monthlycost = this.plancostsummarylist[idx].monthlycost;
                    this.icquotedto.plan2monthlyvariance = this.plancostsummarylist[idx].monthlyvariance;
                    this.icquotedto.plan2annualvariance = this.plancostsummarylist[idx].annualvariance;
                    this.icquotedto.plan2percentdifferencetext = this.calcPlanCostPercent(this.icquotedto.plan2monthlyvariance, this.icquotedto.plan2monthlycost);
                } else if (idx === 2) {
                    this.icquotedto.plan3monthlycost = this.plancostsummarylist[idx].monthlycost;
                    this.icquotedto.plan3monthlyvariance = this.plancostsummarylist[idx].monthlyvariance;
                    this.icquotedto.plan3annualvariance = this.plancostsummarylist[idx].annualvariance;
                    this.icquotedto.plan3percentdifferencetext = this.calcPlanCostPercent(this.icquotedto.plan3monthlyvariance, this.icquotedto.plan3monthlycost);
                } else if (idx === 3) {
                    this.icquotedto.plan4monthlycost = this.plancostsummarylist[idx].monthlycost;
                    this.icquotedto.plan4monthlyvariance = this.plancostsummarylist[idx].monthlyvariance;
                    this.icquotedto.plan4annualvariance = this.plancostsummarylist[idx].annualvariance;
                    this.icquotedto.plan4percentdifferencetext = this.calcPlanCostPercent(this.icquotedto.plan4monthlyvariance, this.icquotedto.plan4monthlycost);
                }
                idx++;
            }

            this.canSaveQuote = true;
            this.isdatachange = true;
        }
        else {
            // this.totalRowCount = data.totalcount;
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }

    }


    calculateNYPlanCostSummry() {
        let idx: number = 0;
        let prevselectedplanids: string[];

        if (this.selectedplanindices == undefined) {
            this.numberofSelectedPlans = 0;
            if (this.selectedplanids !== undefined && this.selectedplanids.length > 0) {
                prevselectedplanids = this.selectedplanids.split(",");
                this.selectedplanindices = [];
                for (var i = 0; i < this.plans.length; i++) {
                    for (var j = 0; j < prevselectedplanids.length; j++) {
                        if (this.plans[i].id === prevselectedplanids[j]) {
                            this.selectedplanindices.push(i);
                            this.numberofSelectedPlans++;
                            break;

                        }
                    }

                }
            }
            else {
                this.searchError = true;
                this.searchErrorMsg = "Please select plans before Calculating";
                return;
            }

        }
        while (idx < this.numberofSelectedPlans) {
            if (idx === 0) {
                this.icquotedto.plan1monthlycost = 0;
                for (var i = 0; i < 51; i++) {
                    this.icquotedto.plan1monthlycost = this.icquotedto.plan1monthlycost + (this.plans[this.selectedplanindices[idx]].individualrate * this.membernumber[i]);
                }
                this.icquotedto.plan1monthlycost = this.icquotedto.plan1monthlycost + (this.icquotedto.medicarepremium * this.membernumber[51]);
                this.icquotedto.plan1monthlyvariance = this.icquotedto.plan1monthlycost - this.icquotedto.currentpremium;
                this.icquotedto.plan1annualvariance = (this.icquotedto.plan1monthlycost * 12) - (this.icquotedto.currentpremium * 12);
                this.icquotedto.plan1percentdifferencetext = this.calcPlanCostPercent(this.icquotedto.plan1monthlyvariance, this.icquotedto.plan1monthlycost);
            }
            else if (idx === 1) {
                this.icquotedto.plan2monthlycost = 0;
                for (var i = 0; i < 51; i++) {
                    this.icquotedto.plan2monthlycost = this.icquotedto.plan2monthlycost + (this.plans[this.selectedplanindices[idx]].individualrate * this.membernumber[i]);
                }
                this.icquotedto.plan2monthlycost = this.icquotedto.plan2monthlycost + (this.icquotedto.medicarepremium * this.membernumber[51]);
                this.icquotedto.plan2monthlyvariance = this.icquotedto.plan2monthlycost - this.icquotedto.currentpremium;
                this.icquotedto.plan2annualvariance = (this.icquotedto.plan2monthlycost * 12) - (this.icquotedto.currentpremium * 12);
                this.icquotedto.plan2percentdifferencetext = this.calcPlanCostPercent(this.icquotedto.plan2monthlyvariance, this.icquotedto.plan2monthlycost);
            } else if (idx === 2) {
                this.icquotedto.plan3monthlycost = 0;
                for (var i = 0; i < 51; i++) {
                    this.icquotedto.plan3monthlycost = this.icquotedto.plan3monthlycost + (this.plans[this.selectedplanindices[idx]].individualrate * this.membernumber[i]);
                }
                this.icquotedto.plan3monthlycost = this.icquotedto.plan3monthlycost + (this.icquotedto.medicarepremium * this.membernumber[51]);
                this.icquotedto.plan3monthlyvariance = this.icquotedto.plan3monthlycost - this.icquotedto.currentpremium;
                this.icquotedto.plan3annualvariance = (this.icquotedto.plan3monthlycost * 12) - (this.icquotedto.currentpremium * 12);
                this.icquotedto.plan3percentdifferencetext = this.calcPlanCostPercent(this.icquotedto.plan3monthlyvariance, this.icquotedto.plan3monthlycost);
            } else if (idx === 3) {
                this.icquotedto.plan4monthlycost = 0;
                for (var i = 0; i < 51; i++) {
                    this.icquotedto.plan4monthlycost = this.icquotedto.plan4monthlycost + (this.plans[this.selectedplanindices[idx]].individualrate * this.membernumber[i]);
                }
                this.icquotedto.plan4monthlycost = this.icquotedto.plan4monthlycost + (this.icquotedto.medicarepremium * this.membernumber[51]);
                this.icquotedto.plan4monthlyvariance = this.icquotedto.plan4monthlycost - this.icquotedto.currentpremium;
                this.icquotedto.plan4annualvariance = (this.icquotedto.plan4monthlycost * 12) - (this.icquotedto.currentpremium * 12);
                this.icquotedto.plan4percentdifferencetext = this.calcPlanCostPercent(this.icquotedto.plan4monthlyvariance, this.icquotedto.plan4monthlycost);
            }
            idx++;
        }
        this.canSaveQuote = true;
        this.isdatachange = true;

    }


    calcPlanCostPercent(planmonthlyvariance: number, planmonthlycost: number) {

        let planpercentdifference: number;

        if (planmonthlyvariance < 0) {  // Negative variance means there is a savings using this plan
            planpercentdifference = ((this.icquotedto.currentpremium - planmonthlycost) / this.icquotedto.currentpremium) * 100;
            return ("Savings is " + planpercentdifference.toFixed(2) + "%");
        }
        else {
            planpercentdifference = ((planmonthlycost - this.icquotedto.currentpremium) / planmonthlycost) * 100;
            return ("Plan is higher by " + planpercentdifference.toFixed(2) + "%");
        }

    }


    loadagerates() {

        if (this.selectedplanids.length === 0)
            return;

        this.effectivedate = new Date(this.effectivedatestr);
        if (isNaN(this.effectivedate.getDate())) {
            this.searchErrorMsg = "Effective date must be entered and be in a valid format (MM/DD/YYYY).";
            this.searchError = true;
            return;
        }

        let planyear: string = this.effectivedate.getUTCFullYear().toString();
        this.totalcostservice.getRatesForComparisonByYear(this.selectedplanids.toString(), this.ratingareaid, this.icquotedto.currentpremium, 0, 0, planyear, this.icquotedto.countyname, this.icquotedto.state)
            .subscribe(
                (data) => { this.loadageratesCallBack(data); });

    }

    loadageratesCallBack(data) {

        if (data.totalcount > 0) {
            // Medicare costs don't come from the plan, so add them to the PlanComparison DTO
            this.searchError = false;
            this.agerategridOptions = Object.assign({}, this.agerategridOptions);
            data.planComparison.push({
                id: '65 and up',
                numberbyage: 0,
                plan1rate: this.icquotedto.medicarepremium,
                plan1rateforage: 0,
                plan2rate: this.icquotedto.medicarepremium,
                plan2rateforage: 0,
                plan3rate: this.icquotedto.medicarepremium,
                plan3rateforage: 0,
                plan4rate: this.icquotedto.medicarepremium,
                plan4rateforage: 0

            });
            this.planagelist = data.planComparison;


            if (this.icquotedto.unitsbyage !== undefined && this.icquotedto.unitsbyage !== "") {

                let idx: number = 0;

                let ageunits: string = this.icquotedto.unitsbyage.toString();
                let ageunitarray: string[] = ageunits.split(',');
                while (idx < 52) {
                    this.planagelist[idx].numberbyage = ageunitarray[idx];
                    this.membernumber[idx] = parseInt(ageunitarray[idx]);

                    if (idx == 51) {
                        this.planagelist[idx].plan1totalforage = this.icquotedto.medicarepremium * this.membernumber[idx];
                        this.planagelist[idx].plan2totalforage = this.icquotedto.medicarepremium * this.membernumber[idx];
                        this.planagelist[idx].plan3totalforage = this.icquotedto.medicarepremium * this.membernumber[idx];
                        this.planagelist[idx].plan4totalforage = this.icquotedto.medicarepremium * this.membernumber[idx];
                    }
                    else {
                        this.planagelist[idx].plan1totalforage = this.planagelist[idx].plan1rate * this.membernumber[idx];
                        this.planagelist[idx].plan2totalforage = this.planagelist[idx].plan2rate * this.membernumber[idx];
                        this.planagelist[idx].plan3totalforage = this.planagelist[idx].plan3rate * this.membernumber[idx];
                        this.planagelist[idx].plan4totalforage = this.planagelist[idx].plan4rate * this.membernumber[idx];

                    }
                    idx++;
                }

            }

            this.ageratesviewstate = "Hide Age Rates";
            this.canShowagerateGrid = true;
            this.canShowGrid = false;
            this.planviewstate = "Show Plans";
            this.plancostbycensusviewstate = "Show All Plan Costs";
            this.canShowplancostbycensusGrid = false;

            this.ageratemydataset = this.planagelist;
        }
        else {
            this.totalRowCount = data.totalcount;
            this.agerategridOptions = Object.assign({}, this.agerategridOptions);
            this.ageratemydataset = [];
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }


    }

    showplanscostbycensus() {
        if (this.canShowplancostbycensusGrid) {
            this.plancostbycensusviewstate = "Show All Plan Costs";
            this.canShowplancostbycensusGrid = false;
        }
        else {
            this.initPlanCostByCensusGrid();
            if (this.plancensuslist.length > 0) {
                this.displayPlanCostByCensusGrid();
                return;

            }
            this.loadallplancostsbycensus();
        }
    }



    loadallplancostsbycensusCallback(data) {

        if (data.totalcount > 0) {
            let idx: number = 0;
            let tempnumber: number = 0;

            // this.gridOptions.pagination.totalItems = data.totalcount;
            // this.totalRowCount = data.totalcount;
            this.plancostbycensusgridOptions = Object.assign({}, this.plancostbycensusgridOptions);

            this.plancensuslist = data.plans;
            while (idx < data.totalcount) {
                if (!isNaN(this.plancensuslist[idx].annualdeductibleindividual)) {
                    this.plancensuslist[idx].individualdeductible = this.plancensuslist[idx].annualdeductibleindividual;
                }
                else {
                   // tempnumber = parseInt(this.plancensuslist[idx].annualdeductibleindividual.replace(/,/g, ''));
                    tempnumber = parseInt(this.plancensuslist[idx].annualdeductibleindividual.replace(/\$|,/g, ''));

                    if (!isNaN(tempnumber)) {
                        this.plancensuslist[idx].individualdeductible = tempnumber;
                    }
                    else {
                        this.plancensuslist[idx].individualdeductible = 0;
                    }
                }
                idx++;
            }

            this.displayPlanCostByCensusGrid();
        }
        else {
            // this.totalRowCount = data.totalcount;
            this.plancostbycensusgridOptions = Object.assign({}, this.plancostbycensusgridOptions);
            this.plancostbycensusmydataset = [];
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;
        }
    }

    displayPlanCostByCensusGrid() {

        this.plancostbycensusmydataset = this.plancensuslist;

        this.ageratesviewstate = "Show Age Rates";
        this.canShowagerateGrid = false;
        this.canShowGrid = false;
        this.planviewstate = "Show Plans";
        this.plancostbycensusviewstate = "Hide All Plan Costs";
        this.canShowplancostbycensusGrid = true;

    }

    loadallplancostsbycensus() {
        // Loads the plans into the grid so that user can select plans

        this.searchError = false;

        this.effectivedate = new Date(this.effectivedatestr);
        if (isNaN(this.effectivedate.getDate())) {
            this.searchErrorMsg = "Effective date must be entered and be in a valid format (MM/DD/YYYY).";
            this.searchError = true;
            return;
        }

        //let planyear: string = this.effectivedatestr.substr(this.effectivedatestr.length - 4, 4);
        let planyear: string = this.effectivedate.getUTCFullYear().toString();
        console.log("Plan Year is: " + planyear + " and effectivedate is: " + this.effectivedate.toLocaleDateString() + " and effectivedatestr is: " + this.effectivedatestr);

        this.acaplanlookupservice.getAllPlanCostsbyCensus(this.icquotedto.postalcode, this.auth.userobjectid, planyear, this.icquotedto.currentpremium, this.icquotedto.medicarepremium, this.icquotedto.numberofemployees, this.icquotedto.countyname, this.icquotedto.unitsbyage)
            .subscribe(
                (data) => { this.loadallplancostsbycensusCallback(data); });
    }



    savequote() {


        this.searchError = false;
        this.searchErrorMsg = "";
        this.displayMessage = "";
        if (this.icquotedto.name === "") {
            this.searchError = true;
            this.searchErrorMsg = "You must enter a description of the quote before saving.";
            return;
        }
        if (this.censustype === "0") {
            this.searchError = true;
            this.searchErrorMsg = "You must select a census type before saving.";
            return;
        }


        let memno: any = '';
        let row: any;
        let idx: number = 0;

        if (this.icquotedto.id == undefined || this.icquotedto.id === "") {
            //this.icquotedto.accountid = null;
            //this.icquotedto.id = null;
            this.icquotedto.createdon = new Date();
            this.icquotedto.createdby = this.auth.useremail;
        }

        this.icquotedto.ownerid = this.userobjectid; // TODO: When we implement security groups, this might not be the right thing to do
        //        this.icquotedto.brokername = this.brokername;
        this.icquotedto.ratingarea = this.ratingareaid;
        this.icquotedto.effectivedate = this.effectivedate;
        this.icquotedto.censustype = this.censustype;

        this.icquotedto.unitsbyage = this.membernumber.toString();
        this.icquotedto.modifiedby = this.auth.useremail;
        this.icquotedto.modifiedon = new Date();
        this.icquotedto.noselectedplans = this.numberofSelectedPlans;


        this.totalcostservice.postIndivdualCoverageByUnitsQuote(this.icquotedto)
            .subscribe(
                (data) => { this.savequoteCallBack(data); }
            );



    }

    savequoteCallBack(data) {


        if (data.success) {
            this.accountid = data.data.accountid;
            this.icquotedto.id = data.data.id;
            this.displayMessage = "Saved Successfully";
            this.modifiedon = new Date(this.icquotedto.modifiedon);
            this.lastquotedate = (this.modifiedon.getMonth() + 1) + "/" + this.modifiedon.getDate() + "/" + this.modifiedon.getFullYear();
            this.isdatachange = false;
        }
        else {
            this.searchErrorMsg = data.displaymessage;
            this.searchError = true;

        }
    }


    //changePostalCode() {

    //    this.resetPlanAttributes();
    //    this.planloaded = false;
    //    this.canShowGrid = false;  // make sure the that showplans() is setup to work.
    //    this.numberofSelectedPlans = 0;
    //    this.selectedplanids = "";
    //    this.iscalculatedisabled = true;
    //    this.didpostalcodechange = true;
    //    this.isdatachange = true;

    //    this.showplans();

    //}



    changePostalCode() {

        this.resetPlanAttributes();
        this.planloaded = false;
        this.canShowGrid = false;  // make sure the that showplans() is setup to work.
        this.numberofSelectedPlans = 0;
        this.selectedplanids = "";
        this.iscalculatedisabled = true;
        this.didpostalcodechange = true;
        this.isdatachange = true;
        this.plancensuslist = [];
        console.log("Zip code changed to " + this.icquotedto.postalcode);
        this.employeeaccountservice.getUSZipCounty(this.icquotedto.postalcode)
            .subscribe(
                (data) => { this.changePostalCodeCallBack(data); }
            );

    }

    changePostalCodeCallBack(data) {

        if (data.totalcount > 0) {

            this.globaldataservice.countystatecitylist = data.locationlist;

            // State should be the same for all counties - watch for those 13 zip codes that map to multiple states
            this.icquotedto.state = this.globaldataservice.countystatecitylist[0].state;
            this.icquotedto.city = this.globaldataservice.countystatecitylist[0].city;
            this.icquotedto.countyname = this.globaldataservice.countystatecitylist[0].countyname;
            this.globaldataservice.countycode = this.globaldataservice.countystatecitylist[0].countycode.toString();
            this.globaldataservice.selectcounty = [];

            if (this.globaldataservice.countystatecitylist.length > 1) {  // There is more than one county
                this.globaldataservice.countycode = "";
                for (var i = 0; i < this.globaldataservice.countystatecitylist.length; i++) {
                    let scounty: SelectList = {
                        id: this.globaldataservice.countystatecitylist[i].countyname,
                        name: this.globaldataservice.countystatecitylist[i].countyname
                    }
                    this.globaldataservice.selectcounty.push(scounty);
                }
            }
            this.planloaded = false;
            this.showplans();
        }
        else {
            this.searchErrorMsg = data.responseMsg + " Postcode entered is: " + this.icquotedto.postalcode;
            this.icquotedto.city = "";
            this.icquotedto.state = "";
            this.icquotedto.postalcode = "";
            this.icquotedto.countyname = "";
            this.globaldataservice.countystatecitylist = [];
            this.searchError = true;

        }

    }


    countyselected(event) {


        if (event !== "") {
            this.icquotedto.countyname = event;
            //if (this.globaldataservice.countycode !== this.formDataService.oldcountycode && this.formDataService.oldcountycode !== '')
            //    this.formDataService.resetAllData();

            for (var i = 0; i < this.globaldataservice.countystatecitylist.length; i++) {
                if (this.icquotedto.countyname == this.globaldataservice.countystatecitylist[i].countyname) {
                    this.icquotedto.city = this.globaldataservice.countystatecitylist[i].city;
                    this.icquotedto.state = this.globaldataservice.countystatecitylist[i].state;
                    break;
                }

            }
            this.resetPlanAttributes();
            this.planloaded = false;
            this.canShowGrid = false;  // make sure the that showplans() is setup to work.
            this.numberofSelectedPlans = 0;
            this.selectedplanids = "";
            this.iscalculatedisabled = true;
            this.didpostalcodechange = true;
            this.isdatachange = true;
            this.plancensuslist = [];
            this.showplans();

        }
        else {
            this.icquotedto.countyname = "";
        }
        this.dataEntered();


    }

    dataEntered() {
        // When data is entered do any clean up that is necessary
        this.searchError = false;
        this.searchErrorMsg = "";
        this.displayMessage = "";
    }

    changeEffectiveDate(value) {

        let tempdate: Date;
        let testdate: Date;
        let testnumber: number;

        this.isdatachange = true;
        tempdate = this.setDate(value);

        console.log("ChangeEffectiveDate is: " + tempdate.toLocaleDateString() + " and value is: " + value);
        if (tempdate.getUTCFullYear() !== this.effectivedate.getUTCFullYear()) {
            this.effectivedatestr = value;
            this.effectivedate = tempdate;
            this.planloaded = false;
            this.showplans();
            return;
        }

        this.effectivedatestr = value;
        this.effectivedate = tempdate;
        this.reset();
        this.displayMessage = "CLICK ON CALCULATE WHEN READY";

    }

    changecalcdependency() {
        // Called when a field changes other than Postal Code or Effective Date that affects rates

        this.reset();
        this.displayMessage = "CLICK ON CALCULATE WHEN READY";
        this.isdatachange = true;
        this.plancensuslist = [];
    }

    changecensustype(value) {

        this.censustype = value;
        this.isdatachange = true;

    }

    changenumberofemployees() {
    // Changed on 25 Mar 21 per Joe
        //if (this.icquotedto.numberofemployees > this.icquotedto.totalmembers) {
        //    this.searchErrorMsg = "Number of Employees cannot exceed number of members in the census";
        //    this.searchError = true;
        //    return;
        //}

        this.searchError = false;
        this.searchErrorMsg = "";

    }

    changeotherfields() {
        this.isdatachange = true;
    }

    backClicked() {

        this.location.back();

    }

    reset() {
        this.searchError = false;
        this.displayMessage = "";
        this.searchErrorMsg = "";
        this.canSaveQuote = false;


        this.ageratesviewstate = "Show Age Rates";
        this.canShowagerateGrid = false;
        this.canShowplancostbycensusGrid = false;
        this.plancostbycensusviewstate = "Show All Plan Costs";

        this.icquotedto.plan1monthlycost = 0;
        this.icquotedto.plan2monthlycost = 0;
        this.icquotedto.plan3monthlycost = 0;
        this.icquotedto.plan4monthlycost = 0;

        this.icquotedto.plan1monthlyvariance = 0;
        this.icquotedto.plan2monthlyvariance = 0;
        this.icquotedto.plan3monthlyvariance = 0;
        this.icquotedto.plan4monthlyvariance = 0;

        this.icquotedto.plan1annualvariance = 0;
        this.icquotedto.plan2annualvariance = 0;
        this.icquotedto.plan3annualvariance = 0;
        this.icquotedto.plan4annualvariance = 0;

        this.icquotedto.plan1percentdifferencetext = "";
        this.icquotedto.plan2percentdifferencetext = "";
        this.icquotedto.plan3percentdifferencetext = "";
        this.icquotedto.plan4percentdifferencetext = "";

    }

    resetPlanAttributes() {

        this.selectedplanids = "";

        this.plan1name = "";
        this.plan2name = "";
        this.plan3name = "";
        this.plan4name = "";

        this.icquotedto.plan1name = "";
        this.icquotedto.plan1id = "";
        this.icquotedto.issuer1name = "";
        this.icquotedto.plan1metallevel = "";

        this.icquotedto.plan2name = "";
        this.icquotedto.plan2id = "";
        this.icquotedto.issuer2name = "";
        this.icquotedto.plan2metallevel = "";

        this.icquotedto.plan3name = "";
        this.icquotedto.plan3id = "";
        this.icquotedto.issuer3name = "";
        this.icquotedto.plan3metallevel = "";

        this.icquotedto.plan4name = "";
        this.icquotedto.plan4id = "";
        this.icquotedto.issuer4name = "";
        this.icquotedto.plan4metallevel = "";

        this.icquotedto.plan1inddeductible = "";
        this.icquotedto.plan2inddeductible = "";
        this.icquotedto.plan3inddeductible = "";
        this.icquotedto.plan4inddeductible = "";


        this.icquotedto.plan1indoopmax = "";
        this.icquotedto.plan2indoopmax = "";
        this.icquotedto.plan3indoopmax = "";
        this.icquotedto.plan4indoopmax = "";

        this.icquotedto.plan1familydeductible = "";
        this.icquotedto.plan2familydeductible = "";
        this.icquotedto.plan3familydeductible = "";
        this.icquotedto.plan4familydeductible = "";


        this.icquotedto.plan1familyoopmax = "";
        this.icquotedto.plan2familyoopmax = "";
        this.icquotedto.plan3familyoopmax = "";
        this.icquotedto.plan4familyoopmax = "";

        this.icquotedto.plan1ishsaeligible = "";
        // this.icquotedto.plan1coinsurance = 0;
        this.icquotedto.plan2ishsaeligible = "";
        // this.icquotedto.plan2coinsurance = 0;
        this.icquotedto.plan3ishsaeligible = "";
        // this.icquotedto.plan3coinsurance = 0;
        this.icquotedto.plan4ishsaeligible = "";
        // this.icquotedto.plan4coinsurance = 0;

        this.reset();

    }

    pasteunits() {

        let textstr: string = "";
        let rows: any;
        let i: number = 0;



        let bdate: Date;
        let memberage: number = 0;
        let month: number = 0;
        let test: any;

        // this.reset();
        while (i < 52) {
            this.membernumber[i] = 0;
            i++;
        }
        i = 0;

        textstr = this.unitstextarea;
        this.icquotedto.birthdatelist = this.unitstextarea;
        rows = textstr.split(/\r\n|\n|\r/);
        this.icquotedto.totalmembers = 0;

        while (i < rows.length) {
            bdate = new Date(rows[i]);
            if (!isNaN(bdate.getDate())) {

                memberage = this.effectivedate.getFullYear() - bdate.getFullYear();
                month = this.effectivedate.getMonth() - bdate.getMonth();
                if (month < 0 || (month === 0 && this.effectivedate.getDate() < bdate.getDate())) {
                    memberage--;
                }
                if (memberage < 14)
                    memberage = 14;
                else if (memberage > 65)
                    memberage = 65;
                this.membernumber[memberage - 14] = this.membernumber[memberage - 14] + 1;
            }
            i++;
        }
        this.icquotedto.totalmembers = this.membernumber.reduce((a, b) => a + b, 0);
        this.icquotedto.unitsbyage = this.membernumber.toString();
    }

    //setDate(aDate: string): Date {
            
    //    let datestr: string;
    //    let resultdate: Date;
    //    var datetemp;

    //    datetemp = aDate.split('-');
    //    datestr = datetemp[1] + '/' + datetemp[2] + '/' + datetemp[0];

    //    resultdate = new Date(datestr);

    //    return resultdate;    
    //}


    togglepastearea() {
        if (!this.displayunittextarea)
            this.displayunittextarea = true;
        else
            this.displayunittextarea = false;

    }

    //myFilter(item, args) {

    //    let testded: number = 0;

    //    testded = parseInt(item["annualdeductibleindividual"]);
    //    if (isNaN(testded))
    //        return false;

    //    if (testded >= this.minIndDeductible && testded <= this.maxIndDeductible) {
    //        return true;
    //    }

    //    return false;
    //}




    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);


    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }



    onSelectedRowsChanged(e, args) {

        if (Array.isArray(args.rows)) {

            this.searchError = false;
            this.numberofSelectedPlans = args.rows.length;
            if (args.rows.length > 4) {
                this.searchError = true;
                this.searchErrorMsg = "You can only select up to 4 plans.";
                this.iscalculatedisabled = true;
                return;
            }
            else if (this.numberofSelectedPlans == 0) {
                this.iscalculatedisabled = true;
                return;
            }

            this.selectedplanindices = args.rows.map(idx => idx);
            this.iscalculatedisabled = false;
            // START HERE LOADING PLANS INTO SUMMARY FIELDS
        }
    }



    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.gridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.gridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}






