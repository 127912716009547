﻿//import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminPlansService } from './adminplans.service';
import { ICQuoteDTO } from '../dto/ICQuoteDTO';
import { Observable, forkJoin } from 'rxjs';


import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';

const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';



@Component({
    templateUrl: './admincontributions.html',
    providers: [
        AdminPlansService,
        ListService
    ]
})
export class AdminContributionsComponent extends BaseComponent implements OnInit {

    constructor(private route: ActivatedRoute,
        protected _router: Router,
        public adminplansservice: AdminPlansService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService,
        private cd: ChangeDetectorRef) {
        super();
    }

    columnDefinitions: Column[] = [];
    gridOptions: GridOption = {};
    mydataset: any[] = [];
    bIsLoaded: boolean = false;
    canShowGrid: boolean = false;
    angularGrid: AngularGridInstance;
    //columnDefinitions: Column[];
    // gridOptions: GridOption;
    //dataset = [];
    metrics: Metrics;

    isCountEnabled = true;
    odataVersion = 2;
    odataQuery = '';
    processing = true;
    totalRowCount: number = 0;
    status = { text: 'processing...', class: 'alert alert-danger' };

    stateList: States[];
    statesearch: string = "0";
    statecode: string = "";

    userSelectsString = '';
    name = 'Angular';
    userSelects = [];

    searchError: boolean = false;
    searchErrorMsg: string = "";

    gridObj: any[];
    selectedGridIDS: number[];
    selectedTitles: any[];
    selectedTitle: any;
    selectedplanids: any[];
    selectedplannames: any[];
    selectedissuernames: any[];
    numberofSelectedPlans: number = 0;
    ratingareanumber: number = 1;
    ratingareaid: string = "";
    state: string = "";
    icquotedto: ICQuoteDTO;
    isplannotselected: boolean = true;
    selectedquoteidx: number = -1;

    plans: any[] = [];


    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};
    }



    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }

    statechange(event) {
        this.statesearch = event;
        this.getPlansForState();
    }



    monthchange(event) {
    }


    ngOnInit(): void {



        this.columnDefinitions = [
            { id: 'issuername', name: 'Carrier Name', field: 'issuername', sortable: true, width: 275, minWidth: 275 },
            { id: 'planmarketingname', name: 'Plan Name', field: 'planmarketingname', sortable: true, width: 275, minWidth: 275 },
            { id: 'metallevel', name: 'Metal Level', field: 'metallevel', sortable: true, width: 100, minWidth: 100 },
            { id: 'ishsaeligible', name: 'HSA Eligible', field: 'ishsaeligible', sortable: true, width: 100, minWidth: 100 },
            { id: 'annualdeductibleindividual', name: 'Individual Deductible', field: 'annualdeductibleindividual', sortable: true, width: 125, minWidth: 125 },
            { id: 'annualdeductiblefamily', name: 'Family Deductible', field: 'annualdeductiblefamily', sortable: true, width: 125, minWidth: 125 },
            { id: 'annualoopmaxindividual', name: 'Individual OOP Max', field: 'annualoopmaxindividual', sortable: true, width: 125, minWidth: 125 },
            { id: 'annualoopmaxfamily', name: 'Family OOP Max', field: 'annualoopmaxfamily', sortable: true, width: 125, minWidth: 125 },
            { id: 'qhpnonqhptypeid', name: 'On/Off Exchange', field: 'qhpnonqhptypeid', sortable: true, width: 100, minWidth: 100 },
            { id: 'planeffectivedate', name: 'Effective Date', field: 'planeffectivedate', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'plantype', name: 'Plan Type', field: 'plantype', sortable: true, width: 125, minWidth: 125 },
            { id: 'id', name: 'Plan Id', field: 'id', sortable: false, width: 60, minWidth: 60 }
            //{ id: 'individualrate', name: 'Age 21 Rate', field: 'individualrate', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
        ];
        this.gridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: false,
            checkboxSelector: {
                // you can toggle these 2 properties to show the "select all" checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true
            },
            enableCheckboxSelector: true,

            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },
        };
        this.gridOptions.showTopPanel = false;

        this.listservice.getLists("states").subscribe(data => {
            this.getlistsResponse(data);
        }
        );

    }


    getlistsResponse(data) {
        //this.stateList = data;
        this.stateList = data["states"];
    }


    addplan() { }

    editplan() {

        let idx: number = 0;
        let numberofplans: number = 0;
        let planids: string[] = [];
        let plannames: string[] = [];
        let issuernames: string[] = [];

        this._router.navigate(['/adminplandetail',
            {
                isaddoredit: 'EDIT',
                standardcomponentid: this.plans[this.selectedquoteidx].id
            }
        ]);

    }

    reset() {

        this.statecode = "";

        //       this.selectedItems = [];
        this.selectedplanids = [];
        this.selectedplannames = [];
        this.selectedissuernames = [];
        // this.angularGrid.gridService.setSelectedRows([]);
        this.totalRowCount = 0;
        this.gridOptions = Object.assign({}, this.gridOptions);
        this.mydataset = [];
        this.searchError = false;
        this.state = "";
        this.ratingareaid = "";
        this.ratingareanumber = 0;
        this.statesearch = "";

    }


    getPlansByStateCallback(data) {
        if (data.totalcount > 0) {

            this.gridOptions.pagination.totalItems = data.totalcount;
            this.totalRowCount = data.totalcount;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = data.acaPlans;
            this.plans = data.acaPlans;
        }
        else {
            this.totalRowCount = data.totalcount;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = [];
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }
    }

    getPlansForState() {


        this.searchError = false;
        this.searchErrorMsg = "";
        // Check for data entry errors before submitting search

        this.adminplansservice.getPlansByState(this.statesearch, 2020)
            .subscribe(
                (data) => { this.getPlansByStateCallback(data); });
    }






    /** Dispatched event of a Grid State Changed event */
    gridStateChanged(gridStateChanges: GridStateChange) {
        console.log('Client sample, Grid State changed:: ', gridStateChanges);
        let test: any;
        let a: number;

        // test = gridStateChanges;
        a = 0;

    }


    onSelectedRowsChanged(e, args) {
        if (Array.isArray(args.rows)) {
            if (args.rows.length > 0) {
                this.isplannotselected = false;
                this.selectedquoteidx = args.rows.map(idx => {
                    return idx;
                });
            }
            else {
                this.isplannotselected = true;
            }
        }
    }







    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);


    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }

    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.gridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.gridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}






