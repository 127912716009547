﻿import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
    GridMenu,
    GridMenuItem,
    SlickEventHandler,
    SlickEvent,
    SlickEventData,
    AngularSlickgridComponent
} from 'angular-slickgrid';
import * as XLSX from 'xlsx';
import { EmployerContributionDTO } from '../dto/EmployerContributionDTO';
import { EmployerSetupService } from './employersetup.services';
import { GlobaldataService } from '../service/globaldata.service';
import { MemberDTO } from '../dto/MemberDTO';
import { MemberAndChildrenDTO } from '../dto/MemberAndChildrenDTO';
import { OrderService } from './order.service';
import { OrderItemViewDTO } from '../dto/OrderItemViewDTO';


const defaultPageSize = 1000;


@Component({
    templateUrl: './member.component.html',
    styleUrls: ['./employersetup.component.css'],

})
export class MemberComponent extends BaseComponent implements OnInit {

    constructor(private route: ActivatedRoute,
        protected _router: Router,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private cd: ChangeDetectorRef,
        private employerSetupService: EmployerSetupService,
        private globaldataservice: GlobaldataService,
        private location: Location,
        public orderService: OrderService
    ) {
        super();


        this.route.params.subscribe(params => {
            this.employername = params.employername;
            this.employerid = params.accountid;
            this.addingmember = params.addingmember;
            this.memberdto = JSON.parse(params.memberdto);

        });
    }

    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};



/*        this.angularGrid.dataView.onRowCountChanged.subscribe((e, args) => this.rowCountChanged(e, args));*/
    }

    angularOrderGridReady(angularGrid: AngularGridInstance) {
        this.angularOrderGridInstance = angularGrid;
    }

    angularDependentGridReady(angularGrid: AngularGridInstance) {
        this.angularDependentGridInstance = angularGrid;
    }

    name = 'Angular';
    angularGrid: AngularGridInstance;
    userSelects = [];


    ordercolumnDefinitions: Column[] = [];
    ordergridOptions: GridOption = {};
    orderdataset: any[] = [];
    orderitemviewDTO: OrderItemViewDTO[] = [];
    angularOrderGridInstance: AngularGridInstance;


    dependentcolumnDefinitions: Column[] = [];
    dependentgridOptions: GridOption = {};
    dependentdataset: any[] = [];
    angularDependentGridInstance: AngularGridInstance;


    gridMenu: GridMenu;
    gridMenuItem: GridMenuItem;
    slickEventHandler: SlickEventHandler;
    slickEvent: SlickEvent;
    slickEventData: SlickEventData;
    _addon: any;

    gridObj: any;
    isCountEnabled = true;
    odataVersion = 2;


    searchError: boolean = false;
    searchErrorMsg: string = "";
    file: any;
    arrayBuffer: any;
    filelist: any[];

    employerid: string;
    memberid: string;
    employername: string;
    addingmember: number;
    memberdto: MemberDTO;
    ismembersaved: boolean = true;
    birthdatestr: string = "";
    terminationdatestr: string = "";
    displayMessage: string = "";

    currenttabid: string = "tab-1-div";


    selectedquoteidx: number = -1;
    isquotenotselected: boolean = true;

    //dependents: MemberDependentDTO[] = [];
    //memberaccount: MemberAccountDTO;



    genderlist: SelectList[] = [
        {
            id: "Female",
            name: "Female"
        },
        {
            id: "Male",
            name: "Male"
        }

    ];

    statuslist: SelectList[] = [
        {
            id: "Active",
            name: "Active"
        },
        {
            id: "Inactive",
            name: "Inactive"
        },
        {
            id: "Terminated",
            name: "Terminated"
        }

    ];


    ngOnInit(): void {
        //this.initContributionGrid();
        this.initMemberPage();



    }

    initMemberPage() {

        if (this.addingmember === 1) { // this is not a saved employer
            this.memberdto = new MemberDTO();
            this.memberdto.accountid = this.employerid;
            this.ismembersaved = false;
        }
        else {
            this.employerid = this.memberdto.accountid;
            this.memberid = this.memberdto.id;
            if (this.memberdto.birthdate !== null) {
                this.memberdto.birthdate = new Date(this.memberdto.birthdate);
                this.birthdatestr = this.memberdto.birthdate.getMonth() + 1 + "/" + this.memberdto.birthdate.getDate() + "/" + this.memberdto.birthdate.getFullYear();
            }
            else {
                this.birthdatestr = "";
            }

            if (this.memberdto.status === "Terminated" && !this.memberdto.terminationdate !== null) {
                this.memberdto.terminationdate = new Date(this.memberdto.terminationdate);
                this.terminationdatestr = this.memberdto.terminationdate.getMonth() + 1 + "/" + this.memberdto.terminationdate.getDate() + "/" + this.memberdto.terminationdate.getFullYear();
            }
            this.globaldataservice.orderdetails = [];
            this.globaldataservice.orderTotalCount = 0;
            this.globaldataservice.dependents = [];
            this.globaldataservice.dependentTotalCount = 0;


            this.ismembersaved = true;
            this.initOrderGrid();
            this.initDependentGrid();

            if (this.memberid != "") {
                this.memberdto = this.globaldataservice.members.find(a => a.id == this.memberid);
                this.getOrders();
            }
            
            /*            this.getDependents();*/
        }
    }

    initOrderGrid() {
        this.ordercolumnDefinitions = [
            { id: 'ordernumber', name: 'Order No', field: 'ordernumber', sortable: false, width: 80, minWidth: 80 },
            { id: 'statusview', filterable: true, name: 'Status', field: 'statusview', sortable: true, width: 125, minWidth: 125 },
            { id: 'amountspent', name: 'Price', field: 'amountspent', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'vendorname', filterable: true, name: 'Vendor Name', field: 'vendorname', sortable: true, width: 200, minWidth: 200 },
            { id: 'productname', filterable: true, name: 'Product Name', field: 'productname', sortable: true, width: 225, minWidth: 225 },
            { id: 'options', filterable: true, name: 'Options', field: 'options', sortable: true, width: 125, minWidth: 125 },
            { id: 'category', filterable: true, name: 'Category', field: 'category', sortable: true, width: 125, minWidth: 125 },
            { id: 'modifiedon', name: 'Last Modified', field: 'modifiedon', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'policyholdersname', name: 'Policy Holders Name', field: 'policyholdersname', sortable: true, width: 125, minWidth: 125 },
            { id: 'policyeffectivedate', name: 'Policy Eff Date', field: 'policyeffectivedate', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'id', name: '#', field: 'id', sortable: false, width: 40, minWidth: 40 }
        ];
        this.ordergridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: true,
            checkboxSelector: {
                // you can toggle these 2 properties to show the "select all" checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true,
            },
            enableCheckboxSelector: true,

            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },
        };

    }

    initDependentGrid() {
        this.dependentcolumnDefinitions = [
            { id: 'firstname', name: 'First Name', field: 'firstname', sortable: true, width: 175, minWidth: 175 },
            { id: 'lastname', name: 'Last Name', field: 'lastname', sortable: true, width: 175, minWidth: 175 },
            { id: 'relationship', name: 'Relationship', field: 'relationship', sortable: true, width: 150, minWidth: 150 },
            { id: 'dateofbirth', name: 'Date of Birth', field: 'dateofbirth', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'age', name: 'Age', field: 'age', sortable: true, width: 125, minWidth: 125 },
            { id: 'isonhealthpolicy', name: 'On Health Policy', field: 'isonhealthpolicy', sortable: true, width: 150, minWidth: 150 },
            { id: 'contributionamt', name: 'Employer Contribution', field: 'contributionamt', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'modifiedon', name: 'Last Modified', field: 'modifiedon', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'id', name: 'Id', field: 'id', sortable: false, width: 60, minWidth: 60 }
        ];
        this.dependentgridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: true,
            checkboxSelector: {
                // you can toggle these 2 properties to show the "select all" checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true,
            },
            enableCheckboxSelector: true,

            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },
        };

    }

    //selectSuggestion(s) {
    //    this.userSelects.find((item) => item.id === s.id) ?
    //        this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
    //        this.userSelects.push(s);
    //    // this.assignToNgModel();
    //}

    savemember() {
        this.searchError = false;
        this.searchErrorMsg = "";

        let memberandchildren: MemberAndChildrenDTO = new MemberAndChildrenDTO();

        memberandchildren.member = this.memberdto;
        memberandchildren.member.modifiedon = new Date();
        memberandchildren.member.modifiedby = this.globaldataservice.useremail;

        if (this.addingmember === 1) {
            memberandchildren.member.createdon = new Date();
            memberandchildren.member.createdby = this.globaldataservice.useremail;

            this.employerSetupService.postMember(memberandchildren)
                .subscribe((data) => { this.saveMemberCallBack(data) });
        }
        else {

            this.employerSetupService.putMember(memberandchildren)
                .subscribe((data) => { this.saveMemberCallBack(data) });
        }



    }

    saveMemberCallBack(data) {

        this.displayMessage = "";
        if (data.success) {
            if (this.addingmember === 1)
                this.displayMessage = "Successfully added the member."
            else
                this.displayMessage = "Successfully updated the member."

            this.memberdto = data.data;
            this.memberid = this.memberdto.id;
            this.addingmember = 0;
            this.ismembersaved = true;
        }
        else {
            this.searchErrorMsg = data.displaymsg;
            this.searchError = true;
        }


    }



    deletemember() {
        this.searchError = false;
        this.searchErrorMsg = "";

        var result = confirm("Do you want to delete member: " + this.memberdto.firstname + " " + this.memberdto.lastname);

        if (result) {
            this.employerSetupService.deleteMember(this.memberdto.id)
                .subscribe((data) => { this.deleteMemberCallBack(data) });
        }


    }

    deleteMemberCallBack(data) {

        this.displayMessage = "";
        if (data.success) {
            this.location.back();
        }
        else {
            this.searchErrorMsg = data.displaymsg;
            this.searchError = true;
        }


    }


    employerchange(event) {

        let employerid: string = event;

        this.employerid = employerid;


    }

    getOrders() {

        this.searchError = false;

        this.orderService.getOrdersByMemberId(this.memberdto.id)
            .subscribe(
                (data) => { this.getOrdersCallBack(data); });

    }


    getOrdersCallBack(data) {


        if (data.totalcount > 0) {
            this.searchError = false;
            this.ordergridOptions = Object.assign({}, this.ordergridOptions);
            this.globaldataservice.orderTotalCount = data.totalcount;

            this.globaldataservice.orderdetails = data.orderdetails;
            this.orderitemviewDTO = data.orderdetails;

            for (var i = 0; i < this.orderitemviewDTO.length; i++) {
                if (this.orderitemviewDTO[i].status == 1)
                    this.orderitemviewDTO[i].statusview = "Pending";
                else if (this.orderitemviewDTO[i].status == 2)
                    this.orderitemviewDTO[i].statusview = "Completed";
                else if (this.orderitemviewDTO[i].status == 3)
                    this.orderitemviewDTO[i].statusview = "Canceled";

            }

            this.orderdataset = this.orderitemviewDTO;

        }
        else {
            this.globaldataservice.orderTotalCount = 0;
            this.ordergridOptions = Object.assign({}, this.ordergridOptions);
            this.orderdataset = [];
            this.globaldataservice.orderdetails = [];
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }

    }


    changeBirthDate(e: any) {

        let wrkbirthdate: Date = new Date(e.target.value);
        if (!isNaN(wrkbirthdate.getDate())) {
            this.memberdto.birthdate = wrkbirthdate;
            this.birthdatestr = (this.memberdto.birthdate.getUTCMonth() + 1).toString() + "/" + this.memberdto.birthdate.getUTCDate().toString() + "/" + this.memberdto.birthdate.getUTCFullYear().toString();
            this.changeMemberField();
        }
        else {
            this.searchError = true;
            this.searchErrorMsg = "Please enter a valid date."
        }

    }


    changeTerminationDate(e: any) {

        let wrktermdate: Date = new Date(e.target.value);
        if (!isNaN(wrktermdate.getDate())) {
            this.memberdto.terminationdate = wrktermdate;
            this.terminationdatestr = (this.memberdto.terminationdate.getUTCMonth() + 1).toString() + "/" + this.memberdto.terminationdate.getUTCDate().toString() + "/" + this.memberdto.terminationdate.getUTCFullYear().toString();
            this.changeMemberField();
        }
        else {
            this.searchError = true;
            this.searchErrorMsg = "Please enter a valid date."
        }

    }


    changeMemberField() {
            this.ismembersaved = false;
        this.displayMessage = "";
        this.searchError = false;
        this.searchErrorMsg = "";

    }

    changeGender(e) {
        this.memberdto.gender = e.value;
        this.changeMemberField();
    }

    changeStatus(e) {
        this.memberdto.status = e.value;
        this.changeMemberField();
    }

    backClicked() {

        this.location.back();

    }



    tabClicked(event, tabId) {

        var element;
        let wrktabid: string = "";
        this.searchError = false;
        this.searchErrorMsg = "";

        if (tabId === this.currenttabid)
            return;

        if (this.currenttabid !== "") {
            wrktabid = this.currenttabid.substring(0, 5);
            element = document.getElementById(wrktabid);
            element.classList.remove('active');
        }
        wrktabid = tabId.substring(0, 5);
        element = document.getElementById(wrktabid);
        element.classList.add('active');

        if (this.currenttabid !== "") {
            element = document.getElementById(this.currenttabid);
            element.classList.remove("tcc_tab_on");
            element.classList.add("tcc_tab_off");
        }

        element = document.getElementById(tabId);
        element.classList.remove("tcc_tab_off");
        element.classList.add("tcc_tab_on");
        this.currenttabid = tabId;
        if (tabId === "tab-2-div")
            this.getDependents();

        window.scroll(0, 0);

    }


    getDependents() {

        this.searchError = false;
        if (this.globaldataservice.dependents.length === 0) {  // did we already lookup members

            this.employerSetupService.getDependentList(this.memberdto.id)
                .subscribe(
                    (data) => { this.getDependentsCallBack(data); });
        }
        else {
            this.dependentdataset = this.globaldataservice.dependents;

        }

    }

    getDependentsCallBack(data: any) {
        this.searchError = false;
        this.searchErrorMsg = "";

        if (data.totalcount > 0) {
            this.globaldataservice.dependentTotalCount = data.totalcount;
            this.dependentdataset = data.dependents;
            this.globaldataservice.dependents = data.dependents;
        }
        else {
            this.globaldataservice.dependentTotalCount = 0;
            this.dependentdataset = [];
            this.globaldataservice.dependents = [];
            if (data.responseStatus !== 0) {
                this.searchError = true;
                this.searchErrorMsg = data.responseMsg;
            }

        }

    }

    openorder() {
        var test;


        let membername: string = this.memberdto.firstname + " " + this.memberdto.lastname;

        var filteredItems = this.angularOrderGridInstance.dataView.getFilteredItems();
        let orderIdx: number = -1;

        for (var i = 0; i < this.globaldataservice.orderdetails.length; i++) {
            if (this.globaldataservice.orderdetails[i].id == filteredItems[this.selectedquoteidx].id) {
                orderIdx = i;
                break;
            }
        }

        if (orderIdx == -1) {
            this.searchError = true;
            this.searchErrorMsg = "Error occurred getting order data. Call your administrator.";
            return;

        }



        this._router.navigate(['/orderdetail',
            {
                employername: this.employername,
                accountid: this.employerid,
                membername: membername,
                addingorder: 0,
                orderdetaildto: JSON.stringify(this.globaldataservice.orderdetails[orderIdx])
            }
        ]);

    }


    opendependent() {
        var test;


        let membername: string = this.memberdto.firstname + " " + this.memberdto.lastname;

        var filteredItems = this.angularDependentGridInstance.dataView.getFilteredItems();
        let dependentIdx: number = -1;

        for (var i = 0; i < this.globaldataservice.dependents.length; i++) {
            if (this.globaldataservice.dependents[i].id == filteredItems[this.selectedquoteidx].id) {
                dependentIdx = i;
                break;
            }
        }

        if (dependentIdx == -1) {
            this.searchError = true;
            this.searchErrorMsg = "Error occurred getting dependent data. Call your administrator.";
            return;

        }



        this._router.navigate(['/dependentdetail',
            {
                employername: this.employername,
                accountid: this.employerid,
                membername: membername,
                addingdependent: 0,
                dependentdetaildto: JSON.stringify(this.globaldataservice.dependents[dependentIdx])
            }
        ]);

    }


    onSelectedRowsChanged(e, args) {
        if (Array.isArray(args.rows)) {
            if (args.rows.length > 0) {
                this.isquotenotselected = false;
                this.selectedquoteidx = args.rows.map(idx => {
                    return idx;
                });
            }
            else {
                this.isquotenotselected = true;
            }
        }
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);


    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }


    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.ordergridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.ordergridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

}