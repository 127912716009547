﻿import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { Location } from '@angular/common';
import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
    GridMenu,
    GridMenuItem,
    SlickEventHandler,
    SlickEvent,
    SlickEventData,
    AngularSlickgridComponent
} from 'angular-slickgrid';
import * as XLSX from 'xlsx';
import { EmployerContributionDTO } from '../dto/EmployerContributionDTO';
import { EmployerSetupService } from './employersetup.services';
import { GlobaldataService } from '../service/globaldata.service';
import { EmployerSetupContributionContainerDTO } from '../dto/EmployerSetupContributionContainerDTO';
import { EmployerContributionDetailDTO } from '../dto/EmployerContributionDetailDTO';

const defaultPageSize = 1000;


@Component({
    templateUrl: './employercontribution.component.html',

})
export class EmployerContributionComponent extends BaseComponent implements OnInit {

    constructor(private route: ActivatedRoute,
        protected _router: Router,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private cd: ChangeDetectorRef,
        private employerSetupService: EmployerSetupService,
        private globaldataservice: GlobaldataService,
        private location: Location
        ) {
        super();


        this.route.params.subscribe(params => {
            this.employername = params.employername;
            this.employerid = params.accountid;
            this.addingcontribution = params.addingcontribution;
            this.contributiondto = JSON.parse(params.contributiondto);

        });
    }

    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};



        this.angularGrid.dataView.onRowCountChanged.subscribe((e, args) => this.rowCountChanged(e, args));


    }

    name = 'Angular';
    columnDefinitions: Column[] = [];
    angularGrid: AngularGridInstance;
    gridOptions: GridOption = {};
    gridMenu: GridMenu;
    gridMenuItem: GridMenuItem;
    slickEventHandler: SlickEventHandler;
    slickEvent: SlickEvent;
    slickEventData: SlickEventData;
    _addon: any;
    mydataset: any[] = [];
    gridObj: any;


    searchError: boolean = false;
    searchErrorMsg: string = "";
    file: any;
    arrayBuffer: any;
    filelist: any[];
    totalRowCount: number = 0;

    employerid: string;
    employername: string;
    addingcontribution: number;
    contributiondto: EmployerContributionDTO;
    iscontributionsaved: boolean = true;
    effectivedatestr: string = "";
    contributiondetails: EmployerContributionDetailDTO[] = [];
    onboardingURL: string = "";

    locationclass: string = "";


    strategylist: SelectList[] = [
        {
            id: "PERSONAGE",
            name: "By Persons Age"
        },
        {
            id: "FLATNOAGE",
            name: "Flat Amount by Dependents"
        },
        {
            id: "FLATPLUSDEP",
            name: "Flat Amount plus 1 Dependent"
        },
        {
            id: "HOMOGENEOUS",
            name: "Same Contribution for All"
        },
        {
            id: "FAMILYSTATUS",
            name: "By Family Status (no longer supported)"
        }


    ];


    ngOnInit(): void {
        this.initContributionGrid();
        this.initContributionPage();



    }

    initContributionPage() {
        if (this.contributiondto == undefined) { // this is not a saved employer
            this.contributiondto = new EmployerContributionDTO();
            this.contributiondto.effectivedate = new Date();
            this.effectivedatestr = (this.contributiondto.effectivedate.getMonth() + 2).toString() + "/01/" + this.contributiondto.effectivedate.getFullYear().toString();
            this.contributiondto.effectivedate = new Date(this.effectivedatestr);
            this.contributiondto.accountname = this.employername;
            this.contributiondto.accountid = this.employerid;
            this.contributiondto.externalemployerid = this.employername;
            this.contributiondto.contributionamount = 0;
            this.iscontributionsaved = false;
        }
        else {
            this.employerid = this.contributiondto.accountid;
            this.employername = this.contributiondto.accountname;
            this.locationclass = this.contributiondto.location;
            if (this.contributiondto.effectivedate !== null) {
                this.contributiondto.effectivedate = new Date(this.contributiondto.effectivedate);
                this.effectivedatestr = this.contributiondto.effectivedate.getMonth() + 1 + "/" + this.contributiondto.effectivedate.getDate() + "/" + this.contributiondto.effectivedate.getFullYear();
            }
            else {
                this.effectivedatestr = "";
            }
            this.iscontributionsaved = true;
            this.getContributionDetails();
        }
        this.onboardingURL = "https://nfor1portal.com/welcome;sym=" + this.employerid;
    }

    initContributionGrid() {
        this.columnDefinitions = [
            { id: 'id', name: 'id', field: 'id', sortable: false, width: 60, minWidth: 60 },
            { id: 'age', name: 'age', field: 'age', sortable: true, width: 125, minWidth: 125 },
            { id: 'employeebyage', name: 'employeebyage', field: 'employeebyage', width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'spousebyage', name: 'spousebyage', field: 'spousebyage', width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'childbyage', name: 'childbyage', field: 'childbyage', width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'waiveamt', name: 'waiveamt', field: 'waiveamt', width: 125, minWidth: 100, formatter: Formatters.dollar }

        ];
        this.gridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: true,
            checkboxSelector: {
                // you can toggle these 2 properties to show the "select all" checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true,
            },
            enableCheckboxSelector: true,

            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },
        };

    }

    getContributionDetails() {

        this.searchError = false;
        if (this.contributiondto.id !== undefined && this.contributiondto.id != "" &&
            this.contributiondto.strategytype !== 'HOMOGENEOUS' && this.contributiondto.strategytype !== 'FLATNOAGE' && this.contributiondto.strategytype !== 'FLATPLUSDEP') {

            this.employerSetupService.getEmployerContributionDetail(this.contributiondto.id)
                .subscribe(
                    (data) => { this.getContributionsDetailsCallBack(data); });
        }
    }

    getContributionsDetailsCallBack(data) {

        this.searchError = false;
        this.searchErrorMsg = "";

        if (data.totalcount > 0) {
            /*            this.quotegridOptions = Object.assign({}, this.quotegridOptions);*/
            this.totalRowCount = data.totalcount;

            this.mydataset = data.employercontributiondetaillist;
            this.contributiondetails = data.employercontributiondetaillist;
            var test = 0;
            /*            this.quotes = data.quotelist;*/
        }
        else {
            this.totalRowCount = 0;
            /*            this.quotegridOptions = Object.assign({}, this.quotegridOptions);*/
            this.mydataset = [];
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }
    }

    savecontribution() {
        this.searchError = false;
        this.searchErrorMsg = "";

        let container: EmployerSetupContributionContainerDTO = new EmployerSetupContributionContainerDTO();


        if (this.contributiondto.id == undefined || this.contributiondto.id === "") {
            this.contributiondto.createdon = new Date();
            this.contributiondto.createdby = this.globaldataservice.useremail;
        }
        this.contributiondto.modifiedon = new Date();
        this.contributiondto.modifiedby = this.globaldataservice.useremail;
        if (this.contributiondto.contributiontype == undefined || this.contributiondto.contributiontype === "")
            this.contributiondto.contributiontype = "FRINGE";
        if (this.contributiondto.contributionamount == undefined)
            this.contributiondto.contributionamount = 0;
        if (this.contributiondto.medicarerul == undefined || this.contributiondto.medicarerul === "")
            this.contributiondto.medicarerul = "https://www.medicare.gov/";

        if (this.contributiondto.flatemployee == undefined)
            this.contributiondto.flatemployee = 0;
        if (this.contributiondto.flatemployeeandspouse == undefined)
            this.contributiondto.flatemployeeandspouse = 0;
        if (this.contributiondto.flatemployeeandchildren == undefined)
            this.contributiondto.flatemployeeandchildren = 0;
        if (this.contributiondto.flatfamily == undefined)
            this.contributiondto.flatfamily = 0;


        this.contributiondto.accountname = this.employername;
        container.contributiondto = this.contributiondto;
        for (var i = 0; i < this.contributiondetails.length; i++) {
            if (this.contributiondetails[i].employercontributionid == undefined || this.contributiondetails[i].employercontributionid === "") {
                this.contributiondetails[i].createdon = new Date();
                this.contributiondetails[i].createdby = this.globaldataservice.useremail;
            }
            if (this.contributiondto.id !== undefined && this.contributiondto.id !== "") {
                this.contributiondetails[i].employercontributionid = this.contributiondto.id;
            }
            this.contributiondetails[i].modifiedon = new Date();
            this.contributiondetails[i].modifiedby = this.globaldataservice.useremail;

        }


        container.contributiondetaildto = this.contributiondetails;

        this.employerSetupService.putEmployerContribution(container)
            .subscribe((data) => { this.savecontributionCallBack(data) });

    }

    savecontributionCallBack(data) {

        if (data.responseStatus == 0) {
            this.searchError = true;
            this.searchErrorMsg = "Successfully updated contribution."
            this.contributiondto = data.contributiondto;
            this.contributiondetails = data.contributiondetaildto;
            this.mydataset = this.contributiondetails;
        }
        else {
            this.searchErrorMsg = data.responseMsg;
            this.searchError = true;
        }


    }



    addfile(event) {
        var test;
        this.file = event.target.files[0];
        let fileReader = new FileReader();
        var arraylist;
        fileReader.readAsArrayBuffer(this.file);
        fileReader.onload = (e) => {
            this.arrayBuffer = fileReader.result;
            var data = new Uint8Array(this.arrayBuffer);
            var arr = new Array();
            for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
            var bstr = arr.join("");
            var workbook = XLSX.read(bstr, { type: "binary" });
            var first_sheet_name = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[first_sheet_name];
            console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
            arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });
            this.mydataset = arraylist;
            this.contributiondetails = arraylist;
            //this.filelist = [];
            //console.log(this.filelist)

        }
        this.iscontributionsaved = false;
    }



    employerchange(event) {

        let employerid: string = event;

        this.employerid = employerid;


    }

    getorders() {


    }


    rowCountChanged(event, args) {

        console.log("***** rowCountChanged ZZZZZZ");
        // this.totalRowCount = this.angularGrid.dataView.getLength();
    }

    changeEffectiveDate() {

        let effdate: Date = new Date(this.effectivedatestr);
            if (!isNaN(effdate.getDate())) {
            this.contributiondto.effectivedate = effdate;
            this.iscontributionsaved = false;
        }

    }


    changeContributionEffectiveDate(e: any) {
        var a;
        let adate: Date;

        console.log("***** change effective contribution date: " + e.target.value);
        a = e.target.value;
        this.contributiondto.effectivedate = new Date(a);
        this.effectivedatestr = (this.contributiondto.effectivedate.getUTCMonth() + 1).toString() + "/" + this.contributiondto.effectivedate.getUTCDate().toString() + "/" + this.contributiondto.effectivedate.getUTCFullYear().toString();
        this.iscontributionsaved = false;

    }

    changeContributionField() {

        this.contributiondto.location = this.locationclass;
        this.iscontributionsaved = false;
    }

    changeStrategy(e) {

        let strategy: string = e.value;

        this.contributiondto.strategytype = strategy;


    }

    backClicked() {

        this.location.back();

    }

    copyportalurl() {

        let mynavigator: any;

        mynavigator = window.navigator;

        mynavigator.clipboard.writeText(this.onboardingURL);

    }



}