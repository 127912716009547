﻿//import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderCompleteDTO } from '../dto/OrderCompleteDTO';

import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
    GridMenu,
    GridMenuItem,
    SlickEventHandler,
    SlickEvent,
    SlickEventData,
    AngularSlickgridComponent
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';
import { ResponseBaseDTO } from '../dto/ResponseBaseDTO';
import { OrderService } from './order.service';
import { OrderPayrollService } from '../admin/orderpayroll.service';
import { GlobaldataService } from '../service/globaldata.service';



const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';


@Component({
    templateUrl: 'orderpivot.component.html',
    providers: [
        OrderService,
        ListService
    ]
})
export class OrderPivotComponent extends BaseComponent implements OnInit {

    columnDefinitions: Column[] = [];
    angularGrid: AngularGridInstance;
    gridOptions: GridOption = {};
    gridMenu: GridMenu;
    gridMenuItem: GridMenuItem;
    slickEventHandler: SlickEventHandler;
    slickEvent: SlickEvent;
    slickEventData: SlickEventData;
    _addon: any;
    mydataset: any[] = [];



    bIsLoaded: boolean = false;
    canShowGrid: boolean = false;
    metrics: Metrics;
    //companyName: string;
    isCountEnabled = true;
    odataVersion = 2;
    odataQuery = '';
    processing = true;
    totalRowCount: number = 0;
    status = { text: 'processing...', class: 'alert alert-danger' };

    userSelectsString = '';
    name = 'Angular';
    userSelects = [];
    dropdownList = [];
    //    selectedItems = [];
    dropdownSettings = {};
    searchError: boolean = false;
    searchErrorMsg: string = "";

    gridObj: any;

    selectedGridIDS: number[];
    selectedTitles: any[];
    selectedTitle: any;

    useremail: string;
    userobjectid: string;
    // employerid: string = "";

    isordernotselected: boolean = true;

    selectedquoteidx: number = -1;

    orders: OrderCompleteDTO[] = [];

    constructor(
        public orderService: OrderService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService,
        private cd: ChangeDetectorRef,
        protected _router: Router,
        private route: ActivatedRoute,
        public orderPayrollService: OrderPayrollService,
        private globaldataservice: GlobaldataService,
    ) {

        super();

        this.userobjectid = auth.userobjectid;
        this.useremail = auth.useremail;
    }


    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};



        this.angularGrid.dataView.onRowCountChanged.subscribe((e, args) => this.rowCountChanged(e, args));


    }


    get eventHandler(): SlickEventHandler {

        return this.slickEventHandler
    }



    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }




    ngOnInit(): void {
        this.setupOrderGrid();
        this.getAllAuthorizedOrders();
    }


    setupOrderGrid() {
        this.columnDefinitions = [
            { id: 'accountname', filterable: true, name: 'Employer Name', field: 'accountname', sortable: true, width: 225, minWidth: 225 },
            { id: 'firstname', name: 'First Name', field: 'firstname', filterable: true, sortable: true, width: 100, minWidth: 100 },
            { id: 'lastname', filterable: true, name: 'Last Name', field: 'lastname', sortable: true, width: 125, minWidth: 125 },
            { id: 'division', filterable: true, name: 'Division', field: 'division', sortable: true, width: 100, minWidth: 100 },
            { id: 'additionalid', filterable: true, name: 'SSN', field: 'additionalid', sortable: true, width: 125, minWidth: 125 },
            { id: 'memberstatus', filterable: true, name: 'Status', field: 'memberstatus', sortable: true, width: 125, minWidth: 125 },
            { id: 'employercontribution', filterable: true, name: 'Employer Contribution', field: 'employercontribution', sortable: true, width: 125, minWidth: 125, formatter: Formatters.dollar },
            { id: 'totalamtspent', filterable: true, name: 'Total Spent', field: 'totalamtspent', sortable: true, width: 125, minWidth: 125, formatter: Formatters.dollar },
            { id: 'payrolldeduction', filterable: true, name: 'Payroll Deduction', field: 'payrolldeduction', sortable: true, width: 125, minWidth: 125, formatter: Formatters.dollar },
            { id: 'currentbalance', filterable: true, name: 'Account Balance', field: 'currentbalance', sortable: true, width: 125, minWidth: 125 },
            { id: 'amountspent_healthinsurance', name: 'Health Insurance Premium', field: 'amountspent_healthinsurance', sortable: true, width: 125, minWidth: 125, formatter: Formatters.dollar },
            { id: 'amountspent_hsa', filterable: true, name: 'HSA Contribution', field: 'amountspent_hsa', sortable: true, width: 125, minWidth: 125, formatter: Formatters.dollar },
            { id: 'amountspent_product1', filterable: true, name: 'Additional Product Cost', field: 'amountspent_product1', sortable: true, width: 125, minWidth: 125, formatter: Formatters.dollar },
            { id: 'category_product1', filterable: true, name: 'Add Product Category', field: 'category_product1', sortable: true, width: 120, minWidth: 120 },
            { id: 'waivecoverage', filterable: true, name: 'Waived', field: 'waivecoverage', sortable: true, width: 125, minWidth: 125 },
            { id: 'productname_healthinsurance', filterable: true, name: 'Health Plan', field: 'productname_healthinsurance', sortable: true, width: 200, minWidth: 200 },
            { id: 'vendor_healthinsurance', filterable: true, name: 'Carrier', field: 'vendor_healthinsurance', sortable: true, width: 200, minWidth: 200 },
            { id: 'effectivedate_healthinsurance', filterable: true, name: 'Policy Eff Date', field: 'effectivedate_healthinsurance', sortable: true, width: 120, minWidth: 120 },
            { id: 'description', filterable: true, name: 'Product Description', field: 'description', sortable: true, width: 225, minWidth: 225 },
            { id: 'birthdate', filterable: true, name: 'Date of Birth', field: 'birthdate', sortable: true, width: 120, minWidth: 120 },
            { id: 'city', filterable: true, name: 'City', field: 'city', sortable: true, width: 120, minWidth: 120 },
            { id: 'stateorprovince', filterable: true, name: 'State', field: 'stateorprovince', sortable: true, width: 120, minWidth: 120 },
            { id: 'postalcode', filterable: true, name: 'Zip Code', field: 'postalcode', sortable: true, width: 120, minWidth: 120 },
            { id: 'options', filterable: true, name: 'Entry Method', field: 'options', sortable: true, width: 125, minWidth: 125 },
            { id: 'createdby', filterable: true, name: 'Created By', field: 'createdby', sortable: true, width: 125, minWidth: 125 },
            { id: 'createdon', filterable: true, name: 'Created On', field: 'createdon', sortable: true, width: 125, minWidth: 125, formatter: Formatters.dateUs },
            { id: 'modifiedby', filterable: true, name: 'Last Modified By', field: 'modifiedby', sortable: true, width: 125, minWidth: 125 },
            { id: 'modifiedon', filterable: true, name: 'Last Modified On', field: 'modifiedon', sortable: true, width: 125, minWidth: 125, formatter: Formatters.dateUs },
            { id: 'testemployer', filterable: true, name: 'Test Case', field: 'testemployer', sortable: true, width: 75, minWidth: 75 },
            { id: 'orderid', name: 'Order ID', field: 'orderid', width: 150, minWidth: 150 },
            { id: 'id', name: 'Order #', field: 'id', sortable: true, width: 60, minWidth: 60 }

        ];
        this.gridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: true,
            checkboxSelector: {
                // you can toggle these 2 properties to show the "select all" checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true,
            },
            enableCheckboxSelector: true,


            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },
            gridMenu: {
                onCommand: (e, args) => {
                    const dataview = args.grid.getData();
                    this._addon = dataview;
                    this.onCommandEvent(e, args);
                }
            }

        };
        this.gridOptions.showTopPanel = false;
    }

    onCommandEvent(event, args) {
        var menuText = event.target.innerText;


        let orderitemids: string;
        let dataView: DataView;

        var countofdata = 0;
        countofdata = this.mydataset.length;
        countofdata = this.angularGrid.dataView.getLength();
        dataView = this.angularGrid.dataView;
        console.log("***** Executed onCommandEvent: " + menuText + "  Count= " + countofdata.toString());

    }

    rowCountChanged(event, args) {


        this.totalRowCount = this.angularGrid.dataView.getLength();
        console.log("***** order: rowcount is " + this.totalRowCount.toString());


    }


    getAllAuthorizedOrders() {

        if (this.globaldataservice.role === "EXTERNAL") {
            this.getAuthorizedOrders();
        }
        else {
            this.getAllOrders();
        }

    }

    getAllOrders() {
        this.searchError = false;

        this.orderService.getAllPivotOrders()
            .subscribe(
                (data) => { this.getOrdersCallBack(data); });

    }

    getAuthorizedOrders() {
        this.searchError = false;
        this.orderService.getAuthorizedCompleteOrders(this.auth.userobjectid)
            .subscribe(
                (data) => { this.getOrdersCallBack(data); });
    }



    getOrdersCallBack(data) {


        if (data.totalcount > 0) {
            this.searchError = false;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.totalRowCount = data.totalcount;

            this.orders = data.ordercomplete;

            this.mydataset = this.orders;

        }
        else {
            this.totalRowCount = 0;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = [];
            this.orders = [];
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }

    }


    onSelectedRowsChanged(e, args) {
        if (Array.isArray(args.rows)) {
            if (args.rows.length > 0) {
                this.isordernotselected = false;
                this.selectedquoteidx = args.rows.map(idx => {
                    return idx;
                });
            }
            else {
                this.isordernotselected = true;
            }
        }
    }

    // NO CUSTOM CODE BEYOND THIS POINT

    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);


    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }


    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.gridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.gridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}






