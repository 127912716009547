﻿import { ChangeDetectorRef, Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminPlansService } from './adminplans.service';
import { ICQuoteDTO } from '../dto/ICQuoteDTO';
import { ACAUniquePlanDTO } from '../dto/ACAUniquePlanDTO';
//import { CalendarModule } from 'primeng/primeng';


import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl, appInjector } from '../core/util.service';
import { ResponseBaseDTO } from '../dto/ResponseBaseDTO';
import { ProductExDTO } from '../dto/ProductExDTO';
import { ProductDTO } from '../dto/ProductDTO';
import { ProductDetailService } from './productdetail.service';
import { ImageDetailService } from './imagedetail.service';
import { ImageDTO } from '../dto/ImageDTO';
import { HttpRequest, HttpEventType, HttpClient } from '@angular/common/http';
import { Http } from '@angular/http';

const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';



@Component({
    templateUrl: 'imagedetail.html',
    providers: [
        ImageDetailService,
        //CalendarModule,
        ListService
    ]
})
export class ImageDetailComponent extends BaseComponent implements OnInit {


    @ViewChild("fileInput") fileInput;
    isCountEnabled = true;
    odataVersion = 2;
    totalRowCount: number = 0;

    name = 'Angular';
    userSelects = [];

    searchError: boolean = false;
    searchErrorMsg: string = "";
    displayMessage: string = "";

    gridObj: any[];

    zipcode: string = "";
    ratingareanumber: number = 1;
    ratingareaid: string = "";



    useremail: string;
    userobjectid: string;

    icquotedto: ICQuoteDTO;

    planagelist: any[] = [];
    createdon: Date;
    createdby: string;

    isaddoredit: string = "";
    imageUrl: string;
    plan: ACAUniquePlanDTO;

    imagename: string = "";
    id: string = "";
    entityid: string = "";
    state: string = "";
    issuername: string = "";
    planmarketingname: string = "";
    businessyear: number;
    effectivedate: Date = new Date();
    planeffectivedatestr: string = "";
    test: string;
    imageDTO: ImageDTO;
    productid: string=null;
    fileToUpload: any | null = null;
    formdata: FormData;

    constructor(
        public adminplanservice: AdminPlansService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService,
        private cd: ChangeDetectorRef,
        protected _router: Router,
        private route: ActivatedRoute,
        private imagedetailsservice: ImageDetailService) {

        super();

        this.route.params.subscribe(params => {
            this.isaddoredit = params.isaddoredit;
            this.imagename = params.imagename;
            this.entityid = params.entityid;
            if (this, this.isaddoredit == "EDIT") {
                this.entityid = params.entityid;
                this.id = params.id;
            }

        });       
    }



    addFile(file): void {

        this.fileToUpload = file[0];        
    }

    onUpload() {
        this.imagedetailsservice
            .uploadFilenew(this.fileToUpload, this.imageDTO)
            .subscribe(res => {
                this.onuploadResponse(res);            });
    }

    onuploadResponse(response) {
        //var image: ImageDTO = response.data;
        //let imageBinary = image.image; //image binary data response from api
        let imageBase64String = response.data;
        this.imageUrl = 'data:image/jpeg;base64,' + imageBase64String;
    }

  

    handleFileInput(files: FileList) {
        this.fileToUpload = files.item(0);

        var rc: boolean = false;
        let fi = this.fileInput.nativeElement;
        if (fi.files && fi.files[0]) {

            rc = true;
                     
        }
        return rc;

    }


    ngOnInit(): void {    
        if (this.isaddoredit == "EDIT") {
            this.imagedetailsservice.getImage(this.id)
                .subscribe(
                    (data) => { this.getImageResponse(data); },

                );
        }
        else {
            this.imageDTO = new ImageDTO();
            this.imageDTO.entityid = this.entityid;
            
        }
       
    }


    onaddImage() {
        //this.getVendorName();

        this._router.navigate(['/imagedetail',
            {
                isaddoredit: 'ADD',
                entityid: this.productid,
                //vendorname: this.getVendorName(),

            }
        ]);
    }
    


    onadd() {
        this.imageDTO.entityid= this.entityid;
        this.imagedetailsservice.addImage(this.imageDTO)
            .subscribe(
                (data) => { this.addProductResponse(data); },

            );
        
    }

    getImageResponse(response) {
        this.imageDTO = response.data;
        this.imageUrl = 'data:image/jpeg;base64,' + this.imageDTO.image;
    }

    getProductResponse(response) {
        this.imageDTO = response.data;
    }

    addProductResponse(response) {    

        this.productid = response.data.productdto.id;
    }


    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }




}






