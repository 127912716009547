﻿//import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ChangeDetectorRef, Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
//import { ACAPlanLookupService } from './acaplanlookup.service';
import { EmployerAccountService } from '../employer/employeraccount.service';
import { AccountAndAddressDTO } from '../dto/AccountAndAddressDTO';
import { EmployerContributionDTO } from '../dto/EmployerContributionDTO';
import { MediaDTO } from '../dto/MediaDTO';
import { GlobaldataService } from '../service/globaldata.service';
import { EmployerSetupService } from './employersetup.services';
//import * as moment from 'moment-mini';
import { MediaManagementService } from '../admin/mediamanagement.service';
import * as Papa from 'papaparse';


import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';
import { ResponseBaseDTO } from '../dto/ResponseBaseDTO';
// import { MemberDTO } from '../dto/MemberDTO';

const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';

interface MemberDTO {
    id: string; // Use string for GUIDs in TypeScript
    accountid: string; // Adjust based on how you obtain this value
    priormemberid: string; // Adjust based on your logic
    externalid: string;
    firstname: string;
    lastname: string;
    address1: string;
    address2?: string;
    city: string;
    stateorprovince: string;
    postalcode: string;
    emailaddress: string;
    personalemailaddress: string;
    birthdate: Date;
    hiredate: Date;
    phonenumber: string;
    origination: number;
    createdon: Date;
    createdby: string;
    modifiedon: Date;
    modifiedby: string;
    relationship: number; // Adjust type as necessary
    primarymemberid: string; // Adjust based on your logic
    istobaccouser: string;
    isdisabled?: string;
    gender: string;
    additionalid?: string;
    pcpname?: string;
    pcpid?: string;
    existingpatient: string;
    status?: string;
    terminationdate?: Date;
    division: string;
}

interface MemberDependentDTO {
    id: string; // Use string for GUIDs in TypeScript
    memberid: string; // Adjust based on how you obtain this value
    relationship: string;
    dateofbirth: Date;
    age: number;
    contributionamt: number;
    istobaccouser: string;
    isdisabled?: string;
    gender: string;
    isonhealthpolicy?: string;
    firstname: string;
    lastname: string;
    createdon: Date;
    createdby: string;
    modifiedon: Date;
    modifiedby: string;
    additionalid?: string;
    irsdependent?: string;
    pcpname?: string;
    pcpid?: string;
    existingpatient: string;
}


@Component({
    templateUrl: 'employersetup.component.html',
    styleUrls: ['./employersetup.component.css'],
    providers: [
        EmployerAccountService,
        ListService,
        MediaManagementService
    ]
})

export class EmployerSetupComponent extends BaseComponent implements OnInit {

    csvData: any[] = [];
    uploadedMemberList: any[] = [];
    uploadedDependentList: any[] = [];

    onFileChange(event: any) {
        const file = event.target.files[0];
        this.uploadedMemberList = [];
        this.uploadedDependentList = [];
        this.memberdisplayMessage = "";
        if (file) {
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: (result) => {
                    this.csvData = result.data;
                    console.log(this.csvData); // Parsed CSV data
                    const data = this.csvData;
                    const validationErrors: string[] = [];

                    console.log("Yo yo : ", this.accountid, data, this.auth.useremail);
    
                    data.forEach(item => {
                        // Validate required fields
                        if (!item.id) {
                            validationErrors.push(`Missing ID for item: ${JSON.stringify(item)}`);
                        }
                        if (!item.externalid) {
                            validationErrors.push(`Missing external ID for item with ID: ${item.id}`);
                        }
                        if (!item.firstname) {
                            validationErrors.push(`Missing first name for item with ID: ${item.id}`);
                        }
                        if (!item.lastname) {
                            validationErrors.push(`Missing last name for item with ID: ${item.id}`);
                        }
                        if (!item.birthdate || isNaN(Date.parse(item.birthdate))) {
                            validationErrors.push(`Invalid birthdate for item with ID: ${item.id}`);
                        }
                        // Additional type checks or validations can be added here

                        if (item.emailaddress) {
                            if (!item.hiredate || isNaN(Date.parse(item.hiredate))) {
                                validationErrors.push(`Invalid hire date for item with ID: ${item.id}`);
                            }
                            if (!item.postalcode) {
                                validationErrors.push(`Missing postal code for item with ID: ${item.id}`);
                            }
                        }
    
                        // Proceed with member or dependent creation if no errors
                        if (validationErrors.length === 0) {
                            this.membersError = "";
                            if (item.emailaddress) {
                                const member: MemberDTO = {
                                    id: item.id,
                                    accountid: this.accountid,
                                    priormemberid: "00000000-0000-0000-0000-000000000000",
                                    externalid: item.externalid,
                                    firstname: item.firstname,
                                    lastname: item.lastname,
                                    address1: item.address1,
                                    address2: "",
                                    city: item.city || "",
                                    stateorprovince: item.stateorprovince || "",
                                    postalcode: item.postalcode,
                                    emailaddress: item.emailaddress,
                                    personalemailaddress: item.personalemailaddress,
                                    birthdate: new Date(item.birthdate),
                                    hiredate: new Date(item.hiredate),
                                    phonenumber: item.phonenumber,
                                    origination: 1,
                                    createdon: new Date(),
                                    createdby: this.auth.useremail || "",
                                    modifiedon: new Date(),
                                    modifiedby: this.auth.useremail || "",
                                    relationship: 1,
                                    primarymemberid: "00000000-0000-0000-0000-000000000000",
                                    istobaccouser: item.istobaccouser,
                                    isdisabled: item.isdisabled || "",
                                    gender: item.gender,
                                    additionalid: item.additionalid || "",
                                    pcpname: item.pcpname || "",
                                    pcpid: item.pcpid || "",
                                    existingpatient: item.existingpatient,
                                    status: item.status || "Active",
                                    terminationdate: null,
                                    division: item.division,
                                };
                                this.uploadedMemberList.push(member);
                            } else {
                                const dependent: MemberDependentDTO = {
                                    id: "00000000-0000-0000-0000-000000000000",
                                    memberid: item.id,
                                    relationship: item.relationship,
                                    dateofbirth: new Date(item.birthdate),
                                    age: 0,
                                    contributionamt: 0,
                                    istobaccouser: item.istobaccouser,
                                    isdisabled: item.isdisabled || "",
                                    gender: item.gender,
                                    isonhealthpolicy: item.isonhealthpolicy || "",
                                    firstname: item.firstname,
                                    lastname: item.lastname,
                                    createdon: new Date(),
                                    createdby: this.auth.useremail || "",
                                    modifiedon: new Date(),
                                    modifiedby: this.auth.useremail || "",
                                    additionalid: item.additionalid || "",
                                    irsdependent: item.irsdependent || "",
                                    pcpname: item.pcpname || "",
                                    pcpid: item.pcpid || "",
                                    existingpatient: item.existingpatient,
                                };
                                this.uploadedDependentList.push(dependent);
                            }
                        }
                    });
    
                    // Show alerts if there are validation errors
                    if (validationErrors.length > 0) {
                        this.membersError = `Validation Errors:\n${validationErrors.join(',\n')}`;
                    }
                }
            });
        }
    }
    

    
    uploadMembers(){
        this.membersError = "";
        var bulkData = {
            members: this.uploadedMemberList,
            dependents: this.uploadedDependentList
        }

        console.log("Upload Member clicked : ", bulkData);

        this.employerSetupService.postBulkMember(bulkData)
            .subscribe(
                (data) => { 
                    console.log("API Response: ", data);
                    this.savebulkMemberCallBack(data); 
                },
                (error) => {
                    console.error("Error Response: ", error);
                    this.membersError = "Error Response: ", error;
                }
            );
    }

    addMember() {
        this.addmembersError = "";
        this.addmemberdisplayMessage = "";

        const validationErrors: string[] = [];

        if (!this.memberDTO.externalid) {
            validationErrors.push("Missing external ID.");
        }
        if (!this.memberDTO.firstname) {
            validationErrors.push("Missing first name.");
        }
        if (!this.memberDTO.lastname) {
            validationErrors.push("Missing last name.");
        }
        if (!this.memberDTO.address1) {
            validationErrors.push("Missing Address 1.");
        }
        if (!this.memberDTO.address2) {
            validationErrors.push("Missing Address 2.");
        }
        if (!this.memberDTO.city) {
            validationErrors.push("Missing city.");
        }
        if (!this.memberDTO.stateorprovince) {
            validationErrors.push("Missing state or province.");
        }
        if (!this.memberDTO.postalcode) {
            validationErrors.push("Missing postal code.");
        }
        if (!this.memberDTO.emailaddress) {
            validationErrors.push("Missing email address.");
        }
        if (!this.memberDTO.birthdate || isNaN(Date.parse(this.memberDTO.birthdate))) {
            validationErrors.push("Invalid birth date.");
        }
        if (!this.memberDTO.hiredate || isNaN(Date.parse(this.memberDTO.hiredate))) {
            validationErrors.push("Invalid hire date.");
        }
        if (!this.memberDTO.phonenumber) {
            validationErrors.push("Missing phone number.");
        }
        if (!this.memberDTO.origination) {
            validationErrors.push("Missing Origination.");
        }
        if (!this.memberDTO.relationship) {
            validationErrors.push("Missing relationship.");
        }
        if (!this.memberDTO.primarymemberid || !this.isValidGuid(this.memberDTO.primarymemberid)) {
            validationErrors.push("Missing primary member ID.");
        }
        if (this.memberDTO.istobaccouser === null || this.memberDTO.istobaccouser === undefined) {
            validationErrors.push("Missing tobacco user status.");
        }
        if (this.memberDTO.isdisabled === null || this.memberDTO.isdisabled === undefined) {
            validationErrors.push("Missing disability status.");
        }
        if (!this.memberDTO.gender) {
            validationErrors.push("Missing gender.");
        }
        if (!this.memberDTO.status) {
            validationErrors.push("Missing status.");
        }
        if (!this.memberDTO.terminationdate || isNaN(Date.parse(this.memberDTO.terminationdate))) {
            validationErrors.push("Invalid termination date.");
        }
        if (!this.memberDTO.additionalid) {
            validationErrors.push("Missing Additonal ID date.");
        }
        if (!this.memberDTO.division) {
            validationErrors.push("Missing division.");
        }

        if (validationErrors.length === 0) {
            const memberData = {
                id: "e24ff762-9f87-46dd-aa50-0c0aaa2a4535",
                accountid: this.accountid,
                priormemberid: "00000000-0000-0000-0000-000000000000",
                externalid: this.memberDTO.externalid,
                firstname: this.memberDTO.firstname,
                lastname: this.memberDTO.lastname,
                address1: this.memberDTO.address1,
                address2: this.memberDTO.address2,
                city: this.memberDTO.city,
                stateorprovince: this.memberDTO.stateorprovince,
                postalcode: this.memberDTO.postalcode,
                emailaddress: this.memberDTO.emailaddress,
                personalemailaddress: this.memberDTO.personalemailaddress,
                birthdate: this.memberDTO.birthdate,
                hiredate: this.memberDTO.hiredate,
                phonenumber: this.memberDTO.phonenumber,
                origination: this.memberDTO.origination,
                createdon: new Date(),
                createdby: this.auth.useremail || "",
                modifiedon: new Date(),
                modifiedby: this.auth.useremail || "",
                relationship: this.memberDTO.relationship,
                primarymemberid: this.memberDTO.primarymemberid,
                istobaccouser: this.memberDTO.istobaccouser,
                isdisabled: this.memberDTO.isdisabled,
                gender: this.memberDTO.gender,
                status: this.memberDTO.status,
                terminationdate: this.memberDTO.terminationdate,
                additionalid: this.memberDTO.additionalid,
                division: this.memberDTO.division
            };
            console.log("Add Member clicked:", memberData);
            this.employerSetupService.postMember({ memberdto: memberData })
                .subscribe(
                    (response) => {
                        if(response.success){
                            console.log("Member added successfully:", response);
                            this.displayMessage = "Member added successfully!";
                            // Reset the form if needed
                            this.resetForm();
                        }else{
                            this.searchError = true;
                            this.searchErrorMsg = "Failed to add member. Please try again.";
                        }
                    },
                    (error) => {
                        console.error("Error adding member:", error);
                        this.addmembersError = "Error Response: " + error.message;
                        this.searchError = true;
                        this.searchErrorMsg = "Failed to add member. Please try again.";
                    }
                );

            this.addmemberdisplayMessage = "Member Added Successfully";
        } else {
            // Show validation errors to the user
            console.log("Validation Errors: ", validationErrors);
            this.addmembersError = `Validation Errors:\n${validationErrors.join(',\n')}`;
        }
    }
    
    resetForm() {
        this.memberDTO = {};  // Clear the form fields
    }

    isValidGuid(guid: string): boolean {
        const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
        return guidRegex.test(guid);
    }

    savebulkMemberCallBack(data) {
        if(data.success){
            this.memberdisplayMessage = "Members created successfully";
        }else{
            this.membersError = data.displaymessage;
        }
    }

    employeazecustomerlist: SelectList[] = [
        {
            id: "0",
            name: "No"
        },
        {
            id: "1",
            name: "All Products"
        },
        {
            id: "2",
            name: "No Products"
        }

    ];



    statuslist: SelectList[] = [
        {
            id: "1",
            name: "Active"
        },
        {
            id: "0",
            name: "Inactive"
        }

    ];

    onoffexchangelist: SelectList[] = [
        {
            id: "0",
            name: "OFF"
        },
        {
            id: "1",
            name: "ON"
        }

    ];

    hsaonlylist: SelectList[] = [
        {
            id: "0",
            name: "No"
        },
        {
            id: "1",
            name: "Yes"
        }

    ];

    teamlist: SelectList[] = [];

    contributioncolumnDefinitions: Column[] = [];
    contributiongridOptions: GridOption = {};
    contributiondataset: any[] = [];
    angularContributionGridInstance: AngularGridInstance;


    membercolumnDefinitions: Column[] = [];
    membergridOptions: GridOption = {};
    memberdataset: any[] = [];
    angularMemberGridInstance: AngularGridInstance;

    ichradoccolumnDefinitions: Column[] = [];
    ichradocgridOptions: GridOption = {};
    ichradocdataset: any[] = [];
    angularIchraDocGridInstance: AngularGridInstance;

    // memberDTO: MemberDTO;
    memberDTO: any = {};


    bIsLoaded: boolean = false;
    canShowGrid: boolean = false;
    angularGrid: AngularGridInstance;
    metrics: Metrics;
    //companyName: string;
    isCountEnabled = true;
    odataVersion = 2;
    odataQuery = '';
    processing = true;
    totalRowCount: number = 0;
    status = { text: 'processing...', class: 'alert alert-danger' };

    userSelectsString = '';
    name = 'Angular';
    userSelects = [];
    dropdownList = [];
    //    selectedItems = [];
    dropdownSettings = {};
    searchError: boolean = false;
    searchErrorMsg: string = "";
    membersError: string = "";
    addmembersError: string = "";
    addmemberdisplayMessage: string = "";
    displayMessage: string = "";
    memberdisplayMessage: string = "";
    testdate: string;

    gridObj: any[];
    selectedGridIDS: number[];
    selectedTitles: any[];
    selectedTitle: any;

    zipcode: string = "";

    addingaccount: number = 0;

    city: string = "";
    state: string = "";



    totalquotes: number = 0;
    renewaldate: Date;
    renewaldatestr: string = "";

    employername: string = "";
    quotename: string = "";
    brokername: string = "";
    accountstatus: string = "Active";

    acctaddrdto: AccountAndAddressDTO;
    employercontributionlist: EmployerContributionDTO[] = [];


    accountid: string = "";
    quoteid: string = "";
    quotelist: any[] = [];
    createdon: Date;
    createdby: string;

    selectedquoteidx: number = -1;
    isquotenotselected: boolean = true;
    isaccountnotsaved: boolean = true;


    currenttabid: string = "tab-3-div";

    // ICHRA DOCS MANAGEMENT

    ichradocs: MediaDTO[] = [];
    ichradoc: MediaDTO;
    ichradocTotalCount: number = 0;
    ichradoctoupload: File = null;

    @ViewChild("fileInput") fileInput;

    constructor(
        public employeeaccountservice: EmployerAccountService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService,
        private cd: ChangeDetectorRef,
        protected _router: Router,
        private route: ActivatedRoute,
        private employerSetupService: EmployerSetupService,
        private globaldataservice: GlobaldataService,
        public mediaservice: MediaManagementService) {

        super();

        this.route.params.subscribe(params => {
            this.addingaccount = params.addingaccount;
            this.acctaddrdto = JSON.parse(params.acctaddrdto);

        });

        this.globaldataservice.useremail = auth.useremail;
        this.globaldataservice.userobjectid = auth.userobjectid;
    }


    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};
    }

    angularMemberGridReady(angularGrid: AngularGridInstance) {
        this.angularMemberGridInstance = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};
    }

    angularContributionGridReady(angularGrid: AngularGridInstance) {
        this.angularContributionGridInstance = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};
    }

    angularIchraDocGridReady(angularGrid: AngularGridInstance) {
        this.angularIchraDocGridInstance = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};
    }


    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }


    ngOnInit(): void {

        // Initialize when doing coming to this page
        this.globaldataservice.members = [];
        this.globaldataservice.memberTotalCount = 0;
        this.ichradoctoupload = null;


        if (this.acctaddrdto == undefined) { // this is not a saved employer
            this.renewaldate = new Date();
            this.renewaldatestr = (this.renewaldate.getMonth() + 2).toString() + "/01/" + this.renewaldate.getFullYear().toString();
            this.renewaldate = new Date(this.renewaldatestr);
            this.accountstatus = "Active";
            this.acctaddrdto = new AccountAndAddressDTO();
            this.acctaddrdto.accountname = "";
            this.acctaddrdto.accounttype = 1;  // Employer
            this.acctaddrdto.addresstype = 3;  // Primary Address
            this.acctaddrdto.status = 1;       // Active Account
            this.acctaddrdto.parenttype = 0;   // The parent of the address is the account entity
            this.acctaddrdto.postalcode = "";
            this.acctaddrdto.employeazecustomer = 0;
            this.acctaddrdto.employeazestatus = 0;
            this.acctaddrdto.teamname = "";
        }
        else {
            this.accountid = this.acctaddrdto.accountid;
            if (this.acctaddrdto.renewaldate !== null) {
                this.renewaldate = new Date(this.acctaddrdto.renewaldate);
                this.renewaldatestr = (this.renewaldate.getMonth() + 1).toString() + "/" + this.renewaldate.getDate().toString() + "/" + this.renewaldate.getFullYear().toString();
            }
            else {
                this.renewaldatestr = "";
            }
            if (this.acctaddrdto.postalcode == undefined)
                this.acctaddrdto.postalcode = "";
            else
                this.isaccountnotsaved = false;

        }

        if (this.globaldataservice.role !== 'ADMIN') {
            var element;
            element = document.getElementById("productconfigid");
            element.disabled = true;
            element = document.getElementById("hsaonlyid");
            element.disabled = true;
            element = document.getElementById("offonexchangeid");
            element.disabled = true;
            element = document.getElementById("teamselectid");
            element.disabled = true;

        }

        this.setupContributionGrid();
        this.setupMemberGrid();
        this.setupIchraDocGrid();
        this.runEmployeazeSetupMode();

        for (var i = 0; i < this.globaldataservice.teamlist.length; i++) {
            this.teamlist.push(
                {
                    id: this.globaldataservice.teamlist[i].id,
                    name: this.globaldataservice.teamlist[i].name
                }
            )

        }



        //this.angularGrid.resizerService.pauseResizer(true);
    }

    setupContributionGrid() {

        this.contributioncolumnDefinitions = [
            { id: 'location', filterable: true, name: 'Location or Class', field: 'location', sortable: true, width: 175, minWidth: 175 },
            { id: 'effectivedate', name: 'Effective Date', field: 'effectivedate', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'contributiontype', name: 'Money Account Type', field: 'contributiontype', sortable: true, width: 100, minWidth: 100 },
            { id: 'strategytype', name: 'Strategy Type', field: 'strategytype', sortable: true, width: 150, minWidth: 150 },
            { id: 'contributionamount', name: 'Flat Contribution', field: 'contributionamount', sortable: true, width: 125, minWidth: 100, formatter: this.moneyFormatter },
            { id: 'modifiedon', name: 'Last Modified', field: 'modifiedon', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'id', name: 'Contribution Id', field: 'id', sortable: false, width: 60, minWidth: 60 }
        ];
        this.contributiongridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: true,
            checkboxSelector: {
                // you can toggle these 2 properties to show the "select all" checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true,
            },
            enableCheckboxSelector: true,

            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },

        };
        this.contributiongridOptions.showTopPanel = false;

    }

    runEmployeazeSetupMode() {

        if (this.accountid != "") {

            this.acctaddrdto = this.globaldataservice.employers.find(a => a.accountid == this.accountid);
            this.getMembers();
        }

    }

    getContributions() {

        this.searchError = false;

        this.employerSetupService.getEmployerContributionList(this.accountid)
            .subscribe(
                (data) => { this.getContributionsCallBack(data); });

    }

    getContributionsCallBack(data) {

        this.searchError = false;
        this.searchErrorMsg = "";

        if (data.totalcount > 0) {
            this.totalRowCount = data.totalcount;

            this.contributiondataset = data.employercontributionlist;
            this.employercontributionlist = data.employercontributionlist;
        }
        else {
            this.totalRowCount = 0;
            this.contributiondataset = [];
            if (data.responseStatus !== 0) {
                this.searchError = true;
                this.searchErrorMsg = data.responseMsg;
            }

        }
    }

    getICHRADocs() {

        this.searchError = false;

        this.mediaservice.getmedia(this.accountid, "ichra")
            .subscribe(
                (data) => { this.getICHRADocssCallBack(data); });

    }

    getICHRADocssCallBack(data) {

        this.searchError = false;
        this.searchErrorMsg = "";

        if (data.totalcount > 0) {
            this.ichradocTotalCount = data.totalcount;
            this.ichradocs = data.mediadtos;
            for (var i = 0; i < this.ichradocTotalCount; i++) {
                this.ichradocs[i].medianame = this.ichradocs[i].medianame.substring(this.ichradocs[i].medianame.indexOf("ichradocs") + 9);
            }


            this.ichradocdataset = this.ichradocs;

        }
        else {
            this.totalRowCount = 0;
            this.contributiondataset = [];
            if (data.responseStatus !== 0) {
                this.searchError = true;
                this.searchErrorMsg = data.responseMsg;
            }

        }
    }

    setupMemberGrid() {

        this.membercolumnDefinitions = [
            { id: 'lastname', name: 'Last Name', field: 'lastname', filterable: true, sortable: true, width: 150, minWidth: 150 },
            { id: 'firstname', name: 'First Name', field: 'firstname', filterable: true, sortable: true, width: 150, minWidth: 150 },
            { id: 'status', filterable: true, name: 'Status', field: 'status', sortable: true, width: 100, minWidth: 100 },
            { id: 'emailaddress', name: 'Email Addr', field: 'emailaddress', sortable: true, width: 175, minWidth: 175 },
            { id: 'phonenumber', name: 'Phone', field: 'phonenumber', sortable: true, width: 100, minWidth: 100 },
            { id: 'city', name: 'City', field: 'city', sortable: true, width: 100, minWidth: 100 },
            { id: 'stateorprovince', filterable: true, name: 'State', field: 'stateorprovince', sortable: true, width: 80, minWidth: 80 },
            { id: 'postalcode', name: 'Zip Code', field: 'postalcode', sortable: true, width: 100, minWidth: 100 },
            { id: 'terminationdate', name: 'Termination Date', field: 'terminationdate', sortable: true, width: 120, minWidth: 120, formatter: Formatters.dateIso },
            { id: 'division', name: 'Division', field: 'division', filterable: true, sortable: true, width: 150, minWidth: 150 },
            { id: 'modifiedon', name: 'Last Modified On', field: 'modifiedon', sortable: true, width: 120, minWidth: 120, formatter: Formatters.dateIso },
            { id: 'createdon', name: 'Created On', field: 'createdon', sortable: true, width: 120, minWidth: 120, formatter: Formatters.dateIso },
            { id: 'id', name: 'Member Id', field: 'id', sortable: false, width: 60, minWidth: 60 }
        ];
        this.membergridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: true,
            checkboxSelector: {
                // you can toggle these 2 properties to show the "select all" checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true,
            },
            enableCheckboxSelector: true,

            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },

        };
        this.membergridOptions.showTopPanel = false;

    }

    setupIchraDocGrid() {
        this.ichradoccolumnDefinitions = [
            { id: 'medianame', name: 'Document Name', field: 'medianame', filterable: true, sortable: true, width: 250, minWidth: 250 },
            { id: 'tagline', name: 'Tag Line', field: 'tagline', filterable: true, sortable: true, width: 250, minWidth: 250 },
            { id: 'modifiedon', name: 'Last Modified On', field: 'modifiedon', sortable: true, width: 120, minWidth: 120, formatter: Formatters.dateIso },
            { id: 'modifiedby', name: 'Modified by', field: 'modifiedby', filterable: true, sortable: true, width: 150, minWidth: 150 },
            { id: 'mediaid', name: 'Media Id', field: 'mediaid', sortable: false, width: 100, minWidth: 100 },
            { id: 'id', name: 'Doc Id', field: 'id', sortable: false, width: 60, minWidth: 60 }
        ];
        this.ichradocgridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: true,
            checkboxSelector: {
                // you can toggle these 2 properties to show the "select all" checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true,
            },
            enableCheckboxSelector: true,

            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },

        };
        this.ichradocgridOptions.showTopPanel = false;

    }

    saveaccount() {

        this.searchError = false;
        this.displayMessage = "";

        if (this.acctaddrdto.employeazestatus.toString() == "") {
            this.searchError = true;
            this.searchErrorMsg = "Test Employer must be set to 1 for test or 0 for live.";
            return;
        }

        if (this.renewaldatestr !== "") {
            this.renewaldate = new Date(this.renewaldatestr);
            if (isNaN(this.renewaldate.getDate())) {
                this.searchErrorMsg = "Effective date must be in the format MM/DD/YYYY.";
                this.searchError = true;
                this.renewaldatestr = "";
                this.renewaldate = null;
                return;
            }
            this.acctaddrdto.renewaldate = this.renewaldate;
        }
        else {
            this.acctaddrdto.renewaldate = null;
            this.renewaldate = null;
        }

        if (this.acctaddrdto.postalcode == undefined || this.acctaddrdto.postalcode === "") {
            this.searchError = true;
            this.searchErrorMsg = "Please enter a valid zipcode..";
            return;
        }

        if (this.acctaddrdto.accountname == undefined || this.acctaddrdto.accountname === "") {
            this.searchError = true;
            this.searchErrorMsg = "Please enter the name of the employer.";
            return;
        }



        if (this.accountid !== undefined && this.accountid !== "") {
            this.acctaddrdto.modifiedon = new Date();
            this.acctaddrdto.modifiedby = this.auth.useremail;
        }
        else {
            this.acctaddrdto.createdon = new Date();
            this.acctaddrdto.createdby = this.auth.useremail;
            this.acctaddrdto.ownertenantid = this.auth.userobjectid;
        }
        if (this.acctaddrdto.teamid == null || this.acctaddrdto.teamid == "") {
            this.acctaddrdto.teamid = "00000000-0000-0000-0000-000000000000";
        }


        this.employeeaccountservice.postAccountAndAddress(this.acctaddrdto)
            .subscribe(
                (data) => { this.saveaccountCallback(data); }
            );

    }

    saveaccountCallback(data) {


        if (data.success) {
            this.accountid = data.data.accountdto.accountid;
            this.acctaddrdto.accountid = this.accountid;
            this.acctaddrdto.addressid = data.data.addressdto.addressid;
            this.acctaddrdto.parentid = data.data.addressdto.parentid;
            this.acctaddrdto.parenttype = data.data.addressdto.parenttype;
            this.isaccountnotsaved = false;

            this.displayMessage = "Saved Successfully";
        }
        else {
            this.searchErrorMsg = data.displaymessage;
            this.searchError = true;

        }

    }



    opencontribution() {
        var filteredItems = this.angularContributionGridInstance.dataView.getFilteredItems();
        let contributionIdx: number = -1;

        for (var i = 0; i < this.employercontributionlist.length; i++) {
            if (this.employercontributionlist[i].id == filteredItems[this.selectedquoteidx].id) {
                contributionIdx = i;
                break;
            }
        }

        if (contributionIdx == -1) {
            this.searchError = true;
            this.searchErrorMsg = "Error occurred getting contribution data. Call your administrator.";
            return;

        }

        this._router.navigate(['/employercontribution',
            {
                employername: this.acctaddrdto.accountname,
                accountid: this.accountid,
                addingcontribution: 0,
                contributiondto: JSON.stringify(this.employercontributionlist[contributionIdx])
            }
        ]);

    }

    addcontribution() {


        this._router.navigate(['/employercontribution',
            {
                employername: this.acctaddrdto.accountname,
                accountid: this.accountid,
                addingcontribution: 1
            }
        ]);
    }

    copycontribution() {

        //this.icquotedto = new ICQuoteDTO();

        //this.icquotedto = this.quotes[this.selectedquoteidx];

        //this.icquotedto.id = '00000000-0000-0000-0000-000000000000';
        //this.icquotedto.createdon = new Date();
        //this.icquotedto.createdby = this.auth.useremail;
        //this.icquotedto.name = "Copy " + this.icquotedto.name;

        //this.icquotedto.ownerid = this.userobjectid; // TODO: When we implement security groups, this might not be the right thing to do
        //this.icquotedto.modifiedby = this.auth.useremail;
        //this.icquotedto.modifiedon = new Date();
        //this.icquotedto.clientmessage = "RETURNALLQUOTES";


        //this.totalcostservice.postIndivdualCoverageByUnitsQuote(this.icquotedto)
        //    .subscribe(
        //        (data) => { this.copyquoteCallBack(data); }
        //    );

    }



    changeAccount(e) {
        this.isaccountnotsaved = true;
    }

    changeRenewalDate(e: any) {
        var a;
        let adate: Date;

        console.log("***** change renewal date: " + e.target.value);
        a = e.target.value;
        this.acctaddrdto.renewaldate = new Date(a);
        this.renewaldatestr = (this.acctaddrdto.renewaldate.getUTCMonth() + 1).toString() + "/" + this.acctaddrdto.renewaldate.getUTCDate().toString() + "/" + this.acctaddrdto.renewaldate.getUTCFullYear().toString();
        this.isaccountnotsaved = true;

    }

    changePostalCode() {
        this.employeeaccountservice.getCityStatebyZip(this.acctaddrdto.postalcode)
            .subscribe(
                (data) => { this.changePostalCodeCallBack(data); }
            );

    }

    changePostalCodeCallBack(data) {
        this.isaccountnotsaved = true;
        if (data.success) {
            this.acctaddrdto.city = data.data.city;
            this.acctaddrdto.stateorprovince = data.data.stateorprovince;
        }
        else {
            this.searchErrorMsg = data.displaymessage + " Postcode entered is: " + this.acctaddrdto.postalcode;
            this.acctaddrdto.city = "";
            this.acctaddrdto.stateorprovince = "";
            this.acctaddrdto.postalcode = "";
            this.searchError = true;

        }

    }



    changeOnOffExchange(e) {

        let onoff: number = e.value;

        this.acctaddrdto.onoffexchange = onoff;
        this.isaccountnotsaved = true;


    }

    changeHSAOnly(e) {

        let onoff: number = e.value;

        this.acctaddrdto.hsaonly = onoff;
        this.isaccountnotsaved = true;


    }

    changeTeam(e) {

        if (e.value == 'none') {
            this.acctaddrdto.teamid = "";
            this.acctaddrdto.teamname = "";
        }
        else {
            this.acctaddrdto.teamid = e.value;
            this.acctaddrdto.teamname = e.textContent;
        }
        this.isaccountnotsaved = true;

    }



    changeEmployeazeCustomer(target) {
        this.acctaddrdto.employeazecustomer = target.value;
        this.isaccountnotsaved = true;
    }

    changeStatus(target) {
        this.acctaddrdto.status = target.value;
        this.isaccountnotsaved = true;
    }




    tabClicked(event, tabId) {

        var element;


        if (tabId === this.currenttabid)
            return;

        if (this.currenttabid === 'tab-3-div') {  // JJM - This is a kludge, for some reason the first tab (not tab-content) needs active to be removed manually.  The rest don't
            element = document.getElementById(this.currenttabid);
            element.classList.remove('active');

        }

        let wrktabid: string = this.currenttabid.substring(0, 5);
        element = document.getElementById(wrktabid);
        element.classList.remove('active');

        wrktabid = tabId.substring(0, 5);
        element = document.getElementById(wrktabid);
        element.classList.add('active');



        if (this.currenttabid !== "") {
            element = document.getElementById(this.currenttabid);
            element.classList.remove("tcc_tab_on");
            element.classList.add("tcc_tab_off");
        }

        element = document.getElementById(tabId);
        element.classList.remove("tcc_tab_off");
        element.classList.add("tcc_tab_on");
        this.currenttabid = tabId;

        if (this.currenttabid === 'tab-1-div') {  // Contributions Tab
            this.getContributions();
        }
        else if (this.currenttabid === 'tab-4-div') { // ICHRA Docs Tab
            this.getICHRADocs();
        }

        window.scroll(0, 0);

    }

    getMembers() {


        this.searchError = false;
        if (this.globaldataservice.members.length === 0) {  // did we already lookup members

            this.employerSetupService.getMemberList(this.accountid)
                .subscribe(
                    (data) => { this.getMembersCallBack(data); });
        }
        else {
            this.memberdataset = this.globaldataservice.members;

        }

    }

    getMembersCallBack(data: any) {
        this.searchError = false;
        this.searchErrorMsg = "";

        if (data.totalcount > 0) {
            this.globaldataservice.memberTotalCount = data.totalcount;

            this.globaldataservice.members = data.members;
            this.memberdataset = this.globaldataservice.members;

        }
        else {
            this.globaldataservice.memberTotalCount = 0;
            this.memberdataset = [];
            this.globaldataservice.members = [];
            if (data.responseStatus !== 0) {
                this.searchError = true;
                this.searchErrorMsg = data.responseMsg;
            }

        }

    }

    openMember() {



        var filteredItems = this.angularMemberGridInstance.dataView.getFilteredItems();
        let memberIdx: number = -1;

        for (var i = 0; i < this.globaldataservice.members.length; i++) {
            if (this.globaldataservice.members[i].id == filteredItems[this.selectedquoteidx].id) {
                memberIdx = i;
                break;
            }
        }

        if (memberIdx == -1) {
            this.searchError = true;
            this.searchErrorMsg = "Error occurred getting member data. Call your administrator.";
            return;

        }

        this._router.navigate(['/member',
            {
                employername: this.acctaddrdto.accountname,
                accountid: this.accountid,
                addingmember: 0,
                memberdto: JSON.stringify(this.globaldataservice.members[memberIdx])
            }
        ]);

    }

    onupload(mediatype: string) {
        let fi = this.fileInput.nativeElement;
        if (fi.files && fi.files[0]) {
            let fileToUpload = fi.files[0];


            //let file: File = fileList[0];
            let formData: FormData = new FormData();
            formData.append('accountid', this.accountid);
            formData.append('uploadFile', fileToUpload, fileToUpload.name);
            formData.append('mediatype', mediatype);

            this.mediaservice
                .addmedia(formData)
                .subscribe(data => {
                    this.fileuploadResponse(data);
                });
        }
    }

    fileuploadResponse(data: any) {
        
    }

    choosefile(file: File) {
        this.ichradoctoupload = file[0];
    }

    uploadichradoc() {

        this.searchErrorMsg = "";
        this.searchError = false;

        let formData: FormData = new FormData();
        formData.append('accountid', this.accountid);
        formData.append('uploadFile', this.ichradoctoupload, this.ichradoctoupload.name);
        formData.append('mediatype', "ichra");
        formData.append('modifiedby', this.globaldataservice.useremail);
        formData.append('createdby', this.globaldataservice.useremail);
        formData.append('accountname', this.acctaddrdto.accountname);

        this.mediaservice
            .addmedia(formData)
            .subscribe(data => {
                this.uploadichradocCallBack(data);
            });

    }

    uploadichradocCallBack(data: any) {

        this.ichradoctoupload = null;
        if (data.success) {
            this.getICHRADocs();
        }
        else {
            this.searchErrorMsg = data.displaymessage;
            this.searchError = true;

        }

    }


    removeICHRADoc() {
        this.searchError = false;
        this.searchErrorMsg = "";


        var filteredItems = this.angularIchraDocGridInstance.dataView.getFilteredItems();
        let mediaIdx: number = -1;

        for (var i = 0; i < this.ichradocs.length; i++) {
            if (this.ichradocs[i].mediaid == filteredItems[this.selectedquoteidx].mediaid) {
                mediaIdx = i;
                break;
            }
        }

        if (mediaIdx == -1) {
            this.searchError = true;
            this.searchErrorMsg = "Error occurred getting member data. Call your administrator.";
            return;

        }

        if (this.ichradocs[mediaIdx].id == "D00") {
            this.searchError = true;
            this.searchErrorMsg = "Cannot remove the Default ICHRA document.";
            return;

        }

        var result = confirm("Do you want to remove ICHRA document: " + this.ichradocs[mediaIdx].medianame);   

        if (result) {
            this.ichradocs[mediaIdx].mediatype = "ichra_removed";
            this.ichradocs[mediaIdx].modifiedby = this.globaldataservice.useremail;
            this.ichradocs[mediaIdx].modifiedon = new Date();
            this.mediaservice.updatemedia(this.ichradocs[mediaIdx])
                .subscribe((data) => { this.removeICHRADocCallBack(data) });
        }


    }

    removeICHRADocCallBack(data) {

        this.displayMessage = "";
        if (data.success) {
            this.getICHRADocs();
        }
        else {
            this.searchErrorMsg = data.displaymsg;
            this.searchError = true;
        }


    }


    onSelectedRowsChanged(e, args) {
        if (Array.isArray(args.rows)) {
            if (args.rows.length > 0) {
                this.selectedquoteidx = args.rows.map(idx => {
                    return idx;
                });
            }
        }
    }



    // NOT PART OF CUSTOM CODE

    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);


    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }



    //onSelectedRowsChanged(e, args) {

    //    let test: number = 0;
    //    if (Array.isArray(args.rows)) {

    //        this.searchError = false;
    //        this.numberofSelectedPlans = args.rows.length;
    //        //if (args.rows.length > 4) {
    //        //    this.searchError = true;
    //        //    this.searchErrorMsg = "You can only select up to 4 plans.";
    //        //    return;
    //        //}

    //        test = args.rows.map(idx => idx);

    //        this.membernumber = args.rows.map(idx => {
    //            const item = this.angularGrid.gridService.getDataItemByRowIndex(idx);
    //            return item.numberbyage || 0;
    //        });
    //    }
    //}



    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.membergridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.membergridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}






