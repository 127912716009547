﻿import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminPlansService } from './adminplans.service';
import { ICQuoteDTO } from '../dto/ICQuoteDTO';
import { ACAUniquePlanDTO } from '../dto/ACAUniquePlanDTO';
//import { CalendarModule } from 'primeng/primeng';

import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';
import { ResponseBaseDTO } from '../dto/ResponseBaseDTO';

const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';



@Component({
    templateUrl: 'adminplandetail.html',
    providers: [
        AdminPlanDetailComponent,
        //CalendarModule,
        ListService
    ]
})
export class AdminPlanDetailComponent extends BaseComponent implements OnInit {

    columnDefinitions: Column[] = [];
    gridOptions: GridOption = {};
    mydataset: any[] = [];
    angularGrid: AngularGridInstance;
    metrics: Metrics;

    isCountEnabled = true;
    odataVersion = 2;
    totalRowCount: number = 0;

    name = 'Angular';
    userSelects = [];

    searchError: boolean = false;
    searchErrorMsg: string = "";
    displayMessage: string = "";

    gridObj: any[];

    zipcode: string = "";
    ratingareanumber: number = 1;
    ratingareaid: string = "";



    useremail: string;
    userobjectid: string;

    icquotedto: ICQuoteDTO;

    planagelist: any[] = [];
    createdon: Date;
    createdby: string;

    isaddoredit: string = "";

    plan: ACAUniquePlanDTO;

    standardcomponentid: string = "";
    state: string = "";
    issuername: string = "";
    planmarketingname: string = "";
    businessyear: number;
    effectivedate: Date = new Date();
    planeffectivedatestr: string = "";




    constructor(
        public adminplanservice: AdminPlansService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService,
        private cd: ChangeDetectorRef,
        protected _router: Router,
        private route: ActivatedRoute) {

        super();

        this.route.params.subscribe(params => {
            this.isaddoredit = params.isaddoredit;
            this.standardcomponentid = params.standardcomponentid;

        });

        this.userobjectid = auth.userobjectid;
        this.useremail = auth.useremail;
       
    }




    ngOnInit(): void {


        //this.columnDefinitions = [
        //    { id: 'id', name: 'Age', field: 'id', sortable: false, width: 80, minWidth: 80, cssClass: 'age' },
        //    { id: 'numberbyage', name: 'Units', field: 'numberbyage', sortable: false, width: 100, minWidth: 100, type: FieldType.number, editor: { model: Editors.integer, placeholder: 1 } },
        //    { id: 'plan1rate', name: planname1, field: 'plan1rate', sortable: false, width: 150, minWidth: 150, formatter: Formatters.dollar, cssClass: 'plan1' },
        //    { id: 'plan1totalforage', name: this.plan1grandtotalstr, field: 'plan1totalforage', sortable: false, width: 100, minWidth: 100, formatter: Formatters.dollar, cssClass: 'plan1' },
        //    { id: 'plan2rate', name: planname2, field: 'plan2rate', sortable: false, width: 150, minWidth: 150, formatter: Formatters.dollar, cssClass: 'plan2' },
        //    { id: 'plan2totalforage', name: this.plan2grandtotalstr, field: 'plan2totalforage', sortable: false, width: 100, minWidth: 100, formatter: Formatters.dollar, cssClass: 'plan2' },
        //    { id: 'plan3rate', name: planname3, field: 'plan3rate', sortable: false, width: 150, minWidth: 150, formatter: Formatters.dollar, cssClass: 'plan3' },
        //    { id: 'plan3totalforage', name: this.plan3grandtotalstr, field: 'plan3totalforage', sortable: false, width: 100, minWidth: 100, formatter: Formatters.dollar, cssClass: 'plan3' },
        //    { id: 'plan4rate', name: planname4, field: 'plan4rate', sortable: false, width: 150, minWidth: 150, formatter: Formatters.dollar, cssClass: 'plan4' },
        //    { id: 'plan4totalforage', name: this.plan4grandtotalstr, field: 'plan4totalforage', sortable: false, width: 100, minWidth: 100, formatter: Formatters.dollar, cssClass: 'plan4' }
        //];
        //this.gridOptions = {
        //    /// enableAutoResize: true,
        //    //enablePagination: true,

        //    autoResize: {
        //        containerId: 'demo-container',
        //        sidePadding: 15
        //    },
        //    enableCellNavigation: true,
        //    enableAutoResize: true,
        //    enableColumnReorder: false,
        //    forceFitColumns: false,
        //    enableRowSelection: true,
        //    editable: true,
        //    autoEdit: true,
        //    // multiSelect: true,


        //    // Multi-row selection support
        //    // enableFiltering: true,

        //    // Paging Support
        //    pagination: {
        //        pageSizes: [50, 1000],
        //        pageSize: defaultPageSize,
        //        totalItems: 50
        //    },

        //};
        //this.gridOptions.showTopPanel = false;
        //this.compareplans();

        //this.angularGrid.resizerService.pauseResizer(true);


        let test: string;
        if (this.isaddoredit === "EDIT") {
            test = this.standardcomponentid;
            this.plan = new ACAUniquePlanDTO();
            this.plan.issuername = "Hello";

            this.getplan();

        }

    }

    getplan() {

        this.searchError = false;


        this.adminplanservice.getPlanById(this.standardcomponentid)
            .subscribe(
                (data) => { this.getplanCallback(data); });

    }


    getplanCallback(data) {

        this.searchError = false;

        if (data === undefined) {
            this.searchError = true;
            this.searchErrorMsg = "Could not find the plan.";
            return;

        }
        this.plan = data.acaPlan;
    }

    saveplan() {
    // 10 Dec 2020 JJM Not used anymore since it only dealt with 2020 plans.
        //this.searchError = false;
        //this.displayMessage = "";

        //this.plan.modifiedby = this.auth.useremail;
        //this.plan.modifiedon = new Date();

        //this.adminplanservice.postPlan(this.plan)
        //    .subscribe(
        //        (data) => { this.saveplanCallBack(data); }
        //    );

    }

    saveplanCallBack(data) {

        if (data.success) {
            this.displayMessage = "The Plan Updated Successfully";
        }
        else {
            this.searchErrorMsg = data.displaymessage;
            this.searchError = true;

        }

    }




    // THE FOLLOWING METHODS ARE NOT PART OF THE BUSINESS LOGIC

    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};
    }



    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }



    // THE FOLLOWING METHODS ARE USED FOR PAGING AND FILTERING WHEN APPLICABLE

    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);


    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }


    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.gridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.gridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}






