import { Router } from "@angular/router";
import * as auth0 from 'auth0-js';
import { Injectable } from "@angular/core";
import { MsalService } from "./msal.service";
import { EnvironmentService } from "../core/environment.service";
//import { Router } from '@angular/router';

@Injectable()
export class B2CAuthService {
    
    ishandleLoginCallback:boolean= false   
    // Store authentication data
    userProfile: any;
    //accessToken: string;
    apiToken: string;
    authenticated: boolean;
    loginFailed: boolean;
    user: any;
    hasloggedin: boolean = false;
    accesstoken: string;
    username: string;
    useremail: string;
    userobjectid: string;
    role: string;


    constructor(private env: EnvironmentService,
        private msalService: MsalService) {
     
        var tg = 10;
    }

    oncallBackLogin(user:any,token:any,loginfailed:boolean) {
        this.user = user;
        this.hasloggedin = true;
        this.msalService.token = token;
        this.accesstoken = this.msalService.token;
        var obj = JSON.parse(user);
        this.username = obj["name"];
    }

    setemailandobjectid(useremail: string, userobjectid: string) {
        this.useremail = useremail;
        this.userobjectid = userobjectid;

    }

    login(callback:any) {
        this.loginFailed = false;
        return this.msalService.login(callback);
        //this.msalService.login()
        //    .then(user => {
        //        if (user) {
        //            this.user = user;
        //            this.hasloggedin=true
        //            this.router.navigate(['/dashboard']);
        //        } else {
        //            this.loginFailed = true;
        //        }
        //    }, () => {
        //        this.loginFailed = true;
        //    });
        
    }

  
  
    logout() {
        this.msalService.logout();
        // Remove auth data and update login status
        //localStorage.removeItem('expires_at');
        this.userProfile = undefined;
        this.accesstoken = undefined;
        this.authenticated = false;
        this.hasloggedin = false;
    }

    get isLoggedIn(): boolean {
        // Check if current date is before token
        // expiration an;d user is signed in locally
        return this.hasloggedin;
    }

}

