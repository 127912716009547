﻿//import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderViewDTO } from '../dto/OrderViewDTO';
import { OrderItemDTO } from '../dto/OrderItemDTO';
import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
    GridMenu,
    GridMenuItem,
    SlickEventHandler,
    SlickEvent,
    SlickEventData,
    AngularSlickgridComponent
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';
import { ResponseBaseDTO } from '../dto/ResponseBaseDTO';
import { OrderService } from './order.service';
import { OrderPayrollService } from '../admin/orderpayroll.service';
import { GlobaldataService } from '../service/globaldata.service';



const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';


@Component({
    templateUrl: 'order.component.html',
    providers: [
        OrderService,
        ListService
    ]
})
export class OrderComponent extends BaseComponent implements OnInit {

    columnDefinitions: Column[] = [];
    angularGrid: AngularGridInstance;
    gridOptions: GridOption = {};
    gridMenu: GridMenu;
    gridMenuItem: GridMenuItem;
    slickEventHandler: SlickEventHandler;
    slickEvent: SlickEvent;
    slickEventData: SlickEventData;
    _addon: any;
    mydataset: any[] = [];



    bIsLoaded: boolean = false;
    canShowGrid: boolean = false;
    metrics: Metrics;
    //companyName: string;
    isCountEnabled = true;
    odataVersion = 2;
    odataQuery = '';
    processing = true;
    totalRowCount: number = 0;
    status = { text: 'processing...', class: 'alert alert-danger' };

    userSelectsString = '';
    name = 'Angular';
    userSelects = [];
    dropdownList = [];
    //    selectedItems = [];
    dropdownSettings = {};
    searchError: boolean = false;
    searchErrorMsg: string = "";

    gridObj: any;

    selectedGridIDS: number[];
    selectedTitles: any[];
    selectedTitle: any;

    useremail: string;
    userobjectid: string;
   // employerid: string = "";



    isordernotselected: boolean = true;

    selectedquoteidx: number = -1;

    orderviewdto: OrderViewDTO;
    orders: OrderViewDTO[] = [];
    orderstatus: number = 0;

    orderIdx: number = -1;

    employeazecustomerlist: SelectList[] = [];


    constructor(
        public orderService: OrderService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService,
        private cd: ChangeDetectorRef,
        protected _router: Router,
        private route: ActivatedRoute,
        public orderPayrollService: OrderPayrollService,
        private globaldataservice: GlobaldataService,
    ) {

        super();

        this.userobjectid = auth.userobjectid;
        this.useremail = auth.useremail;
    }


    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};



        this.angularGrid.dataView.onRowCountChanged.subscribe((e, args) => this.rowCountChanged(e, args));


    }


    get eventHandler(): SlickEventHandler {

        return this.slickEventHandler
    }



    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }




    ngOnInit(): void {



        this.setupOrderGrid();
        let test: string = this.globaldataservice.role;

        this.orderPayrollService.getEmployeazeCustomers(this.userobjectid)
            .subscribe(
                (data) => { this.getEmployeazeCustomersCallBack(data); });


        //this.angularGrid.resizerService.pauseResizer(true);
    }

    getEmployeazeCustomersCallBack(data) {
        let idx: number = 0;
        let newidx: number = 0;
        let locationfound: boolean = false;

        if (data !== undefined && data.totalcount > 0) {

            while (idx < data.totalcount) {
                this.employeazecustomerlist.push(
                    {
                        id: data.accountlist[idx].accountid,
                        name: data.accountlist[idx].accountname
                    }
                )
                idx++;
            }
            if (this.globaldataservice.manage_order_called === 2) {
                if (this.globaldataservice.order_employerid !== "none") {
                    this.getOrdersByEmployerId();
                }
                else if (this.globaldataservice.role === "EXTERNAL")
                    this.getAuthorizedOrders();
                else if (this.globaldataservice.role === "ADMIN")
                    this.getAllOrders();
            }
            else {
                this.globaldataservice.order_employerid = "none";
            }
            this.globaldataservice.manage_order_called = 0;
        }
        else {
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }

    }

    setupOrderGrid() {
        this.columnDefinitions = [

            { id: 'ordernumber', name: 'Order Number', field: 'ordernumber', sortable: true, width: 100, minWidth: 100 },
            { id: 'orderstatustext', filterable: true, name: 'Order Status', field: 'orderstatustext', sortable: true, width: 125, minWidth: 125 },
            { id: 'accountname', filterable: true, name: 'Employer Name', field: 'accountname', sortable: true, width: 225, minWidth: 225 },
            { id: 'firstname', name: 'First Name', field: 'firstname', filterable: true, sortable: true, width: 100, minWidth: 100 },
            { id: 'lastname', filterable: true, name: 'Last Name', field: 'lastname', sortable: true, width: 125, minWidth: 125 },
            { id: 'memberstatus', name: 'Member Status', field: 'memberstatus', filterable: true, sortable: true, width: 100, minWidth: 100 },
            { id: 'terminationdate', name: 'Termination Date', field: 'terminationdate', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },            
            { id: 'effectivedate', name: 'Order Date', field: 'effectivedate', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'amountspent', name: 'Price', field: 'amountspent', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'vendorname', filterable: true, name: 'Vendor Name', field: 'vendorname', sortable: true, width: 200, minWidth: 200 },
            { id: 'category', filterable: true, name: 'Category', field: 'category', sortable: true, width: 125, minWidth: 125 },
            { id: 'productname', filterable: true, name: 'Product Name', field: 'productname', sortable: true, width: 225, minWidth: 225 },
            { id: 'policyholdersname', name: 'Policy Holders Name', field: 'policyholdersname', sortable: true, width: 125, minWidth: 125 },
            { id: 'policynumber', name: 'Policy Number', field: 'policynumber', width: 100, minWidth: 100 },
            { id: 'policyeffectivedate', name: 'Policy Eff Date', field: 'policyeffectivedate', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateUs },
            { id: 'waivecoverage', name: 'Health Ins Waived', field: 'waivecoverage', sortable: true, width: 125, minWidth: 100, formatter: Formatters.checkmark },
            { id: 'modifiedon', name: 'Last Modified', field: 'modifiedon', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'modifiedby', filterable: true, name: 'Modified By', field: 'modifiedby', sortable: true, width: 150, minWidth: 150 },
            { id: 'modifiedreason', filterable: true, name: 'Modified Reason', field: 'modifiedreason', sortable: true, width: 175, minWidth: 175 }, 
            { id: 'phonenumber', name: 'Phone Number', field: 'phonenumber', sortable: true, width: 100, minWidth: 100 },
            { id: 'emailaddress', name: 'Email Address', field: 'emailaddress', sortable: true, width: 100, minWidth: 100 },
            { id: 'address1', name: 'Address 1', field: 'address1', width: 100, minWidth: 100 },
            { id: 'city', name: 'City', field: 'city', sortable: true, width: 100, minWidth: 100 },
            { id: 'stateorprovince', name: 'State', field: 'stateorprovince', sortable: true, width: 100, minWidth: 100 },
            { id: 'postalcode', name: 'Zipcode', field: 'postalcode', sortable: true, width: 100, minWidth: 100 },
            { id: 'id', name: 'Order Item Id', field: 'id', sortable: true, width: 60, minWidth: 60 }

        ];
        this.gridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: true,
            checkboxSelector: {
                // you can toggle these 2 properties to show the "select all" checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true,
            },
            enableCheckboxSelector: true,


            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },
            gridMenu: {
                onCommand: (e, args) => {
                    const dataview = args.grid.getData();
                    this._addon = dataview;
                    this.onCommandEvent(e, args);
                }
            }

        };
        this.gridOptions.showTopPanel = false;
    }

    onCommandEvent(event, args) {
        var menuText = event.target.innerText;

        let ordersinview: OrderViewDTO[];
        let orderitemids: string;
        let dataView: DataView;

        var countofdata = 0;
        countofdata = this.mydataset.length;
        countofdata = this.angularGrid.dataView.getLength();
        dataView = this.angularGrid.dataView;
        var localgridobj = this.gridObj;


        // 22 Feb 2021 JJM This code will mark the visible items in the grid as exported. Next post this information to the API
        if (menuText === "Export to Excel DO NOT RUN") {

            ordersinview = this.angularGrid.dataView.getFilteredItems();
            for (var i = 0; i < ordersinview.length; i++) {
                ordersinview[i].status = 6;
                ordersinview[i].orderstatustext = "Exported";
                this.orders[ordersinview[i].id-1].status = 6;
                this.orders[ordersinview[i].id-1].orderstatustext = 'Exported';
                //this.setOrderStatus(ordersinview[i].id);
            }
            
            this.mydataset = this.orders;

            this.angularGrid.slickGrid.invalidate();

            

        }


        console.log("***** Executed onCommandEvent: " + menuText + "  Count= " + countofdata.toString());

    }

    rowCountChanged(event, args) {


        this.totalRowCount = this.angularGrid.dataView.getLength();
        console.log("***** order: rowcount is " + this.totalRowCount.toString());
        

    }

    setOrderStatus(orderitemid: number) {

        for (var i = 0; i < this.orders.length; i++) {
            if (this.orders[i].id === orderitemid) {
                this.orders[i].status = 6;
                this.orders[i].orderstatustext = "Exported";
                return;
            }   
        }
    }

    getAllAuthorizedOrders() {

        if (this.globaldataservice.role === "EXTERNAL") {
            this.getAuthorizedOrders();
        }
        else {
            this.getAllOrders();
        }

    }

    getAllOrders() {
        this.searchError = false;

            this.orderService.getAllOrders()
                .subscribe(
                    (data) => { this.getOrdersCallBack(data); });

    }

    getAuthorizedOrders() {
        this.searchError = false;
        this.orderService.getAuthorizedOrders(this.auth.userobjectid)
            .subscribe(
                (data) => { this.getOrdersCallBack(data); });
    }

    getOrdersByEmployerId() {

        this.searchError = false;

        this.orderService.getOrdersByEmployer(this.globaldataservice.order_employerid)
            .subscribe(
                (data) => { this.getOrdersCallBack(data); });

    }


    getOrdersCallBack(data) {


        if (data.totalcount > 0) {
            this.searchError = false;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.totalRowCount = data.totalcount;

            this.orders = data.orderitems;
            for (var i = 0; i < data.totalcount; i++) {
                if (this.orders[i].status == 1)
                    this.orders[i].orderstatustext = "Pending";
                else if (this.orders[i].status == 2)
                    this.orders[i].orderstatustext = "Completed";
                else if (this.orders[i].status == 3)
                    this.orders[i].orderstatustext = "Canceled";
                else if (this.orders[i].status == 4)
                    this.orders[i].orderstatustext = "Canceled by Member";
                else if (this.orders[i].status == 5)
                    this.orders[i].orderstatustext = "Expired";
                else if (this.orders[i].status == 6)
                    this.orders[i].orderstatustext = "Exported";
            }

            this.mydataset = this.orders;

        }
        else {
            this.totalRowCount = 0;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = [];
            this.orders = [];
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }

    }


    employerchange(event) {

        let employerid: string = event;

        this.globaldataservice.order_employerid = employerid;
        if (this.globaldataservice.order_employerid !== "none")
            this.getOrdersByEmployerId();


    }

    openOrder() {
        var test;


        this.searchError = false;
        this.searchErrorMsg = "";

        var filteredItems = this.angularGrid.dataView.getFilteredItems();

        this.orderIdx = -1;
        for (var i = 0; i < this.orders.length; i++) {
            if (this.orders[i].id == filteredItems[this.selectedquoteidx].id) {
                this.orderIdx = i;
                break;
            }
        }

        if (this.orderIdx == -1) {
            this.searchError = true;
            this.searchErrorMsg = "Error occurred getting order data. Call your administrator.";
            return;

        }


        this.orderService.getOrderItem(this.orders[this.orderIdx].orderitemid)
            .subscribe(
                (data) => { this.getOrderItemCallBack(data); });

    }

    getOrderItemCallBack(data: any) {

        
        let membername: string = "";
        membername = this.orders[this.orderIdx].firstname + " " + this.orders[this.orderIdx].lastname;

        let orderitem: OrderItemDTO = new OrderItemDTO;

        if (data.success) {
            orderitem = data.data;
            this.globaldataservice.manage_order_called = 1;
            this._router.navigate(['/orderdetail',
                {
                    employername: this.orders[this.orderIdx].accountname,
                    accountid: this.globaldataservice.order_employerid,
                    membername: membername,
                    addingorder: 0,
                    orderdetaildto: JSON.stringify(orderitem)
                }
            ]);
        }
        else {
            this.searchError = true;
            this.searchErrorMsg = data.displaymessage;

        }

    }



    onSelectedRowsChanged(e, args) {
        if (Array.isArray(args.rows)) {
            if (args.rows.length > 0) {
                this.isordernotselected = false;
                this.selectedquoteidx = args.rows.map(idx => {
                    return idx;
                });
            }
            else {
                this.isordernotselected = true;
            }
        }
    }


    orderstatuschange(event) {

        let wrkorderstr: string = event;
        if (wrkorderstr !== "0") {
            this.orderstatus = parseInt(wrkorderstr);

        }
        else
            this.orderstatus = 0;

    }

   


    // NO CUSTOM CODE BEYOND THIS POINT

    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);


    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }


    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.gridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.gridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}






