﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../core/environment.service';
import { ExtendedHttpService } from '../core/extendedhttp.service';
import { HttpHeaders } from '@angular/common/http';
import { Http } from '@angular/http';
import { catchError, map } from 'rxjs/operators';
import { OrderViewDTO } from '../dto/OrderViewDTO';


const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
@Injectable({
    providedIn: 'root',

})
export class OrderService extends ExtendedHttpService {

    constructor(private env: EnvironmentService) {
        super();
    }

    // JJM 20 Feb 2022 Gets all the orders
    getAllOrders() {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Order').
            pipe(
                map((data) => data.json())
            );
    }

    getAllPivotOrders() {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Order/GetAllCompleteOrders').
            pipe(
                map((data) => data.json())
            );
    }

    getAuthorizedOrders(userid: string) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Order/GetAuthorizedOrders/' + userid).
            pipe(
                map((data) => data.json())
            );
    }

    getAuthorizedCompleteOrders(userid: string) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Order/GetAuthorizedCompleteOrders/' + userid).
            pipe(
                map((data) => data.json())
            );
    }



    getOrdersByEmployer(employerid: string) {
        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Order/' + employerid).
            pipe(
                map((data) => data.json())
            );

    }


    getOrdersByMemberId(memberid: string) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Order/' + memberid + '/marker').
            pipe(
                map((data) => data.json())
            );
    }





    postProcessOrderItems(orderitemids: string) {


        return this.postData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Order/', orderitemids).
            pipe(
                map((data) => data.json())
            );


    }

    getOrderItem(orderitemid: string) {
        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Order/GetOrderItem/' + orderitemid).
            pipe(
                map((data) => data.json())
            );
    }


    getOrderItemHistory(orderitemid: string) {
        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Order/GetOrderItemHistory/' + orderitemid).
            pipe(
                map((data) => data.json())
            );
    }


}
