﻿//import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { EmployerContributionDTO } from '../dto/EmployerContributionDTO';
import { EmployeeOnBoardingTrackerDTO } from '../dto/EmployeeOnBoardingTrackerDTO';
import { OnboardService } from './onboard.service';

import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';

const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';



@Component({
    templateUrl: './onboard.component.html',
    providers: [
        OnboardService,
        ListService
    ]
})
export class OnboardComponent extends BaseComponent implements OnInit {

    constructor(private route: ActivatedRoute,
        protected _router: Router,
        public onboardservice: OnboardService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService,
        private cd: ChangeDetectorRef) {
        super();
    }

    columnDefinitions: Column[] = [];
    gridOptions: GridOption = {};
    mydataset: any[] = [];
    bIsLoaded: boolean = false;
    canShowGrid: boolean = false;
    angularGrid: AngularGridInstance;
    //columnDefinitions: Column[];
    // gridOptions: GridOption;
    //dataset = [];
    metrics: Metrics;
    isCountEnabled = true;
    odataVersion = 2;
    odataQuery = '';
    processing = true;
    totalRowCount: number = 0;
    status = { text: 'processing...', class: 'alert alert-danger' };

    userSelectsString = '';
    name = 'Angular';
    userSelects = [];
    dropdownList = [];
    //    selectedItems = [];
    dropdownSettings = {};
    searchError: boolean = false;
    searchErrorMsg: string = "";
    displayMessage: string = "";

    gridObj: any[];
    selectedGridIDS: number[];
    selectedTitles: any[];
    selectedTitle: any;
    selectedplanids: any[];
    selectedplannames: any[];
    selectedissuernames: any[];
    numberofSelectedPlans: number = 0;

// ONBOARD COMPONENT SPECIFIC VARIABLES

    locationList: Contributionlocations[] = [];
    currentLocationStr: string = "";

    familystatusList: Familystatus[] = [
        {
            id: "employeeonly",
            name: "Employee Only"
        },
        {
            id: "employeeandspouse",
            name: "Employee and Spouse"
        },
        {
            id: "employeeandchildren",
            name: "Employee and Children"
        },
        {
            id: "family",
            name: "Family"
        }

    ];

    currentFamilystatusStr: string = "";

    birthdate: Date;
    birthdatestr: string = "";
    age: number = 0;
    contributionpretax: number = 0;
    contributionposttax: number = 0;
    moneymovedtopretax: number = 0;
    fundsremainingposttax: number = 0;

    employercontributionid: string = "";
    externalemployerid: string = "";
    healthinsurancepremium: number = 0;
    selectedcontributionidx: number = -1;
    employercontributionDTO: EmployerContributionDTO = new EmployerContributionDTO();
    healthinsuranceurl: string = "";
    accountname: string = "";
    firstname: string = "";
    lastname: string = "";
    healthurlclicked: boolean = false;
    savedsuccessfully: boolean = false;
    displaysavebutton: boolean = true;

    contributions: any[] = [];



    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};
    }



    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }


    ngOnInit(): void {



        //this.columnDefinitions = [
        //    { id: 'planmarketingname', name: 'Plan Name', field: 'planmarketingname', sortable: true, width: 275, minWidth: 275 },
        //    { id: 'issuername', name: 'Carrier Name', field: 'issuername', sortable: true, width: 275, minWidth: 275 },
        //    { id: 'metallevel', name: 'Metal Level', field: 'metallevel', sortable: true, width: 100, minWidth: 100 },
        //    { id: 'individualrate', name: 'Age 21 Rate', field: 'individualrate', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
        //    { id: 'ishsaeligible', name: 'HSA Eligible', field: 'ishsaeligible', sortable: true, width: 100, minWidth: 100 },
        //    { id: 'annualdeductibleindividual', name: 'Individual Deductible', field: 'annualdeductibleindividual', sortable: true, width: 125, minWidth: 125 },
        //    { id: 'annualdeductiblefamily', name: 'Family Deductible', field: 'annualdeductiblefamily', sortable: true, width: 125, minWidth: 125 },
        //    { id: 'annualoopmaxindividual', name: 'Individual OOP Max', field: 'annualoopmaxindividual', sortable: true, width: 125, minWidth: 125 },
        //    { id: 'annualoopmaxfamily', name: 'Family OOP Max', field: 'annualoopmaxfamily', sortable: true, width: 125, minWidth: 125 },
        //    { id: 'onoffexchange', name: 'On/Off Exchange', field: 'onoffexchange', sortable: true, width: 100, minWidth: 100 },
        //    //{ id: 'planeffectivedate', name: 'Effective Date', field: 'planeffectivedate', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
        //    { id: 'plantype', name: 'Plan Type', field: 'plantype', sortable: true, width: 125, minWidth: 125 },
        //    { id: 'id', name: 'Plan Id', field: 'id', sortable: false, width: 60, minWidth: 60 }
        //];
        //this.gridOptions = {
        //    /// enableAutoResize: true,
        //    //enablePagination: true,

        //    autoResize: {
        //        containerId: 'demo-container',
        //        sidePadding: 15
        //    },
        //    enableCellNavigation: true,
        //    enableAutoResize: true,
        //    enableColumnReorder: false,
        //    forceFitColumns: false,
        //    enableRowSelection: true,
        //    // multiSelect: true,


        //    // Multi-row selection support
        //    enableFiltering: false,
        //    checkboxSelector: {
        //        // you can toggle these 2 properties to show the "select all" checkbox in different location
        //        hideInFilterHeaderRow: false,
        //        hideInColumnTitleRow: true
        //    },
        //    rowSelectionOptions: {
        //        // True (Single Selection), False (Multiple Selections)
        //        selectActiveRow: false
        //    },
        //    enableCheckboxSelector: true,

        //    // Paging Support
        //    pagination: {
        //        pageSizes: [50, 1000],
        //        pageSize: defaultPageSize,
        //        totalItems: 50
        //    },
        //};
        //this.gridOptions.showTopPanel = false;

        //this.angularGrid.resizerService.pauseResizer(true);

        this.getEmployerContributionInfo();


    }


    locationselected(event) {
        let idx = 0;
        this.currentLocationStr = event;

        this.displayMessage = "";

        while (idx < this.contributions.length) {
            if (this.currentLocationStr === this.contributions[idx].location) {
                this.selectedcontributionidx = idx;
                this.employercontributionid = this.contributions[idx].id;
                this.healthinsuranceurl = this.contributions[idx].healthinsuranceurl;
                return;
            }
            idx++;

        }
        this.selectedcontributionidx = -1;
        this.currentLocationStr = "";


    }

    familystatusselected(event) {

        this.displayMessage = "";
        if (event !== "0")
            this.currentFamilystatusStr = event;
        else
            this.currentFamilystatusStr = "";

    }


    birthdateentered() {

        let today: Date = new Date();
        let month: number = -1;
        this.birthdate = new Date(this.birthdatestr);
        this.displayMessage = "";

        if (!isNaN(this.birthdate.getDate())) {

            this.age = today.getFullYear() - this.birthdate.getFullYear();
            month = today.getMonth() - this.birthdate.getMonth();
            if (month < 0 || (month === 0 && today.getDate() < this.birthdate.getDate())) {
                this.age--;
            }
        }
        else
            this.age = 0;
    }

    computepremium() {

        this.displayMessage = "";
        this.moneymovedtopretax = this.healthinsurancepremium - this.contributionpretax;

    }

    healthinsuranceclick() {
        this.healthurlclicked = true;

    }

    authorizepayrolldeduction() {

        this.searchError = false;
        let onboardingDTO: EmployeeOnBoardingTrackerDTO = new EmployeeOnBoardingTrackerDTO();
        onboardingDTO.employercontributionid = this.employercontributionid;
        onboardingDTO.familystatus = this.currentFamilystatusStr;
        onboardingDTO.employercontributionpretaxbucket = this.contributionpretax;
        onboardingDTO.healthinsurancepremium = this.healthinsurancepremium;
        onboardingDTO.birthdate = this.birthdate;
        onboardingDTO.location = this.currentLocationStr;
        onboardingDTO.moneyfromposttaxtopretax = this.moneymovedtopretax;
        onboardingDTO.firstname = this.firstname;
        onboardingDTO.lastname = this.lastname;
        onboardingDTO.createdby = this.auth.useremail;
        onboardingDTO.modifiedby = this.auth.useremail;
        onboardingDTO.createdon = new Date();
        onboardingDTO.modifiedon = new Date();
        onboardingDTO.processedon = new Date();
        
        this.onboardservice.postEmployeeSpending(onboardingDTO)
            .subscribe(
                (data) => { this.postEmployeeSpendingCallBack(data) }
            );
    }

    postEmployeeSpendingCallBack(data) {

        if (data.success) {
            this.displayMessage = "Congratulations! You have completed the sign-up process. Have a great day!";
            this.savedsuccessfully = true;
            this.displaysavebutton = false;
        }
        else {
            this.searchErrorMsg = data.displaymessage;
            this.searchError = true;
        }
    }


    getcontributiondetail() {

        this.searchError = false;
        this.onboardservice.getContributiondetail(this.employercontributionid, this.age)
            .subscribe(
                (data) => { this.getcontributiondetailCallBack(data); });

    }


    getcontributiondetailCallBack(data) {

        let idx: number = 0;
        let newidx: number = 0;

        if (data.totalcount > 0) {
            this.totalRowCount = data.totalcount;
            if (this.currentFamilystatusStr == 'employeeonly')
                this.contributionpretax = data.employercontributiondetaillist[0].employeeamt;
            else if (this.currentFamilystatusStr == 'employeeandspouse')
                this.contributionpretax = data.employercontributiondetaillist[0].employeespouseamt;
            else if (this.currentFamilystatusStr == 'employeeandchildren')
                this.contributionpretax = data.employercontributiondetaillist[0].employeechildrenamt;
            else if (this.currentFamilystatusStr == 'family')
                this.contributionpretax = data.employercontributiondetaillist[0].familyamt;
        }
        else {
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }
    }



    getEmployerContributionInfo() {

        this.searchError = false;
        this.onboardservice.getEmployerContributionInfo("Woodstock Grapes")
            .subscribe(
                (data) => { this.getEmployerContributionInfoCallBack(data); });
    }



    getEmployerContributionInfoCallBack(data) {
        
        let idx: number = 0;
        let newidx: number = 0;

        if (data.totalcount > 0) {

            //this.gridOptions.pagination.totalItems = data.totalcount;
            this.totalRowCount = data.totalcount;
            this.accountname = data.employercontributionlist[0].accountname;
            this.contributions = data.employercontributionlist;
            while (idx < this.totalRowCount) {
                this.locationList.push(
                    {
                        id: data.employercontributionlist[idx].location,
                        name: data.employercontributionlist[idx].location
                    }

                )
                idx++;
            }
            //this.gridOptions = Object.assign({}, this.gridOptions);
            //this.mydataset = data.acaUniquePlans;
        }
        else {
            //this.totalRowCount = data.totalcount;
            //this.gridOptions = Object.assign({}, this.gridOptions);
            //this.mydataset = [];
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }
    }




    /** Dispatched event of a Grid State Changed event */
    gridStateChanged(gridStateChanges: GridStateChange) {
        console.log('Client sample, Grid State changed:: ', gridStateChanges);
        let test: any;
        let a: number;

        // test = gridStateChanges;
        a = 0;

    }

    /** Dispatched event of a Grid State Changed event */
    //grid1StateChanged(gridStateChanges: GridStateChange) {
    //    console.log('Grid State changed:: ', gridStateChanges);
    //    console.log('Grid State changed:: ', gridStateChanges.change);
    //}


    //onSelectedRowsChanged(e, args) {
    //    if (Array.isArray(args.rows)) {

    //        this.isgetratesdisabled = false;
    //        this.iscompareplandisabled = false;

    //        this.searchError = false;
    //        this.numberofSelectedPlans = args.rows.length;
    //        if (this.numberofSelectedPlans > 4) {
    //            this.searchError = true;
    //            this.searchErrorMsg = "You can only select up to 4 plans.";
    //            this.isgetratesdisabled = true;
    //            this.iscompareplandisabled = true;
    //            return;
    //        }
    //        else if (this.numberofSelectedPlans == 0) {
    //            this.isgetratesdisabled = true;
    //            this.iscompareplandisabled = true;
    //            return;
    //        }
    //        else if (this.numberofSelectedPlans > 3) {
    //            this.iscompareplandisabled = true;
    //        }

    //        this.selectedplanids = args.rows.map(idx => {
    //            const item = this.angularGrid.gridService.getDataItemByRowIndex(idx);
    //            return item.id || '';
    //        });
    //        this.selectedplannames = args.rows.map(idx => {
    //            const item = this.angularGrid.gridService.getDataItemByRowIndex(idx);
    //            return item.planmarketingname + "|" || '';
    //        });
    //        this.selectedissuernames = args.rows.map(idx => {
    //            const item = this.angularGrid.gridService.getDataItemByRowIndex(idx);
    //            return item.issuername || '';
    //        });
    //    }
    //}





    // THE FOLLOWING METHODS ARE NOT CUSTOM

    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);


    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }




    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.gridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.gridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}






