﻿ 
export class MemberDTO {
 
        public id: string;
        public accountid: string;
        public priormemberid: string;
        public externalid: string;
        public firstname: string;
        public lastname: string;
        public address1: string;
        public address2: string;
        public city: string;
        public stateorprovince: string;
        public postalcode: string;
        public emailaddress: string;
        public personalemailaddress: string;
        public birthdate: Date;
        public hiredate: Date;
        public phonenumber: string;
        public origination: number;
        public createdon: Date;
        public createdby: string;
        public modifiedon: Date;
        public modifiedby: string;
        public relationship: number;
        public primarymemberid: string;
        public istobaccouser: string;
        public isdisabled: string;
    public gender: string;
    public status: string;
    public terminationdate: Date;
    public additionalid: string;
    public division: string;
    }
