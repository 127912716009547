﻿import { Component, OnInit } from '@angular/core';
import { CompanyService } from './company.service';


import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
//import { AllContractsService } from './allcontracts.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';

const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';



@Component({
    templateUrl: './company.component.html',
    providers: [
        CompanyService
    ]
})
export class CompanyComponent extends BaseComponent implements OnInit {

    constructor(public companyservice: CompanyService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService) {
        super();
    }

  //  stateList: States[];
    columnDefinitions: Column[] = [];
    gridOptions: GridOption = {};
    companydataset: any[] = [];
    stateList: States[];
    bIsLoaded: boolean = false;
    canShowGrid: boolean = false;
    angularGrid: AngularGridInstance;
    metrics: Metrics;
    totalRowCount: number = 0;
    statesearch: string = "0";
    companysearch: string = "";
    naicscodesearch: string = "";
    totalparticipationminsearch: number = 0;
    totalparticipationmaxsearch: number = 1000;

    isCountEnabled = true;
    odataVersion = 2;
    odataQuery = '';
    processing = true;
    status = { text: 'processing...', class: 'alert alert-danger' };


    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
    }

    //statechange(event) {
    //    var tg = event;
    //}

    ngOnInit(): void {

        this.columnDefinitions = [

           // { id: 'erisaack_id', name: 'ID', field: 'erisaack_id' },
            { id: 'companyname', name: 'Company Name', field: 'companyname', sortable: true, width: 200 },
            { id: 'totalparticipation_boy_cnt', name: 'Participants', field: 'totalparticipation_boy_cnt', sortable: true, width: 75 },
            { id: 'cityname', name: 'City Name', field: 'cityname', sortable: true, width: 125 },
            { id: 'state', name: 'State', field: 'state', sortable: true, width: 40},
            { id: 'phonenumber', name: 'Phone No', field: 'phonenumber', width: 100, formatter: Formatters.mask, params: { mask: '(000) 000-0000' }},
            { id: 'cbsaname', name: 'CBSA Name', field: 'cbsaname', sortable: true, width: 200 },
            { id: 'naics_code', name: 'NAICS Code', field: 'naics_code', width: 75 },
            { id: 'naics_description', name: 'NAICS Desc', field: 'naics_description', width: 150 },
            { id: 'filingreceiveddate', name: 'Filing Date', field: 'filingreceiveddate', width: 100, formatter: Formatters.dateIso },
            { id: 'address1', name: 'Address', field: 'address1', width: 150 },
            { id: 'countyname', name: 'County', field: 'countyname', width: 100 },
            { id: 'sponsor_signer', name: 'Sponsor', field: 'sponsor_signer', width: 125 },
            { id: 'companyein', name: 'EIN', field: 'companyein', width: 75}

        ];
        this.gridOptions = {
            enableAutoResize: true,
            enablePagination: true,
            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableFiltering: true,
            // enableCheckboxSelector: true,
            enableRowSelection: true,
            forceFitColumns: true,
            pagination: {
                pageSizes: [1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },

            //backendServiceApi: {
            //    service: new GridOdataService(),
            //    options: {
            //        executeProcessCommandOnInit: false,
            //    },
            //    preProcess: () => this.displaySpinner(true),
            //    process: (query) => this.getCustomerApiCall(query),
            //    postProcess: (response) => {
            //        this.metrics = response.metrics;
            //        this.displaySpinner(false);
            //        this.getCustomerCallback(response);
            //    }
            //}
        };


        this.gridOptions.showTopPanel = false;

        this.listservice.getLists("states").subscribe(data => {
            this.getlistsResponse(data);
        });


    }


    statechange(event) {
        this.statesearch = event;
    }

    getlistsResponse(data) {
        //this.stateList = data;
        this.stateList = data["states"];
       // this.brokerstateList = data["states"];
    }

    search() {
        this.canShowGrid = true;
        this.getCustomerApiCall(null);
        //this.spinner.show();
        //this.angularGrid.pluginService.refreshBackendDataset();
    }

    displaySpinner(isProcessing) {
        this.processing = isProcessing;
        this.status = (isProcessing)
            ? { text: 'processing...', class: 'alert alert-danger' }
            : { text: 'done', class: 'alert alert-success' };
    }



    getCustomerCallback(data) {

        this.gridOptions.pagination.totalItems = data.totalcount;
        this.totalRowCount = data.totalcount;
        this.gridOptions = Object.assign({}, this.gridOptions);
        this.companydataset = data.companylist;
        //this.spinner.hide();
    }

    getCustomerApiCall(query) {
        // in your case, you will call your WebAPI function (wich needs to return a Promise)
        // for the demo purpose, we will call a mock WebAPI function
        // return this.company.service.getData();
        this.companyservice.getcompanies(1, 1000, this.statesearch, this.companysearch,
            this.naicscodesearch, this.totalparticipationminsearch,
            this.totalparticipationmaxsearch)
            .subscribe(
                (data) => { this.getCustomerCallback(data); });;
    }

    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);
    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }



    /** Dispatched event of a Grid State Changed event */
    gridStateChanged(gridStateChanges: GridStateChange) {
        console.log('Client sample, Grid State changed:: ', gridStateChanges);
    }

    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.gridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.gridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}





