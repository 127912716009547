﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../core/environment.service';
import { ExtendedHttpService } from '../core/extendedhttp.service';
import { HttpHeaders } from '@angular/common/http';
import { Http, RequestOptions } from '@angular/http';
import { catchError, map } from 'rxjs/operators';
import { ACAUniquePlanDTO } from '../dto/ACAUniquePlanDTO';
import { MediaDTO } from '../dto/MediaDTO';



const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
@Injectable({
    providedIn: 'root',

})
export class MediaManagementService extends ExtendedHttpService {

    constructor(private env: EnvironmentService) {
        super();
    }

    getmedia(accountid: string, mediatype: string) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Media/GetCloudMediaLocations/' + accountid + '/' + mediatype).
                pipe(
                    map((data) => data.json())
                );

    }


    addmedia(media: any) {
        //let headers = new Headers();
      
        //headers.append('Accept', 'application/json');

        //let options = new RequestOptions({ headers: headers });

        //return this._http.post("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Logo/', logo, options).
        //    pipe(
        //        map((data) => data.json())
        //    );

        return this.postData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Media/', media,null,true).
            pipe(
                map((data) => data.json())
            );
    }

    updatemedia(mediaDTO: MediaDTO) {

        return this.putData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Media/', mediaDTO).
            pipe(
                map((data) => data.json())
            );
    }
    



}
