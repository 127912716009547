﻿//import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderPayrollViewDTO } from '../dto/OrderPayrollViewDTO';
import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
    GridMenu,
    GridMenuItem,
    SlickEventHandler,
    SlickEvent,
    SlickEventData,
    AngularSlickgridComponent
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';
import { ResponseBaseDTO } from '../dto/ResponseBaseDTO';
import { OrderPayrollService } from './orderpayroll.service';



const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';


@Component({
    templateUrl: 'orderpayroll.component.html',
    providers: [
        OrderPayrollService,
        ListService
    ]
})
export class OrderPayrollComponent extends BaseComponent implements OnInit {

    columnDefinitions: Column[] = [];
    angularGrid: AngularGridInstance;
    gridOptions: GridOption = {};
    gridMenu: GridMenu;
    gridMenuItem: GridMenuItem;
    slickEventHandler: SlickEventHandler;
    slickEvent: SlickEvent;
    slickEventData: SlickEventData;
    _addon: any;
    mydataset: any[] = [];



    bIsLoaded: boolean = false;
    canShowGrid: boolean = false;
    metrics: Metrics;
    //companyName: string;
    isCountEnabled = true;
    odataVersion = 2;
    odataQuery = '';
    processing = true;
    totalRowCount: number = 0;
    status = { text: 'processing...', class: 'alert alert-danger' };

    userSelectsString = '';
    name = 'Angular';
    userSelects = [];
    dropdownList = [];
    //    selectedItems = [];
    dropdownSettings = {};
    searchError: boolean = false;
    searchErrorMsg: string = "";

    gridObj: any;

    selectedGridIDS: number[];
    selectedTitles: any[];
    selectedTitle: any;

    useremail: string;
    userobjectid: string;

    startdate: Date;
    startdatestr: string;
    enddate: Date;
    enddatestr: string;


    employeazecustomerlist: SelectList[] = [];

    isordernotselected: boolean = true;

    selectedquoteidx: number = -1;

    orderviewdto: OrderPayrollViewDTO;
    orders: OrderPayrollViewDTO[] = [];
    employerid: string = "none";


    constructor(
        public orderService: OrderPayrollService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService,
        private cd: ChangeDetectorRef,
        protected _router: Router,
        private route: ActivatedRoute) {

        super();

        this.userobjectid = auth.userobjectid;
        this.useremail = auth.useremail;
    }


    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};



        this.angularGrid.dataView.onRowCountChanged.subscribe((e, args) => this.rowCountChanged(e, args));


    }


    get eventHandler(): SlickEventHandler {

        return this.slickEventHandler
    }



    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }




    ngOnInit(): void {



        this.columnDefinitions = [
            { id: 'id', name: 'Order Item Id', field: 'id', sortable: false, width: 60, minWidth: 60 },
            { id: 'memberstatus', name: 'Status', field: 'memberstatus', filterable: true, sortable: true, width: 100, minWidth: 100 },
            { id: 'terminationdate', name: 'Termination Date', field: 'terminationdate', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'accountname', name: 'Employer Name', field: 'accountname', sortable: true, width: 225, minWidth: 225 },
            { id: 'firstname', name: 'First Name', field: 'firstname', filterable: true, sortable: true, width: 100, minWidth: 100 },
            { id: 'lastname', filterable: true, name: 'Last Name', field: 'lastname', sortable: true, width: 125, minWidth: 125 },
            { id: 'additionalid', filterable: true, name: 'SSN', field: 'additionalid', sortable: true, width: 125, minWidth: 125 },
            { id: 'waivecoverage', name: 'Health Ins Waived', field: 'waivecoverage', filterable: true, sortable: true, width: 125, minWidth: 100, formatter: Formatters.checkmark },
            { id: 'policyeffectivedate', name: 'Policy Eff Date', field: 'policyeffectivedate', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'effectivedate', name: 'Order Date', field: 'effectivedate', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'pretax_amountspent', name: 'Pre-Tax Amt', field: 'pretax_amountspent', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'posttax_amountspent', name: 'Post-Tax Amt', field: 'posttax_amountspent', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'employercontribution', name: 'Employer Contribution', field: 'employercontribution', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'payrolldeduction', name: 'Payroll Deduction', field: 'payrolldeduction', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },  
            { id: 'currentbalance', name: 'Current Balance', field: 'currentbalance', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },             
            { id: 'emailaddress', name: 'Email Address', field: 'emailaddress', sortable: true, width: 100, minWidth: 100 },
            { id: 'waivecoveragereason', name: 'Waive Reason', field: 'waivecoveragereason', sortable: true, width: 125, minWidth: 125 },
            { id: 'orderid', name: 'Order Id', field: 'orderid', sortable: true, width: 100, minWidth: 100 }

        ];
        this.gridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: true,
            checkboxSelector: {
                // you can toggle these 2 properties to show the "select all" checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true,
            },
            enableCheckboxSelector: true,


            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },
            gridMenu: {
                onCommand: (e, args) => {
                    const dataview = args.grid.getData();
                    this._addon = dataview;
                    this.onCommandEvent(e, args);
                }
            }

        };
        this.gridOptions.showTopPanel = false;

        this.orderService.getEmployeazeCustomers(this.userobjectid)
            .subscribe(
                (data) => { this.getEmployeazeCustomersCallBack(data); });


        //this.angularGrid.resizerService.pauseResizer(true);
    }


    getEmployeazeCustomersCallBack(data) {
        let idx: number = 0;
        let newidx: number = 0;
        let locationfound: boolean = false;
        console.log("Process in getStateHealthPlanSitesCallback data.TotalCount is: " + data.totalcount);

        if (data !== undefined && data.totalcount > 0) {

            while (idx < data.totalcount) {
                this.employeazecustomerlist.push(
                    {
                        id: data.accountlist[idx].accountid,
                        name: data.accountlist[idx].accountname
                    }
                )
                idx++;
            }
        }
        else {
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }

    }



    onCommandEvent(event, args) {
        //var menuText = event.target.innerText;

        //let ordersinview: OrderPayrollViewDTO[];
        //let orderitemids: string;
        //let dataView: DataView;

        //var countofdata = 0;
        //countofdata = this.mydataset.length;
        //countofdata = this.angularGrid.dataView.getLength();
        //dataView = this.angularGrid.dataView;
        //var localgridobj = this.gridObj;


        //// 22 Feb 2021 JJM This code will mark the visible items in the grid as exported. Next post this information to the API
        //if (menuText === "Export to Excel") {

        //    ordersinview = this.angularGrid.dataView.getFilteredItems();
        //    for (var i = 0; i < ordersinview.length; i++) {
        //        ordersinview[i].status = 6;
        //        ordersinview[i].orderstatustext = "Exported";
        //        this.orders[ordersinview[i].id - 1].status = 6;
        //        this.orders[ordersinview[i].id - 1].orderstatustext = 'Exported';
        //    }

        //    this.mydataset = this.orders;
        //    this.angularGrid.slickGrid.invalidate();
        //}


        //console.log("***** Executed onCommandEvent: " + menuText + "  Count= " + countofdata.toString());

    }

    rowCountChanged(event, args) {

        console.log("***** rowCountChanged ZZZZZZ");
        this.totalRowCount = this.angularGrid.dataView.getLength();


    }

    setOrderStatus(orderitemid: number) {

        //for (var i = 0; i < this.orders.length; i++) {
        //    if (this.orders[i].id === orderitemid) {
        //        this.orders[i].status = 6;
        //        this.orders[i].orderstatustext = "Exported";
        //        return;
        //    }
        //}
    }

    getOrders() {

        this.searchError = false;
        this.searchErrorMsg = "";
        //this.startdate = new Date();
        //this.startdatestr = (this.startdate.getMonth() + 2).toString() + "/01/" + this.startdate.getFullYear().toString();
        this.startdate = new Date("01/01/2021");
        this.enddate = new Date("12/31/2030");
        if (this.employerid !== "none") {
            this.orderService.getOrderPayrollByEmployerID(this.employerid, this.startdate, this.enddate)
                .subscribe(
                    (data) => { this.getOrdersCallBack(data); });
        }
        else {
            this.employerid = "none";
            this.searchError = true;
            this.searchErrorMsg = "Pick an employer from the list."
        }

    }


    getOrdersCallBack(data) {


        if (data.totalcount > 0) {
            this.searchError = false;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.totalRowCount = data.totalcount;

            this.orders = data.orderpayrolldto;
            //for (var i = 0; i < data.totalcount; i++) {
            //    if (this.orders[i].status == 1)
            //        this.orders[i].orderstatustext = "Pending";
            //    else if (this.orders[i].status == 2)
            //        this.orders[i].orderstatustext = "Completed";
            //    else if (this.orders[i].status == 3)
            //        this.orders[i].orderstatustext = "Canceled by Vendor";
            //    else if (this.orders[i].status == 4)
            //        this.orders[i].orderstatustext = "Canceled by Member";
            //    else if (this.orders[i].status == 5)
            //        this.orders[i].orderstatustext = "Expired";
            //    else if (this.orders[i].status == 6)
            //        this.orders[i].orderstatustext = "Exported";
            //}

            this.mydataset = this.orders;

        }
        else {
            this.totalRowCount = 0;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = [];
            this.orders = [];
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }

    }


    //vieworderdetails() {

    //    var filteredItems = this.angularGrid.dataView.getFilteredItems();

    //    this._router.navigate(['/accountdetail',
    //        {
    //            addingaccount: 0,
    //            acctaddrdto: JSON.stringify(filteredItems[this.selectedquoteidx])
    //        }
    //    ]);

    //}

    addaccount() {

        this._router.navigate(['/accountdetail',
            {
                addingaccount: 1
            }
        ]);
    }



    onSelectedRowsChanged(e, args) {
        if (Array.isArray(args.rows)) {
            if (args.rows.length > 0) {
                this.isordernotselected = false;
                this.selectedquoteidx = args.rows.map(idx => {
                    return idx;
                });
            }
            else {
                this.isordernotselected = true;
            }
        }
    }


    employerchange(event) {

        let employerid: string = event;

        this.employerid = employerid;

        this.getOrders();


    }




    // NO CUSTOM CODE BEYOND THIS POINT

    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);


    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }


    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.gridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.gridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}






