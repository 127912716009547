﻿//import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EBUserDTO } from '../dto/EBUserDTO';
import { UserService } from './user.service';
import { GlobaldataService } from '../service/globaldata.service';
//import * as moment from 'moment-mini';


import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';
import { ResponseBaseDTO } from '../dto/ResponseBaseDTO';

const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';
const EMPLOYER = 1;



@Component({
    templateUrl: 'user.component.html',
})
export class UserComponent extends BaseComponent implements OnInit {

    columnDefinitions: Column[] = [];
    gridOptions: GridOption = {};
    mydataset: any[] = [];
    bIsLoaded: boolean = false;
    canShowGrid: boolean = false;
    angularGrid: AngularGridInstance;
    metrics: Metrics;
    //companyName: string;
    isCountEnabled = true;
    odataVersion = 2;
    odataQuery = '';
    processing = true;
    totalRowCount: number = 0;
    status = { text: 'processing...', class: 'alert alert-danger' };

    userSelectsString = '';
    name = 'Angular';
    userSelects = [];
    dropdownList = [];
    //    selectedItems = [];
    dropdownSettings = {};
    searchError: boolean = false;
    searchErrorMsg: string = "";

    gridObj: any[];
    selectedGridIDS: number[];
    selectedTitles: any[];
    selectedTitle: any;

    useremail: string;
    userobjectid: string;
    isusernotselected: boolean = true;
    showAllCustomers: boolean = false;

    selectedquoteidx: number = -1;

    employeedesc: string = "Members";


    constructor(
        public userservice: UserService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService,
        private cd: ChangeDetectorRef,
        protected _router: Router,
        private route: ActivatedRoute,
        private globalDataService: GlobaldataService) {

        super();

        this.userobjectid = auth.userobjectid;
        this.useremail = auth.useremail;
    }


    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};
    }



    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }


    ngOnInit(): void {



        this.columnDefinitions = [
            { id: 'lastname', filterable: true, name: 'Last Name', field: 'lastname', sortable: true, width: 225, minWidth: 225 },
            { id: 'firstname', name: 'First Name', field: 'firstname', sortable: true, width: 175, minWidth: 175 },
            { id: 'role', filterable: true, name: 'Role', field: 'role', sortable: true, width: 150, minWidth: 150 },
            { id: 'teamname', filterable: true, name: 'Team Name', field: 'teamname', sortable: true, width: 200, minWidth: 200 },
            { id: 'teamstatus', filterable: true, name: 'Team Status', field: 'teamstatus', sortable: true, width: 125, minWidth: 125 },
            { id: 'emailaddress', name: 'Email Address', field: 'emailaddress', sortable: true, width: 200, minWidth: 200 },
            { id: 'displayname', filterable: true, name: 'Display Name', field: 'displayname', sortable: true, width: 200, minWidth: 200 },
            { id: 'phonenumber', filterable: true, name: 'Phone Number', field: 'phonenumber', sortable: true, width: 125, minWidth: 125 },
            { id: 'primarycontact', filterable: true, name: 'Primary Contact', field: 'primarycontact', sortable: true, width: 100, minWidth: 100 },
            { id: 'modifiedon', name: 'Modified Date', field: 'modifieddon', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'modifiedby', filterable: true, name: 'Modified By', field: 'modifiedby', sortable: true, width: 200, minWidth: 200 },
            { id: 'createdon', name: 'Create Date', field: 'createdon', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'createdby', filterable: true, name: 'Created By', field: 'createdby', sortable: true, width: 200, minWidth: 200 },
            { id: 'id', name: 'Id', field: 'id', sortable: false, width: 225, minWidth: 225 }
        ];
        this.gridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: true,
            checkboxSelector: {
                // you can toggle these 2 properties to show the "select all" checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true,
            },
            enableCheckboxSelector: true,

            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },
        };
        this.gridOptions.showTopPanel = false;
        this.getUsers();

        //this.angularGrid.resizerService.pauseResizer(true);
    }

    getUsers() {


        this.userservice.getUsers()
            .subscribe(
                (data) => { this.getUsersCallBack(data); });

    }

    openuser() {

        var filteredItems = this.angularGrid.dataView.getFilteredItems();

        this._router.navigate(['/userdetail',
            {
                addinguser: 0,
                ebuserdto: JSON.stringify(filteredItems[this.selectedquoteidx])
            }
        ]);

    }

    adduser() {


        this._router.navigate(['/userdetail',
            {
                addinguser: 1
            }
        ]);

    }



    getUsersCallBack(data) {


        if (data.totalcount > 0) {
            this.searchError = false;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.totalRowCount = data.totalcount;

            this.mydataset = data.ebuserdtolist;
        }
        else {
            this.totalRowCount = 0;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = [];
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }

    }


    onSelectedRowsChanged(e, args) {
        if (Array.isArray(args.rows)) {
            if (args.rows.length > 0) {
                this.isusernotselected = false;
                this.selectedquoteidx = args.rows.map(idx => {
                    return idx;
                });
            }
            else {
                this.isusernotselected = true;
            }
        }
    }

    ShowAllEmployers(e) {
        if (e.target.checked) {
            this.showAllCustomers = true;
        }
        else {
            this.showAllCustomers = false;
        }
        this.getUsers();

    }


    // NO CUSTOM CODE BEYOND THIS POINT

    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);


    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }


    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.gridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.gridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}






