﻿
//Auto Generated file

  
export class ICContributionStrategyDTO {
		// This file is generated from the entities located in the MAKTech.Entities project
 
        public id: string;
        public accountid: string;
        public ownerid: string;
        public accountname: string;
        public city: string;
        public state: string;
        public postalcode: string;
        public ratingarea: string;
        public currentcontribution: number;
        public modelcost: number;
        public percentofrate: number;
        public effectivedate: Date;
        public unitsbyage: string;
        public totalmembers: number;
        public avg5year: number;
        public avgeveryyear: number;
        public avgeveryyearflat: number;
        public avgpercentofplan: number;
        public plan1id: string;
        public plan2id: string;
        public plan3id: string;
        public plan4id: string;
        public createdon: Date;
        public createdby: string;
        public modifiedon: Date;
        public modifiedby: string;
        public plan1name: string;
        public plan2name: string;
        public plan3name: string;
        public plan4name: string;
        public issuer1name: string;
        public issuer2name: string;
        public issuer3name: string;
        public issuer4name: string;
    }
