// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment2 = {
    production: false,
    APIEndpoint: 'https://dev.endpoint.com',
    audience: 'https://dev-j2-47ge3.auth0.com/api/v2/',
    redirectUri: 'http://localhost:9220/callback',
    // clientID: '8YfnISMKx8rAkZtMJBNrbd6tAx0MWH7a',
    clientID: '0395727a-fd55-4e5d-a43e-76964ed0ad43',
    domain: 'dev-j2-47ge3.auth0.com',
   apiEndpoint: 'https://localhost:44384/', //   JJM - TEMP USED TO ACCESS API running locally: 
  //  JJM - TEMP USED TO ACCESS nfor1 API located in Azure: apiEndpoint: 'https://n4onehrapi.azurewebsites.net/',
  //   apiEndpoint: 'https://quantabenefits.azurewebsites.net/',
    /* B2C related stuff */
    b2cRedirectUri: 'http://localhost:9220/signin-oidc',
    tenant: 'quantabenefits.onmicrosoft.com',
    // b2cclientID: '41fc9e7e-4464-4b1d-a2d6-7c75eeaab2e4',
    b2cclientID: '0395727a-fd55-4e5d-a43e-76964ed0ad43',
    signUpSignInPolicy: 'B2C_1_Quanta_SignIn',

};


export const environment = {
    production: false,
    // APIEndpoint: 'https://dev.endpoint.com',
    // audience: 'https://dev-j2-47ge3.auth0.com/api/v2/',
    // redirectUri: 'http://localhost:9220/callback',
    // clientID: '8YfnISMKx8rAkZtMJBNrbd6tAx0MWH7a',
    // clientID: '0124d8f4-fd18-4b8a-bfef-e6fd5b185541',
    // domain: 'dev-j2-47ge3.auth0.com',
//    apiEndpoint: 'https://localhost:44384/', //   JJM - TEMP USED TO ACCESS API running locally: 
  //  JJM - TEMP USED TO ACCESS nfor1 API located in Azure: apiEndpoint: 'https://n4onehrapi.azurewebsites.net/',
  //   apiEndpoint: 'https://quantabenefits.azurewebsites.net/',
    /* B2C related stuff */
    b2cRedirectUri: 'http://localhost:9220/signin-oidc',
    // tenant: 'enrichlyhr2.onmicrosoft.com',
    tenant: 'b23fb56c-50f0-4c0a-ae3b-4e69677b0a0f',
    b2cclientID: 'cddbf387-56cd-4b0a-b84e-4fbde6f3a4c6',
    // b2cclientID: '0395727a-fd55-4e5d-a43e-76964ed0ad43',
    signUpSignInPolicy: 'B2C_1_SignIn',

};
/*

export const environment = {
    production: false,
    APIEndpoint: 'https://dev.endpoint.com',
    audience: 'https://dev-j2-47ge3.auth0.com/api/v2/',
    redirectUri: 'http://localhost:9220/callback',
    clientID: '8YfnISMKx8rAkZtMJBNrbd6tAx0MWH7a',
    domain: 'dev-j2-47ge3.auth0.com',
    // apiEndpoint: 'https://localhost:44384/', //   JJM - TEMP USED TO ACCESS API running locally: 
    //  JJM - TEMP USED TO ACCESS nfor1 API located in Azure: apiEndpoint: 'https://n4onehrapi.azurewebsites.net/',
    apiEndpoint: 'https://gowellbenefitshrapitest.azurewebsites.net/',

    b2cRedirectUri: 'http://localhost:9220/redirect.html',
    tenant: 'ebtoolkit.onmicrosoft.com',
    b2cclientID: '41fc9e7e-4464-4b1d-a2d6-7c75eeaab2e4',
    signUpSignInPolicy: 'B2C_1_Signinnew',

};
*/