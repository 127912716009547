﻿
export class AccountAndAddressDTO {

        public id: string;
        public accountid: string;
        public accountname: string;
        public accounttype: number;
        public primaryAccountid: string;
        public status: number;
        public mainaddressid: string;
        public mainphonenumber: string;
        public ownertenantid: string;
        public createdon: Date;
        public createdby: string;
        public modifiedon: Date;
        public modifiedby: string;
        public webURL: string;
        public numberofemployees: number;
        public brokername: string;
        public renewaldate: Date;
        public addressid: string;
        public address1: string;
        public address2: string;
        public address3: string;
        public country: string;
        public stateorprovince: string;
        public city: string;
        public county: string;
        public postalcode: string;
        public addresstype: number;
        public addressnumber: number;
        public latitude: number;
        public longitude: number;
        public parentid: string;
        public parenttype: number;
        public displayname: string;
        public employeazecustomer: number;
        public externalid: string;
    public emailaddress: string;
    public onoffexchange: number;
    public hsaonly: number;
    public employeazestatus: number;    
    public waivecount: number;
    public fein: string;
    public teamname: string;
    public teamid: string;
    public tpapepm: number;
    public companypepm: number;
    public companyinitialfee: number;
    }
