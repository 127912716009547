﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../core/environment.service';
import { ExtendedHttpService } from '../core/extendedhttp.service';
import { HttpHeaders } from '@angular/common/http';
import { Http } from '@angular/http';
import { catchError, map } from 'rxjs/operators';
import { OrderPayrollViewDTO } from '../dto/OrderPayrollViewDTO';


const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
@Injectable({
    providedIn: 'root',

})
export class OrderPayrollService extends ExtendedHttpService {

    constructor(private env: EnvironmentService) {
        super();
    }


    getOrderPayrollByEmployerID(employerid: string, startdate: Date, enddate: Date) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Order/' + employerid + '/' + startdate +  '/' + enddate).
            pipe(
                map((data) => data.json())
            );
    }


    getEnrollmentsForEmployer(ownerid: string, employerid: string, startdate: string, enddate: string, requesttype: string) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Order/' + ownerid + '/'  + employerid + '/' + startdate + '/' + enddate + '/' + requesttype).
            pipe(
                map((data) => data.json())
            );
    }

    getEmployeazeCustomers(ownerid: string) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Account/' + ownerid + '/1/1').
            pipe(
                map((data) => data.json())
            );
    }

    getOrdersHSAByEmployer(employerid: string, startdate: string, enddate: string) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Order/' + employerid + '/' + startdate + '/' + enddate + '/hsa').
            pipe(
                map((data) => data.json())
            );
    }


    //postProcessOrderItems(orderitemids: string) {


    //    return this.postData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Order/', orderitemids).
    //        pipe(
    //            map((data) => data.json())
    //        );


    //}


}
