﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../core/environment.service';
import { ExtendedHttpService } from '../core/extendedhttp.service';
import { HttpHeaders } from '@angular/common/http';
import { Http } from '@angular/http';
import { catchError, map } from 'rxjs/operators';
import { ACAUniquePlanDTO } from '../dto/ACAUniquePlanDTO';
import { IndPlans2021DTO } from '../dto/IndPlans2021DTO';



const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
@Injectable({
    providedIn: 'root',

})
export class AdminProductsService extends ExtendedHttpService {

    constructor(private env: EnvironmentService) {
        super();
    }

    
    getVendors(ownerid: string, accounttype: number) {
        let marker: number = 0;

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Account/' + ownerid + '/' + accounttype + '/0' ).
            pipe(
                map((data) => data.json())
            );
    }


    getProductsByVendor(vendorid: string) {
        let marker: number = 0;

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/ProductV2/' + vendorid ).
            pipe(
                map((data) => data.json())
            );
    }

   


    getPlanById(standardcomponentid: string) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/AdministerPlans/' + standardcomponentid).
            pipe(
                map((data) => data.json())
            );
    }


    getPlanById2021(standardcomponentid: string, businessyear: number) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/AdministerPlans/' + standardcomponentid + '/' + businessyear + '/marker').
            pipe(
                map((data) => data.json())
            );
    }

    postPlan(planDTO: IndPlans2021DTO) {


        return this.postData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/AdministerPlans/', planDTO).
            pipe(
                map((data) => data.json())
            );


    }



}
