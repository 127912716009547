﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../core/environment.service';
import { ExtendedHttpService } from '../core/extendedhttp.service';
import { HttpHeaders } from '@angular/common/http';
import { Http } from '@angular/http';
import { catchError, map } from 'rxjs/operators';
import { EmployeeOnBoardingTrackerDTO } from '../dto/EmployeeOnBoardingTrackerDTO';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
@Injectable({
    providedIn: 'root',

})
export class OnboardService extends ExtendedHttpService {

    constructor(private env: EnvironmentService) {
        super();
    }


    getEmployerContributionInfo(externalemployerid: string): Observable<any> {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/EmployerContribution/' + externalemployerid).
            pipe(
                map((data) => data.json())
            );
    }



    getContributiondetail(employercontributionid: string, age: number): Observable<any> {

        //return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/ACARateCalcForTwoPlans/' + state + '/' + businessyear);


        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/EmployerContribution/' + employercontributionid + '/' + age).
            pipe(
                map((data) => data.json())
            );
    }


    // TODO
    postEmployeeSpending(employeeOnBoardingTracker: EmployeeOnBoardingTrackerDTO) {


        return this.postData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/EmployerContribution/', employeeOnBoardingTracker).
            pipe(
                map((data) => data.json())
            );


    }


}
