﻿import { EmployerContributionDTO } from '../dto/EmployerContributionDTO';
import { EmployerContributionDetailDTO } from '../dto/EmployerContributionDetailDTO';

  
export class EmployerSetupContributionContainerDTO {
		// This file is generated from the entities located in the MAKTech.Entities project
 
        public contributiondto: EmployerContributionDTO;
        public contributiondetaildto: EmployerContributionDetailDTO[];
    }
