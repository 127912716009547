﻿//import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ACAPlanLookupService } from './acaplanlookup.service';


import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';

const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';



@Component({
    templateUrl: 'plancostcompare.html',
    providers: [
        ACAPlanLookupService,
        ListService
    ]
})
export class PlanCostCompareComponent extends BaseComponent implements OnInit {

    columnDefinitions: Column[] = [];
    gridOptions: GridOption = {};
    mydataset: any[] = [];
    bIsLoaded: boolean = false;
    canShowGrid: boolean = false;
    angularGrid: AngularGridInstance;
    metrics: Metrics;
    //companyName: string;
    isCountEnabled = true;
    odataVersion = 2;
    odataQuery = '';
    processing = true;
    totalRowCount: number = 0;
    status = { text: 'processing...', class: 'alert alert-danger' };

    userSelectsString = '';
    name = 'Angular';
    userSelects = [];
    dropdownList = [];
    //    selectedItems = [];
    dropdownSettings = {};
    searchError: boolean = false;
    searchErrorMsg: string = "";

    gridObj: any[];
    selectedGridIDS: number[];
    selectedTitles: any[];
    selectedTitle: any;

    zipcode: string = "";
    selectedplanids: any[];
    selectedplannames: string;
    numberofSelectedPlans: number = 0;
    ratingareanumber: number = 1;
    ratingareaid: string = "";
    city: string = "";
    state: string = "";
    nameofplan1: string = "";
    nameofplan2: string = "";
    nameofplan3: string = "";
    plan1_employeeavgsavings: number = 0;
    plan2_employeeavgsavings: number = 0;
    plan3_employeeavgsavings: number = 0;
    currentplan_rate: number = 0;
    currentemployer_contribution: number = 0;
    currentemployee_contribution: number = 0;


    constructor(public acaplanlookupservice: ACAPlanLookupService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService,
        private cd: ChangeDetectorRef,
        protected _router: Router,
        private route: ActivatedRoute) {

        super();

        this.route.params.subscribe(params => {
            this.ratingareaid = params.ratingareaid;
            this.city = params.city;
            this.state = params.state;
            this.zipcode = params.zipcode;
            this.numberofSelectedPlans = params.numberofSelectedPlans;
            this.selectedplanids = params.planids;
            this.selectedplannames = params.plannames;

        });


    }


    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};
    }



    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }

    statechange(event) {

    }


    monthchange(event) {
    }


    ngOnInit(): void {
        let planname1: string = "No Plan";
        let planname2: string = "No Plan";
        let planname3: string = "No Plan";

        let planstr: string[];
        planstr = this.selectedplannames.split(',');
        for (var i = 0; i < this.numberofSelectedPlans; i++) {
            if (i == 0) {
                this.nameofplan1 = planstr[i].toString();
                planname1 = planstr[i].toString();
            }
            else if (i == 1) {
                this.nameofplan2 = planstr[i].toString();
                planname2 = planstr[i].toString();
            }
            else if (i == 2) {
                this.nameofplan3 = planstr[i].toString();
                planname3 = planstr[i].toString();
            }

        }


        this.columnDefinitions = [
            { id: 'id', name: 'Age', field: 'id', sortable: false, width: 80, minWidth: 80, cssClass: 'age' },
            { id: 'plan1rate', name: planname1, field: 'plan1rate', sortable: false, width: 150, minWidth: 150, formatter: Formatters.dollar, cssClass: 'plan1' },
            { id: 'plan1variancefromcurrent', name: 'Variance form Current', field: 'plan1variancefromcurrent', sortable: false, width: 100, minWidth: 100, formatter: Formatters.dollar, cssClass: 'plan1' },
            { id: 'plan1employeecontribution', name: 'Employee Contribution', field: 'plan1employeecontribution', sortable: false, width: 100, minWidth: 100, formatter: Formatters.dollar, cssClass: 'plan1' },
            { id: 'plan1employeeannualcostimpact', name: 'Annual Impact', field: 'plan1employeeannualcostimpact', sortable: false, width: 100, minWidth: 100, formatter: Formatters.dollar, cssClass: 'plan1' },
            { id: 'plan2rate', name: planname2, field: 'plan2rate', sortable: false, width: 150, minWidth: 150, formatter: Formatters.dollar, cssClass: 'plan2' },
            { id: 'plan2variancefromcurrent', name: 'Variance form Current', field: 'plan2variancefromcurrent', sortable: false, width: 100, minWidth: 100, formatter: Formatters.dollar, cssClass: 'plan2' },
            { id: 'plan2employeecontribution', name: 'Employee Contribution', field: 'plan2employeecontribution', sortable: false, width: 100, minWidth: 100, formatter: Formatters.dollar, cssClass: 'plan2' },
            { id: 'plan2employeeannualcostimpact', name: 'Annual Impact', field: 'plan2employeeannualcostimpact', sortable: false, width: 100, minWidth: 100, formatter: Formatters.dollar, cssClass: 'plan2' },
            { id: 'plan3rate', name: planname3, field: 'plan3rate', sortable: false, width: 150, minWidth: 150, formatter: Formatters.dollar, cssClass: 'plan3' },
            { id: 'plan3variancefromcurrent', name: 'Variance form Current', field: 'plan3variancefromcurrent', sortable: false, width: 100, minWidth: 100, formatter: Formatters.dollar, cssClass: 'plan3' },
            { id: 'plan3employeecontribution', name: 'Employee Contribution', field: 'plan3employeecontribution', sortable: false, width: 100, minWidth: 100, formatter: Formatters.dollar, cssClass: 'plan3' },
            { id: 'plan3employeeannualcostimpact', name: 'Annual Impact', field: 'plan3employeeannualcostimpact', sortable: false, width: 100, minWidth: 100, formatter: Formatters.dollar, cssClass: 'plan3' }
        ];
        this.gridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            editable: true,
            autoEdit: true,
            // multiSelect: true,


            // Multi-row selection support
           // enableFiltering: true,

            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },

        };
        this.gridOptions.showTopPanel = false;
        this.compareplans();
       // this.getrates();

        //this.angularGrid.resizerService.pauseResizer(true);
    }

    compareplans() {

        this.searchError = false;
        let count: number;

        count = this.selectedplanids.length;


        this.acaplanlookupservice.getRatesForComparison(this.selectedplanids.toString(), this.ratingareaid, this.currentplan_rate, this.currentemployer_contribution, this.currentemployee_contribution)
            .subscribe(
                (data) => { this.getRatesForCompareCallback(data); });

    }


    getRatesForCompareCallback(data) {

        let newdata: any[] = [];
        let idx: number = 0;
        let newidx: number = 0;

        if (data.totalcount > 0) {
            while (idx < 51) {  // there are 51 ages of 14 to 64, but we need to skip over the first 4 and start at age 18
                if (idx >= 4) {
                    newdata[newidx] = data.planComparison[idx];
                    newidx++;
                }
                idx++;
            }
            this.gridOptions.pagination.totalItems = data.totalcount;
            this.totalRowCount = data.totalcount;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.plan1_employeeavgsavings = data.plan1_employeeavgannualsavings;
            this.plan2_employeeavgsavings = data.plan2_employeeavgannualsavings;
            this.plan3_employeeavgsavings = data.plan3_employeeavgannualsavings;
            this.mydataset = newdata;
        }
        else {
            this.totalRowCount = data.totalcount;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = [];
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }

    }

    calcEmployeeContribution(event) {

        this.currentemployee_contribution = this.currentplan_rate - this.currentemployer_contribution;
        this.compareplans();

    }



    reset() {

        //       this.selectedItems = [];
        // this.selectedplanids = [];
        // this.angularGrid.gridService.setSelectedRows([]);
        this.totalRowCount = 0;
        this.gridOptions = Object.assign({}, this.gridOptions);
        this.mydataset = [];
        this.searchError = false;
        this.currentplan_rate = 0;
        this.currentemployer_contribution = 0;
        this.currentemployee_contribution = 0;
        this.plan1_employeeavgsavings = 0;
        this.plan2_employeeavgsavings = 0;
        this.plan3_employeeavgsavings = 0;
    }



    getCustomerCallback(data) {
        if (data.totalcount > 0) {

            this.gridOptions.pagination.totalItems = data.totalcount;
            this.totalRowCount = data.totalcount;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = data.acaUniquePlans;
            this.city = data.city;
            this.state = data.state;
            this.ratingareaid = data.ratingareaid;
        }
        else {
            this.totalRowCount = data.totalcount;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = [];
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;
            this.city = "";
            this.state = "";
            this.ratingareaid = "";
            this.ratingareanumber = 0;

        }
    }

    getCustomerApiCall(query) {

        this.searchError = false;
        this.acaplanlookupservice.getPlansForServiceArea(this.zipcode)
            .subscribe(
                (data) => { this.getCustomerCallback(data); });
    }

    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);


    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }



    /** Dispatched event of a Grid State Changed event */
    gridStateChanged(gridStateChanges: GridStateChange) {
        console.log('Client sample, Grid State changed:: ', gridStateChanges);
        let test: any;
        let a: number;

        // test = gridStateChanges;
        a = 0;

    }

    /** Dispatched event of a Grid State Changed event */
    grid1StateChanged(gridStateChanges: GridStateChange) {
        console.log('Grid State changed:: ', gridStateChanges);
        console.log('Grid State changed:: ', gridStateChanges.change);
    }

    ///** Dispatched event of a Grid State Changed event */
    //grid2StateChanged(gridStateChanges: GridStateChange) {
    //    console.log('Grid State changed:: ', gridStateChanges);
    //    console.log('Grid State changed:: ', gridStateChanges.change);

    //    if (gridStateChanges.gridState.rowSelection) {
    //        this.selectedGridIDS = (gridStateChanges.gridState.rowSelection.filteredDataContextIds || []) as number[];
    //        this.selectedGridIDS = this.selectedGridIDS.sort((a, b) => a - b); // sort by ID
    //        this.selectedTitles = this.selectedGridIDS.map(dataContextId => `Task ${dataContextId}`);
    //        this.cd.detectChanges();
    //    }
    //}


    onSelectedRowsChanged(e, args) {
        if (Array.isArray(args.rows)) {

            this.searchError = false;
            this.numberofSelectedPlans = args.rows.length;
            if (args.rows.length > 4) {
                this.searchError = true;
                this.searchErrorMsg = "You can only select up to 4 plans.";
                return;
            }

            this.selectedplanids = args.rows.map(idx => {
                const item = this.angularGrid.gridService.getDataItemByRowIndex(idx);
                return item.id || '';
            });
        }
    }



    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.gridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.gridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}






