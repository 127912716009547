﻿//import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
//import { ACAPlanLookupService } from './acaplanlookup.service';
import { TotalCostService } from './totalcost.service';
import { ICQuoteDTO } from '../dto/ICQuoteDTO';
//import * as moment from 'moment-mini';


import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';
import { ResponseBaseDTO } from '../dto/ResponseBaseDTO';
import { HasUnsavedData } from '../interfaces/hasunsaveddata';

const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';



@Component({
    templateUrl: 'totalcost.html',
    providers: [
        TotalCostService,
        ListService
    ]
})
export class TotalCostComponent extends BaseComponent implements OnInit {

    columnDefinitions: Column[] = [];
    gridOptions: GridOption = {};
    mydataset: any[] = [];
    bIsLoaded: boolean = false;
    canShowGrid: boolean = false;
    angularGrid: AngularGridInstance;
    metrics: Metrics;
    //companyName: string;
    isCountEnabled = true;
    odataVersion = 2;
    odataQuery = '';
    processing = true;
    totalRowCount: number = 0;
    status = { text: 'processing...', class: 'alert alert-danger' };

    userSelectsString = '';
    name = 'Angular';
    userSelects = [];
    dropdownList = [];
    //    selectedItems = [];
    dropdownSettings = {};
    searchError: boolean = false;
    searchErrorMsg: string = "";
    displayMessage: string = "";

    gridObj: any[];
    selectedGridIDS: number[];
    selectedTitles: any[];
    selectedTitle: any;

    zipcode: string = "";
    selectedplanids: string;;
    selectedplannames: string;
    selectedissuernames: string;
    numberofSelectedPlans: number = 0;
    ratingareanumber: number = 1;
    ratingareaid: string = "";
    city: string = "";
    state: string = "";
    nameofplan1: string = "";
    nameofplan2: string = "";
    nameofplan3: string = "";
    nameofplan4: string = "";

    issuername1: string = "";
    issuername2: string = "";
    issuername3: string = "";
    issuername4: string = "";


    totalmonthlycost: number = 0;

    plan1grandtotal: number = 0;
    plan2grandtotal: number = 0;
    plan3grandtotal: number = 0;
    plan4grandtotal: number = 0;

    plan1monthlyvariance: number = 0;
    plan2monthlyvariance: number = 0;
    plan3monthlyvariance: number = 0;
    plan4monthlyvariance: number = 0;

    plan1annualvariance: number = 0;
    plan2annualvariance: number = 0;
    plan3annualvariance: number = 0;
    plan4annualvariance: number = 0;


    plan1grandtotalstr: string = "Cost By Unit";
    plan2grandtotalstr: string = "Cost By Unit";
    plan3grandtotalstr: string = "Cost By Unit";
    plan4grandtotalstr: string = "Cost By Unit";

    totalmembers: number = 0;
    medicarecost: number = 0;

    unitstextarea: string = "";
    displayunittextarea: boolean = false;
    effectivedate: Date;
    effectivedatestr: string = "";


    useremail: string;
    userobjectid: string;

    employername: string = "";
    quotename: string = "";
    brokername: string = "";
    quotestatus: string = "Active";
    isdatachange: boolean = false;

    icquotedto: ICQuoteDTO;
    unitsbyage: string = "";
    accountid: string = "";
    quoteid: string = "";
    planagelist: any[] = [];
    createdon: Date;
    createdby: string;


    membernumber: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    constructor(
        public totalcostservice: TotalCostService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService,
        private cd: ChangeDetectorRef,
        protected _router: Router,
        private route: ActivatedRoute) {

        super();

        this.route.params.subscribe(params => {
            this.ratingareaid = params.ratingareaid;
            this.city = params.city;
            this.state = params.state;
            this.zipcode = params.zipcode;
            this.numberofSelectedPlans = params.numberofSelectedPlans;
            this.selectedplanids = params.planids;
            this.selectedplannames = params.plannames;
            this.selectedissuernames = params.issuernames;
            this.icquotedto = JSON.parse(params.icquotedto);

        });

        this.userobjectid = auth.userobjectid;
        this.useremail = auth.useremail;
    }


    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};
    }



    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }


    ngOnInit(): void {
        let planname1: string = "No Plan";
        let planname2: string = "No Plan";
        let planname3: string = "No Plan";
        let planname4: string = "No Plan";
        let dayno: number = 0;


        if (this.icquotedto == undefined) { // this is not a saved quote


            this.effectivedate = new Date();
            this.effectivedatestr = (this.effectivedate.getMonth() + 2).toString() + "/01/" + this.effectivedate.getFullYear().toString();
            this.effectivedate = new Date(this.effectivedatestr);
            this.quotestatus = "Active";
    

            let issuerstr: string[];
            if (this.selectedissuernames !== undefined) {
                issuerstr = this.selectedissuernames.split(",");
            }
            else
                issuerstr = ["", "", "", ""];

            let planstr: string[];
            planstr = this.selectedplannames.split('|,');

            for (var i = 0; i < this.numberofSelectedPlans; i++) {
                planstr[i] = planstr[i].replace("|", "");
                if (i == 0) {
                    this.nameofplan1 = planstr[i].toString();
                    planname1 = planstr[i].toString();
                    this.issuername1 = issuerstr[i].toString();
                }
                else if (i == 1) {
                    this.nameofplan2 = planstr[i].toString();
                    planname2 = planstr[i].toString();
                    this.issuername2 = issuerstr[i].toString();
                }
                else if (i == 2) {
                    this.nameofplan3 = planstr[i].toString();
                    planname3 = planstr[i].toString();
                    this.issuername3 = issuerstr[i].toString();
                }
                else if (i == 3) {
                    this.nameofplan4 = planstr[i].toString();
                    planname4 = planstr[i].toString();
                    this.issuername4 = issuerstr[i].toString();
                }

            }
        }
        else {
            this.accountid = this.icquotedto.accountid;
            this.quoteid = this.icquotedto.id;
            this.ratingareaid = this.icquotedto.ratingarea;
            this.city = this.icquotedto.city;
            this.state = this.icquotedto.state;
            this.zipcode = this.icquotedto.postalcode;
            this.employername = this.icquotedto.accountname;
            this.quotename = this.icquotedto.name;
            this.brokername = this.icquotedto.brokername;
            this.effectivedate = new Date(this.icquotedto.effectivedate);
            this.effectivedatestr = this.effectivedate.getMonth() + 1 + "/" + this.effectivedate.getDate() + "/" + this.effectivedate.getFullYear();
            this.membernumber = this.icquotedto.unitsbyage.split(",").map(function (item) { return parseInt(item, 10) });
            this.totalmembers = this.membernumber.reduce((a, b) => a + b, 0);
            this.totalmonthlycost = this.icquotedto.currentpremium;
            this.medicarecost = this.icquotedto.medicarepremium;
            this.quotestatus = this.quotestatus;
            this.createdon = this.icquotedto.createdon;
            this.createdby = this.icquotedto.createdby;


            this.numberofSelectedPlans = 0;
            if (this.icquotedto.plan1name !== "") {
                this.nameofplan1 = this.icquotedto.plan1name;
                planname1 = this.nameofplan1;
                this.issuername1 = this.issuername1;
                this.selectedplanids = this.icquotedto.plan1id;
                this.numberofSelectedPlans++;
            }
            if (this.icquotedto.plan2name !== "") {
                this.nameofplan2 = this.icquotedto.plan2name;
                planname2 = this.nameofplan2;
                this.issuername2 = this.issuername2;
                this.selectedplanids = this.selectedplanids + "," +  this.icquotedto.plan2id;
                this.numberofSelectedPlans++;
            }
            if (this.icquotedto.plan3name !== "") {
                this.nameofplan3 = this.icquotedto.plan3name;
                planname3 = this.nameofplan3;
                this.issuername3 = this.issuername3;
                this.selectedplanids = this.selectedplanids + "," + this.icquotedto.plan3id;
                this.numberofSelectedPlans++;
            }
            if (this.icquotedto.plan4name !== "") {
                this.nameofplan4 = this.icquotedto.plan4name;
                planname4 = this.nameofplan4;
                this.issuername4 = this.issuername4;
                this.selectedplanids = this.selectedplanids + "," + this.icquotedto.plan4id;
                this.numberofSelectedPlans++;
            }
            this.unitsbyage = this.icquotedto.unitsbyage;
            



        }


        this.columnDefinitions = [
            { id: 'id', name: 'Age', field: 'id', sortable: false, width: 80, minWidth: 80, cssClass: 'age' },
            { id: 'numberbyage', name: 'Units', field: 'numberbyage', sortable: false, width: 100, minWidth: 100, type: FieldType.number, editor: { model: Editors.integer, placeholder: 1 } },
            { id: 'plan1rate', name: planname1, field: 'plan1rate', sortable: false, width: 150, minWidth: 150, formatter: Formatters.dollar, cssClass: 'plan1' },
            { id: 'plan1totalforage', name: this.plan1grandtotalstr, field: 'plan1totalforage', sortable: false, width: 100, minWidth: 100, formatter: Formatters.dollar, cssClass: 'plan1' },
            { id: 'plan2rate', name: planname2, field: 'plan2rate', sortable: false, width: 150, minWidth: 150, formatter: Formatters.dollar, cssClass: 'plan2' },
            { id: 'plan2totalforage', name: this.plan2grandtotalstr, field: 'plan2totalforage', sortable: false, width: 100, minWidth: 100, formatter: Formatters.dollar, cssClass: 'plan2' },
            { id: 'plan3rate', name: planname3, field: 'plan3rate', sortable: false, width: 150, minWidth: 150, formatter: Formatters.dollar, cssClass: 'plan3' },
            { id: 'plan3totalforage', name: this.plan3grandtotalstr, field: 'plan3totalforage', sortable: false, width: 100, minWidth: 100, formatter: Formatters.dollar, cssClass: 'plan3' },
            { id: 'plan4rate', name: planname4, field: 'plan4rate', sortable: false, width: 150, minWidth: 150, formatter: Formatters.dollar, cssClass: 'plan4' },
            { id: 'plan4totalforage', name: this.plan4grandtotalstr, field: 'plan4totalforage', sortable: false, width: 100, minWidth: 100, formatter: Formatters.dollar, cssClass: 'plan4' }
        ];
        this.gridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            editable: true,
            autoEdit: true,
            // multiSelect: true,


            // Multi-row selection support
            // enableFiltering: true,

            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },

        };
        this.gridOptions.showTopPanel = false;
        if (this.medicarecost == undefined)
            this.medicarecost = 0;
        this.compareplans();

        //this.angularGrid.resizerService.pauseResizer(true);
    }

    compareplans() {

        this.searchError = false;

        this.totalcostservice.getRatesForComparison(this.selectedplanids.toString(), this.ratingareaid, this.totalmonthlycost, 0, 0)
            .subscribe(
                (data) => { this.getRatesForCompareCallback(data); });

    }

    calculatecost() {
        this.effectivedate = new Date(this.effectivedatestr);
        if (isNaN(this.effectivedate.getDate())) {
            this.searchErrorMsg = "Effective date must be in the format MM/DD/YYYY.";
            this.searchError = true;
            return;
        }
        this.isdatachange = true;
        if (this.unitstextarea !== "") {
            this.pasteunits();
            this.calculateforeachage(false);
        }
        else {
            this.calculateforeachage(true);
        }
    }

    calculateforeachage(bGetAgeFromPage: boolean) {

        this.searchError = false;
        this.displayMessage = "";
        let count: number;

        let memnostr: string = "";
        let row: any;
        let idx: number = 0;
        let data: any[] = [];

        count = this.membernumber.length;
        this.plan1grandtotal = 0;
        this.plan2grandtotal = 0;
        this.plan3grandtotal = 0;
        this.plan4grandtotal = 0;
        this.totalmembers = 0;

        while (idx < 52) {  // there are 52 age slots from 14 to 64 plus 65 medicare cost
            row = this.angularGrid.gridService.getDataItemByRowIndex(idx);
            if (bGetAgeFromPage) {
                memnostr = row['numberbyage'];
                if (memnostr == "")
                    this.membernumber[idx] = 0;
                else
                    this.membernumber[idx] = parseInt(memnostr);
            }

            if (idx == 51) {
                data[idx] = {
                    id: row['id'],
                    numberbyage: this.membernumber[idx],
                    plan1rate: this.medicarecost,
                    plan1totalforage: this.medicarecost * this.membernumber[idx],
                    plan2rate: this.medicarecost,
                    plan2totalforage: this.medicarecost * this.membernumber[idx],
                    plan3rate: this.medicarecost,
                    plan3totalforage: this.medicarecost * this.membernumber[idx],
                    plan4rate: this.medicarecost,
                    plan4totalforage: this.medicarecost * this.membernumber[idx]

                }
            }
            else {
                data[idx] = {
                    id: row['id'],
                    numberbyage: this.membernumber[idx],
                    plan1rate: row['plan1rate'],
                    plan1totalforage: row['plan1rate'] * this.membernumber[idx],
                    plan2rate: row['plan2rate'],
                    plan2totalforage: row['plan2rate'] * this.membernumber[idx],
                    plan3rate: row['plan3rate'],
                    plan3totalforage: row['plan3rate'] * this.membernumber[idx],
                    plan4rate: row['plan4rate'],
                    plan4totalforage: row['plan4rate'] * this.membernumber[idx]

                }
            }
            this.plan1grandtotal = this.plan1grandtotal + row['plan1rate'] * this.membernumber[idx];
            this.plan2grandtotal = this.plan2grandtotal + row['plan2rate'] * this.membernumber[idx];
            this.plan3grandtotal = this.plan3grandtotal + row['plan3rate'] * this.membernumber[idx];
            this.plan4grandtotal = this.plan4grandtotal + row['plan4rate'] * this.membernumber[idx];
            this.totalmembers = this.totalmembers + this.membernumber[idx];

            idx++;
        }
        this.plan1monthlyvariance = this.totalmonthlycost - this.plan1grandtotal;
        this.plan2monthlyvariance = this.totalmonthlycost - this.plan2grandtotal;
        this.plan3monthlyvariance = this.totalmonthlycost - this.plan3grandtotal;
        this.plan4monthlyvariance = this.totalmonthlycost - this.plan4grandtotal;

        this.plan1annualvariance = (this.totalmonthlycost * 12) - (this.plan1grandtotal * 12);
        this.plan2annualvariance = (this.totalmonthlycost * 12) - (this.plan2grandtotal * 12);
        this.plan3annualvariance = (this.totalmonthlycost * 12) - (this.plan3grandtotal * 12);
        this.plan4annualvariance = (this.totalmonthlycost * 12) - (this.plan4grandtotal * 12);



        this.mydataset = data;

    }

    getRatesForCompareCallback(data) {


        if (data.totalcount > 0) {
        // Medicare costs don't come from the plan, so add them to the PlanComparison DTO
            this.searchError = false;
            this.gridOptions = Object.assign({}, this.gridOptions);
            data.planComparison.push({
                id: '65 and up',
                numberbyage: 0,
                plan1rate: this.medicarecost,
                plan1rateforage: 0,
                plan2rate: this.medicarecost,
                plan2rateforage: 0,
                plan3rate: this.medicarecost,
                plan3rateforage: 0,
                plan4rate: this.medicarecost,
                plan4rateforage: 0

            });
            this.planagelist = data.planComparison;


            if (this.unitsbyage !== undefined && this.unitsbyage !== "") {

                let idx: number = 0;

                let ageunits: string = this.unitsbyage.toString();
                let ageunitarray: string[] = ageunits.split(',');
                while (idx < 52) {
                    this.planagelist[idx].numberbyage = ageunitarray[idx];
                    this.membernumber[idx] = parseInt(ageunitarray[idx]);

                    if (idx == 51) {
                        this.planagelist[idx].plan1totalforage = this.medicarecost * this.membernumber[idx];
                        this.planagelist[idx].plan2totalforage = this.medicarecost * this.membernumber[idx];
                        this.planagelist[idx].plan3totalforage = this.medicarecost * this.membernumber[idx];
                        this.planagelist[idx].plan4totalforage = this.medicarecost * this.membernumber[idx];
                    }
                    else {
                        this.planagelist[idx].plan1totalforage = this.planagelist[idx].plan1rate * this.membernumber[idx];
                        this.planagelist[idx].plan2totalforage = this.planagelist[idx].plan2rate * this.membernumber[idx];
                        this.planagelist[idx].plan3totalforage = this.planagelist[idx].plan3rate * this.membernumber[idx];
                        this.planagelist[idx].plan4totalforage = this.planagelist[idx].plan4rate * this.membernumber[idx];

                    }
                    this.plan1grandtotal = this.plan1grandtotal + this.planagelist[idx].plan1rate * this.membernumber[idx];
                    this.plan2grandtotal = this.plan2grandtotal + this.planagelist[idx].plan2rate * this.membernumber[idx];
                    this.plan3grandtotal = this.plan3grandtotal + this.planagelist[idx].plan3rate * this.membernumber[idx];
                    this.plan4grandtotal = this.plan4grandtotal + this.planagelist[idx].plan4rate * this.membernumber[idx];
                    idx++;
                }
                this.plan1monthlyvariance = this.totalmonthlycost - this.plan1grandtotal;
                this.plan2monthlyvariance = this.totalmonthlycost - this.plan2grandtotal;
                this.plan3monthlyvariance = this.totalmonthlycost - this.plan3grandtotal;
                this.plan4monthlyvariance = this.totalmonthlycost - this.plan4grandtotal;

                this.plan1annualvariance = (this.totalmonthlycost * 12) - (this.plan1grandtotal * 12);
                this.plan2annualvariance = (this.totalmonthlycost * 12) - (this.plan2grandtotal * 12);
                this.plan3annualvariance = (this.totalmonthlycost * 12) - (this.plan3grandtotal * 12);
                this.plan4annualvariance = (this.totalmonthlycost * 12) - (this.plan4grandtotal * 12);

            }


            this.mydataset = this.planagelist;
        }
        else {
            this.totalRowCount = data.totalcount;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = [];
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }

    }


    savequote() {

        if (this.employername === "" || this.quotename === "") {
            this.searchError = true;
            this.searchErrorMsg = "You must enter both an employer name and quote name to save the quote.";
            return;
        }

        this.searchError = false;
        this.displayMessage = "";

        let memno: any = '';
        let row: any;
        let idx: number = 0;


        while (idx < 52) {  // there are 52 age slots from 14 to 64 plus 65 medicare cost
            row = this.angularGrid.gridService.getDataItemByRowIndex(idx);
            memno = row['numberbyage'];
            if (memno === undefined || memno == "")
                this.membernumber[idx] = 0;
            else
                this.membernumber[idx] = memno;
            idx++;
        }

        this.icquotedto = new ICQuoteDTO();

        if (this.accountid !== undefined && this.accountid !== "") {
            this.icquotedto.accountid = this.accountid; // user has modified an existing quote
            if (this.quoteid !== undefined && this.quoteid !== "") {
                this.icquotedto.id = this.quoteid;
                this.icquotedto.createdon = this.createdon; // preserve original data
                this.icquotedto.createdby = this.createdby;
            } else {
                this.searchError = true;
                this.searchErrorMsg = "Something went wrong. Please try again.";
                return;
            }
        }
        else {  
            //this.icquotedto.accountid = null;
            //this.icquotedto.id = null;
            this.icquotedto.createdon = new Date();
            this.icquotedto.createdby = this.auth.useremail;
            this.createdon = this.icquotedto.createdon;
            this.createdby = this.icquotedto.createdby;
        }


        this.icquotedto.ownerid = this.userobjectid; // TODO: When we implement security groups, this might not be the right thing to do
        this.icquotedto.name = this.quotename;
        this.icquotedto.accountname = this.employername;
        this.icquotedto.brokername = this.brokername;
        this.icquotedto.city = this.city;
        this.icquotedto.state = this.state;
        this.icquotedto.postalcode = this.zipcode;
        this.icquotedto.ratingarea = this.ratingareaid;
        this.icquotedto.currentpremium = this.totalmonthlycost;
        this.icquotedto.medicarepremium = this.medicarecost;
        this.icquotedto.effectivedate = this.effectivedate;
        this.icquotedto.totalmembers = this.totalmembers;
        this.icquotedto.quotestatus = this.quotestatus;

        this.icquotedto.plan1id = "";
        this.icquotedto.plan2id = "";
        this.icquotedto.plan3id = "";
        this.icquotedto.plan4id = "";
        idx = 0;
        let planstr: string = this.selectedplanids.toString();
        let planarray: string[] = planstr.split(',');
        while (idx < planarray.length) {
            if (idx === 0)
                this.icquotedto.plan1id = planarray[0];
            else if (idx === 1)
                this.icquotedto.plan2id = planarray[1];
            else if (idx === 2)
                this.icquotedto.plan3id = planarray[2];
            else if (idx === 3)
                this.icquotedto.plan4id = planarray[3];
            idx++;
        }

        this.icquotedto.plan1name = this.nameofplan1;
        this.icquotedto.plan2name = this.nameofplan2;
        this.icquotedto.plan3name = this.nameofplan3;
        this.icquotedto.plan4name = this.nameofplan4;
        this.icquotedto.issuer1name = this.issuername1;
        this.icquotedto.issuer2name = this.issuername2;
        this.icquotedto.issuer3name = this.issuername3;
        this.icquotedto.issuer4name = this.issuername4;

        this.icquotedto.unitsbyage = this.membernumber.toString();
        
        this.icquotedto.modifiedby = this.auth.useremail;
        this.icquotedto.modifiedon = new Date();


        this.totalcostservice.postIndivdualCoverageByUnitsQuote(this.icquotedto)
            .subscribe (
                (data) => { this.postIndividualCoverageByUnitsQuoteResponse(data); }
            );



    }

    postIndividualCoverageByUnitsQuoteResponse(data) {


        if (data.success) {
            this.isdatachange = false;
            this.accountid = data.data.accountid;
            this.quoteid = data.data.id;
            this.displayMessage = "Saved Successfully";
        }
        else {
            this.searchErrorMsg = data.displaymessage;
            this.searchError = true;

        }

    }


    get hasUnsavedData() {

        if (this.isdatachange)
            return true;
        return false;

    }

    changeotherfields() {
            this.isdatachange = true;
    }

    reset() {
        this.searchError = false;
        let idx: number = 0;
        let row: any;
        let data: any[] = [];

        while (idx < 52) {  // there are 52 ages from 14 to 64 plus medicare cost
            row = this.angularGrid.gridService.getDataItemByRowIndex(idx);
            this.membernumber[idx] = 0;

            data[idx] = {
                id: row['id'],
                numberbyage: 0,
                plan1rate: row['plan1rate'],
                plan1totalforage: 0,
                plan2rate: row['plan2rate'],
                plan2totalforage: 0,
                plan3rate: row['plan3rate'],
                plan3totalforage: 0,
                plan4rate: row['plan4rate'],
                plan4totalforage: 0

            }
            idx++;
        }
        this.plan1grandtotal = 0;
        this.plan2grandtotal = 0;
        this.plan3grandtotal = 0;
        this.plan4grandtotal = 0;
        this.totalmembers = 0;
        this.plan1monthlyvariance = 0;
        this.plan2monthlyvariance = 0;
        this.plan3monthlyvariance = 0;
        this.plan4monthlyvariance = 0;

        this.plan1annualvariance = 0;
        this.plan2annualvariance = 0;
        this.plan3annualvariance = 0;
        this.plan4annualvariance = 0;


        this.mydataset = data;



    }

    pasteunits() {

        let textstr: string = "";
        let rows: any;      
        let i: number = 0;

        if (isNaN(this.effectivedate.getDate()))
            return;

        let bdate: Date;
        let memberage: number = 0;
        let month: number = 0;
        let test: any;

       // this.reset();
        while (i < 52) {
            this.membernumber[i] = 0;
            i++;
        }
        i = 0;
            
        textstr = this.unitstextarea;
        rows = textstr.split(/\r\n|\n|\r/);

        while (i < rows.length) {
                bdate = new Date(rows[i]);
                if (!isNaN(bdate.getDate())) {

                    memberage = this.effectivedate.getFullYear() - bdate.getFullYear();
                    month = this.effectivedate.getMonth() - bdate.getMonth();
                    if (month < 0 || (month === 0 && this.effectivedate.getDate() < bdate.getDate())) {
                        memberage--;
                    }
                    if (memberage < 14)
                        memberage = 14;
                    else if (memberage > 65)
                        memberage = 65;
                    this.membernumber[memberage - 14] = this.membernumber[memberage - 14] + 1;
                }
            i++;
        }
        this.calculateforeachage(false);
     
    }


    togglepastearea() {
        if (!this.displayunittextarea)
            this.displayunittextarea = true;
        else
            this.displayunittextarea = false;

    }


    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);


    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }



    //onSelectedRowsChanged(e, args) {

    //    let test: number = 0;
    //    if (Array.isArray(args.rows)) {

    //        this.searchError = false;
    //        this.numberofSelectedPlans = args.rows.length;
    //        //if (args.rows.length > 4) {
    //        //    this.searchError = true;
    //        //    this.searchErrorMsg = "You can only select up to 4 plans.";
    //        //    return;
    //        //}

    //        test = args.rows.map(idx => idx);

    //        this.membernumber = args.rows.map(idx => {
    //            const item = this.angularGrid.gridService.getDataItemByRowIndex(idx);
    //            return item.numberbyage || 0;
    //        });
    //    }
    //}



    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.gridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.gridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}






