﻿import { Component } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { Injectable } from '@angular/core';
import { HttpModule } from '@angular/http';
import 'rxjs/add/operator/map';
import { ExtendedHttpService } from './extendedhttp.service';
import { EnvironmentService } from './environment.service';
import { map } from 'rxjs/operators';
import { PermissionDTO } from '../dto/PermissionDTO';


@Injectable()
export class LoginService extends ExtendedHttpService{

    constructor(private http: Http, private env: EnvironmentService) {
        super();
    }

    getuserpermissions(token:string) {
       
        return this.getData(encodeURI("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Permission/' + 'token')).
            pipe(
                map((data) => data.json())
            );
    }


    getpermission(token: string) {
        var permisiondto = new PermissionDTO();
        permisiondto.createdby = token;
        return this.postData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Permission', permisiondto).
            pipe(
                map((data) => data.json())
            );
    }

      
}