﻿import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { BaseComponent } from '../core/base.component';

import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';

import { EmployerSetupService } from './employersetup.services';
import { GlobaldataService } from '../service/globaldata.service';

import { MemberDependentDTO } from '../dto/MemberDependentDTO';
import { OrderService } from './order.service';
import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
    GridMenu,
    GridMenuItem,
    SlickEventHandler,
    SlickEvent,
    SlickEventData,
    AngularSlickgridComponent
} from 'angular-slickgrid';


const defaultPageSize = 1000;


@Component({
    templateUrl: './dependentdetail.component.html',
    styleUrls: ['./employersetup.component.css'],

})
export class DependentDetailComponent extends BaseComponent implements OnInit {

    constructor(private route: ActivatedRoute,
        protected _router: Router,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private cd: ChangeDetectorRef,
        private employerSetupService: EmployerSetupService,
        private globaldataservice: GlobaldataService,
        private location: Location,
        public orderService: OrderService
    ) {
        super();


        this.route.params.subscribe(params => {
            this.employername = params.employername;
            this.employerid = params.accountid;
            this.membername = params.membername;
            this.addingdependent = params.addingdependent;
            this.dependentdetaildto = JSON.parse(params.dependentdetaildto);

        });

        //   console.log("Member name: = " + this.membername);
    }

    angulardependentDetailHistoryGridReady(angularGrid: AngularGridInstance) {
        this.angulardependentDetailHistoryGridInstance = angularGrid;
    }


    name = 'Angular';


    searchErrorMsg: string = "";
    file: any;
    arrayBuffer: any;
    filelist: any[];

    employerid: string;
    employername: string;
    membername: string;
    addingdependent: number;
    dependentdetaildto: MemberDependentDTO;
    isdependentdetailsaved: boolean = true;
    dateofbirthstr: string = "";
    displayMessage: string = "";
    lastmodificationreason: string = "";

    currenttabid: string = "tab-1-div";


    selectedquoteidx: number = -1;
    isquotenotselected: boolean = true;

    //dependents: MemberDependentDTO[] = [];
    //memberaccount: MemberAccountDTO;

    angularGrid: AngularGridInstance;

    dependentDetailHistorycolumnDefinitions: Column[] = [];
    dependentDetailHistorygridOptions: GridOption = {};
    dependentDetailHistorydataset: any[] = [];
    angulardependentDetailHistoryGridInstance: AngularGridInstance;

    dependentDetailHistoryTotalCount: number = 0;

    dependentDetailHistoryDTO: MemberDependentDTO[] = [];



    genderlist: SelectList[] = [
        {
            id: "Female",
            name: "Female"
        },
        {
            id: "Male",
            name: "Male"
        }

    ];

    onhealthpolicylist: SelectList[] = [
        {
            id: "Yes",
            name: "Yes"
        },
        {
            id: "No",
            name: "No"
        }

    ];



    ngOnInit(): void {

        this.initdependentDetailPage();
        this.initdependentDetailHistoryGrid();
        this.getdependentItemHistory();

    }

    initdependentDetailPage() {

       // this.lastmodificationreason = this.dependentdetaildto.modifiedreason;
        // this.dependentdetaildto.modifiedreason = "";
        
        if (this.dependentdetaildto.dateofbirth !== null) {
            this.dependentdetaildto.dateofbirth = new Date(this.dependentdetaildto.dateofbirth);
            this.dateofbirthstr = this.dependentdetaildto.dateofbirth.getMonth() + 1 + "/" + this.dependentdetaildto.dateofbirth.getDate() + "/" + this.dependentdetaildto.dateofbirth.getFullYear();
        }
        else {
            this.dateofbirthstr = "";
        }

    }

    initdependentDetailHistoryGrid() {
        this.dependentDetailHistorycolumnDefinitions = [
            { id: 'id', name: '#', field: 'id', sortable: false, width: 40, minWidth: 40 },
            { id: 'modifiedon', name: 'Last Modified', field: 'modifiedon', sortable: true, width: 120, minWidth: 120, formatter: Formatters.dateUs },
            { id: 'modifiedby', filterable: true, name: 'Modified By', field: 'modifiedby', sortable: true, width: 200, minWidth: 200 },
            { id: 'firstname', filterable: true, name: 'First Name', field: 'firstname', sortable: true, width: 175, minWidth: 175 },
            { id: 'lastname', filterable: true, name: 'Last Name', field: 'lastname', sortable: true, width: 175, minWidth: 175 },
            { id: 'gender', filterable: true, name: 'Gender', field: 'gender', sortable: true, width: 125, minWidth: 125 },
            { id: 'additionalid', name: 'SSN', field: 'additionalid', sortable: true, width: 125, minWidth: 125 },
            { id: 'dateofbirth', name: 'Date of Birth', field: 'dateofbirth', sortable: true, width: 120, minWidth: 120, formatter: Formatters.dateUs },
            { id: 'pcpname', filterable: true, name: 'PCP Name', field: 'pcpname', sortable: true, width: 125, minWidth: 125 },
            { id: 'isonhealthpolicy', name: 'On Health Policy', field: 'isonhealthpolicy', sortable: true, width: 125, minWidth: 125 },

        ];
        this.dependentDetailHistorygridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: true,
            checkboxSelector: {
                // you can toggle these 2 properties to show the "select all" checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true,
            },
            enableCheckboxSelector: true,

            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },
        };

    }

    getdependentItemHistory() {

        this.employerSetupService.getMemberDependentHistory(this.dependentdetaildto.id)
            .subscribe(
                (data) => { this.getMemberDependentHistoryCallBack(data); });

    }



    getMemberDependentHistoryCallBack(data) {


        if (data.success) {
            this.dependentDetailHistorygridOptions = Object.assign({}, this.dependentDetailHistorygridOptions);
            this.dependentDetailHistoryDTO = data.data;
            this.dependentDetailHistoryTotalCount = this.dependentDetailHistoryDTO.length;
            for (var i = 0; i < this.dependentDetailHistoryTotalCount; i++) {
                this.dependentDetailHistoryDTO[i].id = (i + 1).toString();
            }
            this.dependentDetailHistorydataset = this.dependentDetailHistoryDTO;

        }
        else {
            this.dependentDetailHistoryTotalCount = 0;
            this.dependentDetailHistorygridOptions = Object.assign({}, this.dependentDetailHistorygridOptions);
            this.dependentDetailHistorydataset = [];
            this.dependentDetailHistoryDTO = [];
        }

    }

    savedependentDetail() {


        this.displayMessage = "";
        this.searchErrorMsg = "";

        this.dependentdetaildto.modifiedon = new Date();
        this.dependentdetaildto.modifiedby = this.globaldataservice.useremail;


        this.employerSetupService.putMemberDependent(this.dependentdetaildto)
            .subscribe((data) => { this.savedependentDetailCallBack(data) });
    }

    savedependentDetailCallBack(data) {

        if (data.success) {
            this.displayMessage = "Successfully updated the dependent."
            this.addingdependent = 0;
            this.isdependentdetailsaved = true;
            this.getdependentItemHistory();
        }
        else {
            this.searchErrorMsg = data.displaymessage;

        }


    }



    changeDateofBirth(e: any) {

        let wrkdobffdate: Date = new Date(e.target.value);
        let wrkdobffdatestr: string = (wrkdobffdate.getUTCMonth() + 1).toString() + "/" + wrkdobffdate.getUTCDate().toString() + "/" + wrkdobffdate.getUTCFullYear().toString();

/*        console.log("Dependent Date of Birth = " + wrkdobffdatestr);*/

        if (this.dateofbirthstr === wrkdobffdatestr) // If no change
            return;

        if (!isNaN(wrkdobffdate.getDate())) {
            this.dependentdetaildto.dateofbirth = wrkdobffdate;
            this.dateofbirthstr = (this.dependentdetaildto.dateofbirth.getUTCMonth() + 1).toString() + "/" + this.dependentdetaildto.dateofbirth.getUTCDate().toString() + "/" + this.dependentdetaildto.dateofbirth.getUTCFullYear().toString();
            this.changeDependent();
        }
        else {
            this.searchErrorMsg = "Please enter a valid date.";
        }

    }


    changeGender(e) {
        this.dependentdetaildto.gender = e.value;
        this.changeDependent();
    }

    changeOnHealthPolicy(e) {
        this.dependentdetaildto.isonhealthpolicy = e.value;
        this.changeDependent();
    }


    changeDependent() {

        this.displayMessage = "";
        this.searchErrorMsg = "";
        this.isdependentdetailsaved = false;

    }

    backClicked() {

        this.location.back();

    }


}