
import { Injectable } from '@angular/core';
import { EnvironmentService } from '../core/environment.service';
import { B2CAuthService } from './b2cauth.service';
import { appInjector } from '../core/util.service';
import { RedirectService } from './redirect.service';
import { ToolKitEvents } from './toolkitevents.service';
import { environment } from '../../environments/environment';


declare var Msal: any;
@Injectable()
export class MsalService {

    // b2cRedirectUri: 'http://localhost:9220/signin-oidc',
    // tenant: 'enrichlyhr2.onmicrosoft.com',
    // tenant: 'b23fb56c-50f0-4c0a-ae3b-4e69677b0a0f',
    // b2cclientID: 'cddbf387-56cd-4b0a-b84e-4fbde6f3a4c6',
    // b2cclientID: '0395727a-fd55-4e5d-a43e-76964ed0ad43',
    // signUpSignInPolicy: 'B2C_1_SignUpSignIn',

    _calllback: any;
    private tenantConfig: any = {
        tenant: "b23fb56c-50f0-4c0a-ae3b-4e69677b0a0f",
        clientID: "cddbf387-56cd-4b0a-b84e-4fbde6f3a4c6",
        signUpSignInPolicy: "B2C_1_SignIn"

    };
    // JJM 4/23/2024 Changed.
    //  authority = "https://ebtoolkit.b2clogin.com/" + this.tenantConfig.tenant + "/" + this.tenantConfig.signUpSignInPolicy;
    authority = "https://enrichlyhr2.b2clogin.com/" + this.tenantConfig.tenant + "/" + this.tenantConfig.signUpSignInPolicy;

    // authority = "https://quantabenefits.b2clogin.com/" + this.tenantConfig.tenant + "/" + this.tenantConfig.signUpSignInPolicy;
    



    public app: any;
    //access_token: string;
    token: string;

    constructor(private env: EnvironmentService
    ) {

        // errorDesc: any, token: any, error: any, tokenType: any
        console.log("Message master");

  

        this.app = new Msal.UserAgentApplication(this.tenantConfig.clientID, this.authority, (errorDesc: any, token: any, error: any, tokenType: any, user: any) => {

           
            navigateToLoginRequestUrl: false;
            sessionStorage.setItem('token', token);
            sessionStorage.setItem('user', JSON.stringify(user) );
            let msal = window;
            // callback for login redirect
            //let msal = window.msal as Msal.UserAgentApplication;
            //msal.acquireTokenSilent( 
            return token;
            //     var user = this._user;
            //this._calllback.callbackhandler();



        });


        //this.app = new Msal.UserAgentApplication(this.tenantConfig.clientID, this.authority, function (errorDesc: any, token: any, error: any, tokenType: any) {
        //    // Called after loginRedirect or acquireTokenPopup

         //  alert('hi');
         //  navigateToLoginRequestUrl: false

        // //const user = this.app.getUser();
        //    //this.token = token;
        //    var user = this._user;
        //   
        //    //if (user) {
        //    //    return user;
        //    //} else {
        //    //    return null
        //    //};
        //});
        var clientApplication = window.parent;
        this.app.popUpWidth = 500;
        // this.app.redirectUri = "http://localhost:9220/signin-oidc";
        this.app.redirectUri = "https://admin.enrichlyhr.com/signin-oidc";

        // this.auth.oncallBackLogin(user, false); 



    }

    public login(callback: any) {
        this._calllback = callback;

        return this.app.loginRedirect(null);
        //.then(idToken => {
        //    const user = this.app.getUser();        
        //    this.token = idToken;
        //    if (user) {
        //        return user;
        //    } else {
        //        return null;
        //    }
        //}, () => {
        //    return null;
        //});
    }


    public logout() {
        return this.app.logout();
    }


    public getToken() {

        return this.app.acquireTokenSilent(this.tenantConfig.b2cScopes)
            .then(accessToken => {
                //  alert('user');
                alert(accessToken);
                return accessToken;
            }, error => {
                return this.app.acquireTokenPopup(this.tenantConfig.graphScopes)
                    .then(accessToken => {
                        return accessToken;
                    }, err => {
                        console.error(err);
                    });
            });
    }


}





