﻿//import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EmployerAccountService } from '../employer/employeraccount.service';
import { EmployerActiveMemberDTO } from '../dto/EmployerActiveMemberDTO';

import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
    GridMenu,
    GridMenuItem,
    SlickEventHandler,
    SlickEvent,
    SlickEventData,
    AngularSlickgridComponent
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';
import { ResponseBaseDTO } from '../dto/ResponseBaseDTO';
import { OrderService } from './order.service';
import { OrderPayrollService } from '../admin/orderpayroll.service';
import { GlobaldataService } from '../service/globaldata.service';



const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';


@Component({
    templateUrl: 'employerbilling.component.html',
    providers: [
        OrderService,
        ListService
    ]
})
export class EmployerBillingComponent extends BaseComponent implements OnInit {

    columnDefinitions: Column[] = [];
    angularGrid: AngularGridInstance;
    gridOptions: GridOption = {};
    gridMenu: GridMenu;
    gridMenuItem: GridMenuItem;
    slickEventHandler: SlickEventHandler;
    slickEvent: SlickEvent;
    slickEventData: SlickEventData;
    _addon: any;
    mydataset: any[] = [];



    bIsLoaded: boolean = false;
    canShowGrid: boolean = false;
    metrics: Metrics;
    //companyName: string;
    isCountEnabled = true;
    odataVersion = 2;
    odataQuery = '';
    processing = true;
    totalRowCount: number = 0;
    status = { text: 'processing...', class: 'alert alert-danger' };

    userSelectsString = '';
    name = 'Angular';
    userSelects = [];
    dropdownList = [];
    //    selectedItems = [];
    dropdownSettings = {};
    searchError: boolean = false;
    searchErrorMsg: string = "";

    gridObj: any;

    selectedGridIDS: number[];
    selectedTitles: any[];
    selectedTitle: any;

    useremail: string;
    userobjectid: string;
    // employerid: string = "";



    isordernotselected: boolean = true;

    selectedquoteidx: number = -1;

    employerActiveMemberDTO: EmployerActiveMemberDTO[] = [];
    total_currentmonth: number = 0;
    total_previousmonth: number = 0;
    total_thirdmonth: number = 0;

    asofdate_currentmonth: string;
    asofdate_previousmonth: string;
    asofdate_thirdmonth: string;


    orderstatus: number = 0;

    orderIdx: number = -1;

    employeazecustomerlist: SelectList[] = [];


    constructor(
        public employeraccountservice: EmployerAccountService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService,
        private cd: ChangeDetectorRef,
        protected _router: Router,
        private route: ActivatedRoute,
        private globaldataservice: GlobaldataService,
    ) {

        super();

        this.userobjectid = auth.userobjectid;
        this.useremail = auth.useremail;
    }


    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};



        this.angularGrid.dataView.onRowCountChanged.subscribe((e, args) => this.rowCountChanged(e, args));


    }


    get eventHandler(): SlickEventHandler {

        return this.slickEventHandler
    }



    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }




    ngOnInit(): void {


        let todaysdate: Date = new Date();
        let asofdatestr: string = todaysdate.getFullYear().toString() + "-" + (todaysdate.getMonth() + 1).toString() + "-" + todaysdate.getDate().toString();
        if (this.globaldataservice.employerbillingasofdatestr === '')
            this.globaldataservice.employerbillingasofdatestr = todaysdate.toLocaleDateString();



        this.getEmployerContractData(asofdatestr);


        //this.angularGrid.resizerService.pauseResizer(true);
    }

    getEmployerContractData(asofdate: string) {
        this.employeraccountservice.getEmployerBillingContractInfo(this.userobjectid, asofdate)
            .subscribe(
                (data) => { this.getEmployerBillingContractCallBack(data); });


    }

    getEmployerBillingContractCallBack(data) {
        let idx: number = 0;
        let newidx: number = 0;
        let locationfound: boolean = false;

        if (data !== undefined && data.totalcount > 0) {
            this.employerActiveMemberDTO = data.employeractivememberdto;

            this.total_currentmonth = data.total_currentmonth;
            this.total_previousmonth = data.total_previousmonth;
            this.total_thirdmonth = data.total_thirdmonth;
            this.totalRowCount = data.totalcount;

            this.asofdate_currentmonth = this.getFormattedDate(this.employerActiveMemberDTO[0].asofdate_currentmonth);

            this.asofdate_previousmonth = this.getFormattedDate(this.employerActiveMemberDTO[0].asofdate_previousmonth);

            this.asofdate_thirdmonth = this.getFormattedDate(this.employerActiveMemberDTO[0].asofdate_thirdmonth);



            this.setupBillingGrid();
            this.mydataset = this.employerActiveMemberDTO;
            console.log("Finished getEmployerBillingContractCallBack");
        }
        else {
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }

    }

    getFormattedDate(sourcedate: Date): string {
        let wrkDate: Date = new Date(sourcedate);
        return ((wrkDate.getMonth() + 1).toString() + "/" + wrkDate.getDate().toString() + "/" + wrkDate.getFullYear().toString());
    }

    setupBillingGrid() {
        this.columnDefinitions = [
            { id: 'accountname', filterable: true, name: 'Employer Name', field: 'accountname', sortable: true, width: 225, minWidth: 225 },
            { id: 'brokername', filterable: true, name: 'TPA Name', field: 'brokername', sortable: true, width: 125, minWidth: 125 },
            { id: 'companypepm', filterable: true, name: 'nfor1 PEPM', field: 'companypepm', sortable: true, width: 125, minWidth: 125, formatter: Formatters.dollar },
            { id: 'companyinitialfee', filterable: true, name: 'Initial Fee', field: 'companyinitialfee', sortable: true, width: 125, minWidth: 125, formatter: Formatters.dollar },
            { id: 'tpapepm', filterable: true, name: 'TPA PEPM', field: 'tpapepm', sortable: true, width: 125, minWidth: 125, formatter: Formatters.dollar },
            { id: 'activelives_currentmonth', name: this.asofdate_currentmonth, field: 'activelives_currentmonth', filterable: true, sortable: true, width: 100, minWidth: 100 },
            { id: 'company_amtdue_currentmonth', filterable: true, name: 'Amount Due', field: 'company_amtdue_currentmonth', sortable: true, width: 125, minWidth: 125, formatter: Formatters.dollar },
            { id: 'activelives_previousmonth', name: this.asofdate_previousmonth, field: 'activelives_previousmonth', filterable: true, sortable: true, width: 100, minWidth: 100 },
            { id: 'company_amtdue_previousmonth', filterable: true, name: 'Amount Due', field: 'company_amtdue_previousmonth', sortable: true, width: 125, minWidth: 125, formatter: Formatters.dollar },
            { id: 'activelives_thirdmonth', name: this.asofdate_thirdmonth, field: 'activelives_thirdmonth', filterable: true, sortable: true, width: 100, minWidth: 100 },
            { id: 'company_amtdue_thirdmonth', filterable: true, name: 'Amount Due', field: 'company_amtdue_thirdmonth', sortable: true, width: 125, minWidth: 125, formatter: Formatters.dollar },
            { id: 'accountid', name: 'Account ID', field: 'accountid', width: 150, minWidth: 150 },
            { id: 'id', name: 'Id', field: 'id', sortable: true, width: 60, minWidth: 60 }

        ];
        this.gridOptions = {

            enableHeaderMenu: true,

            autoResize: {
                containerId: 'demo-container1',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,

            enableRowSelection: false,
            editable: false,
            autoEdit: false,
            autoHeight: true,   // expand the height of the grid to show all the rows
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: false,

            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },

        };
        this.gridOptions.showTopPanel = false;
    }

    onCommandEvent(event, args) {
        var menuText = event.target.innerText;


        let orderitemids: string;
        let dataView: DataView;

        var countofdata = 0;
        countofdata = this.mydataset.length;
        countofdata = this.angularGrid.dataView.getLength();
        dataView = this.angularGrid.dataView;
        var localgridobj = this.gridObj;

        console.log("***** Executed onCommandEvent: " + menuText + "  Count= " + countofdata.toString());

    }


    changeAsOfDate(e: any) {

        let asofbilldate: Date = new Date(e.target.value);

        if (!isNaN(asofbilldate.getDate())) {
            this.globaldataservice.employerbillingasofdatestr = (asofbilldate.getMonth() + 1).toString() + "/" + asofbilldate.getDate().toString() + "/" + asofbilldate.getFullYear().toString();
            let asofdatestr: string = asofbilldate.getFullYear().toString() + "-" + (asofbilldate.getMonth() + 1).toString() + "-" + asofbilldate.getDate().toString();
            this.clearAllDataSets();
            this.getEmployerContractData(asofdatestr);
        }
        else {
            this.searchError = true;
            this.searchErrorMsg = "Please enter a valid date."
        }

    }


    clearAllDataSets() {


        this.totalRowCount = 0;
        this.total_currentmonth = 0;
        this.total_previousmonth = 0;
        this.total_thirdmonth = 0;
        this.asofdate_currentmonth = "";
        this.asofdate_previousmonth = "";
        this.asofdate_thirdmonth = "";
        this.mydataset = [];
    }

    rowCountChanged(event, args) {


        this.totalRowCount = this.angularGrid.dataView.getLength();
        console.log("***** employers: rowcount is " + this.totalRowCount.toString());


    }


    onSelectedRowsChanged(e, args) {
        if (Array.isArray(args.rows)) {
            if (args.rows.length > 0) {
                this.isordernotselected = false;
                this.selectedquoteidx = args.rows.map(idx => {
                    return idx;
                });
            }
            else {
                this.isordernotselected = true;
            }
        }
    }






    // NO CUSTOM CODE BEYOND THIS POINT

    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);


    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }


    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.gridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.gridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}

