// environment.prod.ts environment variables
export const environmentprod = {
    production: true,
    APIEndpoint: 'https://prod.endpoint.com',
    audience: 'https://dev-j2-47ge3.auth0.com/api/v2/',
    redirectUri: 'http://n4onehr.azurewebsites.net/callback',
    clientID: 'aQwzwWxYKjXHpGkOq4k8wnSVFoFotPhk',
    domain: 'dev-j2-47ge3.auth0.com',
    apiEndpoint: 'https://n4onehrapi.azurewebsites.net/',
    /* B2C relted stuff */
    b2cRedirectUri: 'https://www.ebtoolkit.com/',
    tenant: 'ebtoolkit.onmicrosoft.com',
    b2cclientID: '1dc4b2e9-6f5b-4dcf-a2bb-18f20d92fa72',
    signUpSignInPolicy: 'B2C_1_Signinnew',
};


export const environment = {
    production: true,
    APIEndpoint: 'https://prod.endpoint.com'    
};