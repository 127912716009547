﻿import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ActivityService } from './activity.service';


import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';

const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';



@Component({
    templateUrl: './activity.html',
    providers: [
        ActivityService,
    ]
})

export class ActivityComponent extends BaseComponent implements OnInit {

    constructor(private route: ActivatedRoute,
        protected _router: Router,
        public activityservice :ActivityService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService,
        private cd: ChangeDetectorRef) {
        super();

        this.externalid = auth.userobjectid;
        this.useremail = auth.useremail;

    }

    columnDefinitions: Column[] = [];
    gridOptions: GridOption = {};
    mydataset: any[] = [];
    bIsLoaded: boolean = false;
    canShowGrid: boolean = false;
    angularGrid: AngularGridInstance;
    //columnDefinitions: Column[];
    // gridOptions: GridOption;
    //dataset = [];
    metrics: Metrics;

    isCountEnabled = true;
    odataVersion = 2;
    odataQuery = '';
    processing = true;
    totalRowCount: number = 0;
    status = { text: 'processing...', class: 'alert alert-danger' };

    userSelectsString = '';
    name = 'Angular';
    userSelects = [];

    searchError: boolean = false;
    searchErrorMsg: string = "";

    gridObj: any[];

    plans: any[] = [];
    externalid: string;
    useremail: string;
    adminuser: boolean = false;
    viewallmessages: boolean = false;
    brokeragename: string = "";


    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};
    }



    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }


    ngOnInit(): void {
        if (this.useremail == 'jerrymarkland@outlook.com' || this.useremail == 'jmarkland@marklandconsulting.com')
            this.adminuser = true;

        if (this.adminuser) {
            this.columnDefinitions = [
                { id: 'accountname', name: 'Activity Owner', field: 'accountname', sortable: true, width: 200, minWidth: 200 },
                { id: 'firstname', name: 'First Name', field: 'firstname', sortable: true, width: 200, minWidth: 200 },
                { id: 'lastname', name: 'Last Name', field: 'lastname', sortable: true, width: 200, minWidth: 200 },
                { id: 'message', name: 'Message', field: 'message', sortable: true, width: 300, minWidth: 300 },
                { id: 'emailaddress', name: 'Email Address', field: 'emailaddress', sortable: true, width: 275, minWidth: 200 },
                { id: 'phonenumber', name: 'Phone Number', field: 'phonenumber', sortable: true, width: 100, minWidth: 100 },
                { id: 'companyname', name: 'Company Name', field: 'companyname', sortable: true, width: 275, minWidth: 275 },
                { id: 'createdon', name: 'Created On', field: 'createdon', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
                { id: 'id', name: 'ID', field: 'id', sortable: false, width: 60, minWidth: 60 }
            ];
        }
        else {
            this.columnDefinitions = [
                { id: 'firstname', name: 'First Name', field: 'firstname', sortable: true, width: 200, minWidth: 200 },
                { id: 'lastname', name: 'Last Name', field: 'lastname', sortable: true, width: 200, minWidth: 200 },
                { id: 'message', name: 'Message', field: 'message', sortable: true, width: 300, minWidth: 300 },
                { id: 'emailaddress', name: 'Email Address', field: 'emailaddress', sortable: true, width: 275, minWidth: 200 },
                { id: 'phonenumber', name: 'Phone Number', field: 'phonenumber', sortable: true, width: 100, minWidth: 100 },
                { id: 'companyname', name: 'Company Name', field: 'companyname', sortable: true, width: 275, minWidth: 275 },
                { id: 'createdon', name: 'Created On', field: 'createdon', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
                { id: 'id', name: 'ID', field: 'id', sortable: false, width: 60, minWidth: 60 }
            ];

        }
        this.gridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: false,
            checkboxSelector: {
                // you can toggle these 2 properties to show the "select all" checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true
            },
            enableCheckboxSelector: true,

            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },
        };
        this.gridOptions.showTopPanel = false;
        this.getActivities();


    }


    //editplan() {

    //    let idx: number = 0;
    //    let numberofplans: number = 0;
    //    let planids: string[] = [];
    //    let plannames: string[] = [];
    //    let issuernames: string[] = [];

    //    this._router.navigate(['/adminplandetail',
    //        {
    //            isaddoredit: 'EDIT',
    //            standardcomponentid: this.plans[this.selectedquoteidx].id
    //        }
    //    ]);

    //}


    getActivitiesCallBack(data) {
        if (data.totalcount > 0) {
            this.totalRowCount = data.totalcount;
            this.gridOptions = Object.assign({}, this.gridOptions);
            if (this.viewallmessages)
                this.brokeragename = "All Brokers";
            else
                this.brokeragename = data.activitylist[0].accountname;

            this.mydataset = data.activitylist;
        }
        else {
            this.totalRowCount = 0;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = [];
            this.searchError = true;
            this.searchErrorMsg = "No messages were found.";

        }
    }

    getActivities() {


        this.searchError = false;
        this.searchErrorMsg = "";
        // Check for data entry errors before submitting search

        this.activityservice.getActivities(this.externalid, this.viewallmessages)
            .subscribe(
                (data) => { this.getActivitiesCallBack(data); });
    }


    onviewallmessages(e) {
        if (e.target.checked)
            this.viewallmessages = true;
        else
            this.viewallmessages = false;

        this.getActivities();


    }
    //onSelectedRowsChanged(e, args) {
    //    if (Array.isArray(args.rows)) {
    //        if (args.rows.length > 0) {
    //            this.isplannotselected = false;
    //            this.selectedquoteidx = args.rows.map(idx => {
    //                return idx;
    //            });
    //        }
    //        else {
    //            this.isplannotselected = true;
    //        }
    //    }
    //}




    // GENERAL FUNCTIONS BELOW THIS POINT


    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);


    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }

    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.gridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.gridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}






