﻿
//Auto Generated file


export class ImageDTO {
    // This file is generated from the entities located in the MAKTech.Entities project

    public id: string;
    public entityid: string;
    public entitytype: number;
    public image: number[]; 
    public imagename: string;
    public addedbyprogram: string;
    public description: string;
    public createdon: Date;
    public createdby: string;
    public modifiedon: Date;
    public modifiedby: string;

}
