﻿
//Auto Generated file sometime


  
export class IndividualHealthPlansDTO {
		// This file is generated from the entities located in the MAKTech.Entities project
 
        public id: string;
        public planid: string;
        public businessyear: number;
        public statecode: string;
        public issuerid: string;
        public issuername: string;
        public onoffexchange: string;
        public plannameshort: string;
        public planmarketingname: string;
        public plantype: string;
        public metallevel: string;
        public serviceareaid: string;
        public plandescription: string;
        public planeffectivedate: Date;
        public planexpirationdate: Date;
        public ishsaeligible: string;
        public isnewplan: string;
        public networkid: string;
        public networkname: string;
        public logourl: string;
        public sourceofdata: string;
        public status: string;
        public urlforbenefitsummarycoverage: string;
        public vendorsource: string;
        public age29rider: string;
        public adultdental: string;
        public in_annualdeductibleindividual: string;
        public out_annualdeductibleindividual: string;
        public in_annualdeductiblefamily: string;
        public out_annualdeductiblefamily: string;
        public in_annualoopmaxindividual: string;
        public out_annualoopmaxindividual: string;
        public in_annualoopmaxfamily: string;
        public out_annualoopmaxfamily: string;
        public in_primarycarecopay: string;
        public out_primarycarecopay: string;
        public in_emergencyroomcopay: string;
        public out_emergencyroomcopay: string;
        public in_hospitalstaycopay: string;
        public out_hospitalstaycopay: string;
        public in_xraysanddiagnosticimaging: string;
        public out_xraysanddiagnosticimaging: string;
        public in_specialistvisit: string;
        public out_specialistvisit: string;
        public in_emergencytransportation: string;
        public out_emergencytransportation: string;
        public in_prescriptiondrugcopay: string;
        public out_prescriptiondrugcopay: string;
        public in_genericdrugcopay: string;
        public out_genericdrugcopay: string;
        public in_preferreddrugcopay: string;
        public out_preferreddrugcopay: string;
        public in_coinsurance: string;
        public out_coinsurance: string;
        public in_urgentcare: string;
        public out_urgentcare: string;
        public in_inpatienthospitalservices: string;
        public out_inpatienthospitalservices: string;
        public in_imagingctpetscansmris: string;
        public out_imagingctpetscansmris: string;
        public in_chiropracticcare: string;
        public out_chiropracticcare: string;
        public in_nonpreferreddrugs: string;
        public out_nonpreferreddrugs: string;
        public in_specialtydrugs: string;
        public out_specialtydrugs: string;
        public in_outpatientrehab: string;
        public out_outpatientrehab: string;
        public in_habilitationservices: string;
        public out_habilitationservices: string;
        public in_skillednursingfacility: string;
        public out_skillednursingfacility: string;
        public in_substanceobusedisorderoutpatient: string;
        public out_substanceobusedisorderoutpatient: string;
        public in_substanceobusedisorderinpatient: string;
        public out_substanceobusedisorderinpatient: string;
        public in_prepostnatalcare: string;
        public out_prepostnatalcare: string;
        public in_homehealthcareservices: string;
        public out_homehealthcareservices: string;
        public in_inpatientbehavioralservices: string;
        public out_inpatientbehavioralservices: string;
        public in_durabelmedicalequipment: string;
        public out_durabelmedicalequipment: string;
        public in_hospiceservices: string;
        public out_hospiceservices: string;
        public in_outpatientbehavioralservices: string;
        public out_outpatientbehavioralservices: string;
        public in_childroutineeyeexam: string;
        public out_childroutineeyeexam: string;
        public in_eyeglassesforchildren: string;
        public out_eyeglassesforchildren: string;
        public in_preventivecare: string;
        public out_preventivecare: string;
        public createdby: string;
        public createdon: Date;
        public modifiedby: string;
        public modifiedon: Date;
    }
