// auth.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { B2CAuthService } from './b2cauth.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private authService: B2CAuthService,
        private router: Router
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.authService.isLoggedIn) {
            this.router.navigate(['/']);
            return false;
        }
        return true;
    }
}