﻿//import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminRequestAccessService } from './adminrequestaccess.service';
import { RequestAccessAndQuoteViewDTO } from '../dto/RequestAccessAndQuoteViewDTO';
import { Observable, forkJoin } from 'rxjs';


import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';

const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';



@Component({
    templateUrl: './adminrequestaccess.html',
    providers: [
        AdminRequestAccessService,
    ]
})
export class AdminRequestAccessComponent extends BaseComponent implements OnInit {

    constructor(private route: ActivatedRoute,
        protected _router: Router,
        public adminrequestaccessservcie: AdminRequestAccessService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService,
        private cd: ChangeDetectorRef) {
        super();
    }

    columnDefinitions: Column[] = [];
    gridOptions: GridOption = {};
    mydataset: any[] = [];
    bIsLoaded: boolean = false;
    canShowGrid: boolean = false;
    angularGrid: AngularGridInstance;
    //columnDefinitions: Column[];
    // gridOptions: GridOption;
    //dataset = [];
    metrics: Metrics;

    isCountEnabled = true;
    odataVersion = 2;
    odataQuery = '';
    processing = true;
    totalRowCount: number = 0;
    status = { text: 'processing...', class: 'alert alert-danger' };

    userSelectsString = '';
    name = 'Angular';
    userSelects = [];

    searchError: boolean = false;
    searchErrorMsg: string = "";

    gridObj: any[];
    numberofSelectedPlans: number = 0;
    ratingareanumber: number = 1;
    ratingareaid: string = "";
    state: string = "";
    isplannotselected: boolean = true;
    selectedquoteidx: number = -1;
    startdatestr: string = '2020-07-01';

    plans: any[] = [];


    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};
    }



    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }


    ngOnInit(): void {



        this.columnDefinitions = [
            { id: 'companyname', name: 'Requesting Company', field: 'companyname', sortable: true, width: 275, minWidth: 275 },
            { id: 'emailid', name: 'Email Address', field: 'emailid', sortable: true, width: 275, minWidth: 275 },
            { id: 'requestcreatedon', name: 'Requested On', field: 'requestcreatedon', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'accountname', name: 'Broker Name', field: 'accountname', sortable: true, width: 275, minWidth: 275 },
            { id: 'totalmembers', name: 'Total Members', field: 'totalmembers', sortable: true, width: 125, minWidth: 125 },
            { id: 'city', name: 'City', field: 'city', sortable: true, width: 100, minWidth: 100 },
            { id: 'state', name: 'State', field: 'state', sortable: true, width: 75, minWidth: 75 },
            { id: 'postalcode', name: 'Zip Code', field: 'postalcode', sortable: true, width: 100, minWidth: 100 },
            { id: 'effectivedate', name: 'Effective Date', field: 'effectivedate', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'currentemployeemonthlyrate', name: 'Current Rate', field: 'currentemployeemonthlyrate', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'totalcurrentemployeemonthlyrate', name: 'Total Current Cost', field: 'totalcurrentemployeemonthlyrate', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'totalcost1', name: 'Total Cost Gold Plan', field: 'totalcost1', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'totalcost2', name: 'Total Cost Silver Plan', field: 'totalcost2', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'totalcost3', name: 'Total Cost Bronze Plan', field: 'totalcost3', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'currentdeductible', name: 'Current Deductible', field: 'currentdeductible', sortable: true, width: 100, minWidth: 100 },
            { id: 'id', name: 'ID', field: 'id', sortable: false, width: 60, minWidth: 60 }
            //{ id: 'individualrate', name: 'Age 21 Rate', field: 'individualrate', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
        ];
        this.gridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: false,
            checkboxSelector: {
                // you can toggle these 2 properties to show the "select all" checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true
            },
            enableCheckboxSelector: true,

            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },
        };
        this.gridOptions.showTopPanel = false;
        this.getRequestAccessAndQuotes();


    }


    //editplan() {

    //    let idx: number = 0;
    //    let numberofplans: number = 0;
    //    let planids: string[] = [];
    //    let plannames: string[] = [];
    //    let issuernames: string[] = [];

    //    this._router.navigate(['/adminplandetail',
    //        {
    //            isaddoredit: 'EDIT',
    //            standardcomponentid: this.plans[this.selectedquoteidx].id
    //        }
    //    ]);

    //}


    getRequestAccessAndQuotesCallback(data) {
        if (data.length > 0) {
            this.totalRowCount = data.length;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = data;
        }
        else {
            this.totalRowCount = 0;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = [];
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }
    }

    getRequestAccessAndQuotes() {


        this.searchError = false;
        this.searchErrorMsg = "";
        // Check for data entry errors before submitting search

        this.adminrequestaccessservcie.getRequestAccessQuotes(this.startdatestr, 1)
            .subscribe(
                (data) => { this.getRequestAccessAndQuotesCallback(data); });
    }


    requestaccess() {
        this.getRequestAccessAndQuotes();

    }

    changestartdate(event) {



    }
    //onSelectedRowsChanged(e, args) {
    //    if (Array.isArray(args.rows)) {
    //        if (args.rows.length > 0) {
    //            this.isplannotselected = false;
    //            this.selectedquoteidx = args.rows.map(idx => {
    //                return idx;
    //            });
    //        }
    //        else {
    //            this.isplannotselected = true;
    //        }
    //    }
    //}




    // GENERAL FUNCTIONS BELOW THIS POINT


    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);


    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }

    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.gridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.gridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}






