﻿
//Auto Generated file

  
export class ACAUniquePlanDTO {
		// This file is generated from the entities located in the MAKTech.Entities project
 
        public id: string;
        public issuername: string;
        public planmarketingname: string;
        public businessyear: number;
        public statecode: string;
        public issuerid: string;
        public tin: string;
        public networkid: string;
        public networkname: string;
        public coinsurance: number;
        public marketcoverage: string;
        public serviceareaid: string;
        public plantype: string;
        public metallevel: string;
        public qhpnonqhptypeid: string;
        public planeffectivedate: Date;
        public planexpirationdate: Date;
        public manuallyentered: string;
        public annualdeductibleindividual: string;
        public annualdeductiblefamily: string;
        public annualoopmaxindividual: string;
        public annualoopmaxfamily: string;
        public ishsaeligible: string;
        public creaedon: Date;
        public createdby: string;
        public modifiedon: Date;
        public modifiedby: string;
    }
