﻿import { Component } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { Injectable } from '@angular/core';
import { HttpModule } from '@angular/http';
import 'rxjs/add/operator/map';
import { ExtendedHttpService } from './extendedhttp.service';
import { LoginDetailDTO } from '../dto/LoginDetailDTO';
import { EnvironmentService } from './environment.service';
import { map } from 'rxjs/operators';


@Injectable()
export class IpAddressService extends ExtendedHttpService{

 
    constructor(private env: EnvironmentService) {
        super()
    }

    getIpAddress() {

        return this._http.get('https://ipapi.co/json/').map((data) => data.json())

    }


    addIpAddress(logindetaildto: LoginDetailDTO) {
        logindetaildto.city = 'tg';
        logindetaildto.country = 'ff';
        logindetaildto.ipaddress = 'ff';
       
        
        //logindetaildto.userid = this.globalService.userService.userid;
        return this.postData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/LoginDetail/', logindetaildto).
            pipe(
                map((data) => data.json())
            );
        
    }


      
}