import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';


import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';

const endpoint = 'https://localhost:44373/api/values';

//const endpoint ='https://n4onehrapi.azurewebsites.net/api/values';
const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
@Injectable({
  providedIn: 'root'
})
export class AllContractsService {

    constructor(private http: HttpClient,
        private auth: B2CAuthService,
    private env:EnvironmentService) { }

    public getData(): Observable<any> {


        var reqHeader = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.auth.accesstoken,
           'Access-Control-Allow-Origin': '*'
        });
        //const headers = new Headers();
      
        //headers.append('Authorization', 'Bearer ' + this.auth.accessToken);
        

        return this.http.get("https://enrichlyhr-api-app.azurewebsites.net/", { headers: reqHeader }).pipe(
            map(this.extractData));
    }


    private extractData(res: Response) {
        let body = res;
        return body || {};
    }
}
