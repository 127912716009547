﻿//import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountAndAddressDTO } from '../dto/AccountAndAddressDTO';
import { EmployerAccountService } from './employeraccount.service';
import { GlobaldataService } from '../service/globaldata.service';
//import * as moment from 'moment-mini';


import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';
import { ResponseBaseDTO } from '../dto/ResponseBaseDTO';

const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';
const EMPLOYER = 1;



@Component({
    templateUrl: 'employeraccount.component.html',
    providers: [
        EmployerAccountService,
        ListService
    ]
})
export class EmployerAccountComponent extends BaseComponent implements OnInit {

    columnDefinitions: Column[] = [];
    gridOptions: GridOption = {};
    mydataset: any[] = [];
    bIsLoaded: boolean = false;
    canShowGrid: boolean = false;
    angularGrid: AngularGridInstance;
    metrics: Metrics;
    //companyName: string;
    isCountEnabled = true;
    odataVersion = 2;
    odataQuery = '';
    processing = true;
    totalRowCount: number = 0;
    status = { text: 'processing...', class: 'alert alert-danger' };

    userSelectsString = '';
    name = 'Angular';
    userSelects = [];
    dropdownList = [];
    //    selectedItems = [];
    dropdownSettings = {};
    searchError: boolean = false;
    searchErrorMsg: string = "";

    gridObj: any[];
    selectedGridIDS: number[];
    selectedTitles: any[];
    selectedTitle: any;

    useremail: string;
    userobjectid: string;
    isaccountnotselected: boolean = true;
    showEmployeazeCustomers: boolean = false;

    acctaddrDTO: AccountAndAddressDTO;

    selectedquoteidx: number = -1;


    constructor(
        public employeraccountservice: EmployerAccountService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService,
        private cd: ChangeDetectorRef,
        protected _router: Router,
        private route: ActivatedRoute,
        private globalDataService: GlobaldataService) {

        super();

        this.userobjectid = auth.userobjectid;
        this.useremail = auth.useremail;
    }


    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};
    }



    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }


    ngOnInit(): void {



        this.columnDefinitions = [
            { id: 'accountname', filterable: true, name: 'Employer Name', field: 'accountname', sortable: true, width: 275, minWidth: 275 },
            { id: 'postalcode', name: 'Zip Code', field: 'postalcode', sortable: true, width: 100, minWidth: 100 },
            { id: 'city', name: 'City', field: 'city', sortable: true, width: 100, minWidth: 100 },
            { id: 'stateorprovince', filterable: true, name: 'State', field: 'stateorprovince', sortable: true, width: 100, minWidth: 100 },
            { id: 'county', name: 'County', field: 'county', sortable: true, width: 100, minWidth: 100 },
            { id: 'numberofemployees', name: 'Number of Employees', field: 'numberofemployees', sortable: true, width: 125, minWidth: 125 },
            { id: 'displayname', filterable: true, name: 'Owner Name', field: 'displayname', sortable: true, width: 275, minWidth: 275 },            
            { id: 'renewaldate', name: 'Effective Date', field: 'renewaldate', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'createdon', name: 'Create Date', field: 'createdon', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'id', name: 'Account Id', field: 'id', sortable: false, width: 60, minWidth: 60 }
        ];
        this.gridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: true,
            checkboxSelector: {
                // you can toggle these 2 properties to show the "select all" checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true,
            },
            enableCheckboxSelector: true,

            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },
        };
        this.gridOptions.showTopPanel = false;
        this.getEmployerAccounts();

        //this.angularGrid.resizerService.pauseResizer(true);
    }

    getEmployerAccounts() {

        this.searchError = false;
        let customertype: number = 0;
        if (this.showEmployeazeCustomers)
            customertype = 1;

        this.employeraccountservice.getEmployerAccounts(this.userobjectid, EMPLOYER, customertype)
            .subscribe(
                (data) => { this.getEmployerAccountsCallBack(data); });

    }

    openaccount() {

        var filteredItems = this.angularGrid.dataView.getFilteredItems();

        if (this.globalDataService.employeazesetup) {
            this._router.navigate(['/employersetup',
                {
                    addingaccount: 0,
                    acctaddrdto: JSON.stringify(filteredItems[this.selectedquoteidx])
                }
            ]);
        }
        else {
            this._router.navigate(['/accountdetail',
                {
                    addingaccount: 0,
                    acctaddrdto: JSON.stringify(filteredItems[this.selectedquoteidx])
                }
            ]);
        }
    }

    addaccount() {

        if (this.globalDataService.employeazesetup) {
            this._router.navigate(['/employersetup',
                {
                    addingaccount: 1
                }
            ]);
        } else {
            this._router.navigate(['/accountdetail',
                {
                    addingaccount: 1
                }
            ]);
        }
    }

    openenrollments() {

        var filteredItems = this.angularGrid.dataView.getFilteredItems();

        this._router.navigate(['/enrollment',
            {
                acctaddrdto: JSON.stringify(filteredItems[this.selectedquoteidx])
            }
        ]);

    }


    getEmployerAccountsCallBack(data) {


        if (data.totalcount > 0) {
            this.searchError = false;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.totalRowCount = data.totalcount;

            this.mydataset = data.accountaddresslist;
            this.globalDataService.employers = data.accountaddresslist;
            this.globalDataService.employercount = this.totalRowCount;
        }
        else {
            this.totalRowCount = 0;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = [];
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }

    }


    onSelectedRowsChanged(e, args) {
        if (Array.isArray(args.rows)) {
            if (args.rows.length > 0) {
                this.isaccountnotselected = false;
                this.selectedquoteidx = args.rows.map(idx => {
                    return idx;
                });
            }
            else {
                this.isaccountnotselected = true;
            }
        }
    }

    onlyShowEmployeazeCustomers(e) {
        if (e.target.checked) {
            this.showEmployeazeCustomers = true;
        }
        else {
            this.showEmployeazeCustomers = false;
        }
        this.getEmployerAccounts();

    }


    // NO CUSTOM CODE BEYOND THIS POINT

    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);


    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }


    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.gridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.gridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}






