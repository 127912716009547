﻿//import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobaldataService } from '../service/globaldata.service';
import { AdminPlansService } from './adminplans.service';
import { ICQuoteDTO } from '../dto/ICQuoteDTO';

import { Observable, forkJoin } from 'rxjs';


import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
    AngularSlickgridComponent,
    Filter
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';

const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';



@Component({
    templateUrl: './adminplans.html',
    providers: [
        AdminPlansService,
        ListService
    ]
})
export class AdminPlansComponent extends BaseComponent implements OnInit {

    constructor(private route: ActivatedRoute,
        protected _router: Router,
        public adminplansservice: AdminPlansService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService,
        private cd: ChangeDetectorRef,
        private globaldataservice: GlobaldataService) {
        super();

        this.route.params.subscribe(params => {
            this.state = params.state;
            this.businessyear = params.businessyear;
            this.planid = params.planid;

        });


    }

    columnDefinitions: Column[] = [];
    gridOptions: GridOption = {};
    // filters: Filter = {};

    mydataset: any[] = [];
    bIsLoaded: boolean = false;
    canShowGrid: boolean = false;
    angularGrid: AngularGridInstance;
    //columnDefinitions: Column[];
    // gridOptions: GridOption;
    //dataset = [];
    metrics: Metrics;

    isCountEnabled = true;
    odataVersion = 2;
    odataQuery = '';
    processing = true;
    totalRowCount: number = 0;
    status = { text: 'processing...', class: 'alert alert-danger' };

    stateList: States[];
    statesearch: string = "0";
    statecode: string = "";
    planid: string = "";

    userSelectsString = '';
    name = 'Angular';
    userSelects = [];

    searchError: boolean = false;
    searchErrorMsg: string = "";

    gridObj: any[];
    selectedGridIDS: number[];
    selectedTitles: any[];
    selectedTitle: any;
    selectedplanids: any[];
    selectedplannames: any[];
    selectedissuernames: any[];
    numberofSelectedPlans: number = 0;
    ratingareanumber: number = 1;
    ratingareaid: string = "";
    state: string = "";
    icquotedto: ICQuoteDTO;
    isplannotselected: boolean = true;
    selectedquoteidx: number = -1;
    businessyear: number = 2022;

    plans: any[] = [];
    carriersearch: string = "";
    dataview: any;
    


    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};
       //  this.angularGrid.dataView.setFilter(this.carrierFilter);
    }



    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }

    statechange(event) {
        this.statesearch = event;
        this.getPlansForState();
    }



    monthchange(event) {
    }

    
    ngOnInit(): void {



        this.columnDefinitions = [
            { id: 'issuername', filterable: true, name: 'Carrier Name', field: 'issuername', sortable: true, width: 275, minWidth: 275 },
            { id: 'planmarketingname', name: 'Plan Name', field: 'planmarketingname', sortable: true, width: 275, minWidth: 275 },
            { id: 'metallevel', name: 'Metal Level', field: 'metallevel', sortable: true, width: 100, minWidth: 100 },
            { id: 'ishsaeligible', name: 'HSA Eligible', field: 'ishsaeligible', sortable: true, width: 100, minWidth: 100 },
            { id: 'in_annualdeductibleindividual', name: 'Individual Deductible', field: 'in_annualdeductibleindividual', sortable: true, width: 125, minWidth: 125 },
            { id: 'in_annualdeductiblefamily', name: 'Family Deductible', field: 'in_annualdeductiblefamily', sortable: true, width: 125, minWidth: 125 },
            { id: 'in_annualoopmaxindividual', name: 'Individual OOP Max', field: 'in_annualoopmaxindividual', sortable: true, width: 125, minWidth: 125 },
            { id: 'in_annualoopmaxfamily', name: 'Family OOP Max', field: 'in_annualoopmaxfamily', sortable: true, width: 125, minWidth: 125 },
            { id: 'modifiedon', name: 'Last Modified', field: 'modifiedon', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'modifiedby', name: 'Modified By', field: 'modifiedby', sortable: true, width: 125, minWidth: 125 },
            { id: 'onoffexchange', name: 'On/Off Exchange', field: 'onoffexchange', sortable: true, width: 100, minWidth: 100 },
            { id: 'planeffectivedate', name: 'Effective Date', field: 'planeffectivedate', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'plantype', name: 'Plan Type', field: 'plantype', sortable: true, width: 125, minWidth: 125 },
            { id: 'id', name: 'Plan Id', field: 'id', sortable: false, width: 60, minWidth: 60 }
            //{ id: 'individualrate', name: 'Age 21 Rate', field: 'individualrate', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
        ];
        this.gridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: true,
            checkboxSelector: {
                // you can toggle these 2 properties to show the "select all" checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true
            },
            enableCheckboxSelector: true,

            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },
        };
        this.gridOptions.showTopPanel = false;
        // this.dataview.setFilter(this.carrierFilter);      

        if (this.businessyear == undefined)
            this.businessyear = 2022;


        if (this.globaldataservice.stateList == undefined) {
            this.listservice.getLists("states").subscribe(data => {
                this.getlistsResponse(data);
            });
        }
        else {
            this.stateList = this.globaldataservice.stateList;
            if (this.state !== undefined && this.state !== "") {
                this.statesearch = this.state;
                this.getPlansForState();
            }
        }

    }


    getlistsResponse(data) {
        //this.stateList = data;
        this.stateList = data["states"];
        this.globaldataservice.stateList = this.stateList;
    }


    addplan() { }

    editplan() {

        let idx: number = 0;
        let numberofplans: number = 0;
        let planids: string[] = [];
        let plannames: string[] = [];
        let issuernames: string[] = [];

        var filteredItems = this.angularGrid.dataView.getFilteredItems();
        // JJM 2022

            this._router.navigate(['/adminplandetail2021',
                {
                    isaddoredit: 'EDIT',
                    businessyear: this.businessyear,
                    id: filteredItems[this.selectedquoteidx].id

                }
            ]);

    }

    reset() {

        this.statecode = "";

        //       this.selectedItems = [];
        this.selectedplanids = [];
        this.selectedplannames = [];
        this.selectedissuernames = [];
        // this.angularGrid.gridService.setSelectedRows([]);
        this.totalRowCount = 0;
        this.gridOptions = Object.assign({}, this.gridOptions);
        this.mydataset = [];
        this.searchError = false;
        this.state = "";
        this.ratingareaid = "";
        this.ratingareanumber = 0;
        this.statesearch = "";

    }


    getPlansByStateCallback(data) {
        if (data.totalcount > 0) {

            this.gridOptions.pagination.totalItems = data.totalcount;
            this.totalRowCount = data.totalcount;
            this.gridOptions = Object.assign({}, this.gridOptions);
                this.mydataset = data.indplans;
                this.plans = data.indplans;
        }
        else {
            this.totalRowCount = data.totalcount;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = [];
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }
    }

    getPlansForState() {


        this.searchError = false;
        this.searchErrorMsg = "";
        // Check for data entry errors before submitting search

        this.adminplansservice.getPlansByState(this.statesearch, this.businessyear)
            .subscribe(
                (data) => { this.getPlansByStateCallback(data); });
    }






    /** Dispatched event of a Grid State Changed event */
    gridStateChanged(gridStateChanges: GridStateChange) {
        console.log('Client sample, Grid State changed:: ', gridStateChanges);
        let test: any;
        let a: number;

        // test = gridStateChanges;
        a = 0;

    }


    onSelectedRowsChanged(e, args) {
        if (Array.isArray(args.rows)) {
            if (args.rows.length > 0) {
                this.isplannotselected = false;
                this.selectedquoteidx = args.rows.map(idx => {
                    return idx;
                });
            }
            else {
                this.isplannotselected = true;
            }
        }
    }

    carrierFilter(item, args) {

        let teststr: string = "";

        teststr = item["issuername"];

        if (teststr.startsWith(this.carriersearch)) {
            return true;
        }
        return false;

    }





    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);


    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }

    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.gridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.gridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}






