﻿//import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ACAPlanLookupService } from './acaplanlookup.service';
import { TotalCostService } from './totalcost.service';
import { ICQuoteDTO } from '../dto/ICQuoteDTO';
import { Observable, forkJoin } from 'rxjs';


import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';

const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';



@Component({
    templateUrl: './acaplanlookup.component.html',
    providers: [
        ACAPlanLookupService,
        ListService
    ]
})
export class ACAPlanLookupComponent extends BaseComponent implements OnInit {

    constructor(private route: ActivatedRoute,
        protected _router: Router,
        public acaplanlookupservice: ACAPlanLookupService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService,
        private cd: ChangeDetectorRef) {
        super();
    }

    columnDefinitions: Column[] = [];
    gridOptions: GridOption = {};
    mydataset: any[] = [];
    bIsLoaded: boolean = false;
    canShowGrid: boolean = false;
    angularGrid: AngularGridInstance;
    //columnDefinitions: Column[];
    // gridOptions: GridOption;
    //dataset = [];
    metrics: Metrics;
    companyName: string;
    isCountEnabled = true;
    odataVersion = 2;
    odataQuery = '';
    processing = true;
    totalRowCount: number = 0;
    status = { text: 'processing...', class: 'alert alert-danger' };

    zipcode: string = "";

    userSelectsString = '';
    name = 'Angular';
    userSelects = [];
    dropdownList = [];
//    selectedItems = [];
    dropdownSettings = {};
    searchError: boolean = false;
    searchErrorMsg: string = "";
    displaytitle: string = "Compare Rates of up to 4 Plans";

    gridObj: any[];
    selectedGridIDS: number[];
    selectedTitles: any[];
    selectedTitle: any;
    selectedplanids: any[];
    selectedplannames: any[];
    selectedissuernames: any[];
    numberofSelectedPlans: number = 0;
    ratingareanumber: number = 1;
    ratingareaid: string = "";
    city: string = "";
    state: string = "";
    isgetratesdisabled: boolean = true;
    iscompareplandisabled: boolean = true;
    icquotedto: ICQuoteDTO;
    iscostonly: boolean = false;


    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};
    }



    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }

    statechange(event) {

    }


    monthchange(event) {
    }


    ngOnInit(): void {

        if (this.auth.role === 'MAONLYANDCOST') {
            this.iscostonly = true;
            this.displaytitle = "Select up to 4 plans from available list in Massachusetts";
        }

        this.columnDefinitions = [
            { id: 'planmarketingname', name: 'Plan Name', field: 'planmarketingname', sortable: true, width: 275, minWidth: 275 },
            { id: 'issuername', name: 'Carrier Name', field: 'issuername', sortable: true, width: 275, minWidth: 275 },
            { id: 'metallevel', name: 'Metal Level', field: 'metallevel', sortable: true, width: 100, minWidth: 100 },
            { id: 'individualrate', name: 'Age 21 Rate', field: 'individualrate', sortable: true, width: 125, minWidth: 100, formatter: Formatters.dollar },
            { id: 'ishsaeligible', name: 'HSA Eligible', field: 'ishsaeligible', sortable: true, width: 100, minWidth: 100 },
            { id: 'annualdeductibleindividual', name: 'Individual Deductible', field: 'annualdeductibleindividual', sortable: true, width: 125, minWidth: 125 },
            { id: 'annualdeductiblefamily', name: 'Family Deductible', field: 'annualdeductiblefamily', sortable: true, width: 125, minWidth: 125 },
            { id: 'annualoopmaxindividual', name: 'Individual OOP Max', field: 'annualoopmaxindividual', sortable: true, width: 125, minWidth: 125 },
            { id: 'annualoopmaxfamily', name: 'Family OOP Max', field: 'annualoopmaxfamily', sortable: true, width: 125, minWidth: 125 },
            { id: 'onoffexchange', name: 'On/Off Exchange', field: 'onoffexchange', sortable: true, width: 100, minWidth: 100 },
            //{ id: 'planeffectivedate', name: 'Effective Date', field: 'planeffectivedate', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateIso },
            { id: 'plantype', name: 'Plan Type', field: 'plantype', sortable: true, width: 125, minWidth: 125 },
            { id: 'id', name: 'Plan Id', field: 'id', sortable: false, width: 60, minWidth: 60 }
        ];
        this.gridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,
            

            // Multi-row selection support
            enableFiltering: false,
            checkboxSelector: {
                // you can toggle these 2 properties to show the "select all" checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: false
            },
            enableCheckboxSelector: true,

            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },
        };
        this.gridOptions.showTopPanel = false;

        //this.angularGrid.resizerService.pauseResizer(true);
    }


    search() {

        this.canShowGrid = true;
        this.getCustomerApiCall(null);
        // this.angularGrid.pluginService.refreshBackendDataset();
    }


    getrates() {

        this._router.navigate(['/acaplansandrates',
            {
                ratingareaid: this.ratingareaid,
                city: this.city,
                state: this.state,
                zipcode: this.zipcode,
                numberofSelectedPlans: this.numberofSelectedPlans,
                planids: this.selectedplanids,
                plannames: this.selectedplannames
            }
        ]);

    }



    contributionstrategy() {

        this._router.navigate(['/contributionstrategy',
            {
                ratingareaid: this.ratingareaid,
                city: this.city,
                state: this.state,
                zipcode: this.zipcode,
                numberofSelectedPlans: this.numberofSelectedPlans,
                planids: this.selectedplanids,
                plannames: this.selectedplannames,
                issuernames: this.selectedissuernames
            }
        ]);        
    }

    plancompare() {




        this._router.navigate(['/plancostcompare',
            {
                ratingareaid: this.ratingareaid,
                city: this.city,
                state: this.state,
                zipcode: this.zipcode,
                numberofSelectedPlans: this.numberofSelectedPlans,
                planids: this.selectedplanids,
                plannames: this.selectedplannames
            }
        ]);
    }

    totalcost() {
        this._router.navigate(['/totalcost',
            {
                ratingareaid: this.ratingareaid,
                city: this.city,
                state: this.state,
                zipcode: this.zipcode,
                numberofSelectedPlans: this.numberofSelectedPlans,
                planids: this.selectedplanids,
                plannames: this.selectedplannames,
                issuernames: this.selectedissuernames
            }
        ]);


    }


    reset() {

        this.zipcode = "";

 //       this.selectedItems = [];
        this.selectedplanids = [];
        this.selectedplannames = [];
        this.selectedissuernames = [];
        // this.angularGrid.gridService.setSelectedRows([]);
        this.totalRowCount = 0;
        this.gridOptions = Object.assign({}, this.gridOptions);
        this.mydataset = [];
        this.searchError = false;
        this.city = "";
        this.state = "";
        this.ratingareaid = "";
        this.ratingareanumber = 0;
        this.isgetratesdisabled = true;
        this.iscompareplandisabled = true;
    }

    canShowartifacts() {
        if (this.city != "" && this.state != "" && this.ratingareaid != "") {
            return true;
        } else{
            return false;
        }
    }


    getCustomerCallback(data) {
        if (data.totalcount > 0) {

            this.gridOptions.pagination.totalItems = data.totalcount;
            this.totalRowCount = data.totalcount;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = data.acaUniquePlans;
            this.city = data.city;
            this.state = data.state;
            this.ratingareaid = data.ratingareaid;
        }
        else {
            this.totalRowCount = data.totalcount;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = [];
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;
            this.city = "";
            this.state = "";
            this.ratingareaid = "";
            this.ratingareanumber = 0;

        }
    }

    getCustomerApiCall(query) {

        this.searchError = false;
        this.acaplanlookupservice.getPlansForServiceAreaWithUserId(this.zipcode, this.auth.userobjectid, "2022", "JJM_JUNK")
            .subscribe(
                (data) => { this.getCustomerCallback(data); });
    }

    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);


    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }



    /** Dispatched event of a Grid State Changed event */
    gridStateChanged(gridStateChanges: GridStateChange) {
        console.log('Client sample, Grid State changed:: ', gridStateChanges);
        let test: any;
        let a: number;

        // test = gridStateChanges;
        a = 0;

    }

    /** Dispatched event of a Grid State Changed event */
    grid1StateChanged(gridStateChanges: GridStateChange) {
        console.log('Grid State changed:: ', gridStateChanges);
        console.log('Grid State changed:: ', gridStateChanges.change);
    }

    ///** Dispatched event of a Grid State Changed event */
    //grid2StateChanged(gridStateChanges: GridStateChange) {
    //    console.log('Grid State changed:: ', gridStateChanges);
    //    console.log('Grid State changed:: ', gridStateChanges.change);

    //    if (gridStateChanges.gridState.rowSelection) {
    //        this.selectedGridIDS = (gridStateChanges.gridState.rowSelection.filteredDataContextIds || []) as number[];
    //        this.selectedGridIDS = this.selectedGridIDS.sort((a, b) => a - b); // sort by ID
    //        this.selectedTitles = this.selectedGridIDS.map(dataContextId => `Task ${dataContextId}`);
    //        this.cd.detectChanges();
    //    }
    //}


    onSelectedRowsChanged(e, args) {
        if (Array.isArray(args.rows)) {

            this.isgetratesdisabled = false;
            this.iscompareplandisabled = false;

            this.searchError = false;
            this.numberofSelectedPlans = args.rows.length;
            if (this.numberofSelectedPlans > 4) {
                this.searchError = true;
                this.searchErrorMsg = "You can only select up to 4 plans.";
                this.isgetratesdisabled = true;
                this.iscompareplandisabled = true;
                return;
            }
            else if (this.numberofSelectedPlans == 0) {
                this.isgetratesdisabled = true;
                this.iscompareplandisabled = true;
                return;                
            }
            else if (this.numberofSelectedPlans > 3) {
                this.iscompareplandisabled = true;
            }

            this.selectedplanids = args.rows.map(idx => {
                const item = this.angularGrid.gridService.getDataItemByRowIndex(idx);  
                return item.id || '';
            });
            this.selectedplannames = args.rows.map(idx => {
                const item = this.angularGrid.gridService.getDataItemByRowIndex(idx);
                return item.planmarketingname + "|" || '';
            });
            this.selectedissuernames = args.rows.map(idx => {
                const item = this.angularGrid.gridService.getDataItemByRowIndex(idx);
                return item.issuername || '';
            });
        }
    }



    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.gridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.gridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}






