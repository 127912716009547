﻿//import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminRequestAccessService } from './adminrequestaccess.service';
import { RequestAccessAndQuoteViewDTO } from '../dto/RequestAccessAndQuoteViewDTO';
import { Observable, forkJoin } from 'rxjs';

import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';
import { LogFactService } from './logfact.service';
import { OrderPayrollService } from './orderpayroll.service';


const defaultPageSize = 1000;
const sampleDataRoot = 'assets/data';



@Component({
    templateUrl: './logfact.component.html',
    providers: [
        LogFactService
    ]

})
export class LogFactComponent extends BaseComponent implements OnInit {

    constructor(private route: ActivatedRoute,
        protected _router: Router,
        public adminrequestaccessservcie: AdminRequestAccessService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService,
        private cd: ChangeDetectorRef,
        public logfactservice: LogFactService,
        public orderService: OrderPayrollService,
    ) {
        super();
        this.userobjectid = auth.userobjectid;
    }

    columnDefinitions: Column[] = [];
    gridOptions: GridOption = {};
    mydataset: any[] = [];
    bIsLoaded: boolean = false;
    canShowGrid: boolean = false;
    angularGrid: AngularGridInstance;
    //columnDefinitions: Column[];
    // gridOptions: GridOption;
    //dataset = [];
    metrics: Metrics;

    isCountEnabled = true;
    odataVersion = 2;
    odataQuery = '';
    processing = true;
    totalRowCount: number = 0;
    status = { text: 'processing...', class: 'alert alert-danger' };

    userSelectsString = '';
    name = 'Angular';
    userSelects = [];

    searchError: boolean = false;
    searchErrorMsg: string = "";

    gridObj: any[];
    isplannotselected: boolean = true;
    selectedquoteidx: number = -1;


    startdatestr: string = '';
    startdate: Date;
    enddatestr: string = '';
    enddate: Date;
    employerid: string = '00000000-0000-0000-0000-000000000000';
    employeazecustomerlist: SelectList[] = [];
    userobjectid: string;
    logtypeoption: string = "1";

    logtypeoptionlist: SelectList[] = [
        {
            id: "2",
            name: "ALL LOGS"
        }

    ];



    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};
    }



    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }


    ngOnInit(): void {

        this.setupLogFactGrid();

        this.enddate = new Date();
        this.enddate.setDate(this.enddate.getDate() + 1);
        this.startdate = new Date();
        this.startdate.setDate(this.startdate.getDate() - 1);

        this.startdatestr = (this.startdate.getMonth() + 1).toString() + "/" + this.startdate.getDate().toString() + "/" + this.startdate.getFullYear().toString();
        this.enddatestr = (this.enddate.getMonth() + 1).toString() + "/" + this.enddate.getDate().toString() + "/" + this.enddate.getFullYear().toString();

        this.gridOptions.showTopPanel = false;

        this.orderService.getEmployeazeCustomers(this.userobjectid)
            .subscribe(
                (data) => { this.getEmployeazeCustomersCallBack(data); });

    }

    getEmployeazeCustomersCallBack(data) {
        let idx: number = 0;
        let newidx: number = 0;
        let locationfound: boolean = false;

        if (data !== undefined && data.totalcount > 0) {
            //this.employeazecustomerlist.push({
            //    id: "00000000-0000-0000-0000-000000000000",
            //    name: "-- VIEW ALL -- "

            //});

            while (idx < data.totalcount) {
                this.employeazecustomerlist.push(
                    {
                        id: data.accountlist[idx].accountid,
                        name: data.accountlist[idx].accountname
                    }
                )
                idx++;
            }
        }
        else {
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }

    }

    setupLogFactGrid() {
        this.columnDefinitions = [

            { id: 'logtypetitle', filterable: true, name: 'Log Type', field: 'logtypetitle', sortable: true, width: 175, minWidth: 175 },
            { id: 'employername', filterable: true, name: 'Employer Name', field: 'employername', sortable: true, width: 200, minWidth: 200 },
            { id: 'membername', filterable: true, name: 'Member Name', field: 'membername', sortable: true, width: 175, minWidth: 175 },
            { id: 'createdon', name: 'Created On', field: 'createdon', sortable: true, width: 175, minWidth: 175, formatter: Formatters.dateTimeUs },
            { id: 'city', name: 'City', field: 'city', sortable: true, width: 100, minWidth: 100 },
            { id: 'state', name: 'State', field: 'state', sortable: true, width: 70, minWidth: 70 },
            { id: 'zipcode', name: 'Zip Code', field: 'zipcode', sortable: true, width: 100, minWidth: 100 },
            { id: 'dateofbirth', name: 'Birth Date', field: 'dateofbirth', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateUs },
            { id: 'gender', name: 'Gender', field: 'gender', sortable: true, width: 100, minWidth: 100 },
            { id: 'dependentcount', name: '# Dependents', field: 'dependentcount', sortable: false, width: 40, minWidth: 40 },

            { id: 'carriername', filterable: true, name: 'Carrier', field: 'carriername', sortable: true, width: 175, minWidth: 175 },
            { id: 'planname', filterable: true, name: 'Plan Name', field: 'planname', sortable: true, width: 175, minWidth: 175 },
            { id: 'planid', filterable: true, name: 'Plan Id', field: 'planid', sortable: true, width: 175, minWidth: 175 },
            { id: 'plancost', filterable: true, name: 'Cost', field: 'plancost', sortable: true, width: 100, minWidth: 100, formatter: Formatters.dollar },

            { id: 'policyeffectivedate', name: 'Policy Effective Date', field: 'policyeffectivedate', sortable: true, width: 120, minWidth: 100, formatter: Formatters.dateUs },
            { id: 'quotesource', name: 'Quoted By', field: 'quotesource', sortable: false, width: 100, minWidth: 100 },
            { id: 'ipcity', name: 'IP City', field: 'ipcity', sortable: true, width: 100, minWidth: 100 },
            { id: 'ipstate', name: 'IP State', field: 'ipstate', sortable: true, width: 70, minWidth: 70 },
            { id: 'id', name: '#', field: 'id', sortable: false, width: 40, minWidth: 40 },

        ];
        this.gridOptions = {
            /// enableAutoResize: true,
            //enablePagination: true,

            autoResize: {
                containerId: 'demo-container',
                sidePadding: 15
            },
            enableCellNavigation: true,
            enableAutoResize: true,
            enableColumnReorder: false,
            forceFitColumns: false,
            enableRowSelection: true,
            // multiSelect: true,


            // Multi-row selection support
            enableFiltering: true,
            checkboxSelector: {
                // you can toggle these 2 properties to show the "select all" checkbox in different location
                hideInFilterHeaderRow: false,
                hideInColumnTitleRow: true
            },
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true
            },
            enableCheckboxSelector: true,

            // Paging Support
            pagination: {
                pageSizes: [50, 1000],
                pageSize: defaultPageSize,
                totalItems: 50
            },
        };
        this.gridOptions.showTopPanel = false;

    }

    getLogFactsCallback(data) {
        if (data !== undefined && data.totalcount > 0) {
            this.totalRowCount = data.totalcount;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = data.logfacts;
        }
        else {
            this.totalRowCount = 0;
            this.gridOptions = Object.assign({}, this.gridOptions);
            this.mydataset = [];
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;

        }
    }

    getLogFacts() {


        this.searchError = false;
        this.searchErrorMsg = "";
        // Check for data entry errors before submitting search

        //if (this.employerid === this.guidEmpty()) {

        //    this.logfactservice.getLogFacts(this.employerid, this.startdate, this.enddate)
        //        .subscribe(
        //            (data) => { this.getLogFactsCallback(data); });
        //}

            this.logfactservice.getLogFactsByOption(this.employerid, this.startdate, this.enddate, parseInt(this.logtypeoption))
                .subscribe(
                    (data) => { this.getLogFactsCallback(data); });
        
    }


    searchLogFacts() {
        this.getLogFacts();

    }

    changeStartDate(e: any) {
        var a;
        let adate: Date;

        a = e.target.value;
        this.startdate = new Date(a);
        this.startdatestr = (this.startdate.getUTCMonth() + 1).toString() + "/" + this.startdate.getUTCDate().toString() + "/" + this.startdate.getUTCFullYear().toString();

    }

    changeEndDate(e: any) {
        var a;
        let adate: Date;

        a = e.target.value;
        this.enddate = new Date(a);
        this.enddatestr = (this.enddate.getUTCMonth() + 1).toString() + "/" + this.enddate.getUTCDate().toString() + "/" + this.enddate.getUTCFullYear().toString();


    }

    employerchange(event) {

        let employerid: string = event;

        this.employerid = employerid;


    }

    logtypechange(event) {

        let logtype: string = event;

        this.logtypeoption = logtype;


    }


    guidEmpty(): string {
        return '00000000-0000-0000-0000-000000000000';
    }


    // GENERAL FUNCTIONS BELOW THIS POINT


    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);


    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }

    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        this.isCountEnabled = !this.isCountEnabled;
        const odataService = this.gridOptions.backendServiceApi.service;

        // @ts-ignore
        odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }

    setOdataVersion(version: number) {
        this.odataVersion = version;
        const odataService = this.gridOptions.backendServiceApi.service;
        // @ts-ignore
        odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        odataService.clearFilters();
        this.angularGrid.filterService.clearFilters();
        return true;
    }
}






