﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../core/environment.service';
import { ExtendedHttpService } from '../core/extendedhttp.service';
import { HttpHeaders } from '@angular/common/http';
import { Http } from '@angular/http';
import { catchError, map } from 'rxjs/operators';
import { OrderViewDTO } from '../dto/OrderViewDTO';
import { EmployerSetupContributionContainerDTO } from '../dto/EmployerSetupContributionContainerDTO';
import { MemberDTO } from '../dto/MemberDTO';
import { MemberDependentDTO } from '../dto/MemberDependentDTO';
import { MemberAndChildrenDTO } from '../dto/MemberAndChildrenDTO';
import { OrderItemDTO } from '../dto/OrderItemDTO';


var authToken = sessionStorage.getItem('auth_token');
// if(authToken){
//     authToken = JSON.parse(authToken);
// }
var httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
    })
};
if(authToken){
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        })
    };
}
@Injectable({
    providedIn: 'root',

})
export class EmployerSetupService extends ExtendedHttpService {

    constructor(private env: EnvironmentService) {
        super();
    }

    // EMPLOYER CONTRIBUTION SERVICE CALLS
    getEmployerContributionList(accountid: string) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/EmployerContribution/' + accountid).
            pipe(
                map((data) => data.json())
            );
    }


    getEmployerContributionDetail(employercontributionid: string) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/EmployerContribution/' + employercontributionid + '/0/0/0/0').
            pipe(
                map((data) => data.json())
            );
    }

    putEmployerContribution(employercontribution: EmployerSetupContributionContainerDTO) {
        let marker: string = "test";

        return this.putData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/EmployerContribution/', employercontribution).
            pipe(
                map((data) => data.json())
            );


    }

    // MEMBER SERVICE CALLS
    getMemberList(accountid: string) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Member/' + accountid).
            pipe(
                map((data) => data.json())
            );
    }

    postMember(member: any) {

        return this.postData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Member/', member).
            pipe(
                map((data) => data.json())
            );
    }

    postBulkMember(bulkData: any) {

        return this.postData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Member/BulkUpload', bulkData).
            pipe(
                map((data) => data.json())
            );
    }

    putMember(member: MemberAndChildrenDTO) {

        return this.putData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Member/', member).
            pipe(
                map((data) => data.json())
            );
    }

    deleteMember(memberid: String) {

        return this.deleteData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Member/' + memberid).
            pipe(
                map((data) => data.json())
            );
    }


    // DEPENDENT SERVICE CALLS
    getDependentList(memberid: string) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Dependent/' + memberid).
            pipe(
                map((data) => data.json())
            );
    }


    putMemberDependent(memberdependent: MemberDependentDTO) {

        return this.putData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Dependent/', memberdependent).
            pipe(
                map((data) => data.json())
            );
    }

    getMemberDependentHistory(id: string) {
        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Dependent/GetMemberDependentHistory/' + id).
            pipe(
                map((data) => data.json())
            );
    }



    // ORDER DETAIL SERVICE CALLS
    putOrderDetail(orderdetail: OrderItemDTO) {

        return this.putData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Order/', orderdetail).
            pipe(
                map((data) => data.json())
            );
    }

    //deleteOrderDetail(order: OrderViewDTO) {

    //    return this.deleteData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/Order/', order.id).
    //        pipe(
    //            map((data) => data.json())
    //        );
    //}



}
