﻿import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EmpPlanComparisonService } from './empplancomparison.service';


import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
} from 'angular-slickgrid';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { ListService } from '../core/list.service';
import { getversionedURl } from '../core/util.service';
import { ResponseBaseDTO } from '../dto/ResponseBaseDTO';
import { Observable, forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ACAPlansByServiceAreaDTO } from '../dto/ACAPlansByServiceAreaDTO';
import { ICEmployerPlanQuoteDTO } from '../dto/ICEmployerPlanQuoteDTO';



@Component({
    templateUrl: 'empplancomparison.html',
    providers: [
        EmpPlanComparisonComponent,
        EmpPlanComparisonService,
        ListService
    ]
})
export class EmpPlanComparisonComponent extends BaseComponent implements OnInit {

    angularGrid: AngularGridInstance;
    metrics: Metrics;

    isCountEnabled = true;
    odataVersion = 2;
    totalRowCount: number = 0;

    name = 'Angular';
    userSelects = [];

    searchError: boolean = false;
    searchErrorMsg: string = "";
    displayMessage: string = "";

    gridObj: any[];

    city: string = "";
    state: string = "";
    zipcode: string = "";
    ratingareaid: string = "";
    effectivedate: Date = new Date();
    effectivedatestr: string = (this.effectivedate.getMonth() + 2).toString() + "/01/" + this.effectivedate.getFullYear().toString();  // Default to the first of the next month

    selectedplanids: string;
    selectedplannames: string;
    selectedissuernames: string;
    numberofSelectedPlans: number = 0;

    useremail: string;
    userobjectid: string;

    planid: string = "";
    planagelist: any[] = [];
    ratefactors: any[] = [];
    rates: any[] = [];

    createdon: Date;
    createdby: string;
    totalmembers: number = 0;
    membernumber: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    // User Input
    currentemployercontribution: number = 0;
    modelcost: number = 0;
    percentageofplancost: number = 0;

    medicarecost: number = 0;
    unitstextarea: string = "";
    displayunittextarea: boolean = false;

    // Computed Averages
    avgfiveyearbands: number = 0;
    avgeveryyear: number = 0;
    avgeveryyearflatten: number = 0;
    avgpercentageofplan: number = 0;

    // Names of Strategies
    strategyname_5year: string = "5 Year Bands";
    strategyname_everyyear: string = "Every Year";
    strategyname_everyyearflatten: string = "Every Year Flatten";
    strategyname_percentageofplan: string = "Percentage of Plan";

    planselectedidx: number = 0;  // indicates which plan has been selected and we are working on

    planname: string = "";
    planList: Plans[] = [];
    currentPlan: Plans;



    calculationstatus: string = "Adjust Info and click Calculate";
    employername: string = "";
    currentemployeemonthlycost: number = 0;
    currendeductible: string = "";
    currentinsurer: string = "";
    brokerid: string = "testbrokerid";
    employerid: string = "testemployerid";
    currenttotalmonthlycost: number = 0;

    plandetail: ICEmployerPlanQuoteDTO = new ICEmployerPlanQuoteDTO();
    calculateisdisabled: boolean = true;


    constructor(
        public empplancomparisonservice: EmpPlanComparisonService,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private spinner: NgxSpinnerService,
        private listservice: ListService,
        private cd: ChangeDetectorRef,
        protected _router: Router,
        private route: ActivatedRoute) {

        super();


        this.route.params.subscribe(params => {
            this.brokerid = params.brokerid;
            this.employerid = params.employerid;
        });

        this.userobjectid = auth.userobjectid;
        this.useremail = auth.useremail;
        this.brokerid = "C565F14A-3F43-4FDF-912B-2571CA86F9FE";
        this.employerid = "2947B83D-CC45-48CB-87F7-02517BAAB427";
        this.clearPlanDetails();
    }




    ngOnInit(): void {

    }


    addtoPlanList(planid: string, issuername: string, planname: string) {
        if (planid !== "") {
            this.planList.push({
                id: planid,
                name: issuername + "-" + planname
            });
            return 1;
        }
        return 0;

    }

    calculate() {

        this.displayMessage = "";

        if (isNaN(this.effectivedate.getDate())) {
            this.searchError = true;
            this.searchErrorMsg = "You must enter a valid effective date";
            return;
        }
        this.pasteunits();
        this.submitEmpPlanQuery();

    }


    submitEmpPlanQuery() {

        this.plandetail.brokerid = this.brokerid;
        this.plandetail.requestaccessid = this.employerid;
        this.plandetail.ownerid = this.employerid;

        this.plandetail.postalcode = this.zipcode;

        this.plandetail.modifiedby = this.employerid;
        this.plandetail.createdby = this.employerid;
        this.plandetail.createdon = new Date();
        this.plandetail.modifiedon = new Date();

        this.plandetail.currentdeductible = this.currendeductible;
        this.plandetail.currentemployeemonthlyrate = this.currentemployeemonthlycost;
        this.plandetail.currentissuer = this.currentinsurer;

        this.plandetail.effectivedate = this.effectivedate;
        this.plandetail.unitsbyage = this.membernumber.toString();
        this.plandetail.totalmembers = this.totalmembers;

        this.empplancomparisonservice.postICEmployerPlanQuote(this.plandetail)
            .subscribe(
                (data) => { this.submitEmpPlanQueryCallback(data); });


    }

    submitEmpPlanQueryCallback(data) {

        this.searchError = false;
        this.displayMessage = "";
        this.searchErrorMsg = "";

        if (data.totalcount > 0) {
            this.plandetail = data.plandetails;
            this.calculateisdisabled = true;
            this.calculationstatus = "Information is Calculated";
        }
        else {
            this.searchError = true;
            this.searchErrorMsg = data.responseMsg;
            this.clearPlanDetails();

        }
    }

    clearPlanDetails() {

        this.plandetail.city = "";
        this.plandetail.state = "";
        this.plandetail.ratingarea = "";
        this.plandetail.postalcode = "";
        this.plandetail.totalcurrentemployeemonthlyrate = 0;
        this.plandetail.planname1 = "";
        this.plandetail.planname2 = "";
        this.plandetail.planname3 = "";
        this.plandetail.planname4 = "";
        this.plandetail.issuername1 = "";
        this.plandetail.issuername2 = "";
        this.plandetail.issuername3 = "";
        this.plandetail.issuername4 = "";
        this.plandetail.singledeductible1 = "";
        this.plandetail.singledeductible2 = "";
        this.plandetail.singledeductible3 = "";
        this.plandetail.singledeductible4 = "";
        this.plandetail.familydeductible1 = "";
        this.plandetail.familydeductible2 = "";
        this.plandetail.familydeductible3 = "";
        this.plandetail.familydeductible4 = "";
        this.plandetail.singleoopmax1 = "";
        this.plandetail.singleoopmax2 = "";
        this.plandetail.singleoopmax3 = "";
        this.plandetail.singleoopmax4 = "";
        this.plandetail.familyoopmax1 = "";
        this.plandetail.familyoopmax2 = "";
        this.plandetail.familyoopmax3 = "";
        this.plandetail.familyoopmax4 = "";
        this.plandetail.totalcost1 = 0;
        this.plandetail.totalcost2 = 0;
        this.plandetail.totalcost3 = 0;
        this.plandetail.totalcost4 = 0;
        this.plandetail.totalcostdifference1 = 0;
        this.plandetail.totalcostdifference2 = 0;
        this.plandetail.totalcostdifference3 = 0;
        this.plandetail.totalcostdifference4 = 0;

    }


    enableCalculate(event) {
        let fldid: string = event.srcElement.id;

        if (fldid === "effectivedatefld") {
            this.effectivedate = new Date(this.effectivedatestr);
            if (isNaN(this.effectivedate.getDate())) {
                this.clearPlanDetails();
                this.searchErrorMsg = "Effective date must be in the format MM/DD/YYYY.";
                this.searchError = true;
                return;
            }
        }
        if (fldid === "zipcodefld" || fldid === "currentemployeemonthlycostfld" || fldid == "effectivedatefld" || fldid === "unitstextareafld") {
            this.clearPlanDetails();
        }
            
        if (this.zipcode !== "" && this.unitstextarea !== "") {
            this.calculateisdisabled = false;
            this.displayMessage = "";
            this.calculationstatus = "Please Click Calculate";
        }

    }


    pasteunits() {

        let textstr: string = "";
        let rows: any;
        let i: number = 0;
        let bdate: Date;
        let memberage: number = 0;
        let month: number = 0;


        if (this.unitstextarea === "")
            return;

        this.totalmembers = 0;

        while (i < 51) {
            this.membernumber[i] = 0;
            i++;
        }
        textstr = this.unitstextarea;

        rows = textstr.split(/\r\n|\n|\r/);
        i = 0;
        while (i < rows.length) {
            bdate = new Date(rows[i]);
            if (!isNaN(bdate.getDate())) {

                memberage = this.effectivedate.getFullYear() - bdate.getFullYear();
                month = this.effectivedate.getMonth() - bdate.getMonth();
                if (month < 0 || (month === 0 && this.effectivedate.getDate() < bdate.getDate())) {
                    memberage--;
                }
                // Since this analysis is for employees only exclude all the units under 18.  Those over 64 will be medicare eligible and will be handled with a different strategy
                if (memberage >= 18 && memberage <= 64) {
                    this.membernumber[memberage - 14] = this.membernumber[memberage - 14] + 1;   // Add another unit to this age
                    this.totalmembers = this.totalmembers + 1;
                }
            }
            i++;
        }
        return;

    }


    seteffectivedate(event) {
        this.displayMessage = "";
        this.effectivedate = new Date(this.effectivedatestr);
        if (isNaN(this.effectivedate.getDate())) {
            this.searchErrorMsg = "Effective date must be in the format MM/DD/YYYY.";
            this.searchError = true;
            return;
        }
        this.enableCalculate(event);

    }





    // THE FOLLOWING METHODS ARE NOT PART OF THE BUSINESS LOGIC

    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};
    }



    selectSuggestion(s) {
        this.userSelects.find((item) => item.id === s.id) ?
            this.userSelects = this.userSelects.filter((item) => item.id !== s.id) :
            this.userSelects.push(s);
        // this.assignToNgModel();
    }



    // THE FOLLOWING METHODS ARE USED FOR PAGING AND FILTERING WHEN APPLICABLE

    goToFirstPage() {
        this.angularGrid.paginationService.goToFirstPage();
    }

    goToLastPage() {
        this.angularGrid.paginationService.goToLastPage();
    }

    setFiltersDynamically() {
        // we can Set Filters Dynamically (or different filters) afterward through the FilterService
        this.angularGrid.filterService.updateFilters([
            // { columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
            { columnId: 'name', searchTerms: ['A'], operator: 'a*' },
        ]);


    }

    setSortingDynamically() {
        this.angularGrid.sortService.updateSorting([
            { columnId: 'name', direction: 'DESC' },
        ]);
    }


    // THE FOLLOWING METHODS ARE ONLY FOR DEMO PURPOSES DO NOT USE THIS CODE
    // ---

    changeCountEnableFlag() {
        //this.isCountEnabled = !this.isCountEnabled;
        //const odataService = this.gridOptions.backendServiceApi.service;

        //// @ts-ignore
        //odataService.updateOptions({ enableCount: this.isCountEnabled } as OdataOption);
        //odataService.clearFilters();
        //this.angularGrid.filterService.clearFilters();
        //return true;
    }

    setOdataVersion(version: number) {
        //this.odataVersion = version;
        //const odataService = this.gridOptions.backendServiceApi.service;
        //// @ts-ignore
        //odataService.updateOptions({ version: this.odataVersion } as OdataOption);
        //odataService.clearFilters();
        //this.angularGrid.filterService.clearFilters();
        //return true;
    }
}






