﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../core/environment.service';
import { ExtendedHttpService } from '../core/extendedhttp.service';
import { HttpHeaders } from '@angular/common/http';
import { Http } from '@angular/http';
import { catchError, map } from 'rxjs/operators';
import { ACAUniquePlanDTO } from '../dto/ACAUniquePlanDTO';
import { IndPlans2021DTO } from '../dto/IndPlans2021DTO';
import { IndividualHealthPlansDTO } from '../dto/IndividualHealthPlansDTO';



const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
@Injectable({
    providedIn: 'root',

})
export class AdminPlansService extends ExtendedHttpService {

    constructor(private env: EnvironmentService) {
        super();
    }


    // JJM 2022
    getPlansByState(state: string, businessyear: number) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/AdministerPlans/' + state + '/' + businessyear).
            pipe(
                map((data) => data.json())
            );
    }


    getPlanById(standardcomponentid: string) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/AdministerPlans/' + standardcomponentid).
            pipe(
                map((data) => data.json())
            );
    }


    // JJM 2022
    getPlanById2021(id: string, businessyear: number) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/AdministerPlans/' + id + '/' + businessyear + '/marker').
            pipe(
                map((data) => data.json())
            );
    }

    // JJM 2022 NOT WEBAPI
    postPlan(planDTO: IndividualHealthPlansDTO) {


        return this.postData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/AdministerPlans/', planDTO).
            pipe(
                map((data) => data.json())
            );


    }



}
