﻿import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../core/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { B2CAuthService } from '../auth/b2cauth.service';
import { EnvironmentService } from '../core/environment.service';
import { Location } from '@angular/common';
import {
    AngularGridInstance,
    Column,
    FieldType,
    Filters,
    Formatters,
    RowSelectionExtension,
    GridOdataService,
    GridOption,
    GridStateChange,
    Metrics,
    OdataOption,
    OperatorType,
    Editors,
    GridMenu,
    GridMenuItem,
    SlickEventHandler,
    SlickEvent,
    SlickEventData,
    AngularSlickgridComponent
} from 'angular-slickgrid';
import * as XLSX from 'xlsx';
import { EBUserDTO } from '../dto/EBUserDTO';
import { TeamDTO } from '../dto/TeamDTO';
import { UserService } from './user.service';
import { GlobaldataService } from '../service/globaldata.service';


const defaultPageSize = 1000;


@Component({
    templateUrl: './userdetail.component.html',

})
export class UserDetailComponent extends BaseComponent implements OnInit {

    constructor(private route: ActivatedRoute,
        protected _router: Router,
        public auth: B2CAuthService,
        private env: EnvironmentService,
        private cd: ChangeDetectorRef,
        private userservice: UserService,
        private globaldataservice: GlobaldataService,
        private location: Location
    ) {
        super();


        this.route.params.subscribe(params => {
            this.addinguser = params.addinguser;
            this.ebuserdto = JSON.parse(params.ebuserdto);

        });

        this.globaldataservice.useremail = auth.useremail;
        this.globaldataservice.userobjectid = auth.userobjectid;
    }

    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;
        this.gridObj = angularGrid && angularGrid.slickGrid || {};



        this.angularGrid.dataView.onRowCountChanged.subscribe((e, args) => this.rowCountChanged(e, args));


    }

    name = 'Angular';
    columnDefinitions: Column[] = [];
    angularGrid: AngularGridInstance;
    gridOptions: GridOption = {};
    gridMenu: GridMenu;
    gridMenuItem: GridMenuItem;
    slickEventHandler: SlickEventHandler;
    slickEvent: SlickEvent;
    slickEventData: SlickEventData;
    _addon: any;
    mydataset: any[] = [];
    gridObj: any;


    searchErrorMsg: string = "";
    displayMessage: string = "";
    file: any;
    arrayBuffer: any;
    filelist: any[];
    totalRowCount: number = 0;

    employerid: string;
    employername: string;
    addinguser: number;
    ebuserdto: EBUserDTO;
    isusersaved: boolean = true;

    teamListDTO: TeamDTO[] = [];


    rolelist: SelectList[] = [
        {
            id: "guest",
            name: "Guest"
        },
        {
            id: "external",
            name: "External"
        },
        {
            id: "admin",
            name: "Administrator"
        }

    ];

    teamlist: SelectList[] = [];


    teamstatuslist: SelectList[] = [
        {
            id: "1",
            name: "Active"
        },
        {
            id: "0",
            name: "Deactivate"
        }

    ];


    primarycontactlist: SelectList[] = [
        {
            id: "Yes",
            name: "Yes"
        },
        {
            id: "No",
            name: "No"
        }

    ];



    ngOnInit(): void {
        this.initEBUserPage();
    }

    initEBUserPage() {
        if (this.ebuserdto == undefined) { // this is not a saved employer
            this.ebuserdto = new EBUserDTO();
            this.isusersaved = false;
        }
        else {
            this.isusersaved = true;
        }
        this.getAvailableTeams();
    }

    getAvailableTeams() {

        this.searchErrorMsg = "";

        this.userservice.getTeams()
            .subscribe(
                (data) => { this.getAvailableTeamsCallBack(data); });

    }


    getAvailableTeamsCallBack(data) {

        this.searchErrorMsg = "";

        if (data.totalcount > 0) {
            this.totalRowCount = data.totalcount;
            this.teamListDTO = data.teamdtolist;
            for (var i = 0; i < this.totalRowCount; i++) {
                this.teamlist.push(
                        {
                        id: this.teamListDTO[i].id,
                        name: this.teamListDTO[i].name
                        }
                )

            }

        }
        else {
            this.totalRowCount = 0;
            this.searchErrorMsg = data.responseMsg;

        }
    }

    saveUser() {
        let adding: number = this.addinguser;

        this.displayMessage = "";
        this.searchErrorMsg = "";
        this.ebuserdto.modifiedon = new Date();
        this.ebuserdto.modifiedby = this.globaldataservice.useremail;

        if (parseInt(adding.toString()) === 1) {
            this.ebuserdto.createdon = this.ebuserdto.modifiedon;
            this.ebuserdto.createdby = this.ebuserdto.modifiedby;
            this.userservice.postUserDetail(this.ebuserdto)
                .subscribe((data) => { this.saveUserCallBack(data) });
        }
        else {
            if (this.ebuserdto.teamname == undefined || this.ebuserdto.teamname == null)
                this.ebuserdto.teamname = "";
            this.userservice.putUserDetail(this.ebuserdto)
                .subscribe((data) => { this.saveUserCallBack(data) });
        }

    }

    saveUserCallBack(data) {

        if (data.responseStatus == 0) {
            this.addinguser = 0;
            this.isusersaved = true;
            this.ebuserdto = data.ebuserdtolist[0];
            if (this.isusersaved)
                this.displayMessage = "Successfully updated user."
            else
                this.searchErrorMsg = "Successfully saved user."
        }
        else {
            this.searchErrorMsg = data.responseMsg;

        }


    }



    rowCountChanged(event, args) {

        console.log("***** rowCountChanged ZZZZZZ");
        // this.totalRowCount = this.angularGrid.dataView.getLength();
    }



    changeUserField() {
        this.isusersaved = false;
    }


    changeRole(e) {

        this.ebuserdto.role = e.value;
        this.isusersaved = false;

    }


    changeTeamStatus(e) {

        this.ebuserdto.teamstatus = e.value;
        this.isusersaved = false;

    }

    changePrimaryContact(e) {

        this.ebuserdto.primarycontact = e.value;
        this.isusersaved = false;

    }

    changeTeam(e) {

        if (e.value == 'none') {
            this.ebuserdto.teamid = "";
            this.ebuserdto.teamuserid = "";
            this.ebuserdto.teamname = "";
        }
        else {
            this.ebuserdto.teamid = e.value;
            this.ebuserdto.teamname = e.textContent;
        }
        this.isusersaved = false;

    }


    backClicked() {

        this.location.back();

    }

}