﻿



  
export class EBUserDTO {
		// This file is generated from the entities located in the MAKTech.Entities project
 
        public id: string;
        public firstname: string;
        public lastname: string;
        public emailaddress: string;
        public createdon: Date;
        public createdby: string;
        public modifiedon: Date;
        public modifiedby: string;
        public displayname: string;
        public role: string;
        public phonenumber: string;
        public primarycontact: string;
        public teamuserid: string;
        public teamid: string;
        public teamname: string;
        public teamstatus: number;
    }
