﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../core/environment.service';
import { ExtendedHttpService } from '../core/extendedhttp.service';
import { HttpHeaders } from '@angular/common/http';
import { Http } from '@angular/http';
import { catchError, map } from 'rxjs/operators';
import { ProductExDTO } from '../dto/ProductExDTO';




const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
@Injectable({
    providedIn: 'root',

})
export class ProductDetailService extends ExtendedHttpService {

    constructor(private env: EnvironmentService) {
        super();
    }



    addProduct(productexdto: ProductExDTO) {

        return this.postData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/ProductV2/', productexdto).
            pipe(
                map((data) => data.json())
            );
    }


    updateProduct(productexdto: ProductExDTO) {

        return this.putData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/ProductV2/', productexdto).
            pipe(
                map((data) => data.json())
            );
    }


    getProduct(productid: string) {

        return this.getData("https://enrichlyhr-api-app.azurewebsites.net/" + 'api/ProductV2/GetProduct/' + productid).
            pipe(
                map((data) => data.json())
            );
    }

}
